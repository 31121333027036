import './styles.scss';

import React from "react";
import PropTypes from 'prop-types';
import KoenigImage from "../../../../components/KoenigImage";
import Slider from '../Slider';
import Icon from '../../../../components/Icon';
import Overlay from '../../../../components/Overlay';

const CSS_CLASS = 'fc-vdp-gallery';

class Gallery extends React.Component {
    static propTypes = {
        images: PropTypes.arrayOf(
            PropTypes.shape({
                color_outside: PropTypes.string.isRequired,
                color_inside: PropTypes.string.isRequired,
                thumbnail_src: PropTypes.string.isRequired,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        src: PropTypes.string.isRequired
                    })
                )
            })
        ).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            openOverlay: false
        }
    }

    handleThumbnailClick(idx) {
        const { onIndexChange } = this.props;

        onIndexChange(idx);
    }

    render() {
        const { images, currentIndex } = this.props;
        const { openOverlay } = this.state;
        const { color_outside } = images[currentIndex];

        return (
            <div className={CSS_CLASS}>
                {this.renderVehicleImages()}
                <div>{'Verfügbare Farben: ' + color_outside}</div>
                {this.renderThumbnails()}
                {openOverlay && this.renderOverlay()}
            </div>
        );
    }

    renderVehicleImages() {
        const { images, currentIndex } = this.props;
        const imageCssClass = CSS_CLASS + '--images';

        return (
            <div className={imageCssClass}>
                <Slider>
                    {
                        images[currentIndex].items.map(({ src, alt }, idx) =>
                            <KoenigImage key={'image_slide_' + idx} fileHash={src} alt={alt} />
                        )
                    }
                </Slider>
                <span className={imageCssClass + '--counter'} onClick={() => this.setState({ openOverlay: true })}>
                    {images[currentIndex].items.length + ' Fotos ansehen'}
                </span>
                <span className={CSS_CLASS + '--foot-note'}>Bild zeigt Beispielabbildung des Fahrzeuges</span>
            </div>
        );
    }

    renderThumbnails() {
        const { images, currentIndex } = this.props;
        const thumbnailClass = CSS_CLASS + '--thumbnails';
        const hasThumbnail = typeof images[0].thumbnail_src === 'string' && images[0].thumbnail_src.length > 0;

        return hasThumbnail && (
            <div className={thumbnailClass}>
                <Slider slidesPresented={5}>
                    {
                        images.map(({ thumbnail_src, color_outside }, idx) => {
                            const classes = [thumbnailClass + '--nail'];

                            if (idx === currentIndex) {
                                classes.push('active');
                            }

                            return (
                                <span key={'thumbnail_slide_' + idx} className={classes.join(' ')}>
                                    <img
                                        src={thumbnail_src}
                                        alt={color_outside}
                                        onClick={this.handleThumbnailClick.bind(this, idx)} />
                                     <hr />
                                     <Icon variant="checkmark" color="white" />
                                </span>
                            );
                        })
                    }
                </Slider>
            </div>
        );
    }

    renderOverlay() {
        const { images, overlayTitle, currentIndex } = this.props;

        return (
            <Overlay onClose={() => this.setState({ openOverlay: false })}>
                <span>{overlayTitle}</span>
                <Slider>
                    {
                        images[currentIndex].items.map(({ src, alt }, idx) =>
                            <KoenigImage key={'image_overlay_slide_' + idx} fileHash={src} alt={alt} />
                        )
                    }
                </Slider>
                <span className={CSS_CLASS + '--foot-note'}>Bild zeigt Beispielabbildung des Fahrzeuges</span>
            </Overlay>
        );
    }
}

export default Gallery;