import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import Breadcrumb from '../../../components/Breadcrumb';
import KoenigButton from '../../../elements/KoenigButton';
import Overlay from '../../../components/Overlay';
import ContactFormBranch from '../../../components/ContactFormBranch';

const MAIN_CSS_CLASS = 'page-garantie-service';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Werkstatt', path: '/werkstatt' },
    { label: 'Garantie- & Servicevertraäge', current_page: true }
];

class GarantieServicePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overlayDieselRueckkauf: false,
            overlayFairrueckGarantie: false,
            showSuccessful: false,
            showFailure: false
        };

        this.handleShowSuccessful = this.handleShowSuccessful.bind(this);
        this.handleShowFailure = this.handleShowFailure.bind(this);
    }

    handleShowSuccessful() {
        this.setState({ showSuccessful: true });
    };

    handleShowFailure() {
        this.setState({ showFailure: true });
    };

    render() {
        const contentCssClass = MAIN_CSS_CLASS + '--content';
        const {
            overlayDieselRueckkauf,
            overlayFairrueckGarantie,
            showSuccessful,
            showFailure
        } = this.state;

        return (
            <CommonPage className={MAIN_CSS_CLASS}>
                <Helmet>
                    <title>Garantie- & Servicevertraäge - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={MAIN_CSS_CLASS + '--headline'}>Abgesichert mit unseren Garantie- & Serviceverträgen</h1>

                <div className={contentCssClass}>
                    <div className={contentCssClass + '--row-1'}>
                        <div>
                            <p><b>Diesel Rückkauf-Zusage - kostenlos!</b></p>
                            <p>
                                Alle Vorteile für Sie! Im Fall eines gesetzlichen Diesel-Fahrverbots kauft das Autohaus König lebenslang das erworbene Diesel-Fahrzeug zurück. Diese Rückkauf-Zusage wurde vom Kunden für 199 EUR beim Autohaus König erworben. Diese Urkunde ist an das erworbene Diesel-Fahrzeug gebunden und trifft zu, wenn im Umkreis von 100 km Entfernung zum Wohnort des Fahrzeugkäufers ein gesetzliches Fahrverbot verhängt wird. Das Fahrverbot muss mindestens 30 Tage pro Jahr bestehen.
                            </p>
                            <p> 
                                Bei Finanzierung / Leasing erfolgt die Rücknahme zur Ablösesumme (ohne Überfinanzierung). Im Fall der Barzahlung wird der Rückkauf zum gültigen DAT- bzw. Schwackewert erfolgen (unter Berücksichtigung möglicher Schäden, sowie Mehr- oder Minderkilometern).
                            </p>
                            <p>
                                Bei der Abrechnung wird die Kilometerlaufleistung aus Finanzierung / Leasing an die verkürzte Laufzeit angepasst. Eine Kombination mit dem KWS FairRück ist zulässig.
                            </p>
                            <p>
                                Die lebenslange Rückkauf-Zusage gilt nur im Zusammenhang mit einem Zahlungsnachweis bzw. einer Rechnungsvorlage.
                            </p>
                            <div className="page-garantie-service--content--row-1--contact">
                                <div>
                                    <span>Sie möchten unseren Service nutzen?</span>
                                    <span>Melden Sie sich einfach bei uns - wir beraten Sie gern:</span>
                                </div>
                                <KoenigButton
                                    text="Anfrage starten"
                                    onClick={() => this.setState({ overlayDieselRueckkauf: true })}
                                />
                            </div>
                        </div>
                        <img
                            src="https://t3api.autohaus-koenig.de/relaunch_img/content/werkstatt/garantie-service/diesel-rueckkaufzusage.png"
                            alt="Diesel Lebenslange Rückkauf Zusage" />
                    </div>
                    <hr />
                    <div className={contentCssClass + '--row-2'}>
                        <div>
                            <p><b>Fairrück Garantie - nur 500 EUR!</b></p>
                            <p>
                                Das Autohaus König deckt bis zu 100% die Schäden Ihres Fahrzeugs bei Rückgabe - bei Leasing oder Inzahlungnahme.
                            </p>
                            <p>
                                Bei bis zu 1.000 EUR sind 100% durch die FairRück abgedeckt. Bei Schäden über 1.000 EUR sind zu 50% abgedeckt.
                            </p>
                            <p>
                                Bei einer einmaligen Zahlung von 500 EUR genießen Sie den vollen Service der FairRück Garantie und müssen sich keine Gedanken mehr machen bei Rückgabe Ihres Fahrzeugs.
                            </p>
                            <div className="page-garantie-service--content--row-1--contact">
                                <div>
                                    <span>Sie möchten unseren Service nutzen?</span>
                                    <span>Melden Sie sich einfach bei uns - wir beraten Sie gern:</span>
                                </div>
                                <KoenigButton
                                    text="Anfrage starten"
                                    onClick={() => this.setState({ overlayFairrueckGarantie: true })}
                                />
                            </div>
                        </div>
                        <img
                            src="https://t3api.autohaus-koenig.de/relaunch_img/content/werkstatt/garantie-service/fairrueck-rueckgabeschaeden.png"
                            alt="FairRück! bis zu 100% Abdäckung von Rückgabeschäden" />
                    </div>
                </div>
                {overlayDieselRueckkauf ? this.renderOverlayDieselRueckkauf() : null}
                {overlayFairrueckGarantie ? this.renderOverlayFairrueckGarantie() : null}
                {showSuccessful ? this.renderOverlaySuccessful() : null}
                {showFailure ? this.renderOverlayFailure() : null}
            </CommonPage>
        );
    }

    renderOverlayDieselRueckkauf = () => (
        <Overlay
            onClose={() => this.setState({ overlayDieselRueckkauf: false })}
        >
            <ContactFormBranch
                title="Diesel Rückkauf-Zusage beantragen"
                developedFrom="Diesel Rückkauf-Zusage beantragen"
                onSuccessful={this.handleShowSuccessful}
                onFailure={this.handleShowFailure}
            />
        </Overlay>
    );

    renderOverlayFairrueckGarantie = () => (
        <Overlay
            onClose={() => this.setState({ overlayFairrueckGarantie: false })}
        >
            <ContactFormBranch
                title="Fairrück Garantie - nur 500 EUR!"
                developedFrom="Fairrück Garantie - nur 500 EUR!"
                onSuccessful={this.handleShowSuccessful}
                onFailure={this.handleShowFailure}
            />
        </Overlay>
    );

    renderOverlaySuccessful = () => (
        <Overlay className="successful">
            <h1>Vielen Dank für Ihre Anfrage!</h1>
            <p>
                Schön, dass Sie sich für unser Angebot interessieren.<br />
                Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
                    </p>
            <p>Ihr Autohaus König</p>

            <KoenigButton
                className="btn-back-to-page"
                text="Zurück zur Fahrzeugseite"
                onClick={() => this.handleVisibleState()}
            />
        </Overlay>
    );

    renderOverlayFailure = () => (
        <Overlay className="failure">
            <h1>Vielen Dank für Ihre Anfrage!</h1>
            <p>
                Leider ist ein Problem aufgetreten.<br />
                Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                    </p>
            <p>
                <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
            </p>
            <p>Ihr Autohaus König</p>

            <KoenigButton
                className="btn-back-to-page"
                text="Zurück zur Fahrzeugseite"
                onClick={() => this.handleVisibleState()}

            />
        </Overlay>
    );

    handleVisibleState = () => {
        return this.setState({ 
            showSuccessful: false, 
            overlayDieselRueckkauf: false, 
            overlayFairrueckGarantie: false 
        })
    };
}

export default GarantieServicePage;