export const getNumberRepresentation = starRating => {
    switch (starRating) {
        case 'FOUR':
            return 4;
        case 'FIVE':
            return 5;
        case 'THREE':
            return 3;
        case 'TWO':
            return 2;
        case 'ONE':
            return 1;
        default:
            return 0;
    }
}

export const getWordRepresentation = (number) => {
    switch (number) {
        case 5:
            return 'Sehr gut';
        case 4:
            return 'Gut';
        case 3:
            return 'Befriedigend';
        case 2:
            return 'Mangelhaft';
        case 1:
            return 'Ungenügend';
    }
}