import "./styles.scss";

import React from 'react';
import CommonPage from '../../../CommonPage';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../../../components/Breadcrumb';
import StepIndicator from '../../../../components/StepIndicator/index';
import SectionHeadline from "../../../../components/SectionHeadline";
import ShoppingBasket from "../../../../components/SimpleShop/components/ShoppingBasket";
import NavigationHelper from "../../../../helper/navigation.helper";

class StepPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSummaryVisible: false
        };
    }

    handleShowSummary() {
        if (typeof window !== 'undefined' && window.scrollTo) {
            window.scrollTo(0, 0);
        }

        this.setState({ isSummaryVisible: true });
    }

    render() {
        const { shopUrl, postData } = this.props;
        const { isSummaryVisible } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Produktauswahl', onClick: NavigationHelper.linkTo.bind(null, shopUrl, postData) },
            { label: 'Checkout', current_page: true }
        ];

        const indicatorSteps = [
            this.props.steps,
            ['Persönliche Daten', 'Prüfen & Bestellen']
        ];

        const contentClasses = ['content-wrapper'];
        if (isSummaryVisible) {
            contentClasses.push('to-the-left');
        }

        return (
            <CommonPage className={['page-simple-shop', this.props.className].join(' ')}>
                <Helmet>
                    <title>Checkout - König Shop</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                {
                    isSummaryVisible === true
                        ? this.renderBackToOrderButton()
                        : this.renderShoppingBasketButton()
                }

                <h1>Ihre Bestellung in nur 2 Schritten</h1>

                <StepIndicator steps={indicatorSteps} style={{ maxWidth: '16.25rem', margin: '1.5rem auto 2.5rem' }} />

                <div className={contentClasses.join(' ')}>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </div>
            </CommonPage>
        );
    }

    renderShoppingBasketButton = () => {
        const { shop_items = [] } = this.props;
        const cssShoppingBasket = 'simple-shop--button--shopping-basket';

        return (
            <React.Fragment>
                <div className={cssShoppingBasket} onClick={this.handleShowSummary.bind(this)}>
                    Warenkorb anzeigen
                     <div className={cssShoppingBasket + '--item-ad'}>{shop_items.length}</div>
                </div>
                <div className={cssShoppingBasket + ' fixed-position-helper'}></div>
            </React.Fragment>
        );
    }

    renderBackToOrderButton = () => {
        const cssBackToOrder = 'simple-shop--button--back-to-order';

        return (
            <React.Fragment>
                <div
                    className={cssBackToOrder}
                    onClick={() => this.setState({ isSummaryVisible: false })}
                >
                    zurück zur Buchung
                </div>
                <div className={cssBackToOrder + ' fixed-position-helper'}></div>
            </React.Fragment>
        );
    }

    renderLeftColumn() {
        const { children } = this.props;

        return (
            <div className="left-column">
                {children}
            </div>
        );
    }


    renderRightColumn() {
        const {
            shop_items,
            onQuantityIncrement,
            onQuantityDecrement,
            itemLimit
        } = this.props;

        return (
            <div className="right-column">
                <ShoppingBasket
                    shopItems={shop_items}
                    itemLimit={itemLimit}
                    onQuantityIncrement={onQuantityIncrement}
                    onQuantityDecrement={onQuantityDecrement} />

                <SectionHeadline text='Zahlungs-/ Versandabwicklung' />
                Nach Abschluss der Buchung ist die Gesamtsumme
                über unseren Abrechnungsparter (TeleCash) per
                Kreditkarte, PayPal oder Sofortüberweisung zu
                begleichen. Nach der Buchung werden Sie für den
                Zahlungsabschluss zu TeleCash weitergeleitet.
                Die Lieferung erfolgt innerhalb der nächsten 7
                Werktage an die von Ihnen angegebene Adresse.
            </div>
        );
    }
}

export default StepPage;
