import React from 'react';
import CompanyLogo from '../../CompanyLogo';

export default props => (
    <div id="navigation_section">
        <CompanyLogo />

        <div id="bottom_navigation">
            <a href="/datenschutz/">Datenschutz</a>
            <a href="/agb/">AGB</a>
            <a href="/impressum/">Impressum</a>
        </div>

    </div>
);