import './styles.scss';

import React from 'react';
import RadioButtonGroup from '../../../../elements/RadioButtonGroup';
import KoenigInput from '../../../../elements/KoenigInput';
import KoenigButton from '../../../../elements/KoenigButton';
import NavigationHelper from '../../../../helper/navigation.helper';
import ApiHelper from '../../../../helper/api.helper';
import { renderToString } from 'react-dom/server'

const CSS_CLASS = "unsubscribe-form";

class Abmeldeformular extends React.Component {

    constructor(props) {
        super(props);

        const choices = [
            'Der Newsletter kommt zu oft',
            'Die Inhalte sind für mich nicht relevant',
            'Die E-Mail wird nicht richtig dargestellt',
            'Sonstiges'
        ];

        this.state = {
            reason: choices[0],
            otherReason: '',
            email: null,
            choices
        }
    }

    handleSelection(idx) {
        const { otherReason, choices } = this.state;
        let reason = choices[idx];

        if (idx === 3) {
            reason += ': ' + otherReason;
        }

        this.setState({ reason });
    }

    async handleSubmit() {
        const { email, reason } = this.state;

        await ApiHelper
            .POST('/unsubscriptions', { email, reason })
            .then(() => this.submitCleverReachForm())
            .catch(console.error)
    }

    render() {
        const { choices } = this.state;

        return (
            <div className={CSS_CLASS}>
                <p className={CSS_CLASS + '--description'}>ABMELDUNG: Es tut uns Leid, dass Sie uns verlassen wollen. Vielleicht können wir Ihnen helfen, wenn Sie uns den Grund nennen.</p>

                <RadioButtonGroup
                    selectedIndex="0"
                    groupItems={choices}
                    onChange={this.handleSelection.bind(this)}
                />
                <div className={CSS_CLASS + '--other-reason'}>
                    <textarea
                        maxLength="400"
                        onChange={ev => this.setState({ otherReason: ev.target.value })} />
                    <div className={CSS_CLASS + '--character-limit'}>(Maximal 400 Zeichen)</div>
                    <KoenigInput
                        label="Ihre E-Mail-Adresse"
                        onChange={email => this.setState({ email })}
                        isEmailField
                        isRequired />
                    <KoenigButton
                        text="Ja, ich möchte keine attraktiven Angebote mehr erhalten"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.isEmailValid()} />
                    <div className={CSS_CLASS + '--foot-note'}>(Mit Klick auf diesen Button, melden Sie sich vom Newsletter ab)</div>
                </div>
            </div>
        );
    }

    isEmailValid = () => {
        const { email } = this.state;

        return email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    submitCleverReachForm() {
        if (window && window.document) {
            const { email } = this.state;
            const form = document.createElement('form');
            const input = document.createElement('input');

            input.setAttribute('name', 'email');
            input.setAttribute('id', 'text4425086');
            input.setAttribute('value', email);

            form.appendChild(input);
            form.setAttribute('action', 'https://seu1.cleverreach.com/f/61692-209160/wcu/');
            form.setAttribute('method', 'POST');

            document.body.appendChild(form);

            form.submit();
        }
    }
}

export default Abmeldeformular;