import './src/icon.scss';
import iconSprite from '../../assets/images/icon-sprite.svg';

import React from 'react';

const icons = {
    search: '48 48 48 48',
    fav_heart: '96 0 48 48',
    menu: '144 48 48 48',
    close: '0 0 48 48',
    chevron_right: '240 48 48 48',
    chevron_left: '288 0 48 48',
    black_chevron_down: '96 96 48 48',
    black_chevron_left: '0 96 48 48',
    black_chevron_right: '48 144 48 48',
    black_calendar_sheet: '144 144 48 48',
    add: '336 48 48 48',
    black_add: '192 96 48 48',
    black_favorite_bordered: '240 144 48 48',
    vehicle_small_compact: '384 96 48 48',
    wife_hand: '432 48 48 48',
    location_pin: '432 144 48 48',
    trash: '480 0 48 48',
    phone: '288 96 48 48',
    email: '336 144 48 48',
    profile: '480 96 48 48',
    driver_license: '528 48 48 48',
    edit: '528 144 48 48',
    circle_play: '576 0 48 48',
    checkmark: '576 96 48 48',
    branch_schedule: '624 48 48 48',
    vehicle_identifying: '624 144 48 48',
    pkw_lkw_combi: '672 0 48 48',
    bold_add: '672 96 48 48',
    schedule: '720 48 48 48',
    bank_11: '720 144 48 48',
    to_top: '768 0 48 48',
    crown: '768 96 48 48',
    speedo: '816 48 48 48',
    gearbox: '816 144 48 48',
    gas_can: '864 0 48 48',
    rci: '907 48 48 48',
    shopping_basket: '864 96 48 48',
    star: '907 144 48 48',
    door: '955 96 48 48',
    gas_station: '955 0 48 48',
    share: '1009 48 48 48',
    branch: '1009 146 48 48',
    book: '1056 0 48 48',
    three_sixty_degrees: '1056 96 48 48',
    video: '1100 48 48 48',
    caution_info: "1104 144 48 48"
};

export default props => {
    const { variant, className, style, color = 'dark' } = props;
    const viewBox = icons[variant];
    const classes = ['icon'];
    let fill;

    switch(color) {
        case 'white':
            fill = '#ffffff';
            break;
        case 'green':
            fill = '#7cb932';
            break;
        default:
            fill = '#3b3b3b';
    }

    if (className && className.length > 0) {
        classes.push(className);
    }

    return (
        <span className={classes.join(' ')} onClick={props.onClick} data-variant={variant} style={style}>
            <svg viewBox={viewBox} preserveAspectRatio="xMidYMid meet">
                <use xlinkHref={iconSprite + '#' + variant} fill={fill} />
            </svg>
        </span>
    );
};