import './styles.scss';

import React from 'react';
import Icon from '../../../../components/Icon';
import NumberFormater from '../../../../helper/NumberFormater';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/de';
import {GEAR_TYPES, PROVISION_TYPES, STATUS_TYPES} from '../../../../helper/constant.helper';
import KoenigSelect from '../../../../elements/KoenigSelect';
import CurrencyHelper from '../../../../helper/Currency';
import ReactHtmlParser from 'react-html-parser';
import Hint from '../Hint';
import {getKwsInfo, getInsuranceInfo} from '../PaymentTabs/infos';

const CSS_CLASS = 'fc-vdp-offerdetails';

class OfferDetails extends React.Component {
    handleColorChange(options, color) {
        const { onIndexChange } = this.props;
        const index = options.indexOf(color);

        onIndexChange && onIndexChange(index);
    }

    render() {
        const { vehicle = {} } = this.props;

        return (
            <div className={CSS_CLASS}>
                {this.renderIcons(vehicle)}
                {this.renderDetailContainer(vehicle)}
                {this.renderSelects(vehicle)}
            </div>
        );
    }

    renderIcons(vehicle) {
        const { door_count, gear_type, power, fuel_type } = vehicle;

        return (
            <div className={CSS_CLASS + '--icons'}>
                {this.renderIcon('speedo', power)}
                {this.renderIcon('gas_station', fuel_type)}
                {this.renderIcon('door', door_count + ' Türen')}
                {this.renderIcon('gearbox', GEAR_TYPES[gear_type])}
            </div>
        );
    }

    renderIcon(variant, text) {
        return (
            <span>
                <Icon variant={variant} color="green"/>
                {text}
            </span>
        );
    }

    renderDetailContainer(vehicle) {
        return (
            <div className={CSS_CLASS + '--detail-cnt'}>
                {this.renderDetails(vehicle)}
                {this.renderColorPicker(vehicle)}
            </div>
        );
    }

    renderDetails(vehicle) {
        const {
            status_type, provision_type, mileage,
            initial_registration, is_business
        } = vehicle;
        const initialRegistration = initial_registration ? moment(initial_registration).format("MM / YYYY") : '-';

        return (
            <div className={CSS_CLASS + '--details'}>
                {this.renderDetail('Bereitstellungszeit:', PROVISION_TYPES[provision_type])}
                {this.renderDetail('Angebotstyp:', STATUS_TYPES[status_type])}
                {this.renderDetail('Angebot für:', is_business ? 'Gewerbe' : 'Privat/Gewerbe')}
                {this.renderDetail('Erstzulassung:', initialRegistration)}
                {this.renderDetail('Kilometerstand:', NumberFormater.addNumberSeparator(mileage || 0) + ' km')}
            </div>
        );
    }

    renderDetail(label, value) {
        return (
            <span>
                <span>{label}</span>
                <span>{value}</span>
            </span>
        );
    }

    renderColorPicker(vehicle) {
        const { currentIndex } = this.props;
        const { images } = vehicle;
        const options = images.reduce((res, { color_outside }) => {
            res.push(color_outside);

            return res;
        }, []);

        return (
            <div className={CSS_CLASS + '--color-picker'}>
                <span>Wählen Sie Ihre <b>Wunschfarbe:</b></span>
                <KoenigSelect
                    value={images[currentIndex].color_outside}
                    options={options}
                    onChange={color => this.handleColorChange(options, color)}/>
                <span>Innenfarbe: {images[currentIndex].color_inside}</span>
            </div>
        );
    }

    renderSelects(vehicle) {
        const { kws_option, insurance_option } = vehicle;
        const { onChangeKws, onChangeInsurance } = this.props;
        const hasOption = insurance_option.rates.length > 0 || kws_option.rates.length > 0;

        return hasOption && (
            <div className={CSS_CLASS + '--select-cnt'}>
                {
                    kws_option.rates.length > 0 && this.renderSelect(
                        'Sie möchten unseren komfortablen <b>König Werkstattservice</b> zubuchen?',
                        kws_option,
                        onChangeKws,
                        getKwsInfo(),
                        'ohne König-Werkstatt-Service'
                    )
                }
                {
                    insurance_option.rates.length > 0 && this.renderSelect(
                        'Buchen Sie eine günstige <b>KFZ-Versicherung</b> hinzu:',
                        insurance_option,
                        onChangeInsurance,
                        getInsuranceInfo(),
                        'ohne KFZ-Versicherung'
                    )
                }
            </div>
        );
    }

    renderSelect(label, option = {}, onChange, defaultInfo, withoutOption) {
        const { rates = [], info } = option;
        let options = rates.reduce((res, { type, rate }) => {
            if (type && rate) {
                res.push(`${type} ${CurrencyHelper.toCurrency(rate)} €`);
            }

            return res;
        }, []);
        options = [withoutOption, ...options];

        return (
            <span>
                <span>{ReactHtmlParser(label)}<Hint text={info || defaultInfo}/></span>
                <KoenigSelect
                    value="Bitte wählen"
                    options={options}
                    onChange={onChange}/>
            </span>
        );
    }
}

export default OfferDetails;
