import React from 'react';
import PropTypes from 'prop-types';

class Tab extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired
    };

    onClick() {
        const { label, className, onClick = () => { } } = this.props;

        if (className && className.indexOf('disabled') !== -1) {
            return;
        }

        onClick(label);
    }

    render() {
        const { activeTab, label, className } = this.props;
        let classes = [className];

        if (activeTab === label) {
            classes.push('active');
        }

        return (
            <li
                className={classes.filter(c => c !== null).join(' ')}
                onClick={this.onClick.bind(this)}
            >
                {label}
            </li>
        );
    }
}

export default Tab;