import React from 'react';
import './styles.scss';

import PropTypes from 'prop-types';

const UsedCarButton = (props) => {
	const setButtonType = () => {
		if (props.variant === 'default') {
			return 'default';
		}

		if (props.variant === 'grey') {
			return 'grey';
		}

		return 'default';
	};

	return (
		<button
			onClick={props.onClick ? props.onClick : null}
			disabled={props.disabled}
			className={`gw-btn gw-btn--${setButtonType()} ${props.addClass}`}>
			{props.linkTo && !props.disabled ? (
				<a
					href={props.linkTo}
					target={props.targetTo}
					className='gw-btn__link'>
					{props.title}
				</a>
			) : (
				<span>{props.title}</span>
			)}
		</button>
	);
};

export default UsedCarButton;

UsedCarButton.defaultProps = {
	addClass: '',
	disabled: false,
	targetTo: '_self',
}

UsedCarButton.propTypes = {
	title: PropTypes.string.isRequired,
	linkTo: PropTypes.string,
	targetTo: PropTypes.string,
	variant: PropTypes.string,
	addClass: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};
