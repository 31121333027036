import "./styles.scss";

import React from 'react';
import CommonPage from "../../CommonPage";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/Breadcrumb";
import Icon from "../../../components/Icon";
import InfoBox from "../../../components/InfoBox";
import Functions from "../../../components/SimpleShop/functions";
import KoenigButton from "../../../elements/KoenigButton";
import moment from "moment";

import "moment/locale/de";
import "moment-timezone";

const CSS_CLASS = 'simple-shop--successful';
const CSS_CONFIRMATION = CSS_CLASS + '--confirmation';
const CSS_SUMMARY = CSS_CLASS + '--summary';

class ErfolgreichPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    render() {
        const {
            salutation, firstname, lastname,
            address, zip_code, location,
            email, phone
        } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Bestellbestätigung', current_page: true }
        ];

        return (
            <CommonPage className="page-simple-shop-erfolgreich">
                <Helmet>
                    <title>Bestellbestätigung - Checkout - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <div className={CSS_CLASS}>
                    <h1 className={CSS_CLASS + '--headline'}>Wir haben Ihre Bestellung erhalten.</h1>

                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="checkmark"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Bestellung abgeschlossen</b>
                    </div>

                    <div className={CSS_CONFIRMATION}>
                        <h2 className={CSS_CONFIRMATION + '--headline'}>Vielen Dank</h2>
                        <p className={CSS_CONFIRMATION + '--text'}>Wir werden Ihre Bestellung umgehend bearbeiten und Sie erhalten in wenigen Minuten zur Bestätigung Ihrer Bestellung eine E-Mail an die von Ihnen angegebene E&#8209;Mail&#8209;Adresse:</p>
                        <b className={CSS_CONFIRMATION + '--email'}>{email}</b>
                        <p className={CSS_CONFIRMATION + '--spam-hint'}>Sollten Sie diese E-Mail nicht in Ihrem Posteingang finden, überprüfen Sie bitte auch Ihren Spam-Ordner.</p>
                    </div>

                    <h2 className={CSS_CLASS + '--headline--overview'}>Übersicht Ihrer abgeschlossenen Bestellung</h2>

                    <div className={CSS_CLASS + '--info-box--wrapper'}>
                        <InfoBox
                            label="Ihre persönlichen Daten"
                            className={CSS_CLASS + '--info-box--personal-data'}
                            headline={`${salutation} ${firstname} ${lastname}`}
                            infoItems={[
                                [
                                    { "Adresse": [address, `${zip_code} ${location}`] }
                                ],
                                [
                                    { "E-Mail": email },
                                    { "Telefon": phone }
                                ]
                            ]} />
                    </div>

                    {this.renderSummary()}

                    <KoenigButton
                        className={CSS_CLASS + '--home-link'}
                        text="Zurück zur Startseite"
                        linkTo="/" />
                </div>


            </CommonPage>
        );
    }

    renderSummary() {
        const { shop_items = [], shipping_costs} = this.state;
        const totalPrice = Functions.getTotalPrice(shop_items, shipping_costs);

        return (
            <div className={CSS_SUMMARY}>
                <div className={CSS_SUMMARY + '--row'}>
                    <b className={CSS_SUMMARY + '--label'}>Ausgewählte Artikel im Überblick</b>
                </div>

                <div className={CSS_SUMMARY + '--listing'}>
                    {
                        shop_items.map(({ quantity, product: item }, idx) => {
                            const classes = [CSS_SUMMARY + '--listing--item'];
                            const itemPrice = Functions.getItemPrice(item);

                            return (
                                <div key={`simple-shop-step2-summary-item-${idx}`} className={classes.join(' ')}>
                                    <b className={CSS_SUMMARY + '--listing--item--title'}>{item.title}</b>
                                    <div className={CSS_SUMMARY + '--listing--item--amount'}>
                                        {quantity}x
                                    </div>
                                    <div className={CSS_SUMMARY + '--listing--item--price'}>
                                        <span className={CSS_SUMMARY + '--listing--item--price--default'}>{Functions.toCurrency(itemPrice)} €</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={CSS_SUMMARY + '--total'}>
                    <div className={CSS_SUMMARY + '--total--column'}>
                        <span className={CSS_SUMMARY + '--total--vat-hint'}>inkl. 19% MwSt.</span>
                        <b className={CSS_SUMMARY + '--total--text'}>Summe</b>
                    </div>

                    <div className={CSS_SUMMARY + '--total--price'}>
                        <b>{this.state.payment?.chargetotal || Functions.toCurrency(totalPrice)}</b>
                        <b>€</b>
                    </div>
                </div>

                {this.renderPaymentInformations()}
            </div>
        );
    }

    renderPaymentInformations() {
        const { payment = {} } = this.state;
        let paymentInformation;

        if (payment.txndate_processed) {
            const processedDate = moment(payment.txndate_processed, 'DD.MM.YYYY HH:mm:ss');

            if (processedDate.isValid()) {
                const newDateFormat = processedDate.format('DD.MM.YYYY-HH:mm');
                const [date, time] = newDateFormat.split('-');

                paymentInformation = `Sie haben diesen Betrag am ${date} um ${time} Uhr online bezahlt.`;
            }
        } else {
            return null;
        }

        return <b className={CSS_SUMMARY + '--payment-informations'}>{paymentInformation}</b>;
    }
}

export default ErfolgreichPage;
