import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import Breadcrumb from '../../../components/Breadcrumb';
import SectionHeadline from '../../../components/SectionHeadline';
import AppointmentLink from '../../../components/AppointmentLink';
import KoenigButton from '../../../elements/KoenigButton';
import API from '../../../helper/api.helper';
import KoenigImage from '../../../components/KoenigImage';

const crumbs = [
    {label: 'Start', path: '/'},
    {label: 'Werkstatt', path: '/werkstatt'},
    {label: 'Aktion', current_page: true},
];

class AktionenPage extends React.Component {
    constructor(props) {
        super(props);

        let page = [];

        if (props.staticContext && props.staticContext.initialData) {
            page = props.staticContext.initialData;
        } else {
            page = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            page: page,
        };
    }

    static requestInitialData(url) {
        if (url === '/werkstatt/aktion/') {
            return API
                .GET('/pages/werkstatt_aktion')
                .catch(err => {
                    console.error(err);
                    return [];
                });
        }
    }

    render() {
        const pageData = this.state.page;

        return (
            <CommonPage className="page-werkstatt-aktion">
                <Helmet>
                    <title>Werkstatt-Aktionen des Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs}/>

                <h1>{pageData.headline}</h1>
                {this.renderContentImages()}

                {pageData.aktionen && pageData.aktionen.length > 0 &&
                    <SectionHeadline text={pageData.section_headline}/>
                }
                <section className="action-tiles">
                    {
                        pageData.aktionen.map((tile, idx) => tile.type === "inline-teaser"
                            ? <InlineTeaser key={`action-tile-${idx}`} {...tile}/>
                            : <PageTile key={`action-tile-${idx}`} {...tile} />
                        )
                    }
                </section>

                <div className="page-werkstatt-aktion--content-cnt">
                    <div className="page-werkstatt-aktion--content-cnt--content-col1">
                        <AppointmentLink/>
                    </div>
                    <div className="page-werkstatt-aktion--content-cnt--content-col2">
                        <b>Jeden Monat neue Angebote im Bereich Service und Zubehör</b><br/>
                        <p>Zwölf mal im Jahr überlegen wir uns für Sie ganz besondere Service- und Werkstattangebote,
                            die Sie bei uns besonders günstig erhalten. Ob Reifenwechsel im Frühling, Urlaubscheck im
                            Sommer oder die Standheizung für den Winter - bei uns sollten Sie jeden Monat gespannt
                            sein, welche Leistungen wir als besonderes Angebot anbieten.</p>
                        <p>Es lohnt sich in jedem Fall für Sie auf dem Laufenden zu bleiben - abonnieren Sie dazu ganz
                            einfach unseren kostenlosen Newsletter und profitieren Sie von günstigen Angeboten,
                            aktuellen Aktionen oder spannenden Themen rundum Ihr Autohaus.</p>
                        <a className="link_newsletter" href="/newsletter/">Abonnieren Sie jetzt den kostenlosen
                            Newsletter</a>
                    </div>
                </div>
            </CommonPage>
        );
    }

    renderContentImages() {
        const {title_src = {}, headline = ''} = this.state.page;
        const {mobile = '', desktop = ''} = title_src;
        const CSS_CLASS = 'page-werkstatt-aktion--content-image';
        return (
            <React.Fragment>
                {mobile.length > 0 ? <KoenigImage className={CSS_CLASS + ' ' + CSS_CLASS + '--mobile'} fileHash={mobile}
                                                  alt={headline}/> : null}
                {desktop.length > 0 ?
                    <KoenigImage className={CSS_CLASS + ' ' + CSS_CLASS + '--desktop'} fileHash={desktop}
                                 alt={headline}/> : null}
            </React.Fragment>
        );
    }
}


function PageTile({img_src, img_alt = "", headline_1, headline_2, description, button, link_to}) {
    return (
        <article className="page-tile">
            <header>
                <KoenigImage fileHash={img_src} className="img-responsive" alt={img_alt}/>
                <h2>{headline_1}<br/>{headline_2}</h2>
            </header>

            <p className="tile-text">{description}</p>

            <footer>
                <KoenigButton
                    text={button ? button : "Jetzt Termin vereinbaren"}
                    linkTo={link_to ? link_to : "/werkstatt/terminbuchung"}/>
            </footer>
        </article>
    );
}

function InlineTeaser(props) {
    return (
        <a href={props.link_to} className="inline-teaser" target="_blank">
            <KoenigImage fileHash={props.img_src} alt={props.img_alt}/>
        </a>
    );
}

export default AktionenPage;

