import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import KoenigButton from '../../elements/KoenigButton';
import Checkbox from '../../elements/Checkbox';
import KoenigSelect from '../../elements/KoenigSelect';
import KoenigInput from '../../elements/KoenigInput';
import API from '../../helper/api.helper';

const MAIN_CSS_CLASS = 'page-vip';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Vip Opening Halle', current_page: true },
];

class VipPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salutation: null,
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            participation: null,
            agb: false,
            showSuccessful: false,
            showFailure: false
        };
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    sendInquery = () => {
        const developed_from = 'Vip Opening - Halle';
        const data = {
            ...this.state,
            developed_from
        };
        delete data.showSuccessful;
        delete data.showFailure;

        API
            .POST('/registrations/vip-halle', data)
            .then(res => {
                this.setState({ showSuccessful: true });
            }).catch(error => {
                this.setState({ showFailure: true });
            });
    }

    render() {
        const contentCssClass = MAIN_CSS_CLASS + '--content';

        return (
            <CommonPage className={MAIN_CSS_CLASS}>
                <Helmet>
                    <title>Vip Opening Halle - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>VIP Opening im Autohaus König in Halle</h1>
                <img className="header-img" src="https://t3api.autohaus-koenig.de/relaunch_img/content/vip-page/vip-halle.jpg" />
                <h2>
                    Wir laden Sie herzlich ein zur offiziellen Kia Markeneinführung<br />
                    in Halle auf dem MAFA-Gelände am 10.01.2020
                </h2>
                <div className={contentCssClass}>
                    <div className={contentCssClass + '--col1'}>
                        <p><b>Das dürfen Sie nicht verpassen!</b><br />
                            Seien Sie unser Gast und genießen Sie einen exklusiven VIP Abend in unserem Autohaus König in Halle. Gemeinsam mit Ihnen möchten wir einen ganz besonderen Abend in den alten Fabrikhallen des MAFA-Geländes zelebrieren und Ihnen das gesamte Portfolio unserer Markenpalette präsentieren, allen voran natürlich die neue Marke unseres Hauses - KIA.</p>
                        <p>Freuen Sie sich auf außergewöhnliche Show-Acts, feinste Speisen und Drinks in einem einzigartigen Ambiente - für Sie natürlich alles kostenfrei. Gern können Sie Ihre Begleitung mitbringen, füllen Sie dafür bitte einfach das untenstehende Anmeldeformular aus.</p>
                        <p>Nach Ihrer Anmeldung, erhalten Sie von uns die Anmeldebestätigung an Ihre angegebene E-Mail-Adresse, sowie ein kostenfreies Parkticket als PDF zum Selbstausdrucken. Dieses berechtigt Sie, während der Veranstaltung vorhandene Parkflächen kostenfrei zu nutzen.</p>
                    </div>
                    <div className={contentCssClass + '--col2'}>
                        <div className={contentCssClass + '--col2--addressbox'}>
                            <KoenigButton text="Melden Sie sich jetzt an" linkTo="#toForm" />
                            <p><b>Autohaus König in Halle</b><br />
                                Merseburger Straße 10<br />
                                (Anfahrt über Turmstraße 151)<br />
                                06110 Halle</p>

                            <p><b>Vip-Abend:</b><br />
                                <b>Einlass ab 18 Uhr (Beginn 19 Uhr)</b></p>
                        </div>
                    </div>
                </div>
                <h3 id="toForm">Melden Sie sich hier ganz einfach an:</h3>
                {this.renderForm()}
            </CommonPage>
        );
    }

    isValid = () => {
        const allValuesAreSet = [
            'salutation', 'firstname', 'lastname', 'email', 'phone', 'participation'
        ].reduce((valid, key) => valid && this.state[key] !== null && this.state[key].length > 0, true);
        return allValuesAreSet && this.state.agb === true;
    }

    renderForm() {
        const formCssClass = MAIN_CSS_CLASS + '--form';
        const {
            showSuccessful,
            showFailure
        } = this.state;
        const personDataInputs = [
            ['firstname', 'Vorname'],
            ['lastname', 'Nachname'],
            ['email', 'E-Mail-Adresse'],
            ['phone', 'Telefonnummer']
        ];
        const participations = [
            'Ich komme allein',
            'Ich komme in Begleitung',
            'Ich komme nicht',
        ]

        if (showSuccessful) {
            return this.renderSuccessful(formCssClass);
        }

        if (showFailure) {
            return this.renderFailure(formCssClass);
        }

        return (
            <form className={formCssClass}>
                <div className="contact-form-fields">
                    <div className="cnt-salutation">
                        <KoenigSelect
                            label="Anrede"
                            options={['Herr', 'Frau']}
                            value={this.props.salutation ? this.props.salutation : 'Bitte wählen'}
                            onChange={this.handleChange.bind(this, 'salutation')}
                            isRequired />
                    </div>
                    {
                        personDataInputs.map(([id, labelText]) =>
                            <div key={'contect-form-' + id} className={'cnt-' + id}>
                                <KoenigInput
                                    label={labelText}
                                    value={this.props[id]}
                                    onChange={this.handleChange.bind(this, id)}
                                    isEmailField={id === 'email'}
                                    isRequired />
                            </div>
                        )
                    }
                    <div className="cnt-label-select-participation">
                        <label className="label-participation">Kommen Sie allein oder in Begleitung? *</label>
                        <KoenigSelect
                            className="cnt-select-participation"
                            value="Bitte wählen"
                            options={participations}
                            onChange={participation => this.setState({ participation })}
                        />
                    </div>
                </div>
                <div className="cnt-agb-datenschutz">
                    <Checkbox
                        onChange={agb => this.setState({ agb })} />
                    <span className="koenig-checkbox-text">
                        <a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> habe ich gelesen
                                </span>
                    <span>(*) = Pflichtfelder</span>
                </div>
                <KoenigButton
                    text="Anmeldung absenden"
                    onClick={this.sendInquery.bind(this)}
                    disabled={!this.isValid()}
                />
                <span>
                    Nach dem Versenden Ihrer Anmeldung, erhalten Sie von uns eine Anmeldebestätigung an Ihre angegebene E-Mail-Adresse, sowie das Parkticket als PDF zum Selbstausdrucken.
                </span>
            </form>
        )
    }

    renderSuccessful(formCssClass) {
        if(this.state.participation === 'Ich komme nicht') {
            return this.renderCanceled(formCssClass);
        }

        return (
            <div className={formCssClass}>
                <h2>Vielen Dank für Ihre Anmeldung!</h2>
                <p>Nach dem Versenden Ihrer Anmeldung, erhalten Sie von uns eine Anmeldebestätigung an Ihre angegebene E-Mail-Adresse, sowie das Parkticket als PDF zum Selbstausdrucken.</p>
                <p>Ihr Autohaus König</p>
            </div>
        );
    }

    renderCanceled(formCssClass) {
        return (
            <div className={formCssClass}>
                <h2>Vielen Dank für Ihre Rückmeldung!</h2>
                <p>Auch wenn wir es sehr schade finden, dass Sie nicht dabei sein werden. Sollte es Ihnen doch möglich sein teilzunehmen, können Sie sich noch bis zum 06.01.2020 anmelden.</p>
                <p>Ihr Autohaus König</p>
            </div>
        )
    }

    renderFailure(formCssClass) {
        return (
            <div className={formCssClass}>
                <h2>Vielen Dank für Ihre Anmeldung!</h2>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anmeldung direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>
            </div>
        );
    }
}

export default VipPage;