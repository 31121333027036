import React from 'react';
import Icon from '../../components/Icon'

export default ({
                    className,
                    variant,
                    linkTo,
                    text,
                    style,
                    targetBlank,
                    onClick,
                    disabled,
                    icons = [],
                    icon
                }) => {
    const classes = ['koenig-button'];
    let clickFunc = onClick;
    const actions = {
        href: linkTo,
        onClick: clickFunc
    };

    if (typeof variant !== 'undefined') {
        if (variant === 'outlined') {
            classes.push('koenig-button-outlined');
        }
        if (variant === 'dark') {
            classes.push('koenig-button-dark');
        }
    }

    if (typeof className !== 'undefined' && className.length > 0) {
        classes.push(className);
    }

    if (typeof linkTo === 'undefined') {
        delete actions.href;
    }

    if (typeof onClick !== 'undefined') {
        delete actions.href;
    }

    if (disabled === true) {
        delete actions.href;
        actions.onClick = () => {
        };
        classes.push('disabled');
    }

    if (icon) {
        icons.push(icon);
    }

    if (!Array.isArray(icons) || icons.length === 0) {
        icons = null;
    }

    return (
        <a
            className={classes.join(' ')}
            style={style}
            target={targetBlank === true ? '_blank' : '_self'}
            {...actions}
        >
            {renderIcons(icons)}
            {renderText(text, icons)}
        </a>
    )
};

function renderIcons(icons) {
    return icons &&
        icons
            .filter((icon, idx) => icons.indexOf(icon) === idx)
            .map(
                (icon, idx) => <Icon variant={icon} key={icon + '_' + idx}/>
            );
}

function renderText(text, icons) {
    const classes = ['button-text'];

    icons && classes.push('button-text-icon');

    return text && icons ? <span className={classes.join(' ')}>{text || ''}</span> : text || '';
}
