import imgEmissionAppp from '../../../../assets/images/co2-emission/co2-emission-a+++.svg';
import imgEmissionApp from '../../../../assets/images/co2-emission/co2-emission-a++.svg';
import imgEmissionAp from '../../../../assets/images/co2-emission/co2-emission-a+.svg';
import imgEmissionA from '../../../../assets/images/co2-emission/co2-emission-a.svg';
import imgEmissionB from '../../../../assets/images/co2-emission/co2-emission-b.svg';
import imgEmissionC from '../../../../assets/images/co2-emission/co2-emission-c.svg';
import imgEmissionD from '../../../../assets/images/co2-emission/co2-emission-d.svg';
import imgEmissionE from '../../../../assets/images/co2-emission/co2-emission-e.svg';
import imgEmissionF from '../../../../assets/images/co2-emission/co2-emission-f.svg';
import imgEmissionG from '../../../../assets/images/co2-emission/co2-emission-g.svg';
import React from 'react';
import co2Helper from '../../../../helper/co2.helper';

const imgEmissionMap = {
    "a+++": imgEmissionAppp,
    "a++": imgEmissionApp,
    "a+": imgEmissionAp,
    "a": imgEmissionA,
    "b": imgEmissionB,
    "c": imgEmissionC,
    "d": imgEmissionD,
    "e": imgEmissionE,
    "f": imgEmissionF,
    "g": imgEmissionG
};

class EmissionBadge extends React.Component {
    render() {
        const { combinedCo2, kerbWeight } = this.props;
        const co2Class = co2Helper.getCo2Class(combinedCo2, kerbWeight);

        if (co2Class) {
            return <img title={co2Class}
                        alt={co2Class}
                        src={imgEmissionMap[co2Class]} />;
        }

        return null;
    }
}

export default EmissionBadge;