import "./styles.scss";

import React from 'react';
import CommonPage from '../../../CommonPage';
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/Breadcrumb";
import Icon from "../../../../components/Icon";
import KoenigButton from "../../../../elements/KoenigButton";
import Functions from "../functions";

const CSS_CLASS = 'wtt--failure-booking';

class FehlgeschlagenPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    handleRetryClicking() {
        const postData = { ...this.state };
        delete postData.day;
        delete postData.time;

        Functions.linkTo('/werkstatt/terminbuchung/', postData);
    }

    render() {

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Werkstatt', path: '/werkstatt/' },
            { label: 'Terminbuchung', current_page: true }
        ];

        return (
            <CommonPage className="page-terminbuchung-fehlgeschlagen-buchung">
                <Helmet>
                    <title>Terminbuchung - Werkstatttermin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>Ihre Terminbuchung ist fehlgeschlagen.</h1>

                <div className={CSS_CLASS}>
                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="close"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Terminbuchung fehlgeschlagen</b>
                    </div>

                    <p className={CSS_CLASS + '--reason'}>Leider konnte Ihr Wunschtermin nicht gebucht werden, da dieser wahrscheinlich gerade von einer anderen Person gebucht wurde. Bitte wählen Sie einen alternativen Termin.</p>

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            className={CSS_CLASS + '--button--home'}
                            text="Zurück zur Startseite"
                            linkTo="/" />

                        <KoenigButton
                            className={CSS_CLASS + '--button--step1'}
                            text="Termin anpassen"
                            onClick={this.handleRetryClicking.bind(this)} />
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default FehlgeschlagenPage;