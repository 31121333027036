const addNumberSeparator = val => {
    val = '' + val;

    const parts = val.split('.');
    val = parts[0];

    if (val.length > 3) {
        const mod = val.length % 3;
        let output = (mod > 0 ? (val.substring(0, mod)) : '');
        for (let i = 0; i < Math.floor(val.length / 3); i++) {
            if ((mod == 0) && (i == 0)) {
                output += val.substring(mod + 3 * i, mod + 3 * i + 3);
            } else {
                output += '.' + val.substring(mod + 3 * i, mod + 3 * i + 3);
            }
        }
        return output + (parts.length > 1 ? ',' + parts[1] : '');
    }
    else {
        return val + (parts.length > 1 ? ',' + parts[1] : '');
    }
}

export default {
    addNumberSeparator
}