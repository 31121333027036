import React from 'react';
import PropTypes from 'prop-types';

class Tabs extends React.Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired
    }

    constructor(props) {
        super(props);

        const { defaultTab, children } = this.props;
        const label = children[0] && children[0].props && children[0].props.label
            ? children[0].props.label
            : '';

        this.state = {
            activeTab: defaultTab ? defaultTab : label
        };
    }

    onClickTabItem(tab) {
        const { onChange = () => {} } = this.props;

        onChange(tab);
        this.setState({ activeTab: tab });
    }

    render() {
        const { children } = this.props;
        const { activeTab } = this.state;
        const validTab = this.getValidTab(activeTab, children);

        if (!validTab) return '';

        return (
            <div className="tabs">
                <ul>
                    {children.map((child, idx) => {
                        const { label } = child.props;

                        return React.cloneElement(
                            child,
                            {
                                activeTab: validTab.props.label,
                                key: `${label}_${Math.random()}_${idx}`,
                                onClick: this.onClickTabItem.bind(this)
                            }
                        )
                    })}
                </ul>
                <div className="tab-content">
                    {children.map((child) => {
                        if (child.props.label === validTab.props.label) {
                            return child.props.children;
                        }

                        return undefined
                    })}
                </div>
            </div>
        );
    }

    getValidTab(activeTab, children) {
        if (Array.isArray(children)) {
            const validTab = children.reduce((result, tab) => {
                const { label, children } = tab.props;

                if (label === activeTab && children !== false) {
                    return tab;
                }

                if (result.props.label === activeTab && result.props.children !== false) {
                    return result
                }

                if (result.props.children !== false) {
                    return result;
                }

                if (children !== false) {
                    return tab;
                }

                return result;
            }, children[0]);

            return validTab;
        }

        return null;
    }
}

export default Tabs;