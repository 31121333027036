import './styles.scss';

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import KoenigImage from '../KoenigImage'

class SimpleContent extends React.Component {

    render() {
        const { content } = this.props;
        const hasContent = Array.isArray(content) && content.length > 0;

        if (hasContent) {
            return (
                <div className="cnt-article">
                    {
                        content.map((column, key) => {
                            column.key = key;
                            return this.renderType(column);
                        })
                    }
                </div>
            );
        }

        return null;
    }

    renderType(element) {
        const hasType = typeof element.type !== 'undefined' && element.type.length > 0;

        if (hasType) {
            switch (element.type) {
                case 'headline': return this.renderHeadline(element);
                case 'text': return this.renderText(element);
                case 'image': return this.renderImage(element);
            }
        }

        return null;
    }

    renderHeadline(element) {
        const { headlineLevel = 3 } = this.props;
        const colClass = typeof element.width !== 'undefined' && element.width !== '' ? element.width : 'col-100';
        const CustomHeadline = `h${headlineLevel}`;

        return <CustomHeadline key={element.key} className={colClass}>{ReactHtmlParser(element.value)}</CustomHeadline>;
    }

    renderText(element) {
        const colClass = typeof element.width !== 'undefined' && element.width !== '' ? element.width : 'col-50';

        return <article key={element.key} className={colClass}>{ReactHtmlParser(element.text)}</article>;
    }

    renderImage(element) {
        const colClass = typeof element.width !== 'undefined' && element.width !== '' ? element.width : 'col-50';

        return <div key={element.key} className={colClass}><KoenigImage fileHash={element.src} alt={element.alt} /></div>;
    }
}

export default SimpleContent;