import './styles.scss';

import React from 'react';
import KoenigButton from '../../../../elements/KoenigButton';
import rciHelper from '../../../../helper/rci.helper';
import qs from 'qs';
import RciCalculator from '../RciCalculator';
import InquiryOverlay from '../InquiryOverlay';
import UrlHelper from '../../../../helper/url.helper';
import MoAdPixel from '../../../../components/MoAdPixel';
import apiHelper from '../../../../helper/api.helper';
import fbHelper from '../../../../helper/facebook.helper';
import { withCookies } from 'react-cookie';

const CSS_CLASS = 'fc-payment-buttons';

class PaymentButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sofaUrl: null
        };
    }

    async componentDidMount() {
        const { vehicle, type } = this.props;
        const { payments = {} } = vehicle;
        const { financing = {} } = payments;

        if (type === 'financing' && financing.provider === 'bank11') {
            const result = await apiHelper.GET('/bank11_urls/' + vehicle._id, true);

            if (result.url) {
                this.setState({ sofaUrl: result.url });
            } else {
                this.setState({ sofaUrl: false });
            }
        }
    }

    handleFinanceVehicle() {
        const { sofaUrl } = this.state;

        if (sofaUrl) {
            this.setState({ financingButtonClicked: true });
            fbHelper.track('FinancingButtonClicked', undefined, true);
            window.open(sofaUrl, '_blank');
        }
    }

    handleDasLeasing() {
        const { vehicle, currentImageIndex, cookies } = this.props;
        let url = `https://checkout.autohaus-koenig.de/?fzg_id=${vehicle._id}&image_idx=${currentImageIndex}`;
        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        if (queryParams.coyotetrackingid) {
            url += '&coyoteAffiliTokenId=' + queryParams.coyotetrackingid;
        } else if (cookies.get('mobilityAds')) {
            url += '&coyoteAffiliTokenId=' + cookies.get('mobilityAds');
        }

        fbHelper.track('InitiateCheckout');
        window.open(url, '_blank');
    }

    render() {
        const { vehicle } = this.props;
        const { payments: { exclusive } } = vehicle;
        const { financingButtonClicked } = this.state;

        return (
            <div className={CSS_CLASS}>
                {
                    !exclusive && <KoenigButton
                        className={CSS_CLASS + '--btn-inquiry'}
                        text="Unverbindlich anfragen"
                        onClick={() => this.setState({ showInquiryOverlay: true })}
                    />
                }
                {this.renderPaymentButton()}
                {this.renderRciCalculator()}
                {this.renderReserveVehicleOverlay()}
                {financingButtonClicked && <MoAdPixel vehicle={vehicle}/>}
            </div>
        );
    }

    renderPaymentButton() {
        const { type, vehicle: { payments = {} } } = this.props;
        const { exclusive } = payments;
        const hasFullWidth = typeof exclusive === 'string';

        switch (type) {
            case 'leasing':
                return this.renderLeasingButton(payments.leasing, hasFullWidth);
            case 'financing':
                return this.renderFinancingButton(payments.financing, hasFullWidth);
            case 'cash':
                return this.renderCashButton(payments.prices, hasFullWidth);
            case 'abo':
                return this.renderAboButton(payments.abo, hasFullWidth);
        }
    }

    renderFinancingButton({ provider }) {
        switch (provider) {
            case 'bank11':
                return this.renderBank11Button();
        }
    }

    renderBank11Button() {
        const { sofaUrl } = this.state;
        const classes = [CSS_CLASS + '--btn-buy-vehicle'];
        sofaUrl === null && classes.push('pulse');
        const isDisabled = !sofaUrl;

        return (
            <KoenigButton
                className={classes.join(' ')}
                text="Finanzierung mit"
                icons={['bank_11', 'shopping_basket']}
                onClick={this.handleFinanceVehicle.bind(this)}
                disabled={isDisabled}
            />
        )
    }

    renderLeasingButton({ provider }, hasFullWidth = false) {
        switch (provider) {
            case 'rci':
                return this.renderRciButton(hasFullWidth);
            case 'das':
                return this.renderDasButton(hasFullWidth);
        }
    }

    renderRciButton(hasFullWidth) {
        const { vehicle } = this.props;
        const rciCalculatorLink = rciHelper.createCalculatorLink(vehicle);
        const classes = [CSS_CLASS + '--btn-buy-vehicle'];
        hasFullWidth && classes.push('full-width');

        return (
            <KoenigButton
                className={classes.join(' ')}
                text="Leasing mit"
                icons={['rci', 'shopping_basket']}
                disabled={!rciCalculatorLink}
                onClick={() => this.setState({ showRciCalculator: true })}
            />
        )
    }

    renderDasButton(hasFullWidth) {
        const classes = [CSS_CLASS + '--btn-buy-vehicle button-text'];
        hasFullWidth && classes.push('full-width');

        return (
            <KoenigButton
                className={classes.join(' ')}
                text="Fahrzeug jetzt leasen"
                onClick={this.handleDasLeasing.bind(this)}
            />
        );
    }

    renderCashButton() {
        return (
            <KoenigButton
                className={CSS_CLASS + '--btn-buy-vehicle'}
                text="Fahrzeug jetzt kaufen"
                onClick={() => this.setState({ showInquiryOverlay: true })}
            />
        )
    }

    renderRciCalculator() {
        const { showRciCalculator } = this.state;
        const { vehicle } = this.props;

        return showRciCalculator &&
            <RciCalculator
                vehicle={vehicle}
                onClose={() => this.setState({ showRciCalculator: false })} />

    }

    renderReserveVehicleOverlay() {
        const { showInquiryOverlay = false } = this.state;
        const { vehicle, kws_selection, insurance_selection } = this.props;

        if (!showInquiryOverlay) {
            return null;
        }

        fbHelper.track('InitiateCheckout');

        const params = UrlHelper.serializeQuery(this.props.location || 'undefined');

        return (
            <InquiryOverlay
                vehicle={vehicle}
                brand={vehicle.brand.toLowerCase()}
                kws={kws_selection}
                insurance={insurance_selection}
                className="overlay-reserve-form"
                onClose={() => this.setState({ showInquiryOverlay: false })}
                params={params}
            />
        );
    }

    renderAboButton({ offer_url }, hasFullWidth) {
        const classes = [CSS_CLASS + '--btn-buy-vehicle']
        hasFullWidth && classes.push('full-width');

        return (
            <KoenigButton
                className={classes.join(' ')}
                text="Fahrzeug jetzt abonnieren"
                onClick={() => window.open(offer_url, '_blank')}
            />
        )
    }
}

export default  withCookies(PaymentButtons);