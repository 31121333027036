require('isomorphic-fetch');

async function getOfferUrl(_id) {
    try {
        const result = await fetchFromSmive(_id);

        if (result.offerUrl) {
            return result.offerUrl;
        }

        return null;
    } catch (err) {
        console.log(err);

        return null;
    }
}

async function fetchFromSmive(_id) {
    return fetch(
        `https://api.smive.de/subscriber/calculate-offer/30000020.${_id}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(
            res => res.ok
                ? res.json()
                : Promise.reject('Smive-Api nicht erreichbar. Status: ' + res.status)
        );
}

export default { getOfferUrl };