import React from 'react';
import PropTypes from 'prop-types';

import categorySprite from '../../assets/images/category-sprite.svg';
import './styles/car-chooser.scss';

const sprites = [
	{ id: 'all_items', label: 'Alle anzeigen', x: 173, y: 0, key: 'all' },
	{ id: 'klein-kompakt', label: 'Kleinwagen', y: 0, key: 'Kleinwagen' },
	{ id: 'cabrio-roadster', label: 'Cabrio/Roadster', x: 173, y: 492, key: 'Cabrio/Roadster' },
	{ id: 'kleintransporter', label: 'Kleintransporter', x: 173, y: 410, key: 'Kleintransporter' },
	{ id: 'kombi', label: 'Kombi', y: 82, type: 1, key: 'Kombi' },
	{ id: 'sport-coupe', label: 'Limousine', y: 164, key: 'Limousine' },
	{ id: 'scooter', label: 'Roller', x: 173, y: 82, key: 'Roller/Scooter' },
	{ id: 'sport-coupe', label: 'Sportler/Coupé', y: 164, key: 'Sportwagen/Coupé' },
	{ id: 'suv', label: 'SUV/Geländewagen', y: 246, key: 'SUV/Geländewagen' },
	{ id: 'transporter', label: 'Transporter', y: 410, key: 'Transporter' },
	{ id: 'familie-freizeit', label: 'Van/Kleinbus', y: 328, key: 'Van/Kleinbus' },
];

const CarChooser = ({ value, onChange }) => {
	const [active, setActiveItem] = React.useState(0);

	React.useEffect(() => {
		setActiveItem(sprites.findIndex((s) => s.key === value));
	}, [value]);

	const handleClick = (index, key) => {
		setActiveItem(index);
		key === 'all' ? onChange('') : onChange(key);
	};

	return (
		<div className='car-chooser'>
			{sprites.map(({ id, label, x = 0, y, key }, index) => {
				return (
					<div
						key={label + '-' + y}
						className={`car-chooser__item ${active === index ? 'active' : ''}`}
						onClick={() => handleClick(index, key)}>
						<svg viewBox={`${x} ${y} 171 80`} className='icon'>
							<use xlinkHref={`${categorySprite}#${id}`} />
						</svg>
						<span className={`car-chooser__label ${active === index ? 'active' : ''}`}>
							{label}
						</span>
					</div>
				);
			})}
		</div>
	);
};

export default CarChooser;

CarChooser.defaultProps = {
	value: 'all',
}

CarChooser.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
