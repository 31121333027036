import './styles.scss';

import React from 'react';
import leftBalloon from './assets/balloon_left.png';
import rightBalloon from './assets/balloon_right.png';

const CSS_PREFIX = 'fc-birthday';

class Birthday extends React.Component {
    render() {
        return (
            <div className={CSS_PREFIX}>
                <img src={leftBalloon} alt="König Geburtstag"/>
                <img src={rightBalloon} alt="König Geburtstag"/>
            </div>
        );
    }
}

export default Birthday;