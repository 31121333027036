import React from 'react';
import PropTypes from 'prop-types';

import UsedCarButton from '../../../components/UsedCarButton';
import ContactForm from '../components/ContactForm';
import Overlay from '../../../components/Overlay';
import KoenigButton from '../../../elements/KoenigButton';
import apiHelper from '../../../helper/api.helper';
import fbHelper from '../../../helper/facebook.helper';
import urlHelper from '../../../helper/url.helper';
import rciHelper from '../../../helper/rci.helper';
import RciCalculator from '../../VehicleDetailPage/components/RciCalculator';
import { getAccidentLabel } from '../../GebrauchtwagenPage/components/ResultItem';

class OfferDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOverlay: false,
            success: false,
            error: false,
            sofaUrl: null,
            rciUrl: false,
            showRciCalculator: false
        };
    }

    async componentDidMount() {
        const { vehicle } = this.props;
        const rciUrl = await rciHelper.createCalculatorLinkFromBWS(vehicle);

        if (rciUrl) {
            this.setState({ rciUrl, sofaUrl: false });
        } else {
            const vehiclePrice =
                typeof vehicle.preis === 'string' && vehicle.preis.length > 0
                    ? parseInt(vehicle.preis.replace('.', ''))
                    : 0;

            if (vehiclePrice > 1500) {
                const vehicleQueryString = this.buildVehicleQueryString(vehicle);
                try {
                    const result = await apiHelper.GET('/bank11_urls?' + vehicleQueryString, true);

                    this.setState({ sofaUrl: result?.url ? result.url : false });
                } catch (e) {
                    console.error(e);
                    this.setState({ sofaUrl: false });
                }
            } else {
                this.setState({ sofaUrl: false });
            }
        }
    }

    buildVehicleQueryString(vehicle) {
        const { marke, modell, preis, version, hubraum, erstzulassung = '', wgnr, fzart } = vehicle;
        const dateParts = erstzulassung.split('/');
        const vehiclePrice = parseInt(preis.replace('.', '')) * 100;
        let registrationDate = null;

        if (dateParts.length === 2) {
            registrationDate = `${dateParts[1]}-${dateParts[0]}-01`
        }

        return urlHelper.generateQueryStringFrom({
            wgnr,
            brand: marke,
            model: modell,
            price: vehiclePrice,
            version,
            cubic_capacity: hubraum,
            registration_date: registrationDate,
            fzart
        });
    }

    async handleFinanceVehicle(provider) {
        this.renderFacebookPixel('FinancingButtonClicked');

        if (provider === 'rci') {
            this.setState({ showRciCalculator: true });
        } else if (window && window.location) {
            const { sofaUrl } = this.state;

            if (sofaUrl) {
                window.open(sofaUrl, '_blank');
            }
        }
    }

    handleTradeInButton() {
        this.renderFacebookPixel('TradeInButtonClicked');
        window.auws.openModal(auws.ModuleTypes.TRADE_IN);
    }

    renderFacebookPixel(event) {
        fbHelper.track(event, undefined, true);
    }

    renderError = () => (
        <div className='error-message'>
            <p>
                Leider ist ein Problem aufgetreten.
                <br />
                Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
            </p>
            <p>
                <a href='mailto:anfrage@autohaus-koenig.de'>anfrage@autohaus-koenig.de</a>
            </p>
            <p>Ihr Autohaus König</p>

            <UsedCarButton
                title='Zurück'
                onClick={() => {
                    this.setState({ error: false, showOverlay: false });
                }}
            />
        </div>
    );

    renderSuccess = () => (
        <div className='success-message'>
            <h3>Vielen Dank für Ihre Anfrage!</h3>
            <p>
                Schön, dass Sie sich für unser Angebot interessieren.
                <br />
                Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
            </p>
            <p>Ihr Autohaus König</p>

            <UsedCarButton
                title='Zurück'
                onClick={() => {
                    this.setState({ success: false, showOverlay: false });
                }}
            />
        </div>
    );

    render() {
        const { vehicle } = this.props;
        const { showOverlay, success, error } = this.state;
        let usedDetails = null;

        if (vehicle.fzart !== 'Neuwagen') {
            const accident = getAccidentLabel(parseInt(vehicle.unfallwagen));
            usedDetails = `| ${vehicle.vorbesitzer} Fahrzeughalter | ${accident}`;
        }

        return (
            <div className='offer-details'>
                <h2 className='offer-details__title'>
                    {vehicle.marke} {vehicle.modell} {vehicle.version}
                </h2>
                <p className='offer-details__sub-title'>
                    {vehicle.kw} kW ({vehicle.ps} PS) | {vehicle.kraftstoff} | {vehicle.getriebe} {usedDetails}
                </p>

                <div className='offer-details__price'>
                    <div
                        className="au-widget-car-rating"
                        data-car-url={`https://www.autohaus-koenig.de/gebrauchtwagen/gebrauchtwagenangebote/${vehicle.wgnr}`}
                        data-layout="wider_no_border">
                    </div>
                    <span>
						<span>{`€ ${vehicle.preis} ,-`}</span>
						<span>{vehicle.mwst_ausweisbar ? 'MwSt. ist ausweisbar' : 'MwSt. nicht ausweisbar'}</span>
					</span>
                </div>

                <div className='offer-details__btn-group'>
                    <UsedCarButton
                        addClass='offer-details__inquiry-btn'
                        title='Fahrzeug anfragen'
                        onClick={() =>
                            this.setState((prevState) => ({ showOverlay: !prevState.showOverlay }))
                        }
                    />
                    {
                        this.renderFinancingButton()
                    }
                </div>
                {
                    vehicle.smiveOfferUrl &&
                    <KoenigButton
                        className="offer-details__smive-btn"
                        text="Jetzt abonnieren mit Smive"
                        linkTo={vehicle.smiveOfferUrl}
                        targetBlank
                    />
                }
                <div className='offer-details__trade-in'>
					<span>
						<b>Geben Sie Ihr Fahrzeug in Zahlung.</b>
						<p>Erfahren Sie schnell und kostenlos den
						unverbindlichen Marktwert Ihres Fahrzeugs.</p>
					</span>
                    <UsedCarButton
                        addClass='offer-details__inzahlungnahme-btn'
                        title='Inzahlungnahme'
                        onClick={this.handleTradeInButton.bind(this)}
                    />
                </div>

                {showOverlay && (
                    <Overlay onClose={() => this.setState({ showOverlay: false })}>
                        {!success && !error && (
                            <ContactForm
                                vehicleData={vehicle}
                                onSuccessful={() => this.setState({ success: true })}
                                onFailure={() => this.setState({ error: true })}
                            />
                        )}
                        {success && this.renderSuccess()}
                        {error && this.renderError()}
                    </Overlay>
                )}
                {this.renderRciCalculator()}
            </div>
        );
    }

    renderFinancingButton() {
        const { sofaUrl, rciUrl } = this.state;
        const classes = ['btn-finance-vehicle'];
        let isDisabled = false;
        let provider = 'rci';

        if (!rciUrl) {
            isDisabled = sofaUrl === false;
            sofaUrl === null ? classes.push('pulse') : null;
            provider = 'bank_11';
        }

        return (
            <KoenigButton
                className={classes.join(' ')}
                text="Finanzieren mit"
                icons={[provider, 'shopping_basket']}
                onClick={this.handleFinanceVehicle.bind(this, provider)}
                disabled={isDisabled}
            />
        );
    }

    renderRciCalculator() {
        const { showRciCalculator, rciUrl } = this.state;

        return showRciCalculator &&
            <RciCalculator
                calculatorLink={rciUrl}
                onClose={() => this.setState({ showRciCalculator: false })} />
    }

}

export default OfferDetails;

OfferDetails.propTypes = {
    vehicle: PropTypes.object,
};
