import React from 'react';
import PropTypes from 'prop-types';

import iconSprite from '../../assets/images/icon-sprite-2.svg';
import './styles.scss';

const icons = {
    arrow_downward: '0 0 48 48',
    arrow_upward: '50 0 48 48',
    chevron_down_small: '100 0 48 48',
    list_view: '150 0 48 48',
    grid_view: '200 0 48 48',
    check: '250 0 48 48',
    close: '295 0 48 48',
    print: '0 45 48 48',
    wallet: '50 45 48 48',
    chevron_left_small: '100 49 48 48',
    chevron_right_small: '150 49 48 48',
};

const UsedCarIcon = ({ className, variant, color, onClick, size }) => {
    const viewBox = icons[variant];

    return ( 
        <span className={`icon ${className}`} onClick={onClick} data-variant={variant} style={{ width: size }}>
            <svg viewBox={viewBox} preserveAspectRatio="xMidYMid meet" >
                <use xlinkHref={iconSprite + '#' + variant} fill={color} />
            </svg>
        </span>
     );
}
 
export default UsedCarIcon;

UsedCarIcon.defaultProps = {
    color: '#000',
    className: '',
    size: 24,
}

UsedCarIcon.propTypes = {
    classname: PropTypes.string,
    variant: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};