import React from "react";
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/de';

import './styles.scss';
import KoenigImage from "../KoenigImage";

class TeaserBox extends React.Component {

    render() {
        const now = moment().tz('Europe/Berlin');
        const { start_at: startAt, end_at: endAt } = this.props;

        if (typeof startAt !== 'undefined') {
            const startAtMoment = moment(startAt, "DD.MM.YYYY HH:mm:ss").tz('Europe/Berlin');

            if (startAtMoment.isAfter(now)) {
                return null;
            }
        }

        if (typeof endAt !== 'undefined') {
            const endAtMoment = moment(endAt, "DD.MM.YYYY HH:mm:ss").tz('Europe/Berlin');

            if (now.isAfter(endAtMoment)) {
                return null;
            }
        }

        return (
            <div className="teaser-box" >
                {this.renderBox()}
            </div>
        );
    }

    renderBox() {
        const { onClick, linkTo, target, headline } = this.props;
        const handlerFunc = onClick || function () { };

        if (linkTo) {
            return (
                <a className="box" href={linkTo} target={(target) ? target : '_top'} title={headline}>
                    {this.renderImage()}
                    {this.renderBody()}
                </a>
            );
        } else {
            return (
                <div className="box" onClick={handlerFunc}>
                    {this.renderImage()}
                    {this.renderBody()}
                </div>
            );
        }
    }

    renderImage() {
        const { image, headline } = this.props;

        return (
            <React.Fragment>
                {image ? <KoenigImage fileHash={image} alt={headline} title={headline} /> : <b>{headline}</b>}
            </React.Fragment>
        );
    }

    renderBody() {
        const { headline, text } = this.props;

        return (
            <React.Fragment>
                <span>{headline}</span>
                {text}
            </React.Fragment>
        );
    }
}

export default TeaserBox;