import React from 'react';
import Api from '../../../shared/helper/api.helper';
import Gallery from './components/Gallery';
import OfferButtons from './components/OfferButtons';
import OfferDetails from './components/OfferDetails';
import PaymentTabs from './components/PaymentTabs';
import VehicleButtons from './components/VehicleButtons';
import VehicleDetails from './components/VehicleDetails';
import Breadcrumb from '../../components/Breadcrumb';
import UrlHelper from '../../helper/url.helper';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Icon from '../../components/Icon';
import SimpleFaq from '../../components/SimpleFaq';
import PaymentDetails from './components/PaymentDetails';
import SimpleContent from '../../components/SimpleContent';
import qs from 'qs';
import { BRAND_REG_EXP } from '../../helper/constant.helper';
import {addProductTag} from '../../components/Criteo';

const CSS_CLASS = 'page-vehicle-detail';

class VehicleDetailPage extends React.Component {
    constructor(props) {
        super(props);

        let vehicle, vehicleType;

        if (props.staticContext && props.staticContext.initialData) {
            [vehicle, vehicleType] = props.staticContext.initialData;
        } else {
            [vehicle, vehicleType] = window.__initialData__;
            delete window.__initialData__;
        }

        const paymentType = this.getPaymentType(vehicle);

        this.state = {
            vehicle,
            kws_selection: null,
            insurance_selection: null,
            paymentType,
            currentImageIndex: 0,
            vehicleType
        };
    }

    componentDidMount() {
        const { vehicle } = this.state;

        addProductTag(vehicle._id)
        this.handleColorQuery();
        this.addAutoUncleScript();
        this.addAfiliflarePixel()
    }

    handleColorQuery() {
        const { images } = this.state.vehicle;
        const { color } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        if (color && images.length > 0) {
            images.forEach((set, idx) => {
                if (set.color_outside === color) {
                    this.setState({ currentImageIndex: idx });
                }
            });
        }
    }

    addAutoUncleScript() {
        const { vehicle } = this.state;

        window.addEventListener('autouncle_loaded', () => {
            window.AuEmbed.config.data = { stockId: vehicle._id };
            window.AuEmbed.config.customData = { leadSource: 'Inzahlungnahme Lead' };
        });
    }

    addAfiliflarePixel() {
        const fbPixel = document.createElement('script');
        fbPixel.type = 'text/javascript';
        fbPixel.async = true;
        fbPixel.src = 'https://aflare.de/fbp/v/1263/PageView';

        document.head.appendChild(fbPixel);
    }

    static requestInitialData = reqUrl => {
        const regexp = new RegExp(`\/(neuwagen|roller)\/(${BRAND_REG_EXP})\/(.+)\/(.+)`, 'g');
        const match = regexp.exec(reqUrl);
        const brandID = match !== null && match[2] ? match[2] : null;
        const vehicleID = match !== null && match[4] ? match[4] : null;
        const vehicleType = /^\/(neuwagen|roller)/g.exec(reqUrl);

        if (brandID !== null) {
            return Promise.all([
                Api
                    .GET(`/vehicles/${vehicleID}`)
                    .then(res => {
                        if (res && res.status === 301) {
                            return {
                                status: 301,
                                target: reqUrl.replace(vehicleID, '').replace('//', '/')
                            }
                        }
                        return res.vehicle;
                    }),
                vehicleType[1]
            ]);
        }
        return Promise.resolve({
            status: 301,
            target: `/${vehicleType[1]}/`
        });
    }

    handleTradeInClicked() {
        window.fbq('trackCustom', 'TradeInButtonClicked');
        window.auws.openModal(auws.ModuleTypes.TRADE_IN);
    }

    render() {
        const { vehicle, paymentType } = this.state;
        const crumbs = this.buildCrumbs();
        const title = this.buildTitle();

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title.join(' ')}</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />
                {this.renderHeader()}
                {this.renderDatInfo()}
                <div className={CSS_CLASS + '--columns'}>
                    <div>
                        {this.renderGallery()}
                        {this.renderVehicleButtons()}
                        {this.renderHighlights()}
                        {this.renderClues()}
                    </div>
                    <div>
                        {this.renderOfferDetails()}
                        {this.renderPaymentTabs()}
                        {this.renderOfferButtons()}
                    </div>
                </div>
                {
                    paymentType === 'leasing' &&
                    <PaymentDetails vehicle={vehicle} />
                }
                <hr />
                <h2>Informationen zum {vehicle.brand} {vehicle.model} {vehicle.version}</h2>
                <VehicleDetails vehicle={vehicle} />
                <SimpleContent content={vehicle.content} />
                <SimpleFaq faq={vehicle.faq} />
            </CommonPage>
        );
    }

    buildCrumbs() {
        const { vehicle, vehicleType } = this.state;
        const checkedBrand = vehicle.brand ? vehicle.brand : 'Marke';
        const checkedModel = vehicle.model ? vehicle.model : 'Modell';
        const brandForUrl = UrlHelper.normalize(checkedBrand);
        const modelForUrl = UrlHelper.normalize(checkedModel);
        const $neuwagen = vehicleType === 'roller' ? 'Neuroller' : 'Neuwagen';

        return [
            { label: 'Start', path: '/' },
            { label: $neuwagen, path: `/${vehicleType}` },
            { label: vehicle.brand, path: `/${vehicleType}/${brandForUrl}` },
            { label: vehicle.brand + ' ' + vehicle.model, path: `/${vehicleType}/${brandForUrl}/${modelForUrl}` },
            { label: `${vehicle.brand} ${vehicle.model} ${vehicle.version}`, current_page: true }
        ];
    }

    buildTitle() {
        const { vehicle } = this.state;
        const $neuwagen = vehicle.brand === 'Vespa' ? 'Neuroller' : 'Neuwagen';

        return [
            vehicle.brand || '',
            vehicle.model || '',
            $neuwagen,
            'und Leasing Angebote - Autohaus König'
        ];
    }

    renderHeader() {
        const { brand, model, version, koeniginfo, engine } = this.state.vehicle;

        return (
            <div className={CSS_CLASS + '--header'}>
                <h1>{brand} {model}</h1>
                <div>{version} {engine}</div>
                {
                    koeniginfo.length > 0 && <div>{koeniginfo}</div>
                }
            </div>
        );
    }

    renderDatInfo() {
        const { vehicle: { dat_info } } = this.state;

        return (
            <div className={CSS_CLASS + '--dat-info'}>
                <span>{dat_info}</span>
                <span onClick={this.handleTradeInClicked}>Lassen Sie jetzt Ihr Fahrzeug bewerten!</span>
            </div>
        );
    }

    renderGallery() {
        const { vehicle, currentImageIndex } = this.state;
        const { brand, model, version, top_deal = true } = vehicle;
        const overlayTitle = `${brand} ${model} ${version}`;

        return (
            <div className={CSS_CLASS + '--gallery-wrapper'}>
                <Gallery
                    images={vehicle.images}
                    overlayTitle={overlayTitle}
                    currentIndex={currentImageIndex}
                    onIndexChange={(idx) => this.setState({ currentImageIndex: idx })} />
                {
                    top_deal &&
                    <span>
                        TOP DEAL
                        <Icon variant="crown" color="white" />
                    </span>
                }
            </div>
        );
    }

    renderVehicleButtons() {
        const { vehicle } = this.state;

        return <VehicleButtons vehicle={vehicle} />;
    }

    renderHighlights() {
        const { vehicle: { koenig_highlights, features_highlights } } = this.state;

        return (
            <div className={CSS_CLASS + '--highlights'}>
                {
                    koenig_highlights &&
                    this.renderHighlightGroup('Autohaus König Highlights:', koenig_highlights)
                }
                {
                    features_highlights &&
                    this.renderHighlightGroup('Ausstattungs Highlights:', features_highlights)
                }
            </div>
        );
    }

    renderHighlightGroup(headline, items) {
        return (
            <div>
                <span>{headline}</span>
                {
                    items.map((item, idx) => {
                        return this.renderHighlight(item, idx)
                    })
                }
            </div>
        );
    }

    renderHighlight(item, idx) {
        return (
            <span key={'highlight_' + idx}>
                <Icon variant="checkmark" color="green" />
                <span>{item}</span>
            </span>
        );
    }

    renderOfferDetails() {
        const { vehicle, currentImageIndex } = this.state;

        return <OfferDetails
            vehicle={vehicle}
            onIndexChange={idx => this.setState({ currentImageIndex: idx })}
            currentIndex={currentImageIndex}
            onChangeKws={(val) => this.setState({ kws_selection: val })}
            onChangeInsurance={(val) => this.setState({ insurance_selection: val })} />
    }

    renderPaymentTabs() {
        const { vehicle, currentImageIndex, kws_selection, insurance_selection } = this.state;
        const { payments: { exclusive } } = vehicle;

        if (exclusive === 'leasing') {
            delete vehicle.payments.financing;
            vehicle.payments.prices && delete vehicle.payments.prices.cash;
        }

        return <PaymentTabs
            vehicle={vehicle}
            onPaymentChange={(paymentType) => this.setState({ paymentType })}
            currentImageIndex={currentImageIndex}
            kws_selection={kws_selection}
            insurance_selection={insurance_selection} />
    }

    renderOfferButtons() {
        const { vehicle } = this.state;
        const { payments: { exclusive } } = vehicle;

        return !exclusive && <OfferButtons vehicle={vehicle} />
    }

    getPaymentType({ payments }) {
        const { leasing = {}, financing = {}, prices } = payments;

        switch (true) {
            case financing.is_default:
                return 'financing';
            case leasing.is_default:
                return 'leasing';
            case typeof prices.cash !== 'undefined' && prices.cash > 0:
                return 'cash'
        }
    }

    renderClues() {
        const { vehicle } = this.state;
        const { badges = [] } = vehicle;

        return (
            <React.Fragment>
                {
                    vehicle.clue && <p className="cnt-clues--clue-vehicle">{vehicle.clue}</p>
                }
                {
                    badges
                        .map(({ uid, title, description }) => {
                            return (
                                <p key={'clue' + uid}>
                                    <span><b>{title}</b></span><br />
                                    {description}
                                </p>
                            );
                        })
                }
            </React.Fragment>
        );
    }
}

export default VehicleDetailPage;