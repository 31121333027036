import React from 'react';
import Helmet from 'react-helmet';

import API_USED_CAR from '../../helper/api.usedCars.helper';
import API from '../../helper/api.helper';
import fbHelper from '../../helper/facebook.helper';
import './styles/styles.scss';

import Breadcrumb from '../../components/Breadcrumb';
import PageTile from '../../components/PageTile';
import LoadingSpinner from '../../components/LoadingSpinner';
import ImageGallery from './components/ImageGallery';
import CommonPage from '../CommonPage';

import OfferDetails from './components/OfferDetails';
import ProductDetails from './components/ProductDetails';
import TechnicalData from './components/TechnicalData';
import Features from './components/Features';
import Woosmap from './components/Woosmap';
import KoenigButton from '../../elements/KoenigButton';
import BranchDetails from './components/BranchDetails';
import AutoUncle from '../../components/AutoUncle';
import smiveHelper from '../../helper/smive.helper';
import { addProductTag } from '../../components/Criteo';

class GebrauchtwagenDetailPage extends React.Component {
    constructor(props) {
        super(props);
        let pageTiles, smiveOfferUrl;

        if (props.staticContext && props.staticContext.initialData) {
            [pageTiles, smiveOfferUrl] = props.staticContext.initialData;
        } else {
            if (typeof window !== 'undefined' && window.__initialData__) {
                [pageTiles, smiveOfferUrl] = window.__initialData__;
                delete window.__initialData__;
            }
        }

        this.state = {
            crumbs: [
                { label: 'Start', path: '/' },
                { label: 'Gebrauchtwagen', path: '/gebrauchtwagen' },
                { label: 'Gebrauchtwagenangebote', path: '/gebrauchtwagen/gebrauchtwagenangebote/' },
                { label: `${props.match.params.carId}`, current_page: true },
            ],
            vehicle: null,
            isLoading: true,
            tiles: pageTiles,
            smiveOfferUrl
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/gebrauchtwagen/') === 0) {
            const pageTiles = API.GET('/page_tiles?page=gebrauchtwagen');
            const parts = url.split('/');
            let smiveOfferUrl = null;

            if (parts && parts[3]) {
                const id = parts[3];

                smiveOfferUrl = smiveHelper.getOfferUrl(id);
            }

            return Promise.all([pageTiles, smiveOfferUrl])
                .catch(err => console.error(err));
        }
    }

    fetchCar() {
        const { carId } = this.props.match.params;

        API_USED_CAR.fetchVehicleById(carId)
            .then((res) => {
                this.setState({ vehicle: res.vehicle });
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    componentDidMount() {
        const { carId } = this.props.match.params;
        this.fetchCar();

        window.addEventListener('autouncle_loaded', () => {
            window.AuEmbed.config.data = {stockId: carId};
        });
        addProductTag(carId);
        fbHelper.track('ViewContent', {
            content_type: 'vehicle',
            content_ids: [carId],
        });
    }

    render() {
        const { vehicle, crumbs, isLoading, smiveOfferUrl } = this.state;
        const { tiles = [] } = this.state;

        if (vehicle) {
            vehicle.smiveOfferUrl = smiveOfferUrl
        }

        return (
            <CommonPage className='page-gebrauchtwagen-details'>
                <Helmet>
                    <title>Königs Gebrauchtwagen - Autohaus König</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className='vehicle-content'>

                        <h1>
                            <KoenigButton
                                text="Zurück"
                                linkTo="/gebrauchtwagen/gebrauchtwagenangebote/"
                                className="button-back"
                            />
                            {vehicle.marke} {vehicle.modell}
                        </h1>
                        {vehicle.pictures.length > 0 &&
                        <ImageGallery images={vehicle.pictures} vehicleId={vehicle.wgnr} videoLink={vehicle.phyron?.video} />}

                        <div className='offer-section'>
                            {vehicle.location.address && <Woosmap location={vehicle.location} />}
                            <OfferDetails vehicle={vehicle} />
                        </div>

                        <h2>Informationen zum Standort</h2>

                        <BranchDetails vehicle={vehicle} />

                        <h2>Technische Details</h2>

                        <div className='details-section'>
                            <ProductDetails vehicle={vehicle} />
                            <TechnicalData vehicle={vehicle} />
                        </div>

                        <Features vehicle={vehicle} />
                        <div className='au-widget-similar-cars' data-car-url={'https://www.autohaus-koenig.de/gebrauchtwagen/gebrauchtwagenangebote/' + vehicle.wgnr}></div>
                        <div className='topic-teaser'>
                            <h2>
                                Gebrauchtwagen in geprüfter TOP-Qualität
                                <br />
                                mit dem 7-Sterne-König-Check
                            </h2>
                            <div className='cnt-page-tiles'>
                                {tiles.map((tile, idx) => (
                                    <PageTile key={tile._id + '-' + idx} {...tile} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <AutoUncle />
            </CommonPage>
        );
    }
}

export default GebrauchtwagenDetailPage;