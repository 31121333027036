import React from "react";
import {isScooter} from '../VehicleBox/helper';

import './styles.scss';

class ModellNavigation extends React.Component {

    render() {
        const { models = models || [] } = this.props;

        if (models.length > 0) {
            const brand = models[0].brand;

            return (
                <div className="model-navigation">
                    {<ul>
                        {this.renderBrand(brand)}
                        {this.renderModels()}
                    </ul>}
                </div>
            );
        }

        return null;
    }

    renderBrand(brand) {
        const { activeModel } = this.props;
        const vehicleType = isScooter(brand.label) ? 'roller' : 'neuwagen';

        return (
            <li>
                {typeof (activeModel) === 'undefined'
                    ? <span>{brand.label}-Übersicht</span>
                    : <a href={`/${vehicleType}/` + brand._id + '/'} title={brand.label + '-Übersicht'}>
                        {brand.label}-Übersicht
                      </a>
                }
            </li>
        );
    }

    renderModels() {
        const { models = models || [], activeModel } = this.props;

        return models.map((model, idx) => {
            const vehicleType = isScooter(model.brand.label) ? 'roller' : 'neuwagen';
            const modelUrl = `/${vehicleType}/` + model.brand._id + '/' + model._id.replace('_', '-') + '/';
            
            return (
                <li key={model._id + '-navigation-' + idx}>
                    {activeModel === model._id
                        ? <span>{model.label}</span>
                        : <a href={modelUrl} title={model.label}>
                            {model.label}
                          </a>
                    }
                </li>
            )
        });
    }
}

export default ModellNavigation;