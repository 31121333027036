import './styles.scss';

import React from 'react';
import SectionHeadline from '../../components/SectionHeadline';
import KoenigButton from '../../elements/KoenigButton';

class AppointmentLink extends React.Component {

    render() {
        return (
            <div className="appointment-link">
                <SectionHeadline text="Werkstatt-Termin" />
                <p>Buchen Sie jetzt online einen Werkstatt-Termin mit Ihren Wunsch-Leistungen:</p>
                <p>
                    <KoenigButton text="Termin buchen" linkTo="/werkstatt/terminbuchung" />
                </p>
            </div>
        );
    }
}

export default AppointmentLink;