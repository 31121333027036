import React from 'react';
import './styles.scss';

const LoadingSpinner = () => {
    return ( 
        <div className="loader-wrapper">
            <div className="loader">
                <div className="loader__ball-1"></div>
                <div className="loader__ball-2"></div>
            </div>
            <h3 className="loader__text">Daten werden geladen</h3>
        </div>
     );
}
 
export default LoadingSpinner;