import './styles.scss';

import React from 'react';
import Icon from '../Icon';

const CSS_PREFIX = 'fc-star-rating';

export default ({ average }) => {
    return (
        <span className={CSS_PREFIX}>
            {
                [...Array(average)]
                    .map((e, idx) => <Icon key={'filled_star_' + idx} className="filled" variant="star" />)
            }
            {
                [...Array(5 - average)]
                    .map((e, idx) => <Icon key={'unfilled_star_' + idx} className="unfilled" variant="star" />)
            }
        </span>
    );
}