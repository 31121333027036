import React from 'react';
import ContactForm from '../../../components/ContactForm';
import Checkbox from '../../../elements/Checkbox';
import KoenigButton from '../../../elements/KoenigButton';
import API from '../../../helper/api.helper';
import { addConversionTag } from '../../../components/Criteo';

class UsedCarContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salutation: null,
            firstname: null,
            lastname: null,
            address: null,
            zip_code: null,
            location: null,
            email: null,
            phone: null,
            message: 'Bitte kontaktieren Sie mich',
            agb: false,
            inProgress: false
        };
    }

    async sendInquery() {
        this.setState({ inProgress: true });

        const { vehicleData } = this.props;
        const price = typeof vehicleData.preis !== 'undefined'
            ? vehicleData.preis + ' €'
            : '';
        const developed_from = [
            'wgnr',
            vehicleData.wgnr,
            vehicleData.typtext,
            (price ? 'für' : ''),
            price
        ].join(' ');

        const data = {
            ...this.state,
            developed_from,
            wgnr: vehicleData.wgnr
        };
        delete data.inProgress;

        await API
            .POST('/inquiries_used_cars', data)
            .then(res => {
                addConversionTag(vehicleData.preis_brutto, vehicleData.wgnr, data.email, data.zip_code, res._id);
                this.props.onSuccessful();
            }).catch(error => {
                this.props.onFailure();
            });
    };

    render() {
        const { inProgress } = this.state;
        return (
            <form className='contact-form'>
                <h2 className='contact-form__title'>Angebot zu diesem Fahrzeug anfordern</h2>

                <ContactForm onChange={(key, val) => this.setState({ [key]: val })} />

                <div className="contact-form__agb">
                    <Checkbox
                        onChange={agb => this.setState({ agb })} />
                    <span className="koenig-checkbox-text">
						<a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> habe ich gelesen
					</span>
                    <span>(*) = Pflichtfelder</span>
                </div>
                <KoenigButton
                    className="contact-form__submit"
                    text='Anfrage senden'
                    onClick={this.sendInquery.bind(this)}
                    disabled={!this.isValid() || inProgress}
                />
            </form>
        );
    }

    isValid = () => {
        const allValuesAreSet = [
            'salutation',
            'firstname',
            'lastname',
            'address',
            'zip_code',
            'location',
            'email',
            'phone',
        ].reduce((valid, key) => valid && this.state[key] !== null && this.state[key].length > 0, true);
        return allValuesAreSet && this.state.agb === true;
    };
}

export default UsedCarContactForm;
