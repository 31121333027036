import React from 'react';
import TableRow from './TableRow';
import PropTypes from 'prop-types';

const TechnicalData = ({ vehicle }) => {
	return (
		<div className='technical-data detail-box'>
			<h3 className="h4 detail-box__title">Technische Daten</h3>
			<div className="detail-box__inner">
				<div className='detail-box__table'>
					<TableRow label='Leistung' value={`${vehicle.kw} kW (${vehicle.ps} PS)`} />
					<TableRow label='Hubraum' value={`${vehicle.hubraum} ccm`} />
					<TableRow label='Türen' value={vehicle.tueren} />
					<TableRow label='Sitzplätze' value={vehicle.sitze} />
					<TableRow label='Farbe Außen' value={vehicle.farbe_aussen} />
					<TableRow label='Farbe Innen' value={vehicle.farbe_innen} />
					<TableRow label='Gesamtgewicht' value={`${vehicle.gesamtgewicht}kg`} />
				</div>
			</div>
		</div>
	);
};

export default TechnicalData;

TechnicalData.propTypes = {
	vehicle: PropTypes.object,
};
