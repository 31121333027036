import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track } from './components';

import './styles.scss';

const sliderStyle = {
	position: 'relative',
	width: '100%',
};

const RangeSlider = ({ steps, range, unit, onChangeValue, min, max, values }) => {
	const [defaultValues, setDefaultValues] = React.useState([]);
	const [levels, setLevels] = React.useState(range);

	React.useEffect(() => {
		setLevels(range);

		setDefaultValues([
			values[0] ? values[0] : min,
			values[1] ? values[1] : max
		]);
	}, []);

	const findClosestValue = (value, type) => {
		return levels.reduce((prev, curr) => {
			if (type === 'min') {
				return Math.abs(curr - value) <= Math.abs(prev - value) ? curr : prev;
			}
			return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
		});
	};

	const handleChange = (values) => {
		const closestMin = values[0] === 0 ? undefined : findClosestValue(values[0], 'min');
		const closestMax = values[1] === max ? undefined : findClosestValue(values[1], 'max');

		onChangeValue({von: closestMin, bis: closestMax});
	};

	return (
		<div className='range-slider'>
			<Slider
				mode={3}
				step={steps}
				domain={[Number(min), Number(max)]}
				rootStyle={sliderStyle}
				onChange={(values) => handleChange(values)}
				values={defaultValues}>
				<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
				<Handles>
					{({ handles, getHandleProps }) => (
						<div className='slider-handles'>
							{handles.map((handle, idx) => (
								<Handle
									index={idx}
									key={handle.id}
									handle={handle}
									domain={[Number(min), Number(max)]}
									unit={unit}
									getHandleProps={getHandleProps}
								/>
							))}
						</div>
					)}
				</Handles>
				<Tracks left={false} right={false}>
					{({ tracks, getTrackProps }) => (
						<div className='slider-tracks'>
							{tracks.map(({ id, source, target }) => (
								<Track
									key={id + target}
									source={source}
									target={target}
									getTrackProps={getTrackProps}
								/>
							))}
						</div>
					)}
				</Tracks>
			</Slider>
		</div>
	);
};

export default RangeSlider;


RangeSlider.propTypes = {
	unit: PropTypes.string,
	steps: PropTypes.number.isRequired,
	range: PropTypes.arrayOf(PropTypes.number).isRequired,
	onChangeValue: PropTypes.func.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	values: PropTypes.array,
};
