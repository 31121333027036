import "./styles.scss";

import React from 'react';
import Icon from '../Icon';

const CSS_CLASS = 'step-indicator';
const STEP_CSS = CSS_CLASS + '--step';

class StepIndicator extends React.Component {

    render() {
        const { steps = [] } = this.props;

        {
            return Array.isArray(steps) && steps.length === 0
                ? this.renderFallbackStep()
                : this.renderGivenSteps()
        }

    }

    renderFallbackStep() {
        return (
            <div className={CSS_CLASS} style={this.props.style}>
                <div className={STEP_CSS}>1</div>;
            </div>
        );
    }

    renderGivenSteps() {
        const { steps, style } = this.props;
        const [stepStates = [], labels = []] = steps;

        return (
            <div className={CSS_CLASS} style={style}>
                {
                    stepStates
                        .map((stepState, idx, allStates) => {
                            if (stepState === 0) {
                                return allStates.indexOf(0) === idx ? 0 : 1;
                            }

                            return stepState;
                        })
                        .map((stepState, idx, allSteps) => {
                            const classes = [STEP_CSS];
                            const labelText = typeof labels[idx] !== 'undefined' ? labels[idx] : '';
                            const stepNumber = stepState !== -1
                                ? <span className={STEP_CSS + '--text'}>{idx + 1}</span>
                                : <span className={STEP_CSS + '--icon'}><Icon variant="checkmark" /></span>;

                            if (stepState === -1) {
                                classes.push('completed');
                            }

                            if (stepState === 0) {
                                classes.push('current');
                            }

                            return (
                                <React.Fragment key={`indicator-step-${labelText}-${idx}`} >
                                    <div className={classes.join(' ')}>
                                        {stepNumber}
                                        <span className={STEP_CSS + '--label'}>{labelText}</span>
                                    </div>
                                    {
                                        idx < allSteps.length - 1
                                            ? <div className={STEP_CSS + '-spacer'}></div>
                                            : null
                                    }
                                </React.Fragment>
                            );
                        })
                }
            </div>
        );
    }

}

export default StepIndicator;
