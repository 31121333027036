export const idMap = {
    '8705154835552503676' : 'schoeneberg',
    '1029568408554322392' : 'erfurt-nord',
    '10642695486039453853' : 'heyrothsberge',
    '10959181438917852669' : 'greiz',
    '1117849385630503857' : 'nohra',
    '11321758531259506753' : 'frankfurt-oder',
    '11526888958203157411' : 'charlottenburg-2',
    '11721720882729330988' : 'steglitz',
    '11908719710925424867' : 'reinickendorf-2',
    '1240568893565731890' : 'halle',
    '1249954991711163631' : 'seelow',
    '12563496859975972035' : 'wilmersdorf',
    '12790823354983557627' : 'hamburg',
    '13061679738123233727' : 'leipzig',
    '14203264213568291737' : 'koepenick',
    '14245557846695301169' : 'erfurt-sued-2',
    '14992055771139293' : 'schoenebeck',
    '15084148036537671411' : 'teltow-2',
    '15180772096148373225' : 'erfurt-nord-2',
    '15720558633584433817' : 'erfurt-sued',
    '16733319035040771917' : 'luckenwalde',
    '16860686258944091256' : 'koenigs-wusterhausen',
    '17111787531943180804' : 'zossen',
    '17480506384299499035' : 'oranienburg',
    '17980479492733677975' : 'kahla',
    '17985623734888374360' : 'ueckermuende',
    '219280146641261350' : 'pasewalk',
    '2610588074475385696' : 'prenzlau',
    '2635764877543123207' : 'jena',
    '291755747892446723' : 'dessau',
    '3371509159115823881' : 'finsterwalde-massen',
    '3986522767973369760' : 'hoppegarten',
    '4804171853478782754' : 'spandau-3',
    '5162329596574655920' : 'fuerstenwalde',
    '5482158140794931997' : 'charlottenburg',
    '5955235051021423944' : 'eisenhuettenstadt',
    '6007822011762551239' : 'telwtow',
    '6010047037324142607' : 'pankow',
    '6131965345949304475' : 'henningsdorf',
    '6382808526346052825' : 'gosen',
    '6578509000988530598' : 'spandau-2',
    '8354759960378784485' : 'bad-saarow',
    '869244695800648580' : 'haldensleben',
    '8811862679704381075' : 'zerbst',
    '9418686991873732343' : 'reinickendorf',
    '9431015245624184512' : 'magdeburg-2',
    '9509829989430509939' : 'steglitz-2',
    '9715581518851594235' : 'spandau',
    '9849500363057507884' : 'mitte'
};