import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import API from '../../helper/api.helper';
import SectionHeadline from '../../components/SectionHeadline';
import VehicleBox from '../../components/VehicleBox';
import SimpleContent from '../../components/SimpleContent';
import KoenigImage from '../../components/KoenigImage';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Unter 100 Euro', current_page: true }
]
const CSS_CLASS = 'page-unter-100';

class Unter100EuroPage extends React.Component {
    constructor(props) {
        super(props);

        let data = [];

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            if (typeof window !== 'undefined') {
                data = window.__initialData__;
                delete window.__initialData__;
            }
        }

        const [pageData = {}, vehicles = {}] = data;

        this.state = {
            data: {
                ...pageData,
                ...props.pageData,
                vehicles
            }
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/unter-100-euro') === 0) {
            const vehicles = API
                .GET('/vehicles?filtered=1&sortby=preis&leasing_rate_max=100')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            const pageData = API
                .GET('/pages/unter_100')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            return Promise.all([pageData, vehicles]);
        }
    }

    render() {
        const { headline = "", seo_content = "" } = this.state.data;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Privatangebote UNTER 100€ mtl. Leasingrate</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>{headline}</h1>

                {this.renderTeaser()}

                <SimpleContent content={seo_content} />
                <SectionHeadline text="Unsere Angebote im Überblick" />
                {this.renderVehicleList()}

            </CommonPage>
        );
    }

    renderTeaser() {
        const { teaser_src } = this.state.data;

        return (
            <div className={CSS_CLASS + '--header-img'}>
                {teaser_src && teaser_src.mobile
                    ? this.renderImage(teaser_src.mobile, CSS_CLASS + '--header-img--mobile')
                    : null}
                {teaser_src && teaser_src.desktop
                    ? this.renderImage(teaser_src.desktop, CSS_CLASS + '--header-img--desktop')
                    : null}
            </div>
        );
    }

    renderImage(src, cssClass) {
        const { headline } = this.state.data;

        return <KoenigImage className={cssClass} fileHash={src} alt={headline} />;
    }


    renderVehicleList() {
        const { vehicles = {} } = this.state.data;
        const { private: privateVehicles = [] } = vehicles;

        if (privateVehicles.length > 0) {
            return this.renderVehicleBoxes(privateVehicles)
        } else {
            return this.renderDefaultNote();
        }
    }

    renderVehicleBoxes(vehicles) {
        return (
            <div className={CSS_CLASS + '--vehicles'}>
                {
                    vehicles.map((vehicle, idx) =>
                        <div key={"box-wrapper-" + idx + "-" + vehicle._id} className={CSS_CLASS + '--vehicles--box-wrapper'}>
                            <VehicleBox vehicle={vehicle} />
                        </div>
                    )
                }
            </div>
        );
    }

    renderDefaultNote() {
        return (
            <div className={CSS_CLASS + '--vehicles-empty'}>Zur Zeit gibt es leider keine entsprechenden Angebote</div>
        );
    }
}

export default Unter100EuroPage;