import React from 'react';

const ERR_TOO_SHORT = "Eingabefeld darf nicht leer bleiben.";
const ERR_INVALID_EMAIL = "Die Eingabe ist keine gültige E-Mail Adresse.";

class KoenigInput extends React.Component {
    constructor(props) {
        super(props);
        const isEmpty = typeof props.value !== 'undefined' && props.value !== null && props.value.length === 0;
        let errorText = props.isRequired === true && isEmpty ? ERR_TOO_SHORT : '';
        errorText = props.errorText && props.errorText.length > 0 ? props.errorText : errorText;

        this.state = {
            value: props.value || '',
            errorText
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.selfTriggered = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.selfTriggered && prevState.value !== this.state.value) {
            const changeFunc = this.props.onChange || function () { };
            changeFunc(this.state.value);
        }
        this.selfTriggered = false;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.selfTriggered = true;
            this.setState({ value: this.props.value || '' });
        }
        if (prevProps.errorText !== this.props.errorText) {
            this.setState({ errorText: this.props.errorText });
        }
    }

    handleBlur(ev) {
        let { errorText = '' } = this.state;
        const { value } = ev.target;
        const { isRequired, onValidate, isEmailField } = this.props;
        const validateFunc = onValidate || function () { };

        if (isRequired === true && value.length === 0) {
            errorText = ERR_TOO_SHORT;
        }

        if (isEmailField === true) {
            errorText = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
                ? errorText
                : ERR_INVALID_EMAIL;
        }

        const validateText = validateFunc(value);
        if (typeof validateText === 'string') {
            errorText = validateText;
        } else if (validateText === false) {
            errorText = '__WITHOUT_MSG__';
        }

        this.setState({ errorText });
    }

    render() {
        const { id, placeholder, className, notValid, name, inputStyle, isNumberField } = this.props;
        const { errorText, value } = this.state;
        const classes = ['koenig-input'];

        if (className) {
            classes.push(className);
        }

        if (errorText.length !== 0 || notValid === true) {
            classes.push('error');
        }

        return (
            <span id={id || ''} className={classes.join(' ')}>
                <label>{this.getLabelText()}</label>
                <input
                    type="text"
                    value={value}
                    placeholder={placeholder || ''}
                    name={name || ''}
                    onBlur={this.handleBlur}
                    onFocus={() => this.setState({ errorText: '' })}
                    onChange={ev => this.setState({ value: isNumberField === true ? ev.target.value.replace(/\D/g, '') : ev.target.value })}
                    style={inputStyle} />
                {this.renderErrorText()}
            </span>
        );
    }

    getLabelText() {
        const { label, isRequired } = this.props;
        return isRequired === true ? label + '*' : label;
    }

    renderErrorText() {
        const { errorText } = this.state;
        return (
            <span className="error-text">{errorText.replace('__WITHOUT_MSG__', '')}</span>
        );
    }
}

export default KoenigInput;