import "./styles.scss";

import React, {useRef} from 'react';
import telecashLogo from '../../../assets/images/telecash-logo.png';
import paymentLogos from '../../../assets/images/payment-logos_neu.png';
import KoenigButton from '../../../elements/KoenigButton';
import ApiHelper from "../../../helper/api.helper";
import TelecashHelper from '../../../helper/telecash.helper';
import NavigationHelper from '../../../helper/navigation.helper';
import CommonPage from "../../CommonPage";
import Breadcrumb from "../../../components/Breadcrumb";
import StepIndicator from "../../../components/StepIndicator";
import InfoBox from "../../../components/InfoBox";
import Checkbox from "../../../elements/Checkbox";
import Icon from "../../../components/Icon";
import QuantitySelect from "../../../components/SimpleShop/components/QuantitySelect";
import Functions from '../../../components/SimpleShop/functions';
import moment from "moment";

import "moment/locale/de";
import "moment-timezone";

const CSS_CLASS = 'simple-shop--step2';
const CSS_SUMMARY = CSS_CLASS + '--summary';

class Schritt2Page extends React.Component {
    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        const {shop_items, shipping_costs, shipping_costs_limit} = postData;

        this.state = {
            agb: false,
            blockElements: false,
            ...postData,
            initialShippingCosts: shipping_costs,
            shipping_costs: this.getShippingCosts(shop_items, shipping_costs, shipping_costs_limit),
            shipping_costs_limit
        };
    }

    handleQuantityIncrementing(productId) {
        const {shop_items, itemLimit, initialShippingCosts, shipping_costs_limit} = this.state;
        const newShopItems = shop_items.map(si => {
            if (si && si.product && si.product._id === productId) {
                si.quantity = Math.min(++si.quantity, itemLimit);
                si.pos_total = si.quantity * Functions.getItemPrice(si.product);
            }

            return si;
        })

        this.setState({
            shop_items: newShopItems,
            shipping_costs: this.getShippingCosts(newShopItems, initialShippingCosts, shipping_costs_limit)
        });
    }

    handleQuantityDecrementing(productId) {
        const {shop_items, initialShippingCosts, shipping_costs_limit} = this.state;
        const newShopItems = shop_items
            .map(si => {
                if (si && si.product && si.product._id === productId) {
                    if (--si.quantity === 0) {
                        return null
                    }
                }

                si.pos_total = si.quantity * Functions.getItemPrice(si.product);

                return si;
            })
            .filter(si => si !== null)

        this.setState({
            shop_items: newShopItems,
            shipping_costs: this.getShippingCosts(newShopItems, initialShippingCosts, shipping_costs_limit)
        });
    }

    async handleSubmit() {
        this.setState({
            blockElements: true
        });
        const {shop_items, shipping_costs, voucher} = this.state;
        const voucherPercentage = voucher === "20foryou" ? 20 : 0;

        const order = {
            ...this.state,
            prepayment: true,
            total_price: Functions.getTotalPrice(shop_items, shipping_costs, voucherPercentage)
        };

        delete order.blockElements;
        delete order.isSummaryVisible;
        delete order.chosenCategory;

        await ApiHelper
            .POST('/simple_shop_orders', order)
            .then(async savedOrder => {
                const paymentRequest = {
                    salutation: savedOrder.salutation,
                    firstname: savedOrder.firstname,
                    lastname: savedOrder.lastname,
                    address: savedOrder.address,
                    zip_code: savedOrder.zip_code,
                    location: savedOrder.location,
                    email: savedOrder.email,
                    phone: savedOrder.phone,
                    oid: savedOrder._id,
                    total_price: Functions.getTotalPrice(savedOrder.shop_items, savedOrder.shipping_costs, voucherPercentage),
                    response_success: '/shopping/checkout/erfolgreich/',
                    response_failed: '/shopping/checkout/zahlungsfehler/'
                };

                return await ApiHelper
                    .POST('/telecash/payments', paymentRequest)
                    .then(paymentObject => {
                        TelecashHelper.execPaymentRequest(paymentObject)
                    })
                    .catch(() => NavigationHelper.linkTo('/shopping/checkout/zahlungsfehler/', {oid: savedOrder._id}))
            })
            .catch(() => NavigationHelper.linkTo('/shopping/checkout/fehlgeschlagen/', this.state));
    }

    render() {
        const {
            salutation, firstname, lastname,
            address, zip_code, location,
            email, phone, agb, shop_items,
            blockElements, shopUrl
        } = this.state;

        const crumbs = [
            {label: 'Start', path: '/'},
            {label: 'Produktauswahl', onClick: NavigationHelper.linkTo.bind(null, shopUrl, this.state)},
            {label: 'Checkout', current_page: true}
        ];

        const indicatorSteps = [
            [-1, 0],
            ['Persönliche Daten', 'Prüfen & Bestellen']
        ];

        return (
            <CommonPage className="page-simple-shop--schritt2">
                <Breadcrumb crumbs={crumbs}/>

                <h1 className={CSS_CLASS + '--headline'}>Ihre Bestellung in nur 2 Schritten</h1>

                <div className={CSS_CLASS}>
                    <StepIndicator steps={indicatorSteps}/>

                    <div className={CSS_CLASS + '--intro-text'}>
                        <b>Prüfen Sie nun bitte abschließend die Bestellübersicht und bestellen Sie verbindlich.</b>
                        <p>In dieser Übersicht finden Sie noch einmal alle Informationen zu Ihrer Bestellung. Sollte
                            etwas nicht stimmen, können Sie dies ganz einfach wieder korrigieren. Zum Abschluss dieser
                            Bestellung können Sie ganz einfach und bequem sofort online bezahlen.</p>
                    </div>

                    <div className={CSS_CLASS + '--info-box--wrapper'}>
                        <InfoBox
                            label="Ihre persönlichen Daten"
                            linkTo="/shopping/checkout/schritt1/"
                            className={CSS_CLASS + '--info-box--personal-data'}
                            body={this.state}
                            headline={`${salutation} ${firstname} ${lastname}`}
                            infoItems={[
                                [
                                    {"Adresse": [address, `${zip_code} ${location}`]}
                                ],
                                [
                                    {"E-Mail": email},
                                    {"Telefon": phone}
                                ]
                            ]}/>
                    </div>

                    {this.renderSummary()}

                    <div className={CSS_CLASS + '--legal-informations'}>
                        Es gelten die <a href="/agb/" target="_blank">Allgemeinen Geschäftsbedingungen</a>.
                    </div>
                    <div className={CSS_CLASS + '--data-usage'}>
                        <div className={CSS_CLASS + '--data-usage--check'}>
                            <Checkbox onChange={checked => this.setState({agb: checked})}/>
                        </div>
                        <div className={CSS_CLASS + '--data-usage--text'}>
                            <b>Ja</b>, ich bin einverstanden, dass meine Daten zur Vertragsabwicklung und Daten über
                            meine Nutzung des Onlineshops gemeinsam mit einer pseudonymen Besucher-ID, die
                            auf Basis ausgelesener Informationen meines Endgerätes generiert wird, zu dem Zweck
                            verarbeitet werden, automatisiert zu prüfen, ob Anhaltspunkte für einen Missbrauch unseres
                            Online-Dienstes bestehen. Weitere Details zu diesem Verfahren finden Sie <a
                            href="/datenschutz/" target="_blank">hier</a>.
                        </div>
                    </div>

                    {this.renderPaymentSection()}

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            text="Zurück zu Schritt 1"
                            className="back-button"
                            onClick={() => NavigationHelper.linkTo('/shopping/checkout/schritt1/', this.state)}/>

                        <KoenigButton
                            text="Jetzt verbindlich bestellen"
                            className="submit"
                            onClick={this.handleSubmit.bind(this)}
                            disabled={agb === false || shop_items.length === 0}/>
                    </div>

                    {
                        blockElements === true
                            ? <div className={CSS_CLASS + '--blocker'}></div>
                            : null
                    }

                </div>
            </CommonPage>
        )

    }

    renderSummary() {
        const {shop_items = [], shipping_costs, shopUrl, itemLimit} = this.state;
        const is2022 = moment().tz("Europe/Berlin").year() < 2023;
        const TWENTY_FORYOU_VOUCHER = is2022 ? 20 : 0;
        const totalPrice = Functions.getTotalPrice(shop_items, shipping_costs);
        const voucherPrice = Functions.getTotalPrice(shop_items, shipping_costs, TWENTY_FORYOU_VOUCHER);
        const voucherAreActive = is2022;

        return (
            <div className={CSS_SUMMARY}>
                <div className={CSS_SUMMARY + '--row'}>
                    <b className={CSS_SUMMARY + '--label'}>Ausgewählte Artikel im Überblick</b>
                    <div className={CSS_SUMMARY + '--link'}>
                        <Icon
                            variant="edit"
                            onClick={() => NavigationHelper.linkTo(shopUrl, this.state)}
                            style={{display: 'block', width: '1.25rem'}}/>
                    </div>
                </div>

                <div className={CSS_SUMMARY + '--listing'}>
                    {
                        shop_items.map(({quantity, product: item}, idx) => {
                            const classes = [CSS_SUMMARY + '--listing--item'];
                            const itemPrice = quantity * Functions.getItemPrice(item);

                            return (
                                <div key={`simple-shop-step2-summary-item-${idx}`} className={classes.join(' ')}>
                                    <b className={CSS_SUMMARY + '--listing--item--title'}>
                                        {item.title}
                                        {item.selected_option?.length > 0 &&
                                            <span>, {item.selected_option}</span>
                                        }
                                    </b>
                                    <div className={CSS_SUMMARY + '--listing--item--action'}>
                                        <QuantitySelect
                                            value={quantity}
                                            limit={itemLimit}
                                            onIncrement={this.handleQuantityIncrementing.bind(this, item._id)}
                                            onDecrement={this.handleQuantityDecrementing.bind(this, item._id)}/>
                                    </div>
                                    <div className={CSS_SUMMARY + '--listing--item--price'}>
                                        <span
                                            className={CSS_SUMMARY + '--listing--item--price--default'}>{Functions.toCurrency(itemPrice)} €</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {voucherAreActive && this.state.type === "accessory" &&
                    <div className={CSS_CLASS + "--voucher"}>
                        {this.state.voucher !== "20foryou"
                            ? <VoucherInput onValidVoucherEntered={voucher => this.setState({voucher})}/>
                            : <VoucherHint/>
                        }
                    </div>
                }

                <div className={CSS_SUMMARY + '--total'}>
                    <div className={CSS_SUMMARY + '--total--column'}>
                        <span className={CSS_SUMMARY + '--total--delivery'}>
                            {
                                shipping_costs
                                    ? `inkl. ${Functions.toCurrency(shipping_costs)}€ Versandkosten`
                                    : 'kostenloser Versand'
                            }
                        </span><br/>
                        <span className={CSS_SUMMARY + '--total--vat-hint'}>inkl. 19% MwSt.</span>
                        <b className={CSS_SUMMARY + '--total--text'}>Summe</b>
                    </div>

                    <div className={CSS_SUMMARY + '--total--price'}>
                        {this.state.voucher === "20foryou"
                            ? <VoucherPrice voucherPrice={voucherPrice} regularPrice={totalPrice}/>
                            : <RegularPrice price={totalPrice}/>
                        }

                    </div>
                </div>
            </div>
        );
    }

    renderPaymentSection() {
        return (
            <div className={CSS_CLASS + '--payment'}>
                <div className={CSS_CLASS + '--payment--text'}>
                    <p>Mit Abschluss Ihrer Bestellung werden Sie zu unseren Payment-Partner Telecash geleitet, wo Sie
                        den fälligen Betrag mit Paypal, Kreditkarte oder Sofortüberweisung bezahlen können. Nach der
                        Zahlung werden Sie zurück auf unsere Webseite geleitet.</p>
                    <div className={CSS_CLASS + '--payment--logos'}>
                        <img src={telecashLogo} alt="Telecash Logo" style={{width: '9.375rem'}}/>
                        <img src={paymentLogos} alt="Logos der möglichen Bezahldienstleister wie z.B. PayPal"/>
                    </div>
                </div>
            </div>
        );
    }

    getShippingCosts(shop_items, initialShippingCosts, shipping_costs_limit) {
        if (typeof shipping_costs_limit === 'undefined') {
            return 0;
        }

        const total_price = Functions.getTotalPrice(shop_items);

        return total_price >= shipping_costs_limit ? 0 : initialShippingCosts;
    }
}

function VoucherInput(props) {
    let inputRef = useRef(null);

    const handleClick = () => {
        if (inputRef.current.value?.toLowerCase() === "20foryou") {
            props.onValidVoucherEntered && props.onValidVoucherEntered("20foryou");
        }
    };

    const handleKeyDown = ev => {
        ev.key === "Enter" && handleClick();
    }

    return (
        <>
            <input type="text" placeholder="Gutschein" ref={inputRef} onKeyDown={handleKeyDown}/>
            <button onClick={handleClick}>Anwenden</button>
        </>
    );
}

function VoucherHint() {
    return (
        <>
            <span>Angewendeter Gutschein:</span>
            <b>20FORYOU</b>
        </>
    );
}

function RegularPrice(props) {
    return (
        <>
            <b>{Functions.toCurrency(props.price)}</b>
            <b>€</b>
        </>
    );
}

function VoucherPrice(props) {
    return (
        <>
            <del>{Functions.toCurrency(props.regularPrice, true)} €</del>
            <ins>{Functions.toCurrency(props.voucherPrice, true)}<span>€</span></ins>
        </>
    )
}

export default Schritt2Page;