import './styles.scss';

import React from 'react';
import Icon from '../Icon';

export default props => {
    const classNames = props.className && props.className.length > 0 ? props.className.split(' ') : [];
    const classes = [].concat('icon-button', classNames);
    return (
        <Icon
            {...props}
            className={classes.join(' ')}
            role="button" />
    );
};