import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import UrlHelper from '../../helper/url.helper';

class MobilityAds extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    componentDidMount() {
        if (typeof window !== 'undefined') {
            const params = UrlHelper.serializeQuery(window.location);

            if (params !== null
                && typeof params.coyotetrackingid !== 'undefined'
                && params.coyotetrackingid.length > 0) {

                this.setCookie(params.coyotetrackingid);
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=BUIHJREOR60R2UPF92EG';
            document.head.appendChild(script);
        }
    }

    render() {
        return null;
    }

    setCookie(mobilityAdsParam = null) {
        const cookies = this.props.cookies;
        cookies.set(
            'mobilityAds',
            mobilityAdsParam,
            { path: '/', maxAge: 2592000 }
        );
    }
}

export default withCookies(MobilityAds);