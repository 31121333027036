import React from 'react';
import API from "../../../helper/api.helper";
import KoenigImage from "../../../components/KoenigImage";
import CommonPage from "../../CommonPage";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/Breadcrumb";
import ReactHtmlParser from "react-html-parser";
import KoenigSelect from "../../../elements/KoenigSelect";
import KoenigInput from "../../../elements/KoenigInput";
import RatingWidget from "../../../components/RatingWidget";
import RadioButtonGroup from "../../../elements/RadioButtonGroup";
import Icon from "../../../components/Icon";
import Checkbox from "../../../elements/Checkbox";
import KoenigButton from "../../../elements/KoenigButton";
import formHelper from '../../../helper/form.helper';

const CSS_CLASS = 'page-werkstatt-bewertung';
const CSS_FORM = CSS_CLASS + '--form';
const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Werkstattzufriedenheit', current_page: true },
];

class BewertungPage extends React.Component {
    constructor(props) {
        super(props);

        let pageData, branches;

        if (props.staticContext && props.staticContext.initialData) {
            [pageData, branches] = props.staticContext.initialData;
        } else {
            if (window && window.__initialData__) {
                [pageData, branches] = window.__initialData__;
                delete window.__initialData__;
            }
        }

        this.state = {
            pageData,
            branches,
            firstname: null,
            lastname: null,
            plate: null,
            email: null,
            branch: null,
            agb: false,
            online_booking: false,
            reception: 0,
            recommendation: 0,
            service_quality: 0,
            service_summary: 0,
            payment_financing: false,
            payment_possibilities: false,
            inProgress: false
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/werkstatt/werkstattbewertung') === 0) {
            return Promise.all([
                API
                    .GET('/pages/werkstatt_bewertung')
                    .catch(err => {
                        console.error(err);
                        return [];
                    }),
                API
                    .GET(`/branches`)
                    .catch(err => {
                        console.error(err);
                        return [];
                    })
            ]);
        }
    }

    async handleSubmit() {
        this.setState({ inProgress: true });

        const document = { ...this.state };
        delete document.pageData;
        delete document.branches;
        delete document.inProgress;

        await API
            .POST('/workshop_ratings', document)
            .catch(console.error);

        window.open('/', '_self');
    }

    render() {
        const { headline, sub_headline = "", rating_headline } = this.state.pageData;
        const { inProgress } = this.state;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{headline}</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />
                <h1>{headline}</h1>
                {this.renderTeaser()}
                <h2>{ReactHtmlParser(sub_headline)}</h2>
                {this.renderForm()}
                <h3>{rating_headline}</h3>
                {this.renderRatings()}
                <div className={CSS_CLASS + '--closing'}>
                    {this.renderAgbCheckbox()}
                    <KoenigButton
                        text="Meine Bewertung jetzt absenden"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.isValid() || inProgress} />
                </div>
            </CommonPage>
        );
    }

    renderTeaser() {
        const { teaser_src = { mobile: null, desktop: null } } = this.state.pageData;

        return (
            <div className={CSS_CLASS + '--teaser'}>
                {teaser_src.mobile && this.renderImage(teaser_src.mobile, CSS_CLASS + '--teaser--mobile')}
                {teaser_src.desktop && this.renderImage(teaser_src.desktop, CSS_CLASS + '--teaser--desktop')}
            </div>
        );
    }

    renderImage(src, cssClass) {
        const { headline } = this.state.pageData;

        return <KoenigImage className={cssClass} fileHash={src} alt={headline} />;
    }

    renderForm() {
        const { firstname, lastname, plate, email } = this.state;
        const { branches } = this.state;
        const branchOptions = branches.map(b => (b && b.official_name)
            ? b.name + ' (' + b.official_name + ')'
            : b.name
        );

        return (
            <div className={CSS_FORM}>
                <div className={CSS_FORM + '--left'}>
                    <KoenigInput
                        label="Vorname"
                        value={firstname}
                        onChange={firstname => this.setState({ firstname })}
                        isRequired />

                    <KoenigInput
                        label="Nachname"
                        value={lastname}
                        onChange={lastname => this.setState({ lastname })}
                        isRequired />

                    <KoenigInput
                        value={plate}
                        onChange={plate => this.setState({ plate })}
                        label="Kennzeichen"
                        placeholder="X-XX 000"
                        isRequired />

                    <KoenigInput
                        value={email}
                        onChange={email => this.setState({ email })}
                        isEmailField
                        label="E-Mail"
                        isRequired />

                    <KoenigSelect
                        label="Filiale"
                        options={branchOptions}
                        value="Bitte wählen"
                        onChange={branch => this.setState({ branch })}
                        isRequired />
                </div>
                <div className={CSS_FORM + '--right'}>
                    <label>Gern nehmen wir Ihre Vorschläge zur Verbesserung unseres Service auf:</label>
                    <textarea
                        placeholder="Bitte teilen Sie uns Ihre Meinung mit"
                        onChange={ev => this.setState({ message: ev.target.value })} />
                </div>
            </div>
        );
    }

    renderRatings() {
        const { ratings } = this.state.pageData;

        return ratings &&
            <div className={CSS_CLASS + '--rating-cnt'}>
                {
                    ratings.map((rating, idx) => this.renderRating(rating, idx))
                }
            </div>
    }

    renderRating(rating, idx) {
        const { topic, type, id } = rating;

        return topic &&
            <span className={CSS_CLASS + '--rating'} key={`rating_${id}_${idx}`}>
                <span>{topic}</span>
                {type === 'widget' && <RatingWidget onChange={val => this.setState({ [id]: val })} />}
                {type === 'checkbox' && this.renderRadioButtons(id)}
            </span>
    }

    renderRadioButtons(id) {
        return (
            <RadioButtonGroup
                onChange={idx => this.setState({ [id]: idx === 0 })}
                icon={<Icon color="white" variant="checkmark" />}
            />
        );
    }

    renderAgbCheckbox() {
        return (
            <div className={CSS_CLASS + '--data-privacy'}>
                <Checkbox onChange={agb => this.setState({ agb })} />

                <span className="koenig-checkbox-text">
                    Ich habe die <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen
                </span>
            </div>
        );
    }

    isValid = () => {
        return formHelper.isValid(
            [
                'firstname', 'lastname',
                'plate', 'branch', 'email'
            ],
            this.state
        ) && this.state.agb;
    }
}

export default BewertungPage;