function linkTo(target, body) {
    if (typeof window !== 'undefined' && window.document) {
        const form = document.createElement('form');
        const input = document.createElement('input');

        form.setAttribute('method', 'POST');
        form.setAttribute('action', target);

        input.setAttribute('name', 'data');
        input.setAttribute('type', 'hidden');
        input.setAttribute('value', JSON.stringify(body));

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
    }
}

export default {
    linkTo
};

