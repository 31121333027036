import "./styles.scss";

import React from 'react';
import CommonPage from "../../../CommonPage";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/Breadcrumb";
import Icon from "../../../../components/Icon";
import InfoBox from "../components/InfoBox";
import Functions, { ISO_DATE_FORMAT } from "../functions";
import KoenigButton from "../../../../elements/KoenigButton";
import ApiHelper from "../../../../helper/api.helper";
import moment from "moment";

import "moment/locale/de";
import "moment-timezone";

const CSS_CLASS = 'wtt--successful';
const CSS_CONFIRMATION = CSS_CLASS + '--confirmation';
const CSS_SUMMARY = CSS_CLASS + '--summary';

class ErfolgreichPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    async componentDidMount() {
        const { brand, model, branch } = this.state;
        const isBrandAnObj = typeof brand === 'object';
        const isModelAnObj = typeof model === 'object';
        const isBranchAnObj = typeof branch === 'object';
        const newState = {};

        try {
            if (!isBrandAnObj || !isModelAnObj) {
                const workshopBrands = await ApiHelper.GET('/workshop_brands');
                const [brandObj] = workshopBrands.filter(wb => wb._id === brand);

                if (brandObj) {
                    const [modelObj] = brandObj.models.filter(m => m.uid === model);

                    newState.brand = brandObj;
                    newState.model = modelObj;

                    if (brand.indexOf('sonstige') === 0) {
                        newState.model = { uid: 0, label: model };
                    }
                }
            }

            if (!isBranchAnObj) {
                const workshopBranches = await ApiHelper.GET('/workshop_branches');
                const [branchObj] = workshopBranches.filter(wb => wb._id === branch);

                newState.branch = branchObj;
            }

            this.setState({ ...newState });

        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const {
            brand, model,
            branch, day, time,
            plate, vin, mileage, registration,
            salutation, firstname, lastname,
            address, zip_code, location,
            email, phone
        } = this.state;

        const brandText = brand && brand.label ? brand.label : '';
        const modelText = model && model.label ? model.label : '';
        const branchText = branch && branch.label ? branch.label : '';
        const addressParts = branch && branch.address_row ? branch.address_row.split(', ') : [];

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Werkstatt', path: '/werkstatt/' },
            { label: 'Terminbuchung', current_page: true }
        ];

        let vehicleHeadline = brandText + ' ' + modelText;
        if (brand && brand._id && brand._id.indexOf('sonstige') === 0) {
            vehicleHeadline = modelText;
        }

        return (
            <CommonPage className="page-terminbuchung-erfolgreich">
                <Helmet>
                    <title>Terminbuchung - Werkstatttermin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <div className={CSS_CLASS}>
                    <h1 className={CSS_CLASS + '--headline'}>Wir haben Ihre Terminbuchung erhalten.</h1>

                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="checkmark"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Terminbuchung abgeschlossen</b>
                    </div>

                    <div className={CSS_CONFIRMATION}>
                        <h2 className={CSS_CONFIRMATION + '--headline'}>Vielen Dank</h2>
                        <p className={CSS_CONFIRMATION + '--text'}>Wir werden Ihre Anmeldung umgehend bearbeiten und Sie
                            erhalten in wenigen Minuten zur Bestätigung Ihrer Anmeldung eine E&#8209;Mail an die von
                            Ihnen angegebene E&#8209;Mail&#8209;Adresse:</p>
                        <b className={CSS_CONFIRMATION + '--email'}>{email}</b>
                        <p className={CSS_CONFIRMATION + '--spam-hint'}>Sollten Sie diese E-Mail nicht in Ihrem
                            Posteingang finden, überprüfen Sie bitte auch Ihren Spam-Ordner.</p>
                    </div>

                    <h2 className={CSS_CLASS + '--headline--overview'}>Übersicht Ihrer abgeschlossenen
                        Terminbuchung</h2>

                    <div className={CSS_CLASS + '--info-box--wrapper'}>
                        <InfoBox
                            label="Ihr Termin"
                            className={CSS_CLASS + '--info-box--schedule'}
                            headline={branchText}
                            infoItems={[
                                { "Standort": addressParts },
                                { "Datum": moment(day, ISO_DATE_FORMAT).format('DD.MM.YYYY') },
                                { "Uhrzeit": time }
                            ]} />

                        <InfoBox
                            label="Ihr Fahrzeug"
                            className={CSS_CLASS + '--info-box--vehicle'}
                            headline={vehicleHeadline}
                            infoItems={[
                                { "Kennzeichen": plate },
                                { "Fahrzeug-Identnummer": vin },
                                { "Kilometerstand": mileage },
                                { "Erstzulassung": moment(registration, ISO_DATE_FORMAT).format('DD.MM.YYYY') }
                            ]} />

                        <InfoBox
                            label="Ihre persönlichen Daten"
                            className={CSS_CLASS + '--info-box--personal-data'}
                            headline={`${salutation} ${firstname} ${lastname}`}
                            infoItems={[
                                [
                                    { "Adresse": [address, `${zip_code} ${location}`] }
                                ],
                                [
                                    { "E-Mail": email },
                                    { "Telefon": phone }
                                ]
                            ]} />
                    </div>
                    {this.renderSummary()}

                    <KoenigButton
                        className={CSS_CLASS + '--home-link'}
                        text="Zurück zur Startseite"
                        linkTo="/" />
                </div>


            </CommonPage>
        );
    }

    renderSummary() {
        const { shop_items = [], kws_member } = this.state;
        const totalPrice = Functions.getTotalPrice(shop_items, kws_member);

        return (
            <div className={CSS_SUMMARY}>
                <div className={CSS_SUMMARY + '--row'}>
                    <b className={CSS_SUMMARY + '--label'}>Ausgewählte Leistungen im Überblick</b>
                </div>

                <div className={CSS_SUMMARY + '--listing'}>
                    {
                        shop_items.map((item, idx) => {
                            const { prices } = item;
                            const classes = [CSS_SUMMARY + '--listing--item'];

                            if (kws_member === true && prices && prices.hasOwnProperty('kws')) {
                                classes.push('kws');
                            }

                            return (
                                <div key={`wtt-step4-summary-item-${idx}`} className={classes.join(' ')}>
                                    <b className={CSS_SUMMARY + '--listing--item--title'}>{item.title}</b>
                                    <div className={CSS_SUMMARY + '--listing--item--price'}>
                                        <span
                                            className={CSS_SUMMARY + '--listing--item--price--default'}>{this.getDefaultPrice(item)} €</span>
                                        {this.renderKwsPrice(item)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={CSS_SUMMARY + '--total'}>
                    <div className={CSS_SUMMARY + '--total--column'}>
                        <span className={CSS_SUMMARY + '--total--vat-hint'}>inkl. 19% MwSt.</span>
                        <b className={CSS_SUMMARY + '--total--text'}>Summe</b>
                    </div>

                    <div className={CSS_SUMMARY + '--total--price'}>
                        <b>{Functions.toCurrency(totalPrice)}</b>
                        <b>€</b>
                    </div>
                </div>

                {this.renderPaymentInformations()}
            </div>
        );
    }

    renderKwsPrice(shopItem) {
        const { kws_member = false } = this.state;
        const { prices, special_offer = false } = shopItem;

        if (special_offer === true && prices && prices.hasOwnProperty('special_offer')) {
            return null;
        }

        if (kws_member === true && prices && prices.hasOwnProperty('kws')) {
            return <span
                className={CSS_SUMMARY + '--listing--item--price--kws'}>{Functions.toCurrency(prices.kws)} €</span>;
        }

        return null;
    }

    renderPaymentInformations() {
        const { prepayment = false, payment = {} } = this.state;
        let paymentInformation = 'Bei Abgabe Ihres Fahrzeugs zahlen Sie den Betrag vor Ort in unserer Filiale.';

        if (prepayment && payment.txndate_processed) {
            const processedDate = moment(payment.txndate_processed, 'DD.MM.YYYY HH:mm:ss');

            if (processedDate.isValid()) {
                const newDateFormat = processedDate.format('DD.MM.YYYY-HH:mm');
                const [date, time] = newDateFormat.split('-');

                paymentInformation = `Sie haben diesen Betrag am ${date} um ${time} Uhr online bezahlt.`;
            }
        } else if (prepayment === true) {
            return null;
        }

        return (
            <b className={CSS_SUMMARY + '--payment-informations'}>{paymentInformation}</b>
        );
    }

    getDefaultPrice(shopItem) {
        const { prices, special_offer = false } = shopItem;

        if (special_offer === true && prices && prices.hasOwnProperty('special_offer')) {
            return Functions.toCurrency(prices.special_offer);
        }

        return Functions.toCurrency(prices.default);
    }
}

export default ErfolgreichPage;