import React from 'react';
import EbikeProductBox from '../EbikeShopPage/components/EbikeProductBox';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import API from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';
import EbikeInquiryOverlay from './components/EbikeInquiryOverlay';
import ReactHtmlParser from 'react-html-parser';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Saxxx E-Bike Outlet', current_page: true },
]
const CSS_CLASS = 'page-ebike';

class EbikePage extends React.Component {
    constructor(props) {
        super(props);
        let pageData, ebikes, type;

        if (props.staticContext && props.staticContext.initialData) {
            [pageData, ebikes, type] = props.staticContext.initialData;
        } else if (typeof window !== 'undefined') {
            [pageData, ebikes, type] = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            ...pageData,
            ebikes,
            choosenEbike: false,
            type
        };
    }

    static requestInitialData(url) {
        const match = /(ebike-outlet|ebike-groupon)/g.exec(url);
        const type = match !== null && match[1] ? match[1] : null;

        if (type) {
            return Promise.all([
                API
                    .GET(`/pages/${type.replace('-', '_')}`)
                    .catch(err => {
                        console.error(err);
                        return {};
                    }),
                API
                    .GET(`/ebikes`)
                    .catch(err => {
                        console.error(err);
                        return [];
                    }),
                type
            ]);
        }
    }

    render() {
        const { title, sub_title, text } = this.state;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>{title}</h1>

                {this.renderTeaserImages()}

                <h2>{sub_title}</h2>

                {ReactHtmlParser(text)}
                {this.renderBikes()}
                {this.renderOverlay()}
            </CommonPage>
        );
    }

    renderTeaserImages() {
        const { teaser_src = {}, title } = this.state;
        const { mobile, desktop } = teaser_src;
        const CSS_CLASS_TEASER = CSS_CLASS + '--teaser';

        return (
            <div className={CSS_CLASS_TEASER}>
                {<KoenigImage className={CSS_CLASS_TEASER + ' ' + CSS_CLASS_TEASER + '--mobile'} fileHash={mobile} alt={title} />}
                {<KoenigImage className={CSS_CLASS_TEASER + ' ' + CSS_CLASS_TEASER + '--desktop'} fileHash={desktop} alt={title} />}
            </div>
        );
    }

    renderBikes() {
        const { ebikes } = this.state;

        return (
            <div className={CSS_CLASS + '--vehicles'}>
                {
                    ebikes
                        .map((ebike, idx) =>
                            this.renderBox(ebike, idx)
                        )
                }
            </div>
        );
    }

    renderBox(ebike, idx) {
        const { prices = {}, price_info: priceInfo } = this.state;

        const props = {
            ...ebike, prices, priceInfo, buttonText: "Ebike anfragen", 
            onClick: () => this.setState({ choosenEbike: ebike })
        };

        return (
            <div key={"box-wrapper-" + idx + "-" + ebike._id} className={CSS_CLASS + '--vehicles--box-wrapper'}>
                <EbikeProductBox {...props} />
            </div>
        );
    }

    renderOverlay() {
        const { choosenEbike, type, branches } = this.state;

        if (!choosenEbike) {
            return null;
        }

        return <EbikeInquiryOverlay
            ebike={choosenEbike}
            onClose={() => this.setState({ choosenEbike: false })}
            type={type}
            branches={branches} />
    }
}

export default EbikePage;