import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';

const MAIN_CSS_CLASS = 'page-sixday-berlin';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Sixday Berlin', current_page: true },
];

class SixdayBerlinPage extends React.Component {

    render() {
        const contentCssClass = MAIN_CSS_CLASS + '--content';

        return (
            <CommonPage className={MAIN_CSS_CLASS}>
                <Helmet>
                    <title>Six Day in Berlin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>SIX DAY in berlin & Vespa König</h1>
                <img className={MAIN_CSS_CLASS + '--header-img'} src="https://t3api.autohaus-koenig.de/relaunch_img/content/kampagnen/SixdayBerlin-1280x300.jpg" />
                <h2>Genießen Sie die größte Sportparty Berlins <br />und gewinnen Sie vor Ort eine Vespa für den Sommer</h2>

                <div className={contentCssClass}>
                    <div className={contentCssClass + '--col1'}>
                        <p><b>Das dürfen Sie nicht verpassen!</b><br />Genießen Sie spannende Rennen und außergewöhnliches Showprogramm beim SIX DAY im Berliner Velodrom. On Top haben Sie die Chance, eine originale Vespa für Ihren Sommer zu gewinnen!</p>
                        <p>Auch dieses Jahr wird es wieder DER Treffpunkt in der Radsportszene werden, auf dem die besten Fahrer der Welt gegeneinander antreten. Für eine ganz besondere Atmosphäre können Sie sich auf eine Mischung aus traditioneller Sechstage-Musik und modernen Clubsound freuen. Aber auch der Gaumen wird verwöhnt, mit typischer Currywurst oder mit internationalem Street Food.</p>
                        <p>Für Sie ist der Eintritt sogar kostenlos, da wir Sie herzlich zur Veranstaltung einladen. Bei den Tickets handelt es sich um Eintrittskarten für festgelegte Tage durch den Veranstalter.</p>
                        <p><b>Was müssen Sie dafür tun?<br />Besuchen Sie uns dafür einfach in eine der rechts aufgeführten König Filialen und fragen Sie vor Ort nach den Tickets.</b></p>
                    </div>
                    <div className={contentCssClass + '--col2'}>
                        <div className={contentCssClass + '--col2--contentbox'}>
                            <p className={contentCssClass + '--col2--contentbox--headline'}>Vespa König City Stores:</p>
                            <p>
                                <a href="https://www.autohaus-koenig.de/standorte/berlin/57">Frankfurter Alle 14, 10247 Berlin</a>
                            </p>
                            <p>
                                <a href="https://www.autohaus-koenig.de/standorte/berlin/49">Kurfürstendamm 161, 10709 Berlin</a>
                            </p>
                            <br />
                            <p className={contentCssClass + '--col2--contentbox--headline'}>Vespa im Autohaus König:</p>
                            <p>
                                <a href="https://www.autohaus-koenig.de/standorte/berlin/9">Kolonnenstraße 31, 10829 Berlin</a>
                            </p>
                            <p>
                                <a href="https://www.autohaus-koenig.de/standorte/berlin/28">Am Juliusturm 23, 13599 Berlin</a>
                            </p>
                            <p>
                                <a href="https://www.autohaus-koenig.de/standorte/berlin/28">Am Juliusturm 40-42, 13599 Berlin</a>
                            </p>
                        </div>
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default SixdayBerlinPage;