import './styles.scss';

import React from 'react';
import ShoppingBasket from './components/ShoppingBasket';
import KoenigButton from '../../elements/KoenigButton';
import SectionHeadline from '../SectionHeadline';
import NavigationHelper from '../../helper/navigation.helper';
import Functions from './functions';
import ProductBox from './components/ProductBox';

const MAIN_CSS_CLASS = 'component-simple-shop';
const DEFAULT_ITEM_LIMIT = 999;

class SimpleShop extends React.Component {

    constructor(props) {
        super(props);

        const consumerData = {
            salutation: props.salutation,
            firstname: props.firstname,
            lastname: props.lastname,
            address: props.address,
            zip_code: props.zip_code,
            location: props.location,
            email: props.email,
            phone: props.phone,
        };

        this.state = {
            shop_items: props.shop_items ? props.shop_items : [],
            isSummaryVisible: false,
            ...consumerData,
            shipping_costs: props.shipping_costs ? props.shipping_costs : 0,
            shipping_costs_limit: props.shipping_costs_limit
        }
    }

    handleShowSummary() {
        const { setContentVisible = () => { } } = this.props;

        this.setState({ isSummaryVisible: true });
        setContentVisible(false);

        if (typeof window !== 'undefined' && window.scrollTo) {
            window.scrollTo(0, 0);
        }
    }

    handleBackToProducts() {
        const { setContentVisible = () => { } } = this.props;

        this.setState({ isSummaryVisible: false })
        setContentVisible(true);
    }

    handleStartCheckout() {
        const { shopUrl, itemLimit = DEFAULT_ITEM_LIMIT, type } = this.props;

        NavigationHelper.linkTo('/shopping/checkout/schritt1/', {
            ...this.state,
            shopUrl,
            itemLimit,
            type
        });
    }

    handleAddItem(product) {
        const { shop_items } = this.state;
        const { itemLimit = DEFAULT_ITEM_LIMIT } = this.props;
        let [item] = shop_items.filter(item =>
            item && item.product && item.product._id === product._id
        );
        let newShopItems;

        if (item) {
            item.quantity++;

            const limit = parseInt(itemLimit);
            if (item.quantity > limit) {
                item.quantity = limit;
            }

            item.pos_total = item.quantity * Functions.getItemPrice(item.product);

            newShopItems = shop_items.map(si =>
                si && si.product._id === product._id
                    ? item
                    : si
            );
        } else {
            item = { quantity: 1, product }
            item.pos_total = Functions.getItemPrice(product)
            newShopItems = [].concat(shop_items, item)
        }

        this.setState({
            shop_items: newShopItems
        })
    }

    handleQuantityIncrementing(productId) {
        const { shop_items } = this.state;
        const { itemLimit = DEFAULT_ITEM_LIMIT } = this.props;


        this.setState({
            shop_items: shop_items.map(si => {
                if (si && si.product && si.product._id === productId) {
                    si.quantity = Math.min(++si.quantity, itemLimit);
                    si.pos_total = si.quantity * Functions.getItemPrice(si.product);
                }

                return si;
            })
        });
    }

    handleQuantityDecrementing(productId) {
        const { shop_items } = this.state;

        this.setState({
            shop_items: shop_items
                .map(si => {
                    if (si && si.product && si.product._id === productId) {
                        if (--si.quantity === 0) {
                            return null
                        }
                    }

                    si.pos_total = si.quantity * Functions.getItemPrice(si.product);

                    return si;
                })
                .filter(si => si !== null)
        });
    }

    render() {
        const { shop_items, isSummaryVisible } = this.state;

        return (
            <div className={MAIN_CSS_CLASS}>
                <div className={MAIN_CSS_CLASS + "--summary-bar"}>
                    {
                        !isSummaryVisible && shop_items.length > 0
                            ? this.renderProductSummaryButton()
                            : this.renderBackToProductsButton()
                    }
                </div>
                <div className={`left-column ${!isSummaryVisible ? '' : 'products-visible'}`}>
                    {this.renderProducts()}
                </div>
                <div className={`right-column ${isSummaryVisible ? '' : 'products-visible'}`}>
                    {this.renderProductSummary()}
                </div>
            </div>
        )
    }

    renderProductSummaryButton() {
        const { shop_items } = this.state;
        const cssProductSummary = MAIN_CSS_CLASS + '--button--shopping-basket';

        return (
            <div className={cssProductSummary} onClick={() => this.handleShowSummary()}>
                Artikelauswahl anzeigen
                <div className={cssProductSummary + '--item-ad'}>{shop_items.length}</div>
            </div>
        );
    }

    renderBackToProductsButton() {
        if (!this.state.isSummaryVisible) {
            return null;
        }
        const cssBackToProducts = MAIN_CSS_CLASS + '--button--back-to-products';

        return (
            <div className={cssBackToProducts} onClick={() => this.handleBackToProducts()}>
                zurück zur Artikelübersicht
            </div>
        );
    }

    renderProducts() {
        const {
            products = [],
            itemLimit = DEFAULT_ITEM_LIMIT,
            productComp
        } = this.props;
        const { shop_items } = this.state;
        const ItemBox = productComp ? productComp : ProductBox;

        return (
            <div className={MAIN_CSS_CLASS + '--products'}>
                {
                    products
                        .map((product, idx) => {
                            const [item] = shop_items.filter(si => si && si.product && si.product._id === product._id)
                            let isMaxQuantityReached = typeof item !== 'undefined' && item.quantity === parseInt(itemLimit);

                            return (
                                <div key={"box-wrapper-" + idx + "-" + product._id} className={MAIN_CSS_CLASS + '--products--box-wrapper'}>
                                    <ItemBox
                                        {...product}
                                        onClick={this.handleAddItem.bind(this, product)}
                                        disabled={isMaxQuantityReached} />
                                </div>
                            )
                        })
                }
            </div>
        );
    }

    renderProductSummary() {
        const { shop_items } = this.state;
        const { itemLimit = DEFAULT_ITEM_LIMIT } = this.props;
        const isCartEmpty = 0 === shop_items.length;

        return (
            <div className={MAIN_CSS_CLASS + '--summary-cnt'}>
                <ShoppingBasket
                    shopItems={shop_items}
                    itemLimit={itemLimit}
                    onQuantityIncrement={this.handleQuantityIncrementing.bind(this)}
                    onQuantityDecrement={this.handleQuantityDecrementing.bind(this)} />

                <KoenigButton
                    className="btn-start-checkout"
                    text="Zur Bestellung"
                    onClick={this.handleStartCheckout.bind(this)}
                    disabled={isCartEmpty}
                />
                <SectionHeadline text='Zahlungs-/ Versandabwicklung' />
                <div className= {MAIN_CSS_CLASS + '--payment-info'}>
                    Nach Abschluss der Buchung ist die Gesamtsumme
                    über unseren Abrechnungsparter (TeleCash) per
                    Kreditkarte, PayPal zu
                    begleichen. Nach der Buchung werden Sie für den
                    Zahlungsabschluss zu TeleCash weitergeleitet.
                    Die Lieferung erfolgt innerhalb der nächsten 7
                    Werktage an die von Ihnen angegebene Adresse.
                </div>
            </div>
        )
    }
}

export default SimpleShop;