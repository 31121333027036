import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import routes from "../routes";
import TopBanner from "../components/TopBanner";
import {useCookies} from "react-cookie";
import PromotionComponents from "../components/PromotionComponents";

import "./styles.scss";

export default ({children, className}) => {
    let topBannerStyle, hasTopBanner = false;
    const [topBannerHidden, setTopBannerHidden] = useState(true);
    const [cookies, setCookie] = useCookies(["topBannerHidden"]);

    topBannerStyle = getTopBannerStyle(topBannerHidden);

    useEffect(() => {
        hasTopBanner = parseInt(window.ACTIVE_TOP_BANNER) && window?.API_URL?.length > 0;

        if (cookies.topBannerHidden) {
            setTopBannerHidden(true);
        } else if (hasTopBanner) {
            setTopBannerHidden(false);
        }
    });

    setClassNameToAppRoot(className);

    return (
        <React.Fragment>
            {
                !topBannerHidden &&
                <div id="top_banner">
                    <TopBanner environment={{API_URL: window?.API_URL}}/>
                </div>
            }
            {
                getPromotionComponent()
            }
            <Header routes={routes} style={topBannerStyle}/>
            <div id="page_content" style={topBannerStyle}>
                {children}
            </div>
            <Footer style={topBannerStyle}/>
        </React.Fragment>
    );
};

function setClassNameToAppRoot(className) {
    if (typeof window !== "undefined" && window.document) {
        const appRoot = document.getElementById("app_root");
        if (appRoot) {
            appRoot.removeAttribute("class");
            if (className) {
                appRoot.setAttribute("class", className);
            }
        }
    }
}

function getTopBannerStyle(topBannerHidden) {
    let topBannerStyle;

    if (topBannerHidden) {
        topBannerStyle = {top: "0px"};
    } else {
        topBannerStyle = {top: "44px"};
    }

    return topBannerStyle;
}

function getPromotionComponent() {
    let Component;

    if (process.env.PROMOTION_COMPONENT) {
        Component = PromotionComponents[process.env.PROMOTION_COMPONENT];

        return <Component/>;
    }

    if (typeof window !== "undefined" && window.PROMOTION_COMPONENT && window.PROMOTION_COMPONENT !== "undefined") {
        Component = PromotionComponents[window.PROMOTION_COMPONENT];

        return <Component/>;
    }

    return null;
}