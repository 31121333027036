import './styles.scss';

import React from 'react';
import KoenigImage from "../../../../../components/KoenigImage";
import Functions from "../../../../../components/SimpleShop/functions";
import KoenigButton from "../../../../../elements/KoenigButton";
import Currency from '../../../../../helper/Currency';

export default props => {
    const CSS_PREFIX = 'voucher-product-box';
    const {
        title, description, hint, prices = {},
        images, onClick, disabled
    } = props;
    const hasDiscount = typeof prices.special_offer !== 'undefined' && prices.special_offer < prices.default;
    const discount = Currency.toCurrency(prices.default - prices.special_offer);
    const price = hasDiscount ? prices.special_offer : prices.default;
    const classes = [CSS_PREFIX];
    const [{ src, alt }] = images ? images : [{}];

    if (hasDiscount) {
        classes.push('special-offer');
    }

    return (
        <div className={classes.join(' ')}>
            <div className={CSS_PREFIX + '--header'}>
                <b className={CSS_PREFIX + '--header--title'}>{title}</b>
                <span className={CSS_PREFIX + '--header--subline'}>{description}</span>
            </div>
            <div className={CSS_PREFIX + '--image'}>
                <KoenigImage fileHash={src} title={alt} alt={alt} />
            </div>
            <div className={CSS_PREFIX + '--hint'}>
                {hint}
            </div>
            <div className={CSS_PREFIX + '--prices'}>
                {
                    hasDiscount
                        ? <span className={CSS_PREFIX + '--prices--discount'}>Sie sparen {discount} €</span>
                        : null
                }
                <span className={CSS_PREFIX + '--prices--regular'}>{Functions.toCurrency(price)}<b>€</b></span>
            </div>
            <div className={CSS_PREFIX + '--legal-text'}>
                <b className={CSS_PREFIX + '--legal-text--price-type'}>Kaufpreis</b>
                <span className={CSS_PREFIX + '--legal-text--tax'}>inkl. 19% MwSt.</span>
                <span className={CSS_PREFIX + '--legal-text--shipping'}>Kostenloser Versand</span>
            </div>
            <KoenigButton
                text="In den Warenkorb"
                className={CSS_PREFIX + '--button--add'}
                onClick={onClick}
                disabled={disabled} />
        </div>
    );
}