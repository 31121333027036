import React from 'react';
import UrlHelper from '../../helper/url.helper';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

class MoAdPixel extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            cookieData: this.props.cookies.get('mobilityAds')
        };
    }

    render() {
        const { cookieData } = this.state;

        if (cookieData) {
            const { inquiryId = 'none', vehicle } = this.props;
            const srcBase = 'https://partner.mobility-ads.de/campaignVerifier/noticeOrderId_ahk';
            const srcTracking = srcBase + '_' + cookieData;
            const srcInquiryId = srcTracking + '_' + inquiryId;
            const vehicleQuery = this.generateVehicleQuery(vehicle);

            return <img src={srcInquiryId + '.png?' + vehicleQuery}
                        style={{ display: 'none' }}
                        className="tracking_pixel" />
        }

        return null;
    }

    generateVehicleQuery(vehicle) {
        const { payments = {} } = vehicle;
        const vehicleData = {};

        if (vehicle) {
            const brandForUrl = UrlHelper.normalize(vehicle.brand);
            const modelForUrl = UrlHelper.normalize(vehicle.model);

            vehicleData.i2 = brandForUrl;
            vehicleData.i3 = modelForUrl;

            if (payments.leasing) {
                if (payments.leasing.rate) {
                    vehicleData.i4 = parseInt(payments.leasing.rate / 100);
                }

                if (payments.leasing.duration) {
                    vehicleData.i5 = payments.leasing.duration;
                }
            }

            if (payments.prices && payments.prices.uvp) {
                vehicleData.i1 = parseInt(payments.prices.uvp / 100);
            }
        }

        return UrlHelper.generateQueryStringFrom(vehicleData);
    }
}

export default withCookies(MoAdPixel);