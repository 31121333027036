import './styles.scss';

import React from 'react';
import API from '../../helper/api.helper';
import TeaserImage from '../../components/TeaserImage';
import ContactForm from '../../components/ContactForm';
import KoenigButton from '../../elements/KoenigButton';
import formHelper from '../../helper/form.helper';
import Checkbox from '../../elements/Checkbox';
import ReactHtmlParser from 'react-html-parser';
import Overlay from '../../components/Overlay';
import CommonPage from '../CommonPage';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../components/Breadcrumb';

const CSS_PREFIX = 'page-fahranfaenger-versicherung';

class FahranfaengerVersicherung extends React.Component {
    constructor(props) {
        super(props);
        let pageData;

        if (props.staticContext && props.staticContext.initialData) {
            pageData = props.staticContext.initialData;
        } else if (window) {
            pageData = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            pageData,
            salutation: null,
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            message: 'Bitte kontaktieren Sie mich',
            agb: false,
            inProgress: false
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/fahranfaenger-versicherung/') === 0) {
            return API
                .GET('/pages/fahranfaenger_versicherung')
                .catch(err => {
                    console.error(err);
                    return {};
                });
        }
    }

    sendInquery() {
        this.setState({ inProgress: true });

        const developed_from = 'Kontakt Fahranfaenger-Versicherung';
        const data = { ...this.state, developed_from, receiver: 'versicherung@autohaus-koenig.de', no_lms: true };

        delete data.transmitted;
        delete data.inProgress;
        delete data.pageData;

        API
            .POST('/inquiries', data)
            .then(_ => this.setState({ transmitted: true }))
            .catch(_ => this.setState({ transmitted: false }));
    }

    isFormInvalid() {
        return !formHelper.isValid(
            ['firstname', 'lastname', 'salutation', 'email', 'phone'],
            this.state
        ) || !this.state.agb;
    }

    render() {
        const { inProgress, transmitted, pageData: {headline, sub_headline, teaser_src, text} } = this.state;
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Versicherungs-Sonderaktion für Fahranfänger', current_page: true },
        ];

        return (
            <CommonPage className={CSS_PREFIX}>
                <Helmet>
                    <title>Versicherungs-Sonderaktion für Fahranfänger</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />
                <h1>{headline}</h1>
                <TeaserImage teaser_src={teaser_src} />
                <h2>{sub_headline}</h2>
                <article>{ReactHtmlParser(text)}</article>
                <ContactForm
                    onChange={(key, val) => this.setState({ [key]: val })} />
                <div className={CSS_PREFIX + '--data-privacy'}>
                    <Checkbox onChange={agb => this.setState({agb})}/>

                    <span>
                        Ich habe die <a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen
                    </span>
                </div>
                <KoenigButton
                    className={CSS_PREFIX + '--button'}
                    text={'Jetzt absenden'}
                    onClick={this.sendInquery.bind(this)}
                    disabled={this.isFormInvalid() || inProgress}/>
                {typeof transmitted !== 'undefined' && this.renderOverlay()}
            </CommonPage>
        );
    }

    renderOverlay() {
        const { transmitted } = this.state;
        const overlayCss = CSS_PREFIX + '--overlay';

        if (transmitted) {
            return this.renderSuccessOverlay(overlayCss);
        }

        return this.renderFailureOverlay(overlayCss);
    }

    renderSuccessOverlay(overlayCss) {
        return (
            <Overlay className={overlayCss + '--success'}>
                <h3>Vielen Dank für Ihre Bewertung</h3>
                <p>
                    Sie erhalten von uns eine E-Mail mit einem Aktivierungslink. Erst mit der Bestätigung dieses
                    Links<br />
                    wird Ihre Bewertung aktiv und kann von uns bearbeitet werden.
                </p>
                <KoenigButton
                    text="Zurück zur Übersicht"
                    linkTo="/fahranfaenger-versicherung/"
                />
            </Overlay>
        );
    }

    renderFailureOverlay(overlayCss) {
        return (
            <Overlay className={overlayCss + '--failure'}>
                <h3>Vielen Dank für Ihre Bewertung</h3>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>

                <KoenigButton
                    text="Zurück zur Übersicht"
                    linkTo="/fahranfaenger-versicherung/"
                />
            </Overlay>
        );
    }
}

export default FahranfaengerVersicherung;