import './styles.scss';

import businesJpg from '../assets/business.jpg'
import businesWebp from '../assets/business.webp'
import newOrUsedJpg from '../assets/new_or_used.jpg'
import newOrUsedWebp from '../assets/new_or_used.webp'
import otherBrandsJpg from '../assets/other_brands.jpg'
import otherBrandsWebp from '../assets/other_brands.webp'
import React from 'react';
import KoenigButton from '../../../elements/KoenigButton';

const CSS_PREFIX = 'fc-no-results';

function NoResults() {
    return (
        <div className={CSS_PREFIX}>
            <h3>In den folgenden Kategorien finden Sie andere attraktive Angebote:</h3>
            <div>
                {
                    renderOfferbox(
                        otherBrandsJpg,
                        otherBrandsWebp,
                        'andere Marken',
                        'Wir führen attraktive Gebrauchtwagen auch anderer Marken und Modelle!',
                        'Gebrauchtwagen Angebote anzeigen',
                        '/gebrauchtwagen/gebrauchtwagenangebote/'
                    )
                }
                {
                    renderOfferbox(
                        newOrUsedJpg,
                        newOrUsedWebp,
                        'Neu oder Jahreswagen',
                        'Vielleicht doch ein Neuwagen oder ein Jahreswagen? Stöbern Sie jetzt!',
                        'Neuwagen Angebote anzeigen',
                        '/neuwagen/'
                    )
                }
                {
                    renderOfferbox(
                        businesJpg,
                        businesWebp,
                        'Gewerbe',
                        'Ein passendes Fahrzeug für Ihr Unternehmen? Schau Sie jetzt!',
                        'Gewerbe Angebote anzeigen',
                        '/gewerbebereich/neuwagen/'
                    )
                }
            </div>
        </div>
    );
}

function renderOfferbox(jpg, webp, alt, headline, btnText, linkTo) {
    return (
        <span className={CSS_PREFIX + '--box'}>
            <picture>
                <source srcSet={webp} type="image/webp" />
                <source srcSet={jpg} type="image/jpeg" />
                <img src={jpg} alt={alt} />
            </picture>
            <div>
                <h3>{headline}</h3>
                <KoenigButton text={btnText} linkTo={linkTo}/>
            </div>
        </span>
    );
}

export default NoResults;