const isValid = (keys, stateObj) => {
        return keys.reduce(
            (result, key) => {
                if (result && key === 'email') {
                    return isEmailValid(stateObj[key]);
                }

                return Boolean(result && stateObj[key] && stateObj[key].length > 0);
            },
            true
        );
}

const isEmailValid = (email) => {
    return email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

module.exports = {isValid}