import "./styles.scss";

import React, {useEffect, useState} from "react";
import StarRating from "./StarRating.jsx";

const CSS_CLASS = "fc-top-banner";

function TopBanner(props) {
    const {environment} = props;
    const [data, setData] = useState([]);
    const {total = -1, average = 0} = data?.[0] || {};
    const rounded = Math.round(average);

    useEffect(() => {
        const endpoint = (environment?.API_URL || "") + "/v1.0/google_reviews/average";

        fetch(endpoint.replace(/([^:]\/)\/+/g, "$1"))
            .then(res => res.json())
            .then(data => setData(data))
            .catch(err => console.error(err));
    }, []);

    return (
        <div className={CSS_CLASS}>
            {total !== -1 &&
                <p>
                    <span>Unsere Nutzer sind überzeugt:</span>
                    <span>
                        <b>{average.toFixed(1)} von 5 Sternen</b>, ermittelt aus {total} Bewertungen <br/>(letzte 12
                    Monate, <a
                        href="/bewertung/">mehr erfahren</a>)
                    </span>
                    <span>
                        <StarRating average={rounded}/>
                    </span>
                </p>
            }
        </div>
    );
}

export default TopBanner;