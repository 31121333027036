const filter = branches => {
    return branches
        .filter(b => typeof b.federal_state === 'string' && b.federal_state.length > 0)
        .filter(b => b.name.indexOf('GW-') === -1)
        .filter(b =>
            [
                'Gebrauchtwagenzentrum Halle',
                'Finsterwalde Fiat',
                'Leipzig (Vespa im Hause Fiat König)',
                'Berlin Spandau (Vespa)'
            ].indexOf(b.name) === -1);
};

const normalize = federalState => {
    if (federalState === 'Thüringen') {
        return 'thueringen';
    }
    return federalState && federalState.toLowerCase();
};

export default {
    filter, normalize
};