import React from 'react';

const moment = require('moment');
require('moment-timezone');
require('moment/locale/de');

function OpenCloseInfo({ openingTimes, branchId }) {

    let allOpeningTimes = {
        week: {open:[], close:[]},
        saturday: {open:[], close:[]},
        sunday: {open:[], close:[]}
    };

    if(Object.keys(openingTimes).length == 2){
        const saleTimes = openingTimes.sale;
        const serviceTimes = openingTimes.service;

        allOpeningTimes.week = getLengthOpeningTime(saleTimes.week, serviceTimes.week);
        allOpeningTimes.saturday = getLengthOpeningTime(saleTimes.saturday, serviceTimes.saturday);
        allOpeningTimes.sunday = getLengthOpeningTime(saleTimes.sunday, serviceTimes.sunday);
    } else {
        allOpeningTimes = openingTimes;
    }

    const isMonday = moment().isoWeekday() === 1;
    const isSaturday = moment().isoWeekday() === 6;
    const isSunday = moment().isoWeekday() === 7;
    const isLeipzig3 = branchId === "leipzig-3" && isMonday;
    let diff, text;

    switch (true) {
        case isSaturday:
            diff = getTimeDiff(allOpeningTimes.saturday);
            text = getOpenCloseText(diff);
            break;
        case isSunday: case isLeipzig3:
            text = 'Filiale momentan geschlossen';
            break;
        default:
            diff = getTimeDiff(allOpeningTimes.week);
            text = getOpenCloseText(diff);
    }

    return <h2 className="fc-open-close-info">{text}</h2>;
}

function getTimeDiff({ open, close }) {
    if (!open || !open[0]) {
        return -1;
    }

    const now = moment();
    const openFrom = moment(`${open[0]}:${open[1]}`, ['h:m a', 'H:m']);
    const closedFrom = moment(`${close[0]}:${close[1]}`, ['h:m a', 'H:m']);

    if (!now.isBetween(openFrom, closedFrom)) {
        return -1;
    }

    const duration = moment.duration(closedFrom.diff(now));

    return duration.asMinutes();
}

function getOpenCloseText(diff) {
    if (diff > 0 && diff < 60) {
        return `Filiale schließt in ${Math.round(diff)} Minuten`;
    }

    if (diff < 0) {
        return 'Filiale momentan geschlossen';
    }

    return 'Filiale jetzt geöffnet';
}

function getLengthOpeningTime(sales, service) {
    const maxOpening = {open: [], close: []};

    if(parseInt(sales.open[0]+""+sales.open[1]) > parseInt(service.open[0]+""+service.open[1])){
        maxOpening.open = [sales.open[0], sales.open[1]];
    } else {
        maxOpening.open = [service.open[0], service.open[1]];
    }

    if(parseInt(sales.close[0]+""+sales.close[1]) > parseInt(service.close[0]+""+service.close[1])){
        maxOpening.close = [sales.close[0], sales.close[1]];
    } else {
        maxOpening.close = [service.close[0], service.close[1]];
    }

    return maxOpening;
}

export default OpenCloseInfo;