import './styles.scss';

import React from 'react';
import Helmet from 'react-helmet';
import Breadcrumb from '../../../components/Breadcrumb';
import API from '../../../helper/api.helper';
import TeaserImage from '../../../components/TeaserImage';
import CommonPage from '../../CommonPage';
import AverageRating from './components/AverageRating';
import ReactHtmlParser from 'react-html-parser';
import apiHelper from '../../../helper/api.helper';
import ReviewListing from './components/ReviewListing';

const CSS_PREFIX = 'page-google-rating';
const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Autohaus König Bewertungen & Meinungen', current_page: true },
];

class GoogleRatingPage extends React.Component {
    constructor(props) {
        super(props);
        let pageData;

        if (props.staticContext && props.staticContext.initialData) {
            pageData = props.staticContext.initialData;
        } else if (typeof window !== 'undefined') {
            pageData = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            ...pageData
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/bewertung/') === 0) {
            return API
                .GET('/pages/bewertung')
                .catch(err => {
                    console.error(err);
                    return {};
                });
        }
    }

    render() {
        const { headline, sub_headline, teaser_src, sub_sub_headline } = this.state;
        return (
            <CommonPage className={CSS_PREFIX}>
                <Helmet>
                    <title>Bewertungen - Autohaus König</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />
                <h1>{headline}</h1>
                <TeaserImage teaser_src={teaser_src} />
                <h2>{ReactHtmlParser(sub_headline)}</h2>
                <AverageRating />
                <h3>{sub_sub_headline}</h3>
                <ReviewListing />
            </CommonPage>
        );
    }
}

export default GoogleRatingPage;