import React from 'react';

import "./breadcrumb.scss";

export default React.forwardRef((props, ref) => {
    const { crumbs = [] } = props;
    return (
        <div className="breadcrumb" ref={ref}>
            {
                crumbs.map((crumb, idx) => (
                    <React.Fragment key={idx}>
                        {crumb.current_page === true ? renderCurrentPage(crumb) : renderLink(crumb)}
                    </React.Fragment>
                ))
            }
        </div>
    )
});

function renderCurrentPage({ label }) {
    return (
        <b className="breadcrumb--crumb">{label}</b>
    );
}

function renderLink({ label, path = '/', onClick }) {
    const href = path && path.length > 1 ? path + '/' : path;
    const props = typeof onClick === 'undefined' ? { href: href.replace(/\/\//g, '/') } : { onClick };
    let labelText = label;

    const isBrandCrumb = /\/neuwagen\/(fiat|abarth|fiat-prof.)\/?$/.test(path);
    const isModelCrumb = /\/neuwagen\/(fiat|abarth|fiat-prof.)\/.+\/?$/.test(path);
    if (isBrandCrumb) {
        labelText = "Fiat, Abarth und Fiat Professional";
    }
    if (isBrandCrumb || isModelCrumb) {
        if (typeof onClick === 'undefined') {
            props.href = props.href.replace(/\/neuwagen\/(abarth|fiat-prof.)\//, '/neuwagen/fiat/');
        }
    }


    return (
        <span className="breadcrumb--crumb">
            <a {...props}>{labelText}</a>&nbsp;&raquo;&nbsp;
        </span>
    );
}