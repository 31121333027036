const getIdList = (brands = []) => {
    if (brands.length > 0) {
        return brands
            .map(brand => brand._id)
            .filter(_id => typeof _id !== 'undefined' && _id !== null);
    }
};

export default {
    getIdList
};