import NotFound from './pages/NotFound';
import SingleBrandStartPage from './pages/StartPages/SingleBrand';
import Werkstatt from './pages/Werkstatt';
import SingleBrandsOffersPage from './pages/OfferPages/SingleBrands';
import AllBrandsOffersPage from './pages/OfferPages/AllBrandsOffersPage';
import NewsletterPage from './pages/NewsletterPage';
import NewsletterAbmeldenPage from './pages/NewsletterAbmeldenPage';
import testingRoutes from '../test/.e2e/routes';
import BundeslandStandortePage from './pages/Standorte/BundeslandStandortePage';
import EinzelStandortPage from './pages/Standorte/StandortEinzelansichtPage';
import WerkstattAktionenPage from './pages/Werkstatt/AktionenPage';
import GarantieServicePage from './pages/Werkstatt/GarantieServicePage';
import GebrauchtwagenbereichPage from './pages/GebrauchtwagenbereichPage';
import GebrauchtwagenPage from './pages/GebrauchtwagenPage';
import GebrauchtwagenDetailPage from './pages/GebrauchtwagenDetailPage';
import VipPage from './pages/VipPage';
import SixdayBerlinPage from './pages/SixdayBerlinPage';
import MarkeStandortePage from './pages/Standorte/MarkeStandortePage';
import BranchesPage from './pages/Standorte';
import Schritt1Page from './pages/Werkstatt/Terminbuchung/Schritt1Page';
import Schritt2Page from './pages/Werkstatt/Terminbuchung/Schritt2Page';
import Schritt3Page from './pages/Werkstatt/Terminbuchung/Schritt3Page';
import Schritt4Page from './pages/Werkstatt/Terminbuchung/Schritt4Page';
import ErfolgreichPage from './pages/Werkstatt/Terminbuchung/ErfolgreichPage';
import FehlgeschlagenPage from './pages/Werkstatt/Terminbuchung/FehlgeschlagenPage';
import ZahlungsfehlerPage from './pages/Werkstatt/Terminbuchung/ZahlungsfehlerPage';
import OhneAnzahlungPage from './pages/OhneAnzahlungPage';
import VespaZubehoerPage from './pages/VespaZubehoerPage';
import Unter100EuroPage from './pages/Unter100EuroPage';
import SimpleShopSchritt1Page from './pages/SimpleShop/Schritt1Page';
import SimpleShopSchritt2Page from './pages/SimpleShop/Schritt2Page';
import SimpleShopErfolgreichPage from './pages/SimpleShop/ErfolgreichPage';
import SimpleShopZahlungsfehlerPage from './pages/SimpleShop/ZahlungsfehlerPage';
import SimpleShopFehlgeschlagenPage from './pages/SimpleShop/FehlgeschlagenPage';
import GutscheinShopPage from './pages/Werkstatt/GutscheinShopPage';
import EbikeShopPage from './pages/EbikeShopPage';
import GebrauchtwagenMarkePage from './pages/GebrauchtwagenMarkePage';
import GebrauchtwagenMarkenPage from './pages/GebrauchtwagenMarkenPage';
import GebrauchtwagenModellPage from './pages/GebrauchtwagenModellPage';
import OnlineshopDankePage from './pages/OnlineshopDankePage';
import EbikePage from './pages/EbikePage';
import BewertungPage from "./pages/Werkstatt/BewertungPage";
import VehicleDetailPage from './pages/VehicleDetailPage';
import GoogleRatingPage from './pages/Bewertungen/GoogleRatingPage';
import SimpleRatingPage from './pages/Bewertungen/SimpleRatingPage';
import { BRAND_REG_EXP } from './helper/constant.helper';
import FahranfaengerVersicherung from './pages/FahranfaengerVersicherung';
import OnlineshopPage from './pages/OnlineshopPage';

let routes = [];
if (process.env.NODE_ENV === 'testing') {
    console.log('testing routes are also imported...');
    routes = [...testingRoutes];
}

const brandRegExp = `:brand(${BRAND_REG_EXP})`;
const branchRegExp = ':federal_state(berlin|brandenburg|mecklenburg-vorpommern|sachsen|sachsen-anhalt|thueringen)'

export const MAIN_MENU = 0;
export const SUB_MENU = 1;
export const TOP_MENU = 2;

export default [
    ...routes,
    {
        path: '/newsletter',
        component: NewsletterPage,
        exact: true
    },
    {
        path: '/sonderkonditionen/behindertenrabatt/',
        menu: {
            location: TOP_MENU,
            label: 'Behindertenrabatt',
            position: 1
        }
    },
    {
        path: '/haendlerbereich',
        menu: {
            location: TOP_MENU,
            label: 'Händlerbereich',
            position: 1
        }
    },
    {
        path: '/gebrauchtwagen/',
        component: GebrauchtwagenbereichPage,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Gebrauchtwagen',
            position: 20
        }
    },
    {
        path: `/gebrauchtwagen/automarken/:brandId/`,
        component: GebrauchtwagenMarkePage,
        exact: true
    },
    {
        path: `/gebrauchtwagen/automarken/:brandId/:modelId/`,
        component: GebrauchtwagenModellPage,
        exact: true
    },
    {
        path: '/gebrauchtwagen/gebrauchtwagenangebote',
        component: GebrauchtwagenPage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Gebrauchtwagen-Angebote',
            position: 2010
        }
    },
    // {
    //     path: '/gebrauchtwagen/auto-abo/',
    //     component: AboPage,
    //     exact: true,
    //     menu: {
    //         location: SUB_MENU,
    //         label: 'Auto-Abo',
    //         position: 2020
    //     }
    // },
    {
        path: `/gebrauchtwagen/automarken/`,
        component: GebrauchtwagenMarkenPage,
        menu: {
            location: SUB_MENU,
            label: 'Fahrzeugmarken',
            position: 2030,
        }
    },
    {
        path: '/gebrauchtwagen/inzahlungnahme/',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Fahrzeug-Inzahlungnahme',
            position: 2040
        }
    },
    {
        path: '/gebrauchtwagen/gebrauchtwagen-check/',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Gebrauchtwagen-Check',
            position: 2050
        }
    },
    {
        path: `/gebrauchtwagen/gebrauchtwagenangebote/:carId`,
        component: GebrauchtwagenDetailPage,
        exact: true
    },
    {
        path: '/gewerbebereich',
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Gewerbebereich',
            position: 30
        }
    },
    {
        path: '/gewerbebereich/neuwagen',
        component: SingleBrandsOffersPage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Gewerbe-Angebote',
            position: 3010
        }
    },
    {
        path: '/gewerbebereich/gewerbekunden',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Gewerbekunden',
            position: 3020
        }
    },
    {
        path: '/gewerbebereich/gewerbeflotten',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Gewerbeflotten',
            position: 3030
        }
    },
    // {
    //     path: '/vermietung',
    //     component: VermietungPage,
    //     exact: true,
    //     menu: {
    //         location: MAIN_MENU,
    //         label: 'Vermietung',
    //         position: 60
    //     }
    // },
    {
        path: "/themenwelten/",
        menu: {
            location: TOP_MENU,
            label: "Themenwelten",
            position: 10
        }
    },
    {
        path: "https://news.autohaus-koenig.de/events/",
        menu: {
            location: TOP_MENU,
            label: "Events",
            position: 10,
            target: "_blank"
        }
    },
    {
        path: '/unternehmen',
        exact: true,
        menu: {
            location: TOP_MENU,
            label: 'Unternehmen',
            position: 30
        }
    },
    {
        path: "/service-hilfe",
        menu: {
            location: TOP_MENU,
            label: "Service & Hilfe",
            position: 20
        }
    },
    {
        path: "https://news.autohaus-koenig.de/",
        menu: {
            location: TOP_MENU,
            label: "News",
            position: 30,
            target: "_blank"
        }
    },
    {
        path: "https://news.autohaus-koenig.de/karriere/",
        menu: {
            location: TOP_MENU,
            label: "Karriere",
            position: 40,
            target: "_blank"
        }
    },
    {
        path: '/newsletter/abmeldung',
        component: NewsletterAbmeldenPage,
        exact: true,
    },
    {
        path: '/neuwagen/',
        component: AllBrandsOffersPage,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Neuwagen',
            position: 10
        }
    },
    {
        path: '/zweirad/',
        component: AllBrandsOffersPage,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Zweirad',
            position: 21
        }
    },
    {
        path: `/neuwagen/${brandRegExp}/`,
        component: SingleBrandStartPage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: [
                ["renault", "Renault"],
                ["dacia", "Dacia"],
                ["fiat", "Fiat / Abarth"],
                ["fiat-professional", "Fiat Professional"],
                ["alfa-romeo", "Alfa Romeo"],
                ["jeep", "Jeep"],
                ["volkswagen", "Volkswagen"],
                ["opel", "Opel"],
                ["citroen", "Citroën"],
                ["kia", "Kia"],
                ["mazda", "Mazda"],
                ["suzuki", "Suzuki"],
                ["maxus", "Maxus"]
            ],
            position: 1010
        }
    },
    {
        path: `/zweirad/${brandRegExp}/`,
        component: SingleBrandStartPage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: [
                ['piaggio', 'Piaggio'],
                ['eschwalbe', 'eSchwalbe'],
                ['aprilia', 'Aprilia'],
                ['vespa', 'Vespa'],
                ['ape', 'Ape'],
                ['moto-guzzi', 'Moto Guzzi'],
                ["suzuki-roller", "Suzuki"]
            ],
            position: 1011
        }
    },
    {
        path: '/werkstatt/terminbuchung',
        component: Schritt1Page,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Terminbuchung',
            position: 7010
        }
    },
    {
        path: '/werkstatt/terminbuchung/schritt2',
        component: Schritt2Page,
        exact: true
    },
    {
        path: '/werkstatt/terminbuchung/schritt3',
        component: Schritt3Page,
        exact: true
    },
    {
        path: '/werkstatt/terminbuchung/schritt4',
        component: Schritt4Page,
        exact: true
    },
    {
        path: '/werkstatt/terminbuchung/erfolgreich',
        component: ErfolgreichPage,
        exact: true
    },
    {
        path: '/werkstatt/terminbuchung/fehlgeschlagen',
        component: FehlgeschlagenPage,
        exact: true
    },
    {
        path: '/werkstatt/terminbuchung/zahlungsfehler',
        component: ZahlungsfehlerPage,
        exact: true
    },
    {
        path: '/werkstatt',
        component: Werkstatt,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Werkstatt',
            position: 70
        }
    },
    {
        path: '/werkstatt/aktion',
        component: WerkstattAktionenPage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Aktionen',
            position: 7020
        }
    },
    {
        path: '/werkstatt/unfall_hilfe',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Unfall-Hotline',
            position: 7070
        }
    },
    {
        path: '/werkstatt/service_zubehoer',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Service & Zubehör',
            position: 7040
        }
    },
    {
        path: '/werkstatt/koenig-werkstattservice',
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'König Werkstattservice',
            position: 7060
        }
    },
    {
        path: '/werkstatt/garantie-und-servicevertraege',
        component: GarantieServicePage,
        exact: true,
        menu: {
            location: SUB_MENU,
            label: 'Garantie & Serviceverträge',
            position: 7050
        }
    },
    {
        path: '/werkstatt/werkstattbewertung',
        component: BewertungPage,
        exact: true
    },
    {
        path: '/standorte',
        component: BranchesPage,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Standorte',
            position: 80
        }
    },
    {
        path: `/standorte/${branchRegExp}/`,
        component: BundeslandStandortePage,
        exact: true
    },
    {
        path: `/standorte/${brandRegExp}/`,
        component: MarkeStandortePage,
        exact: true
    },
    {
        path: '/standorte/:federal_state/:branchId',
        component: EinzelStandortPage,
        exact: true,
    },
    {
        path: '/vip-halle',
        component: VipPage,
        exact: true
    },
    {
        path: '/sixday-berlin',
        component: SixdayBerlinPage,
        exact: true
    },
    {
        path: '/404/',
        component: NotFound,
        exact: true
    },
    {
        path: '/ohne-anzahlung',
        component: OhneAnzahlungPage,
        exact: true
    },
    {
        path: '/shopping/checkout/schritt1/',
        component: SimpleShopSchritt1Page,
        exact: true
    },
    {
        path: '/shopping/checkout/schritt2/',
        component: SimpleShopSchritt2Page,
        exact: true
    },
    {
        path: '/shopping/checkout/erfolgreich/',
        component: SimpleShopErfolgreichPage,
        exact: true
    },
    {
        path: '/shopping/checkout/zahlungsfehler/',
        component: SimpleShopZahlungsfehlerPage,
        exact: true
    },
    {
        path: '/shopping/checkout/fehlgeschlagen/',
        component: SimpleShopFehlgeschlagenPage,
        exact: true
    },
    {
        path: '/vespa-zubehoer/',
        component: VespaZubehoerPage,
        excact: true
    },
    {
        path: '/unter-100-euro',
        component: Unter100EuroPage,
        exact: true
    },
    {
        path: '/werkstatt/gutscheine/',
        component: GutscheinShopPage,
        exact: true
    },
    {
        path: '/elektromobilitaet/e-bike-shop/',
        component: EbikeShopPage,
        exact: true
    },
    {
        path: '/ebike-outlet/',
        component: EbikePage,
        exact: true
    },
    {
        path: '/ebike-groupon/',
        component: EbikePage,
        exact: true
    },
    {
        path: '/onlineshop/danke/:vehicleId/:uid',
        component: OnlineshopDankePage,
        exact: true
    },
    {
        path: `/neuwagen/${brandRegExp}/:modelId/:vehicleId/`,
        component: VehicleDetailPage,
    },
    {
        path: `/zweirad/${brandRegExp}/:modelId/:vehicleId/`,
        component: VehicleDetailPage,
    },
    {
        path: '/bewertung/negativ/',
        component: SimpleRatingPage,
        exact: true
    },
    {
        path: '/bewertung/',
        component: GoogleRatingPage,
        exact: true
    },
    {
        path: '/fahranfaenger-versicherung/',
        component: FahranfaengerVersicherung,
        exact: true
    },
    {
        path: '/onlineshop/',
        component: OnlineshopPage,
        exact: true,
        menu: {
            location: MAIN_MENU,
            label: 'Onlineshop',
            position: 50
        }
    }
];
