import "./styles.scss";

import React from 'react';
import moment from 'moment';

import "moment/locale/de";
import "moment-timezone";

import StepPage from "../components/StepPage";
import Icon from '../../../../components/Icon';
import KoenigSelect from '../../../../elements/KoenigSelect';
import DateChooser from '../../../../components/DateChooser';
import Datepicker from '../../../../elements/Datepicker';
import KoenigInput from '../../../../elements/KoenigInput';
import RadioButtonGroup from '../../../../elements/RadioButtonGroup';
import KoenigButton from '../../../../elements/KoenigButton';
import ApiHelper from '../../../../helper/api.helper';
import Functions, {ISO_DATE_FORMAT} from "../functions";
import {DISCOUNT_ITEM} from "../Schritt4Page";
import Overlay from "../../../../components/Overlay";

export const CALL_FOR_SELECT_A_BRAND = "Bitte wählen Sie zuerst eine Marke";
export const CALL_FOR_SELECT_A_BRANCH = "Bitte wählen Sie zuerst eine Filiale";
export const BAD_CHARACTER_VIN = 'Die Eingabe enthält ungültige Zeichen.';
export const TOO_SHORT_VIN = 'Die Fahrzeug-Identnummer hat nicht die korrekte Länge. ([-COUNT-] statt 17)';

export const BranchComp = ({label, address_row}) => (
    <span><b>{label}</b> | {address_row}</span>
);

class Schritt1Page extends React.Component {

    constructor(props) {
        super(props);
        let postData;
        let brands = [];
        let models = [];
        let branches = [];
        let appointments = [];
        let forewardDays;

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [brands, branches, appointments, forewardDays] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [brands, branches, appointments, forewardDays] = window.__initialData__;
            }
        }

        if (postData && postData.brand && postData.brand.models) {
            models = postData.brand.models;
        }

        this.state = {
            brands, models, branches, appointments, forewardDays,

            brand: null, model: null,
            branch: null,
            day: this.regularFirstDay(forewardDays), time: null,
            plate: null, vin: null,
            mileage: null, registration: null,
            kws_member: false,
            isShowOverlayPaymentInfo: false,

            shop_items: [],

            err_vin: '',
            err_mileage: '',

            ...postData
        };
    }

    static requestInitialData() {
        const forewardDays = getAppointmentForewardDays();
        const since = moment().tz('Europe/Berlin').add(forewardDays, 'days').format('YYYYMMDD');

        const brands = ApiHelper.GET('/workshop_brands');
        const branches = ApiHelper.GET('/workshop_branches');

        const appointments = ApiHelper.GET('/appointments?since=' + since)
            .then(appointments => {
                return Functions.createAppointmentMap(appointments);
            });

        return Promise.all([brands, branches, appointments, forewardDays])
            .catch(console.error);
    }

    handleBrandSelection(brandLabel) {
        const {brands, branch} = this.state;
        const newState = {model: null};

        newState.brand = brands.reduce((result, brand) => {
            if (result === null && brand.label === brandLabel) {
                return brand;
            }
            return result;
        }, null);

        newState.models = newState.brand && newState.brand.models ? newState.brand.models : [];

        const brandIsSet = newState.brand && newState.brand._id;
        const branchHasAvailableBrands = branch && branch.available_brands;
        const isBrandInBranch =
            brandIsSet
            && branchHasAvailableBrands
            && branch.available_brands.reduce((result, b) => result || b.indexOf(newState.brand._id) !== -1, false);

        newState.branch = isBrandInBranch ? branch : null;

        this.setState({...newState, shop_items: []});
    }

    handleModelSelection(modelLabel) {
        const {brand, models} = this.state;
        const modelsClone = JSON.parse(JSON.stringify(models));

        if (brand && brand.label && /^sonstige|^wohnmobile/i.test(brand.label)) {
            modelsClone.push({uid: 0, label: modelLabel});
        }

        const model = modelsClone.reduce((result, model) => {
            if (result === null && model.label === modelLabel) {
                return model;
            }
            return result;
        }, null);

        this.setState({model, shop_items: []});
    }

    handleBranchSelection(branchId) {
        const {appointments, branches, day: currentDaySelection, time, forewardDays} = this.state;

        const branch = branches.reduce((result, b) => {
            if (result === null && b && b._id === branchId) {
                return b;
            }
            return result;
        }, null);

        let day = currentDaySelection;
        if (branch !== null && !this.existsFreeTimeslotFor(branch).at(currentDaySelection)) {
            const momentDate = moment(day, ISO_DATE_FORMAT);

            for (let i = 0; i < 365; i++) {
                const nextDay = momentDate.add(1, 'day').format(ISO_DATE_FORMAT);
                if (this.existsFreeTimeslotFor(branch).at(nextDay)) {
                    day = nextDay;
                    break;
                }
            }
        }

        let isAlreadyBooked = false;
        if (day && time && branch && typeof appointments[branchId] !== 'undefined') {
            isAlreadyBooked = appointments[branchId][day] && appointments[branchId][day].indexOf(time) !== -1;
        }

        const isSelectedTimeAvailable =
            !isAlreadyBooked
            && time
            && branch
            && branch.time_slots
            && branch.time_slots.indexOf(time) !== -1;

        this.setState({
            branch,
            day,
            forewardDays: branch.foreward_days || forewardDays,
            time: isSelectedTimeAvailable ? time : null,
            isShowOverlayPaymentInfo: branchId === 2
        });
    }

    handleDayValidation(isoDate) {
        const {forewardDays} = this.state;

        const today = moment().tz('Europe/Berlin');
        const firstValidDay = today.add(forewardDays, 'days');

        if (moment(isoDate, 'YYYYMMDD').isBefore(firstValidDay, 'day')) {
            return false;
        }

        const {branch} = this.state;

        return this.existsFreeTimeslotFor(branch).at(isoDate);
    }

    handleDaySelection(day) {
        const {appointments, branch, time} = this.state;

        let isAlreadyBooked = false;
        if (day && time && branch && typeof appointments[branch._id] !== 'undefined') {
            isAlreadyBooked = appointments[branch._id][day] && appointments[branch._id][day].indexOf(time) !== -1;
        }

        this.setState({
            day,
            time: isAlreadyBooked ? null : time
        });
    }

    handleVinChange(vin) {
        const output = (vin || '').toUpperCase().replace(/\s/g, '');
        const hasBadLength = output.length > 0 && output.length !== 17;
        const hasBadChars = /[IOQ]/g.test(output) || /[^A-Za-z0-9]+/g.test(output);
        const isValid = !hasBadLength && !hasBadChars;
        let err_vin = '';

        if (!isValid) {
            err_vin = hasBadLength
                ? TOO_SHORT_VIN.replace('[-COUNT-]', output.length)
                : BAD_CHARACTER_VIN;
        }

        this.setState({
            vin: isValid === true ? output : vin,
            err_vin
        });
    }

    handleMileageChange(mileage) {
        const err_mileage = mileage && parseInt(mileage) > 99
            ? ''
            : 'Es muss mindestens ein dreistelliger Wert eingegeben werden.';

        this.setState({
            mileage,
            err_mileage
        })
    }

    handleSubmit() {
        if (!this.isValid()) {
            return;
        }

        Functions.linkTo('/werkstatt/terminbuchung/schritt2/', this.state);
    }

    handleShopItemRemoving(shopItemId) {
        const {shop_items} = this.state;
        let newShopItems = shop_items.filter(si => si && si._id !== shopItemId);

        if (newShopItems.length === 1 && newShopItems[0]._id === DISCOUNT_ITEM._id) {
            newShopItems = [];
        }

        this.setState({
            shop_items: newShopItems
        });
    }

    handleClosePaymentInfo() {
        this.setState({isShowOverlayPaymentInfo: false});
    }

    render() {
        const isNoMPO = typeof this.state.branch?.mpo === "undefined";

        return (
            <StepPage
                className="page-terminbuchung-schritt1"
                steps={[0, 1, 1, 1]}
                onRemoveItem={this.handleShopItemRemoving.bind(this)}
                {...this.state}>
                <div className="intro-text">
                    <b>Für eine schnelle Buchung benötigen wir bitte die Informationen zu Ihrem Fahrzeug und Ihrem
                        Wunschtermin.</b><br/>
                    Füllen Sie ganz bequem die nachfolgenden Felder aus. Anhand der Auswahl für Marke und Modell werden
                    Ihnen die möglichen Filialen zur Auswahl angeboten, sowie die passenden Leistungen im nächsten
                    Schritt aufgelistet.
                </div>

                {this.renderVehicleSelection()}
                {this.renderBranchScheduleSelection(isNoMPO)}
                {isNoMPO && this.renderCarIdentifyingSelection()}
                {isNoMPO && this.renderKwsQuestion()}
                {isNoMPO &&
                    <KoenigButton
                        className="next-button"
                        text="Jetzt Leistungen auswählen"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.isValid()}/>
                }

                {this.state.isShowOverlayPaymentInfo &&
                    <Overlay onClose={this.handleClosePaymentInfo.bind(this)}>
                        <h1>Bitte beachten Sie!</h1>
                        <p>In der ausgewählten Werkstatt Berlin-Charlottenburg können Sie nur einen Werkstatttermin buchen,<br />wenn Sie die ausgewählten Leistungen im Abschluss der Buchung direkt online zahlen.</p>
                        <span>Eine Barzahlung ist am Standort nicht möglich!</span>
                        <br /><br /><br /><br />
                        <KoenigButton
                            onClick={this.handleClosePaymentInfo.bind(this)}
                            text="Schließen" />
                    </Overlay>
                }
            </StepPage>
        );
    }

    renderVehicleSelection() {
        const {brands, brand} = this.state;
        const brandValue = brand !== null ? brand.label : null;
        const brandOptions = brands.map(b => b.label);

        return (
            <div className="vehicle-selection">
                <div className="headline">
                    <Icon variant="vehicle_small_compact" style={{width: '3.4375rem', display: 'inline-block'}}/>
                    <b className="headline--text">Welches Fahrzeug möchten Sie uns in die Reparatur geben?</b>
                </div>

                <div className="input-wrapper">

                    <div className="brand-select">
                        <KoenigSelect
                            label="Marke"
                            value={brandValue}
                            options={brandOptions}
                            onChange={this.handleBrandSelection.bind(this)}
                            isRequired/>

                    </div>

                    <div className="model-select">
                        {this.renderModelSelection()}
                    </div>

                </div>
            </div>
        );
    }

    renderModelSelection() {
        const {models, brand, model} = this.state;
        const modelValue = model !== null ? model.label : null;
        const modelOptions = brand === null
            ? [CALL_FOR_SELECT_A_BRAND]
            : models.map(m => m.label);

        if (brand && brand.label && /^sonstige|^wohnmobile/i.test(brand.label)) {
            return (
                <KoenigInput
                    label="Marke und Modell"
                    value={modelValue}
                    onChange={this.handleModelSelection.bind(this)}
                    isRequired/>
            );
        }

        return (
            <KoenigSelect
                label="Modell"
                value={modelValue}
                options={modelOptions}
                disabledOptions={[CALL_FOR_SELECT_A_BRAND]}
                onChange={this.handleModelSelection.bind(this)}
                isRequired/>
        );
    }

    renderBranchScheduleSelection(isNoMPO = true) {
        const {
            appointments,
            brand,
            branches,
            branch,
            day = this.regularFirstDay(),
            time
        } = this.state;
        const branchValue = branch && branch._id ? branch._id : null;
        const branchSelectProps = {};
        const branchOptions = branches.filter(b =>
            brand && brand._id && b && b.available_brands && b.available_brands.indexOf(brand._id) !== -1
        );

        if (branchOptions.length === 0) {
            branchOptions.push(CALL_FOR_SELECT_A_BRAND);
        } else {
            branchSelectProps.itemComponent = BranchComp;
            branchSelectProps.valueProperty = '_id';
        }

        let timeOptions = [CALL_FOR_SELECT_A_BRANCH];
        let disabledOptions = [CALL_FOR_SELECT_A_BRANCH];
        if (branchValue !== null) {
            timeOptions = branch.time_slots;

            const branchId = branchValue + '';
            if (typeof appointments[branchId] !== 'undefined') {
                disabledOptions = appointments[branchId][day];
            }
        }

        return (
            <div className="branch-schedule-selection">
                <div className="headline">
                    <Icon variant="branch_schedule" style={{width: '3.125rem', display: 'inline-block'}}/>
                    <b className="headline--text">Welches Filiale bevorzugen Sie und wann passt es Ihnen?</b>
                </div>

                <div className="input-wrapper">
                    <div className="branch-select">
                        <KoenigSelect
                            label="Filiale"
                            options={branchOptions}
                            disabledOptions={[CALL_FOR_SELECT_A_BRAND]}
                            value={branchValue}
                            {...branchSelectProps}
                            onChange={this.handleBranchSelection.bind(this)}
                            isRequired/>
                    </div>

                    {isNoMPO &&
                        <>
                            <Datepicker
                                className="day-picker"
                                label="Datum"
                                value={day}
                                onDayValidation={this.handleDayValidation.bind(this)}
                                onChange={this.handleDaySelection.bind(this)}
                                isRequired/>

                            <div className="time-select">
                                <KoenigSelect
                                    label="Uhrzeit"
                                    value={time}
                                    options={timeOptions}
                                    disabledOptions={disabledOptions}
                                    onChange={val => this.setState({time: val})}
                                    isRequired/>
                            </div>
                        </>
                    }

                </div>
            </div>
        );
    }

    renderCarIdentifyingSelection() {
        const {
            plate, vin, mileage, registration,
            err_vin, err_mileage
        } = this.state;

        return (
            <div className="car-identifying-selection">
                <div className="headline">
                    <Icon variant="vehicle_identifying" style={{width: '2.5rem', display: 'inline-block'}}/>
                    <b className="headline--text">Mit der Identifikation Ihres Fahrzeuges, können wir Ihre Buchung
                        schneller bearbeiten.</b>
                </div>

                <div className="input-wrapper">
                    <KoenigInput
                        className="plate-input"
                        value={plate}
                        onChange={val => this.setState({plate: val})}
                        label="Kennzeichen"
                        placeholder="X-XX 000"
                        isRequired/>

                    <KoenigInput
                        className="vin-input"
                        label="Fahrzeug-Identnummer (17-stellig)"
                        value={vin}
                        onChange={this.handleVinChange.bind(this)}
                        errorText={err_vin}
                        placeholder="XXXXXXXXXXXXXXXXX"
                        isRequired/>

                    <KoenigInput
                        className="mileage-input"
                        label="Aktueller Kilometerstand"
                        value={mileage}
                        onChange={this.handleMileageChange.bind(this)}
                        errorText={err_mileage}
                        isNumberField
                        isRequired/>

                    <DateChooser
                        className="registration-chooser"
                        label="Erstzulassung"
                        value={registration}
                        onChange={val => this.setState({registration: val})}
                        isRequired/>
                </div>
            </div>
        );
    }

    renderKwsQuestion() {
        const {kws_member} = this.state;

        return (
            <div className="kws-selection">
                <span className="kws-question">Nutzen Sie bereits unseren König Werkstattservice?</span>
                <RadioButtonGroup
                    selectedIndex={kws_member === true ? 0 : 1}
                    onChange={idx => this.setState({kws_member: idx === 0})}
                    className="kws-radiogroup"/>
            </div>
        );
    }

    regularFirstDay(forewardDays) {
        return moment().tz('Europe/Berlin').add(forewardDays, 'days').format('YYYYMMDD');
    }

    existsFreeTimeslotFor(branch) {
        return {
            at: isoDate => {
                const {appointments} = this.state;

                if (moment(isoDate, ISO_DATE_FORMAT).weekday() === 6) {
                    return false;
                }

                if (typeof branch === 'undefined' || branch === null || typeof branch.time_slots === 'undefined') {
                    return false;
                }

                if (appointments && typeof appointments[branch._id] === 'undefined') {
                    return true;
                }

                if (appointments && typeof appointments[branch._id][isoDate] === 'undefined') {
                    return true;
                }

                return appointments[branch._id][isoDate].length < branch.time_slots.length;
            }
        }
    }

    isValid() {
        const {err_vin, err_mileage} = this.state;
        const hasVinError = err_vin && err_vin.length > 0;
        const hasMileageError = err_mileage && err_mileage.length > 0;

        if (hasVinError || hasMileageError) {
            return false;
        }

        const propsFilter = key => [
            'brands', 'branches', 'appointments',
            'models', 'err_vin', 'err_mileage', 'kws_member'
        ].indexOf(key) === -1;

        return Object.keys(this.state)
            .filter(propsFilter)
            .map(key => this.state[key])
            .reduce((result, stateValue) =>
                    result && typeof stateValue !== 'undefined' && stateValue !== null,
                true
            );
    }
}

const getAppointmentForewardDays = () => {
    if (typeof process.env.APPOINTMENT_FOREWARD_DAYS !== 'undefined') {
        return process.env.APPOINTMENT_FOREWARD_DAYS;
    }

    console.log(
        'APPOINTMENT_FOREWARD_DAYS nicht gesetzt, es wird der Standardwert von 14 Tagen verwendet'
    );

    return 14;
}

export default Schritt1Page;