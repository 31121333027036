import React from 'react';

import './styles.scss';

const CSS_CLASS = 'rating-widget';

class RatingWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: 0,
            hoverRating: 0
        }
    }

    handleSetRating(idx) {
        const {onChange = () => {}} = this.props;

        onChange(idx);

        this.setState({rating: idx})
    }

    render() {

        return (
            <div className={CSS_CLASS}>
                {
                    ['Gar nicht', 'Eher nicht', 'Akzeptabel', 'Zufrieden', 'Sehr zufrieden!']
                        .map((text, idx) => this.renderRatingIcon(text, idx + 1))}
            </div>
        );
    }

    renderRatingIcon(text, idx) {
        const color = this.determineColor(idx);

        return (
            <span className="rating-icon" key={'rating_icon_' + idx}
                onMouseEnter={() => this.setState({hoverRating: idx})}
                onMouseLeave={() => this.setState({hoverRating: 0})}
                onClick={() => this.handleSetRating(idx)}>
                <svg viewBox="0 0 33.323 32.062">
                  <path d="M15.639,25.343l8.676,4.59a.917.917,0,0,0,1.329-.971l-1.658-9.723,7.019-6.886a.923.923,0,0,0-.507-1.572L20.8,9.362,16.46.517a.914.914,0,0,0-1.644,0L10.479,9.362l-9.7,1.419a.924.924,0,0,0-.509,1.572l7.02,6.886L5.633,28.962a.916.916,0,0,0,1.329.971l8.677-4.59Z" transform="translate(1.023 0.997)" fill={color} stroke="#434242" strokeWidth="2"/>
                </svg>
                <span>{text}</span>
            </span>
        )
    }

    determineColor(idx) {
        const {rating, hoverRating} = this.state;

        if (hoverRating >= idx) {
            return '#3b3b3b';
        } else if (!hoverRating && rating >= idx) {
            return '#3b3b3b';
        }

        return '#ffffff';
    }
}

export default RatingWidget;