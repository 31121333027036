import React from 'react';
import PropTypes from 'prop-types';

import elementSprite from '../element-sprite.svg';
import "./radio_button_group.scss";

class RadioButtonGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        if (typeof props.selectedIndex !== 'undefined') {
            this.state.selectedItemIndex = parseInt(props.selectedIndex);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedItemIndex !== this.state.selectedItemIndex) {
            const changeFunc = this.props.onChange || function () { };
            changeFunc(this.state.selectedItemIndex);

            return;
        }

        if (typeof prevProps.selectedIndex !== 'undefined') {
            if (parseInt(prevProps.selectedIndex) !== parseInt(this.props.selectedIndex)) {
                this.setState({ selectedItemIndex: parseInt(this.props.selectedIndex) });
            }
        }
    }

    render() {
        const { groupItems, className, disabledIndexes = [], icon } = this.props;
        const { selectedItemIndex } = this.state;
        const classes = ['koenig-radio-button-group'];

        if (className) {
            classes.push(className);
        }

        if (icon) {
            classes.push('with-icon');
        }

        return (
            <span className={classes.join(' ')}>
                {
                    groupItems.map((item, idx) => {
                        const classes = ['koenig-radio-button'];
                        const isSelected = idx === selectedItemIndex;
                        const isDisabled = disabledIndexes.indexOf(idx) !== -1;
                        const id = isSelected ? 'radio_selected' : 'radio';
                        let clickFunc = () => this.setState({ selectedItemIndex: idx });
                        const Comp = typeof item === 'string'
                            ? p => <span className="label">{item}</span>
                            : item;

                        if (isSelected) {
                            classes.push('selected');
                            clickFunc = function () { };
                        }

                        if (isDisabled) {
                            classes.push('disabled');
                            clickFunc = function () { };
                        }

                        return (
                            <span
                                key={idx}
                                className={classes.join(' ')}
                                onClick={clickFunc}
                            >
                                {
                                    icon
                                        ? icon
                                        : <div className="koenig-radio-button--button"></div>
                                }
                                <Comp />
                            </span>
                        );
                    })
                }
            </span>
        );
    }
}

RadioButtonGroup.defaultProps = {
    groupItems: ['ja', 'nein']
};

RadioButtonGroup.propTypes = {
    groupItems: PropTypes.array.isRequired
};

export default RadioButtonGroup;