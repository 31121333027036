import "./styles.scss"

import React from 'react';

import KoenigButton from '../../../../elements/KoenigButton';
import StepPage from "../components/StepPage";
import KoenigSelect from "../../../../elements/KoenigSelect";
import KoenigInput from "../../../../elements/KoenigInput";
import Functions from "../functions";
import { DISCOUNT_ITEM } from "../Schritt4Page";

const CSS_CLASS = 'wtt--step3';
const CSS_FORM = CSS_CLASS + '--form';

class Schritt3Page extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            brand: null, model: null,
            branch: null,
            day: null, time: null,
            plate: null, vin: null,
            mileage: null, registration: null,
            kws_member: false,

            shop_items: [],

            salutation: null, firstname: null, lastname: null,
            address: null, zip_code: null, location: null,
            email: null, phone: null,

            ...postData
        };
    }


    handleShopItemRemoving(shopItemId) {
        const { shop_items } = this.state;
        let newShopItems = shop_items.filter(si => si && si._id !== shopItemId);

        if (newShopItems.length === 1 && newShopItems[0]._id === DISCOUNT_ITEM._id) {
            newShopItems = [];
        }

        this.setState({
            shop_items: newShopItems
        });
    }

    handleBackLinking() {
        Functions.linkTo('/werkstatt/terminbuchung/schritt2/', this.state);
    }

    handleNextLinking() {
        Functions.linkTo('/werkstatt/terminbuchung/schritt4/', this.state);
    }

    render() {
        const { shop_items } = this.state;

        return (
            <StepPage
                className="page-terminbuchung-schritt3"
                steps={[-1, -1, 0, 1]}
                onRemoveItem={this.handleShopItemRemoving.bind(this)}
                {...this.state}>
                <div className="intro-text">
                    <b>Ihre persönlichen Daten benötigen wir für Ihr Kundenkonto und die Rechnung.</b><br />
                    <p>Auch hier füllen Sie ganz einfach die nachfolgenden Felder aus. Ihre E-Mail-Adresse und die Telefonnummer sind für uns sehr wichtig, damit wir uns im Fall eines Buchungsfehlers bei Ihnen melden können. Wir nutzen Ihre Daten ausschließlich zur Durchführung dieser Terminbuchung.</p>
                </div>

                {this.renderForm()}

                <div className="wtt--step3--button--row">
                    <KoenigButton
                        className="back-button"
                        text="Zurück zu Schritt 2"
                        onClick={this.handleBackLinking.bind(this)} />

                    <KoenigButton
                        className="next-button"
                        text="Prüfen & Bestellen"
                        onClick={this.handleNextLinking.bind(this)}
                        disabled={!this.isValid()} />
                </div>

            </StepPage>
        );
    }

    renderForm() {
        const {
            salutation, firstname, lastname, address,
            zip_code, location, email, phone
        } = this.state;

        return (
            <div className={CSS_FORM}>
                <div className={CSS_FORM + '--salutation'}>
                    <KoenigSelect
                        label="Anrede"
                        options={['Herr', 'Frau']}
                        value={salutation}
                        onChange={salutation => this.setState({ salutation })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--firstname'}>
                    <KoenigInput
                        label="Vorname"
                        value={firstname}
                        onChange={firstname => this.setState({ firstname })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--lastname'}>
                    <KoenigInput
                        label="Nachname"
                        value={lastname}
                        onChange={lastname => this.setState({ lastname })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--address'}>
                    <KoenigInput
                        label="Straße und Hausnummer"
                        value={address}
                        onChange={address => this.setState({ address })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--zip_code'}>
                    <KoenigInput
                        label="PLZ"
                        value={zip_code}
                        onChange={zip_code => this.setState({ zip_code })}
                        name="postal_code"
                        isRequired />
                </div>

                <div className={CSS_FORM + '--location'}>
                    <KoenigInput
                        label="Ort"
                        value={location}
                        onChange={location => this.setState({ location })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--email'}>
                    <KoenigInput
                        label="E-Mail"
                        value={email}
                        onChange={email => this.setState({ email })}
                        isEmailField
                        isRequired />
                </div>

                <div className={CSS_FORM + '--phone'}>
                    <KoenigInput
                        label="Telefonnummer"
                        value={phone}
                        onChange={phone => this.setState({ phone })}
                        isNumberField
                        isRequired />
                </div>
            </div>
        );
    }

    isValid() {
        const allInputsNotNull = [
            'salutation', 'firstname', 'lastname',
            'address', 'zip_code', 'location',
            'email', 'phone'
        ].reduce(
            (result, key) => {
                if (result && key === 'email') {
                    return this.isEmailValid(this.state[key]);
                }

                return result && this.state[key] !== null && this.state[key].length > 0
            },
            true
        );

        return allInputsNotNull && this.state.shop_items.length > 0;
    }

    isEmailValid = (email) => {
        return email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }
}

export default Schritt3Page;