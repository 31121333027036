import './styles/styles.scss';

import React from 'react';
import Question from './components/Question';

export default ({ faq }) => {
    return typeof faq === 'object' && Object.keys(faq).length > 0
        ? <div className="cnt-faq">{renderFaq(faq)}</div>
        : null;
}

function renderFaq(faq) {
    return (
        <React.Fragment>
            <h3>{(typeof faq.headline !== 'undefined') ? faq.headline : 'FAQ'}</h3>
            {(typeof faq.items !== 'undefined') ? renderItems(faq.items) : null}
        </React.Fragment>
    )
}

function renderItems(items) {
    const hasItems = typeof items !== 'undefined' && items.length > 0;

    if (hasItems) {
        return items.map((item, key) => {
            item.key = key;
            return <Question key={key} item={item} />
        });
    }

    return null;
}