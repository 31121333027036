export const uniqueItems = (brand, idx, brands) => {
    return brands.indexOf(brand) === idx;
}

export const normalizePossibleBrands = (retVal, brand) => {
    retVal.push(brand.replace('alfa-romeo', 'alfa_romeo'));
    if (brand === 'renault') {
        retVal.push('elektro');
    }
    if (['abarth', 'fiat'].indexOf(brand) !== -1) {
        retVal.push('fiat_abarth_prof');
    }
    return retVal;
};

export const sortBrands = (b1, b2) => {
    const nb1 = transformToPosNumber(b1);
    const nb2 = transformToPosNumber(b2);
    if (nb1 === nb2) {
        return 0;
    }
    return nb1 < nb2 ? -1 : 1;
};

export const transformToPosNumber = (brand) => {
    switch (brand) {
        case 'renault':
            return 1;
        case 'elektro':
            return 2;
        case 'dacia':
            return 3;
        case 'fiat':
            return 4;
        case 'jeep':
            return 5;
        case 'alfa_romeo':
            return 6;
        case 'kia':
            return 7;
        case 'vespa':
            return 8;
        default:
            return 0;
    }
};