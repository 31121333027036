import React from 'react';
import qs from 'qs';
import api from '../../../../helper/api.helper';

import './styles.scss';

const CSS_CLASS = 'fc-gw-model-navigation'

class GwModelNavigation extends React.Component {
    render() {
        const { brandLabel, activeLabel, brand } = this.props;
        const cssClass = activeLabel === brandLabel ? 'active' : '';
        const brandLink = '/gebrauchtwagen/automarken/' + brand;
        const label = brandLabel + '-Übersicht';

        return (
            <div className={CSS_CLASS}>
                {<ul>
                    <li className={cssClass}>
                        {cssClass !== 'active' ? this.renderLink(cssClass, brandLink, label) : label}
                    </li>
                    {this.renderModels()}
                </ul>}
            </div>
        );
    }

    renderModels() {
        const { models = [], brand, activeModel } = this.props;

        return models.map((model, idx) => {
            const cssClass = activeModel === model._id ? 'active' : '';
            const modelUrl = `/gebrauchtwagen/automarken/${brand}/${model._id}/`;

            return (
                <li key={model._id + '-navigation-' + idx} className={cssClass}>
                    {cssClass !== 'active' ? this.renderLink(cssClass, modelUrl, model.label) : model.label}
                </li>
            )
        });
    }

    renderLink(cssClass, href, label) {
        return (
            <a
                className={cssClass}
                href={href}
                title={label}>
                {label}
            </a>
        );
    }
}

export default GwModelNavigation;