import './styles.scss';

import React from 'react';

export default ({ className = 'default', jumpPoints = [] }) => {
    const cssClass = className + '--jump-navigation';

    if (Object.keys(jumpPoints).length != 26) {
        return null;
    }

    return (
        <div className={cssClass}>
            {
                Object.keys(jumpPoints).map(function (key, index) {
                    const elementClass = [cssClass + '--element'];
                    let href = null;

                    if (jumpPoints[key].length > 0) {
                        elementClass.push('active');
                        href = '#' + jumpPoints[key][0];
                    }

                    return (
                        <a key={'jumpPoint'+index} href={href} className={elementClass.join(' ')}>
                            {key}
                        </a>
                    );
                })
            }
        </div>
    );
};