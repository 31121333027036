import './styles.scss';

import React from 'react';
import Overlay from '../../../../../components/Overlay';
import KoenigButton from '../../../../../elements/KoenigButton';
import happySmile from './happy_smile.svg';
import sadSmile from './sad_smile.svg';
import apiHelper from '../../../../../helper/api.helper';
import KoenigSelect from '../../../../../elements/KoenigSelect';

const CSS_PREFIX = 'fc-decision-overlay';

class DecisionOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            overlayVisible: false,
            federalState: false
        };
    }

    async componentDidMount() {
        let branches = [], brands = [];

        try {
            branches = await apiHelper.GET('/branches');
            brands = await apiHelper.GET('/brands');

            brands.push({_id: 'sonstigeAutomarke', label: 'Andere Marke'});
        } catch (e) {
            /* istanbul ignore next */
            console.error(e);
        } finally {
            this.setState({branches, brands});
        }
    }

    handleNegativ() {
        window.open('/bewertung/negativ/', '_self');
    }

    handleBrandSelection(brand) {
        const createOptionWith = b => `${b.name}, ${b.street}`;

        this.setState(ps => {
            const {brands: apiBrands = []} = ps;
            const brandMapping = apiBrands.reduce((result, b) => {
                result[b.label] = b._id;
                return result;
            }, {});

            ps.brand = brand;
            ps.federalState = "";
            ps.mappedBranches = ps.branches.reduce((map, branch) => {
                const {federal_state, brands = []} = branch;

                if (brands.indexOf(brandMapping[brand]) === -1) {
                    return map;
                }

                if (typeof federal_state !== 'undefined') {
                    const branches = map[federal_state] ? map[federal_state] : [];

                    branches.push(createOptionWith(branch));
                    map[federal_state] = branches;
                }

                return map;
            }, {});

            return ps;
        });
    }

    handleBranchSelect(val) {
        const {branches, brand} = this.state;
        const [branch] = branches.filter(b => `${b.name}, ${b.street}` === val);
        let url = '';

        if (branch) {
            switch (brand) {
                case 'Renault':
                    url = branch.google_review_url_renault || branch.google_review_url;
                    break;
                case 'Dacia':
                    url = branch.google_review_url_dacia || branch.google_review_url;
                    break;
                default:
                    url = branch.google_review_url;
            }

            if (!url) {
                url = branch.branch_maps_url || '';
            }

            window.open(url, '_blank');
        }
    }

    render() {
        const {overlayVisible} = this.state;

        return (
            <React.Fragment>
                {!overlayVisible && this.renderDecisionOverlay()}
                {overlayVisible && this.renderBranchOverlay()}
            </React.Fragment>
        )
    }

    renderDecisionOverlay() {
        const {onClose} = this.props;

        return (
            <Overlay className={CSS_PREFIX}>
                <a className={CSS_PREFIX + '--close'} onClick={onClose}>&#10005;</a>
                <h1>So einfach bewerten Sie!</h1>
                <p>Da verschiedene Abteilungen Ihr Feedback verarbeiten, helfen Sie uns
                    sehr, wenn Sie uns hier schon mitteilen, in welche Richtung Ihr Feedback geht:</p>
                {this.renderButtons()}
            </Overlay>
        );
    }

    renderButtons() {
        return (
            <div>
                {
                    this.renderButton(
                        happySmile,
                        'Eher gut, ich bin zufrieden und möchte einen Standort direkt bewerten.',
                        'positiv',
                        () => this.setState({overlayVisible: true})
                    )
                }
                {
                    this.renderButton(
                        sadSmile,
                        'Eher schlecht, ich möchte Kritik loswerden.',
                        'negativ',
                        this.handleNegativ
                    )
                }
            </div>
        );
    }

    renderButton(icon, text, alt, handler) {
        return (
            <span onClick={handler}>
                <img src={icon} alt={alt}/>
                <KoenigButton/>
                <p>{text}</p>
            </span>
        );
    }

    renderBranchOverlay() {
        const {mappedBranches = {}, federalState = "", brand} = this.state;
        const brandOptions = this.getAvailableBrands();
        const {onClose} = this.props;

        return (
            <Overlay className={CSS_PREFIX}>
                <a className={CSS_PREFIX + '--close'} onClick={onClose}>&#10005;</a>
                <h1>Wählen Sie einfach Ihre Marke und Wunschfiliale aus:</h1>
                <p>(Anmeldedaten für Google erforderlich)</p>
                <KoenigSelect
                    value="Bitte wählen"
                    label="Marke auswählen"
                    options={brandOptions}
                    onChange={this.handleBrandSelection.bind(this)}/>

                {brand &&
                    <KoenigSelect
                        options={Object.keys(mappedBranches).sort(this.alphabeticSort)}
                        label="Bundesland auswählen"
                        value={federalState.length > 0 ? federalState : "Bitte wählen"}
                        onChange={federalState => this.setState({federalState})}/>
                }
                {federalState &&
                    this.renderBranchSelect()
                }
            </Overlay>
        );
    }

    renderBranchSelect() {
        const {mappedBranches, federalState} = this.state;

        return <KoenigSelect
            value="Bitte wählen"
            options={mappedBranches[federalState]}
            label="Google-Standortseite"
            onChange={(val) => this.handleBranchSelect(val)}/>;
    }

    alphabeticSort(a, b) {
        const ua = a.toUpperCase(), ub = b.toUpperCase();

        return ua === ub ? 0 : ua < ub ? -1 : 1;
    }

    getAvailableBrands() {
        const {brands = [], branches = []} = this.state;
        const map = branches.reduce((map, b) => {
            const {brands = []} = b;
            brands.forEach(brandId => map[brandId] = map[brandId] ? map[brandId]++ : 1)

            return map;
        }, {});
        map['sonstigeMarke'] = 1;

        return brands
            .filter(b => ['renault', 'dacia', 'elektro'].indexOf(b._id) === -1)
            .filter(b => map[b._id] && map[b._id] > 0)
            .map(b => b.label)
            .sort(this.alphabeticSort)
            .sort(a => a === 'Andere Marke' ? 1 : 0)
            .reduce((result, b) => {
                result.push(b);

                return result;
            }, ['Renault', 'Dacia']);
    }
}

export default DecisionOverlay;