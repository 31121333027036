import React, { useEffect } from 'react';

import UsedCarButton from '../../../components/UsedCarButton';
import Icon from '../../../components/UsedCarIcon';
import fbHelper from '../../../helper/facebook.helper';
import VideoOverlay from './VideoOverlay';

const ImageGallery = ({ images, vehicleId, videoLink }) => {
	const [showOverlay, toggleOverlay] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState(0);
	const [clientX, setClientX] = React.useState({ initial: 0, final: 0 });
	const [clientY, setClientY] = React.useState({ initial: 0, final: 0 });
	const [showVideoOverlay, setVideoOverlay] = React.useState(false);

	const next = () => {
		if (currentImage + 1 < images.length) setCurrentImage(currentImage + 1);
		if (currentImage === images.length - 1) setCurrentImage(0);
	};

	const back = () => {
		if (currentImage > 0) setCurrentImage(currentImage - 1);
		if (currentImage === 0) setCurrentImage(images.length - 1);
	};

	const close = () => {
		toggleOverlay(!showOverlay);
		setCurrentImage(0);
	};

	const handleTouch = (event, type) => {
		const x = event.nativeEvent.touches[0].clientX;
		const y = event.nativeEvent.touches[0].clientY;

		setClientX({ ...clientX, [type]: x });
		setClientY({ ...clientY, [type]: y });
	};

	const handleTouchEnd = () => {
		const horizontalSwipeIntended = clientY.final - clientY.initial < 50;
		const wasSwipedToRight = horizontalSwipeIntended && clientX.final > clientX.initial;
		const wasSwipedToLeft = horizontalSwipeIntended && clientX.final < clientX.initial;

		if (wasSwipedToRight) {
			back();
		}

		if (wasSwipedToLeft) {
			next();
		}

		setClientX({ inital: 0, final: 0 });
		setClientY({ inital: 0, final: 0 });
	};

	const handleClickGalary = () => {
		toggleOverlay(!showOverlay);
		fbHelper.track('AddToWishlist', {
			content_type: 'vehicle',
			content_ids: [vehicleId]
		});
	}

	const renderOverlay = () => (
		<div className='overlay-gallery'>
			<Icon
				className='overlay-gallery__close'
				variant='close'
				color='#fff'
				size={35}
				onClick={() => close()}
			/>
			<div className='overlay-gallery__main'>
				<img
					src={images[currentImage].src}
					onTouchStart={(e) => handleTouch(e, 'initial')}
					onTouchMove={(e) => handleTouch(e, 'final')}
					onTouchEnd={() => handleTouchEnd()}
					loading='lazy'
				/>
			</div>

			<div className='overlay-gallery__caption'>
				<Icon
					className='overlay-gallery__back'
					variant='chevron_left_small'
					color='#fff'
					size={40}
					onClick={() => back()}
				/>
				<span className="overlay-gallery__caption-text">
					{currentImage + 1} / {images.length}
				</span>
				<Icon
					className='overlay-gallery__next'
					variant='chevron_right_small'
					color='#fff'
					size={40}
					onClick={() => next()}
				/>
			</div>
		</div>
	);

	useEffect(() => {
		document.onkeydown = (e) => {
			switch (e.key) {
				case 'ArrowLeft': back();
					break;
				case 'ArrowRight': next();
					break
			}
		}
	}, [currentImage]);

	return (
		<div className='image-gallery'>
			<div className='image-gallery__wrapper'>
				{images.slice(0, 5).map((img, i) => (
					<img
						key={`image-${img.carId}-${i}`}
						src={img.src}
						alt={img.alt}
						onClick={() => toggleOverlay(!showOverlay)}
					/>
				))}
			</div>
			{
				videoLink &&
				<UsedCarButton addClass='image-gallery__video' title={'Video ansehen'} onClick={() => setVideoOverlay(true)}/>
			}
			{images.length >= 5 && (
				<UsedCarButton
					addClass='image-gallery__more'			
					title='Alle Bilder ansehen'
					onClick={() => handleClickGalary()}
				/>
			)}

			{showOverlay && renderOverlay()}
			{showVideoOverlay && <VideoOverlay link={videoLink} onClose={() => setVideoOverlay(false)}/>}
		</div>
	);
};

export default ImageGallery;
