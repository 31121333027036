import React from 'react';
import KoenigSelect from '../../elements/KoenigSelect';
import moment from 'moment';

import "moment/locale/de";
import "./styles.scss";

const MAIN_CSS = 'date-chooser';
const monthOptions = [
    'Januar', 'Februar', 'März', 'April',
    'Mai', 'Juni', 'Juli', 'August',
    'September', 'Oktober', 'November', 'Dezember'
];

class DateChooser extends React.Component {

    constructor(props) {
        super(props);

        const momentDate = moment(props.value, 'YYYYMMDD');
        const validDateValue = momentDate.isValid();

        this.state = {
            day: validDateValue ? momentDate.format('DD') : '--',
            month: validDateValue ? momentDate.format('MMMM') : '--',
            year: validDateValue ? momentDate.format('YYYY') : '----',
            value: props.value || null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const prevDateString = this.getDateString(prevState);
        const currentDateString = this.getDateString(this.state);

        if (prevDateString !== currentDateString) {
            this.setState({
                value: currentDateString.indexOf('-') === -1
                    ? currentDateString
                    : null
            });
        }

        if (prevState.value !== this.state.value) {
            const { onChange = () => { } } = this.props;
            onChange(this.state.value);
        }

        if(prevProps.value !== this.props.value && this.props.value){
            const momentDate = moment(this.props.value, 'YYYYMMDD');
            if(momentDate.isValid()){
                this.setState({
                    day: momentDate.format('DD'),
                    month: momentDate.format('MMMM'),
                    year: momentDate.format('YYYY'),
                    value: this.props.value
                });
            }
        }
    }

    handleMonthChange(selectedMonth) {
        let { day, month, year } = this.state;

        if (day !== '--') {
            const possibleMaxDays = this.getPossibleMaxDays(year);
            const monthIdx = monthOptions.indexOf(selectedMonth);
            const selectedDay = parseInt(day);
            if (selectedDay > possibleMaxDays[monthIdx]) {
                day = '--';
            }
        }

        month = selectedMonth;

        this.setState({ day, month });
    }

    render() {
        const { id, className, label = '', isRequired = false } = this.props;
        const { day, month, year } = this.state;
        const SELECT_CSS = MAIN_CSS + '--select';
        const classes = [MAIN_CSS];
        let labelText = label;

        if (isRequired && label.length > 0) {
            labelText = label + '*';
        }

        if (className) {
            classes.push(className);
        }

        return (
            <div id={id} className={classes.join(' ')}>
                <span className={MAIN_CSS + '--label'}>{labelText}</span>
                <div className={MAIN_CSS + '--select--container'}>
                    <div>
                        <KoenigSelect
                            className={`${SELECT_CSS} ${SELECT_CSS}--day`}
                            options={this.getDayOptions()}
                            onChange={val => this.setState({ day: val })}
                            value={day} />
                    </div>
                    <div>
                        <KoenigSelect
                            className={`${SELECT_CSS} ${SELECT_CSS}--month`}
                            options={monthOptions}
                            onChange={this.handleMonthChange.bind(this)}
                            value={month} />
                    </div>
                    <div>
                        <KoenigSelect
                            className={`${SELECT_CSS} ${SELECT_CSS}--year`}
                            options={this.getYearOptions()}
                            onChange={val => this.setState({ year: val })}
                            value={year} />
                    </div>
                </div>
            </div>
        );
    }

    getDayOptions() {
        let retArr = [];
        for (let i = 1; i <= 31; i++) {
            retArr.push(('0' + i).substr(-2));
        }
        return retArr;
    }

    getYearOptions() {
        const { yearAmount = 40 } = this.props;
        let retArr = [];
        const currentYear = parseInt(moment().tz('Europe/Berlin').format('YYYY'));
        for (let i = currentYear; i >= (currentYear - parseInt(yearAmount)); i--) {
            retArr.push(i + '');
        }
        return retArr;
    }

    getPossibleMaxDays(year) {
        const maxDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (year !== '----' && moment([parseInt(year)]).isLeapYear()) {
            maxDays[1] = 29;
        }
        return maxDays;
    }

    getDateString(state) {
        const { day, month, year } = state;
        let convertedMonth = '--';
        if (month !== '--') {
            convertedMonth = monthOptions.indexOf(month) + 1;
            convertedMonth = ('0' + convertedMonth).substr(-2);
        }
        return year + convertedMonth + day;
    }
}

export default DateChooser;