function execPaymentRequest(paymentRequest) {
    if (typeof window !== 'undefined' && window.document) {
        const data = JSON.parse(JSON.stringify(paymentRequest));
        delete data.url;
        delete data._id;
        delete data.status;
        delete data.created_at;
        delete data.updated_at; 

        const form = document.createElement('form');
        form.setAttribute('style', 'display: none');
        form.setAttribute('action', paymentRequest.url);
        form.setAttribute('method', 'POST');

        Object.keys(data).forEach(key => {
            const input = document.createElement('input');
            input.setAttribute('name', key);
            input.setAttribute('value', data[key]);
            input.setAttribute('type', 'hidden');
            form.appendChild(input);
        });

        document.getElementsByTagName('body')[0].appendChild(form);
        form.submit();
    }
}

export default {
    execPaymentRequest
};