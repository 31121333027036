import React from 'react';

import "./testings.scss";

class FlexboxWrapper extends React.Component {

    componentDidMount() {
        const {className} = this.props;
        const classes = ['flexbox-wrapper-for-testing-purposes'];
        
        if(className){
            classes.push(className);
        }
        
        const appRoot = document.getElementById('app_root');
        if (appRoot) {
            appRoot.setAttribute('class', classes.join(' '));
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default FlexboxWrapper;