import React from 'react';
import FlexboxWrapper from './FlexboxWrapper';
import KoenigSelect from '../../../shared/elements/KoenigSelect';

const opts = ['verdammt langer text um in das Overflow reinzurutschen'];
for (let i = 1; i <= 10; opts.push(`item ${i++}`));

const hintComp = () => (
    <div style={{ padding: '15px', width: '400px', margin: 0 }}>
        Hinweistext der verfickt nochmal richtig angezeigt werden soll
    </div>
);

export default () => (
    <FlexboxWrapper className="koenig-select-setup">

        <KoenigSelect
            options={opts} />

        <KoenigSelect
            label="Mit Label"
            options={opts} />

        <KoenigSelect
            label="Mit Label"
            errorText="Die ist der Error Text"
            options={opts} />

        <KoenigSelect
            label="Mit Hinweis-Button"
            hintContent={hintComp}
            options={opts} />

        <KoenigSelect
            label="Mit kleinerem Option Container"
            options={opts}
            optionsCount="3" />

    </FlexboxWrapper>
);