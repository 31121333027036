import "./styles.scss";

import React from 'react';
import CommonPage from '../../../../CommonPage';
import {Helmet} from 'react-helmet';
import Breadcrumb from '../../../../../components/Breadcrumb';
import StepIndicator from '../../../../../components/StepIndicator';
import Summary from '../Summary';
import TeaserImage from "../../../../../components/TeaserImage";
import moment from "moment/moment";

class StepPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSummaryVisible: false,
            isMpoBooking: false
        };
    }

    handleShowSummary() {
        if (typeof window !== 'undefined' && window.scrollTo) {
            window.scrollTo(0, 0);
        }

        this.setState({isSummaryVisible: true});
    }

    render() {
        const {isSummaryVisible} = this.state;
        const {children} = this.props;

        const crumbs = [
            {label: 'Start', path: '/'},
            {label: 'Werkstatt', path: '/werkstatt'},
            {label: 'Terminbuchung', current_page: true}
        ];

        const indicatorSteps = [
            this.props.steps,
            ['Ihr Fahrzeug', 'Leistungen', 'Persönliche Daten', 'Prüfen & Bestellen']
        ];

        const contentClasses = ['content-wrapper'];
        if (isSummaryVisible) {
            contentClasses.push('to-the-left');
        }

        const teaser_src = {
            mobile: "https://t3api.autohaus-koenig.de/relaunch_img/content/aktionen/2023-01-13-Freitag-13-te-Werkstatt-Aktion-625x300px.jpg",
            desktop: "https://t3api.autohaus-koenig.de/relaunch_img/content/aktionen/2023-01-13-Freitag-13-te-Werkstatt-Aktion-1250x300px.jpg"
        }
        const datetime = moment().tz('Europe/Berlin');
        const endAtMoment = moment("13.01.2023 23:59:59", "DD.MM.YYYY HH:mm:ss").tz('Europe/Berlin');
        const hideBanner = endAtMoment.diff(datetime) < 0 ? false : true;

        return (
            <CommonPage className={['page-terminbuchung', this.props.className].join(' ')}>
                <Helmet>
                    <title>Terminbuchung - Werkstatttermin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs}/>

                {isSummaryVisible === true
                    ? this.renderBackToOrderButton()
                    : this.renderShoppingBasketButton()
                }

                {hideBanner && <TeaserImage teaser_src={teaser_src} />}

                <h1>Buchen Sie Ihren Werkstatttermin in nur 4 Schritten</h1>

                <StepIndicator steps={indicatorSteps} style={{maxWidth: '46.25rem', margin: '1.5rem auto 2.5rem'}}/>

                <div className={contentClasses.join(' ')}>
                    <div className="left-column">
                        {children}
                    </div>
                    <div className="right-column">
                        <Summary {...this.props} />
                    </div>
                    {this.props.branch?.mpo?.length > 0 &&
                        <MpoFrame bir={this.props.branch?.mpo}/>
                    }
                </div>
            </CommonPage>
        );
    }

    renderShoppingBasketButton = () => {
        const {shop_items = []} = this.props;
        const cssShoppingBasket = 'wtt--button--shopping-basket';

        return (
            <React.Fragment>
                <div className={cssShoppingBasket} onClick={this.handleShowSummary.bind(this)}>
                    Warenkorb anzeigen
                    <div className={cssShoppingBasket + '--item-ad'}>{shop_items.length}</div>
                </div>
                <div className={cssShoppingBasket + ' fixed-position-helper'}></div>
            </React.Fragment>
        );
    }

    renderBackToOrderButton = () => {
        const cssBackToOrder = 'wtt--button--back-to-order';

        return (
            <React.Fragment>
                <div
                    className={cssBackToOrder}
                    onClick={() => this.setState({isSummaryVisible: false})}>
                    zurück zur Buchung
                </div>
                <div className={cssBackToOrder + ' fixed-position-helper'}></div>
            </React.Fragment>
        );
    }
}

function MpoFrame(props) {
    return (
        <section className="mpo-section">
            <iframe
                src={`https://sbol-renault.com/?bir=${props.bir}`}
                name="Service Booking Renault">
            </iframe>
        </section>
    )
}

export default StepPage;