import './styles.scss';

import React from 'react';
import CurrencyHelper from '../../../../helper/Currency';
import Functions from '../../functions';
import QuantitySelect from '../QuantitySelect';

const CSS_MAIN_CLASS = 'shopping-basket'

class ShoppingBasket extends React.Component {

    render() {
        const {title = 'Warenkorb'} = this.props;

        return (
            <div className={CSS_MAIN_CLASS}>
                <div className={CSS_MAIN_CLASS + '--header'}>
                    <span className={CSS_MAIN_CLASS + '--header--title'}>{title}</span>
                </div>
                {this.renderShopItems()}
            </div>
        );
    };

    renderShopItems() {
        const {shopItems = []} = this.props;

        if (shopItems.length > 0) {
            return this.renderShopItemElements(shopItems);
        } else {
            return this.renderDefaultNote();
        }
    }

    renderShopItemElements(shopItems) {
        const {
            onQuantityIncrement = () => {
            },
            onQuantityDecrement = () => {
            },
            itemLimit
        } = this.props;

        return (
            <div className={CSS_MAIN_CLASS + '--items-cnt'}>
                <ul>
                    {
                        shopItems.map((item, idx) => {
                            const {quantity, product = {}} = item;
                            const {title, prices, selected_option = ""} = product;

                            if (title && quantity && prices) {
                                const price = typeof prices.special_offer !== 'undefined'
                                    ? prices.special_offer
                                    : prices.default;

                                const formattedPrice = CurrencyHelper.toCurrency(price * quantity, true);

                                return (
                                    <li key={item._id + '-' + idx} className={CSS_MAIN_CLASS + '--item'}>
                                        <span className={CSS_MAIN_CLASS + '--item-title'}>
                                            {title}
                                            {selected_option.length > 0 &&
                                                <span>, {selected_option}</span>
                                            }
                                        </span>
                                        <QuantitySelect
                                            value={quantity}
                                            limit={itemLimit}
                                            onIncrement={() => onQuantityIncrement(item.product._id)}
                                            onDecrement={() => onQuantityDecrement(item.product._id)}/>

                                        <span className={CSS_MAIN_CLASS + '--item-price'}>{formattedPrice} €</span>
                                    </li>
                                );
                            }
                        })
                    }
                </ul>
                <hr/>
                {this.renderSum(shopItems)}
            </div>
        );
    }

    renderDefaultNote() {
        return (
            <div className={CSS_MAIN_CLASS + '--items-cnt'}>
                <div className={CSS_MAIN_CLASS + '--items-empty'}>
                    Bitte wählen Sie einen Artikel aus
                </div>
            </div>
        );
    }

    renderSum(shopItems) {
        const CSS_SUM_CLASS = CSS_MAIN_CLASS + '--sum';
        const total = Functions.getTotalPrice(shopItems);

        return (
            <div className={CSS_SUM_CLASS}>
                <span className={CSS_SUM_CLASS + '--tax'}>
                    inkl. 19% MwSt. <br/>
                    <b>Summe</b>
                </span>

                <span className={CSS_SUM_CLASS + '--price'}>{CurrencyHelper.toCurrency(total, true)}€</span>
            </div>
        )
    }
}

export default ShoppingBasket;