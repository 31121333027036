import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Icon from '../UsedCarIcon';

const DropDown = props => {
	const node = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [selectedOption, setOption] = React.useState();

	React.useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	const onSelect = item => {
		setOption(item);
		setOpen(false);
		props.onChangeValue(item.value);
	};

	const handleClickOutside = e => {
		if (!node.current.contains(e.target)) {
			setOpen(false);
		}
	};

	return (
		<div className='dropdown' ref={node}>
			<button className='dropdown__header' onClick={() => setOpen(true)}>
				<span className='dropdown__title'>
					{selectedOption ? selectedOption.label : props.initialLabel}
					<Icon variant='chevron_down_small' size={28} />
				</span>
			</button>

			<ul
				className={`dropdown__list ${open ? 'open' : 'close'}`}
				style={{
					maxHeight: open ? '250px' : 0,
					overflowY: props.options.length > 5 ? 'scroll' : 'hidden',
				}}>
				{props.options.map((item, i) => (
					<li key={`${item.key}-${i}`} className={`dropdown__list-item`} onClick={() => onSelect(item)}>
						{item.label}
					</li>
				))}
			</ul>
		</div>
	);
};

export default DropDown;

DropDown.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.exact({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	).isRequired,
	initialLabel: PropTypes.string,
	onChangeValue: PropTypes.func.isRequired,
};
