import React from 'react';
import PropTypes from 'prop-types';


const TableRow = ({ label, value, markup }) => {
    const createMarkup = (html) => (
        <span dangerouslySetInnerHTML={{ __html: html || '-' }} />
    );

    return (
        <div className="detail-box__table-row">
            <span>{label}</span>
            {!markup ? <span>{value || '-'}</span> : createMarkup(value)}
        </div>
      );
}
 
export default TableRow;

TableRow.defaultProps = {
    value: '-',
};

TableRow.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    markup: PropTypes.bool,
};

