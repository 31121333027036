import React from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import NewsletterRegistration from '../../components/NewsletterRegistration';
import API from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Newsletter', current_page: true },
];

class NewsletterPage extends React.Component {
    constructor(props) {
        super(props);

        let page = [];

        if (props.staticContext && props.staticContext.initialData) {
            page = props.staticContext.initialData;
        } else {
            page = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            pageData: page
        };
    }

    static requestInitialData(url) {
        if (url === '/newsletter/') {
            return API
                .GET('/pages/newsletter')
                .catch(err => {
                    console.error(err);
                    return [];
                });
        }
    }

    render() {
        const { pageData = {} } = this.state;
        return (
            <CommonPage className="page-newsletter">
                <Helmet>
                    <title>Newsletter Autohaus König: Immer auf dem Laufenden bleiben!</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>{pageData.headline}</h1>
                <KoenigImage className="img-responsive img_teaser" fileHash={pageData.title_img_src} alt={pageData.headline} />
                <div className="applications">
                    {this.renderParagraph(pageData.page_content)}
                    {this.renderApplication(pageData.applications[0])}
                    <NewsletterRegistration />
                </div>
                <br className="clearfix" />
            </CommonPage>
        );
    }

    renderApplication(app) {
        return (
            <article>
                <header>
                    <h2>{app.headline}</h2>
                    {app.paragraphs.map((paragraph, key) => {
                        return (
                            <p key={key}>{this.renderParagraph(paragraph)}</p>
                        );
                    })}
                </header>
            </article>
        );
    }

    renderParagraph(paragraph) {
        return (
            <React.Fragment>
                {ReactHtmlParser(paragraph)}
            </React.Fragment>
        );
    }
}

export default NewsletterPage;