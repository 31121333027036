import './styles.scss';

import React from 'react';

class Badges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            badges: props.badges || [],
            maxItems: parseInt(props.maxItems) || 3,
            dimensions: null,
        };
    }

    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.badges !== this.props.badges) {
            this.setState({ badges: this.props.badges || [] });
        }
    }

    renderContent() {
        const { badges, maxItems } = this.state;

        return (
            <React.Fragment>
                {
                    [...badges]
                        .sort(this.sortBagdes)
                        .map(({ uid, src, alt_text }, index) => {
                            if (index === maxItems) {
                                return false;
                            }

                            return (
                                <div key={uid} className="badge">
                                    <img src={src} alt={alt_text} />
                                </div>
                            );
                        })
                }
            </React.Fragment>
        );
    }

    render() {
        const { dimensions } = this.state;

        return (
            <div className="cnt-badges" ref={el => (this.container = el)}>
                {dimensions && this.renderContent()}
            </div>
        );
    }

    sortBagdes(badge1, badge2) {
        const p1 = badge1.priority;
        const p2 = badge2.priority;
        if (p1 === p2) {
            return 0;
        }
        return p1 > p2 ? 1 : -1;
    }
}

export default Badges;