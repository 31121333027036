import React from 'react';
import Icon from '../../../components/Icon';
import KoenigButton from '../../../elements/KoenigButton';
import apiHelper from '../../../helper/api.helper';
import fbHelper from '../../../helper/facebook.helper';
import { BWS_STORE_MAP } from '../.data/bwsStoreMap';
import OpenTimesBlock from '../../Standorte/components/OpenTimesBlock';

const CSS_CLASS = 'fc-branch-details';

class BranchDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    async componentDidMount() {
        const { vehicle: { location } } = this.props;
        const storeId = BWS_STORE_MAP[location.id];
        const apiBranch = await apiHelper.GET('/branches/' + storeId);
        apiBranch ? this.setState({ apiBranch }) : null;
    }

    handlePhoneClick(e, phoneNumber) {
        const { vehicle: { wgnr } } = this.props;

        e.preventDefault();
        window.location.assign('tel:' + phoneNumber);
        fbHelper.track('Lead', {
            content_type: 'vehicle',
            content_ids: [wgnr]
        });
    }

    render() {
        return (
            <div className={CSS_CLASS}>
                {this.renderAddress()}
                {this.renderOpeningHours()}
                {this.renderTelephone()}
                {this.renderMapsLink()}
            </div>
        )
    }

    renderAddress() {
        const { vehicle = {} } = this.props;
        const { location } = vehicle;

        return (
            <span>
                <strong>{location.name}</strong><br />
                {
                    location.address &&
                    <span>{location.address}<br />
                        {location.zip} {location.city}
                    </span>
                }
            </span>
        );
    }

    renderOpeningHours() {
        const { apiBranch = {} } = this.state;
        const { opening_times } = apiBranch;

        return (
            <span>
                {
                    opening_times && opening_times.sale &&
                    <OpenTimesBlock openingTimes={opening_times.sale} />
                }
            </span>
        );
    }

    renderTelephone() {
        const { vehicle = {} } = this.props;
        const { location } = vehicle;

        return (
            <span>
                <p>Rufen Sie uns gerne an:</p>
                <Icon variant="phone" />
                <a
                    onClick={(e) => this.handlePhoneClick(e, location.phone)}>
                    <strong>{location.phone}</strong>
                </a>
            </span>
        );
    }

    renderMapsLink() {
        const { apiBranch } = this.state;
        const href = apiBranch
            ? `http://www.google.com/maps/place/${apiBranch.degree_of_longitude},${apiBranch.degree_of_latitude}`
            : 'http://www.google.com/maps/';

        return (
            <span>
                Planen Sie Ihre Anfahrt zu uns:
                <KoenigButton
                    text="Route planen"
                    linkTo={href}
                    targetBlank />
            </span>
        )
    }
}

export default BranchDetails;