import React from 'react';
import IconButton from '../../components/IconButton';
import Hint from '../Hint';

export const ERR_NO_SELECTION = 'Die Auswahl darf nicht leer sein.';

class KoenigSelect extends React.Component {
    elementRef = React.createRef();

    state = {
        value: this.props.value || '',
        showOptions: false,
        errorText: this.props.errorText || ''
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.value !== this.state.value && !this.__outerChange) {
            const changeFunc = this.props.onChange || function () { };
            changeFunc(this.state.value);
        } else {
            this.__outerChange = false;
        }
        if (prevProps.value !== this.props.value) {
            this.__outerChange = true;
            this.setState({ value: this.props.value });
        }
        if (prevProps.errorText !== this.props.errorText) {
            this.setState({ errorText: this.props.errorText });
        }
    }

    handleOptionSelect = value => {
        const { onValidate = function () { }, disabledOptions = [] } = this.props;
        const { errorText } = this.state;

        if (disabledOptions.indexOf(value) !== -1) {
            return this.setState({ showOptions: false });
        }

        this.setState({
            value,
            showOptions: false,
            errorText: onValidate(value) || errorText
        })
    }

    handleContainerClosing = () => {
        const { isRequired, onValidate = () => '' } = this.props;
        const { value, errorText } = this.state;
        let errText = '';

        if (isRequired === true) {
            errText = (value + '').length > 0 ? errorText : ERR_NO_SELECTION;
        }

        this.setState({
            showOptions: false,
            errorText: onValidate(value) || errText
        });
    }

    render = () => {
        const { className } = this.props;
        const { showOptions, errorText, value = '' } = this.state;
        const classes = ['koenig-select'];

        if (className) {
            classes.push(className);
        }

        if (errorText.length > 0 || this.props.notValid === true) {
            classes.push('error');
        }

        return (
            <div className={classes.join(' ')} style={this.props.style}>
                {this.renderLabel()}
                {this.renderSelectedItem()}
                {showOptions ? this.renderOptionsContainer() : null}
                {this.renderErrorText()}
                <input value={value || ''} type="hidden" name={this.props.name} />
            </div>
        );
    }

    renderLabel = () => {
        const { label, isRequired, hintContent: HintContent } = this.props;

        const labelText = isRequired === true && label ? label + '*' : label;
        return (
            label &&
            <label className="koenig-select--label">
                {labelText}
                {typeof HintContent !== 'undefined' ? <Hint panelWidth={290}><HintContent /></Hint> : null}
            </label>
        );
    }

    renderSelectedItem = () => {
        const { value } = this.state;
        const {
            options = [],
            itemComponent: CustomItem,
            valueProperty = 'uid',
            disableOnEmpty
        } = this.props;
        const classes = ['koenig-select--selected-item'];
        let child = value;

        if (typeof CustomItem !== 'undefined' && CustomItem !== null && value) {
            const selectedOption = options.filter(opts => opts[valueProperty] === value);
            if (selectedOption.length > 0) {
                child = <CustomItem {...selectedOption[0]} />;
            }
        }

        if (options.length === 0 && disableOnEmpty === true) {
            classes.push('disabled');
        }

        return (
            <div
                className={classes.join(' ')}
                onClick={() => this.setState({ showOptions: options.length > 0, errorText: '' })}
                ref={this.elementRef}
            >
                <span>{child}</span>
                <IconButton
                    variant="black_chevron_down"
                    className="koenig-select--arrow-button" />
            </div>
        );
    }

    renderOptionsContainer = () => {
        const { value } = this.state;
        const {
            options = [],
            disabledOptions = [],
            itemComponent: CustomItem,
            optionsCount = 5,
            valueProperty = 'uid'
        } = this.props;
        const { offsetTop, offsetHeight } = this.elementRef.current;

        const containerStyle = {
            maxHeight: ((15 + parseInt(optionsCount) * 30) / 16) + 'rem',
            top: offsetTop && offsetHeight ? ((offsetTop + offsetHeight) / 16) + 'rem' : null
        };

        return (
            <React.Fragment>
                <div className="koenig-select--options-container" style={containerStyle}>
                    {
                        options.map((opt, idx) => {
                            const classes = ['koenig-select--option'];
                            const optValue = opt && opt[valueProperty] ? opt[valueProperty] : opt;

                            if (typeof opt === 'string' && opt === value) {
                                classes.push('selected');
                            } else if (opt[valueProperty] === value) {
                                classes.push('selected');
                            }

                            if (typeof opt === 'string' && disabledOptions.indexOf(opt) !== -1) {
                                classes.push('disabled');
                            } else if (disabledOptions.indexOf(opt[valueProperty]) !== -1) {
                                classes.push('disabled');
                            }

                            let child = opt;
                            if (typeof CustomItem !== 'undefined' && CustomItem !== null) {
                                child = <CustomItem {...opt} />
                            }
                            return (
                                <span
                                    key={idx}
                                    className={classes.join(' ')}
                                    onClick={this.handleOptionSelect.bind(this, optValue)}
                                >
                                    {child}
                                </span>
                            );
                        })
                    }
                </div>
                <div
                    className="koenig-select--click-area--closing"
                    onClick={this.handleContainerClosing.bind(this)}></div>
            </React.Fragment>
        );
    }

    renderErrorText = () => {
        const { errorText } = this.state;
        return (
            <span className="koenig-select--error-text">{errorText}</span>
        );
    }
}

KoenigSelect.defaultProps = {
    errorText: ''
};

export default KoenigSelect;