import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import API from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';
import HtmlParser from 'react-html-parser';
import ProductCategoryChooser from '../../components/ProductCategoryChooser';
import SimpleShop from '../../components/SimpleShop';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Vespa Zubehör', current_page: true },
];
const CSS_CLASS = 'page-vespa-zubehoer';

class VespaZubehoerPage extends React.Component {
    constructor(props) {
        super(props);
        let data = [],
            postData = {}
            ;

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                data = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                data = window.__initialData__;
            }
        }

        const [pageData = {}, accessories = [], categories = []] = data;

        this.state = {
            data: {
                ...pageData,
                categories
            },
            accessories,
            isContentVisible: true,
            chosenCategory: 1,

            ...postData
        };
    }

    static requestInitialData(url) {
        if (url === '/vespa-zubehoer/') {
            const pageData = API
                .GET('/pages/vespa-zubehoer')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            const accessories = API
                .GET('/accessories')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            const categories = API
                .GET('/accessory_categories')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            return Promise.all([pageData, accessories, categories])
        }
    }

    handleSetContentVisible(isVisible) {
        this.setState({ isContentVisible: isVisible });
    }

    render() {
        const { title = "Autohaus König", headline = "", categories } = this.state.data;
        const { isContentVisible } = this.state;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <div className={isContentVisible ? '' : 'content-visible'}>
                    <Breadcrumb crumbs={crumbs} />

                    <h1 className={CSS_CLASS + '--headline'}>{headline}</h1>
                    {this.renderTeaserImages()}
                    {this.renderContent()}

                    <ProductCategoryChooser
                        categories={categories}
                        activeItem={1}
                        onChange={category =>
                            this.setState({ chosenCategory: category })
                        } />
                </div>

                {this.renderSimpleShop()}
            </CommonPage>
        )
    }

    renderTeaserImages() {
        const { teaser_src = {}, headline } = this.state.data;
        const { mobile = '', desktop = '' } = teaser_src;
        const CssClassTeaser = CSS_CLASS + '--teaser';

        return (
            <React.Fragment>
                {
                    mobile.length > 0
                        ? <KoenigImage className={CssClassTeaser + ' ' + CssClassTeaser + '--mobile'} fileHash={mobile} alt={headline} />
                        : null
                }
                {
                    desktop.length > 0
                        ? <KoenigImage className={CssClassTeaser + ' ' + CssClassTeaser + '--desktop'} fileHash={desktop} alt={headline} />
                        : null
                }
            </React.Fragment>
        );
    }

    renderContent() {
        let { content = null } = this.state.data;
        if (content === null) {
            content = {
                title: '',
                text: '',
                img: null
            }
        }

        return (
            <React.Fragment>
                <h2>{content.title}</h2>
                <div className={CSS_CLASS + "--content"}>
                    <div>
                        {HtmlParser(content.text)}
                    </div>
                    <div>
                        {content.img !== null ? this.renderContentImg() : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderContentImg() {
        const { content } = this.state.data;
        const title = content.title ? content.title : '';
        const { img } = content;

        if (typeof (img) !== 'undefined' && typeof (img.src) !== 'undefined') {
            if (img.link) {
                return (
                    <a href={img.link}>
                        <KoenigImage fileHash={img.src} alt={title} />
                    </a>
                )
            } else {
                return (
                    <KoenigImage fileHash={img.src} alt={title} />
                )
            }
        }

        return null;
    }

    renderSimpleShop() {
        const { accessories, chosenCategory = 1, shop_items = [] } = this.state;
        let filteredAccessories = [].concat(accessories);

        if (chosenCategory !== 1) {
            filteredAccessories = accessories.filter(({ categories = [] }) =>
                categories.map(c => c._id).indexOf(chosenCategory) !== -1
            );
        }

        return (
            <SimpleShop
                type='accessory'
                products={filteredAccessories}
                itemLimit="3"
                shopUrl="/vespa-zubehoer/"
                {...this.state}
                setContentVisible={this.handleSetContentVisible.bind(this)} />
        );
    }
}

export default VespaZubehoerPage;