import './styles.scss';

import React from 'react';
import NavigationPoint from './components/NavigationPoint';

export default ({ className = '', active = '', brands = [] }) => {
    const filterCssClass = className + '--brand-navigation';

    if (brands.length < 1) {
        return null;
    }

    return (
        <div className={filterCssClass}>
            <NavigationPoint
                variant="all_items"
                text="Alle anzeigen"
                isActive={active === ''}
                url='/standorte/'
            />
            {
                brands.map((brand, idx) => {
                    let variant = brand;

                    if (variant === 'fiat') {
                        variant = 'fiat_abarth_prof_short';
                    }

                    if(variant === 'fiat_professional'){
                        return;
                    }

                    if (variant === 'vespa') {
                        variant = 'vespa_plain';
                    }

                    let urlBrand = brand;

                    switch (brand) {
                        case "alfa_romeo": urlBrand = "alfa-romeo"; break;
                        case "moto_guzzi": urlBrand = "moto-guzzi"; break;
                    }

                    const url = "/standorte/" + urlBrand + '/';

                    return (
                        <NavigationPoint
                            key={'navigation-point-' + idx}
                            variant={variant}
                            url={url}
                            isActive={active === brand}
                        />
                    )
                }
                )
            }
        </div>
    );
};