import React from 'react';
import {sortBrands, uniqueItems, reduceBrands} from './functions';
import BrandLogo from '../../elements/BrandLogo';

class BrandListing extends React.Component {

    render() {
        const {brands} = this.props;

        return (typeof brands !== 'undefined') ?
            brands
                .reduce(reduceBrands, [])
                .filter(uniqueItems)
                .sort(sortBrands)
                .map((brand, idx) => {
                    let brandID = "";

                    switch (brand) {
                        case "vespa":
                            brandID = "vespa_plain";
                            break;
                        case "fiat_abarth_prof":
                            brandID = "fiat_abarth_prof_short";
                            break;
                        case "motoGuzzi":
                            brandID = "moto_guzzi";
                            break;
                        case "citroën":
                            brandID = "citroen";
                            break;
                        case "suzukiRoller":
                            brandID = "suzuki";
                            break;
                        case "mGMotor":
                            brandID = "mg_motor";
                            break;
                        default:
                            brandID = brand;
                    }

                    return <BrandLogo
                        key={brand + '-logo-' + idx}
                        brandId={brandID}
                        style={{maxHeight: '40px'}}
                    />
                })
            : null
            ;
    }
}

export default BrandListing;