import './styles.scss';

import React from 'react';
import SectionHeadline from '../SectionHeadline';
import KoenigImage from '../KoenigImage';

export default ({ category, image, alt, title, content, link, target, hasSectionHeadline = true }) => (
    <div className="page-tile">
        {hasSectionHeadline && renderCategory(category)}
        <a {...getLinkHref(link)} target={(target) ? target : '_top'}>
            {renderImage(image, title, alt)}
            {renderTitle(title)}
            {renderContent(content)}
        </a>
    </div>
);

function getLinkHref(link) {
    return link
        ? { href: link }
        : { onClick: () => { } };
}

function renderCategory(category) {
    return category ? <SectionHeadline text={category} /> : null;
};

function renderImage(image, title = "Bild", alt="") {
    return image ? <KoenigImage fileHash={image} title={title} alt={alt} /> : null;
}

function renderTitle(title) {
    return title ? <h2>{title}</h2> : null;
}

function renderContent(content) {
    return content ? <p className="tile-text">{content}</p> : null;
}