import React from 'react';
import PropTypes from 'prop-types';

import brandSprite from '../../assets/images/marken-logos-plain-sprite.svg';

export const VIEWBOXES = {
    elektro: "153 30 26 26",
    dacia: "150 60 82 25",
    fiat: "159 0 50 25",
    jeep: "225 0 46 25",
    alfa_romeo: "286 0 25 25",
    vespa: "316 0 46 25",
    vespa_plain: "220 90 51 25",
    fiat_prof: "367 0 27 25",
    abarth: "62 30 23 25",
    fiat_gw_combi: "0 30 57 25",
    all_items: "91 30 25 25",
    fiat_single: "122 30 25 25",
    fiat_abarth: "185 30 53 25",
    fiat_professional: "241 30 23 25",
    kia: "270 30 50 25",
    renault: "326 30 20 25",
    ape: "359 30 40 25",
    piaggio: "0 60 73 25",
    piaggio_short: "275 90 32 25",
    fiat_abarth_prof_short: "79 60 25 25",
    scooter_triple: "110 60 34 25",
    piaggio_one: "238 60 133 25",
    leasbike: "0 0 78 25",
    suzuki: "377 60 100 25",
    suzuki_short: "90 90 37 25",
    alpine: "412 30 40 25",
    renault_dacia: "33 90 47 25",
    jeep_alfa: "0 90 26 25",
    aprilia: "175 90 41 25",
    maxus: "130 90 40 25",
    volkswagen: "322 90 22 25",
    moto_guzzi: "362 88 59 24",
    schwalbe: "0 115 57 23",
    mazda: "64 120 26 25",
    citroen: "96 120 26 25",
    opel: "136 120 29 25",
    eschwalbe: "171 120 50 25",
    gebrauchtwagen: "230 120 76 25",
    mg_motor: "320 120 25 25"
};

class BrandLogo extends React.Component {

    render = () => {
        const {
            brandId,
            color = '#3b3b3b',
            className,
            style,
            alt
        } = this.props;
        const classes = ['brand-logo'];

        if (className) {
            classes.push(className);
        }

        return (
            <svg className={classes.join(' ')} viewBox={VIEWBOXES[brandId]} style={style}>
                <title>{alt}</title>
                <use xlinkHref={brandSprite + '#' + brandId} fill={color.replace('dark', '#3b3b3b')}/>
            </svg>
        );
    }
}

BrandLogo.propTypes = {
    brandId: PropTypes.oneOf(Object.keys(VIEWBOXES)).isRequired
};

export default BrandLogo;