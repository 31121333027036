import '../styles/_inquiry.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Checkbox from '../../../elements/Checkbox';
import KoenigButton from '../../../elements/KoenigButton';
import ApiHelper from '../../../helper/api.helper';
import KoenigSelect from '../../../elements/KoenigSelect';
import KoenigInput from '../../../elements/KoenigInput';

class EbikeInquiryOverlay extends React.Component {
    constructor(props) {
        super(props);

        const { type } = props;

        this.state = {
            salutation: null,
            firstname: null,
            lastname: null,
            address: null,
            zip_code: null,
            location: null,
            email: null,
            phone: null,
            message: 'Bitte kontaktieren Sie mich',
            agb: false,
            showSuccessful: false,
            showFailure: false,
            type
        };

        if (type.indexOf('groupon') > -1) {
            this.state = {
                ...this.state,
                code: null,
                branch: null,
            }
        }
    }

    sendInquiry() {
        const { ebike, type } = this.props;
        const data = {
            ...this.state,
            model: ebike.title,
            type
        };

        delete data.showSuccessful;
        delete data.showFailure;

        ApiHelper.POST('/inquiries_ebikes', data)
            .then(() => {
                this.setState({ showSuccessful: true });
            }).catch(error => {
                this.setState({ showFailure: true });
            });
    }

    handleChangeMessage(value) {
        if (typeof value === 'string' && value.length > 0) {
            this.setState({ message: value })
        } else {
            this.setState({ message: 'Bitte kontaktieren Sie mich' });
        }
    }

    render = () => {
        const { showSuccessful, showFailure, type } = this.state;
        const { branches } = this.props;
        const isGroupon = type.indexOf('groupon') > -1;

        if (showSuccessful) {
            return this.renderSuccessful();
        }

        if (showFailure) {
            return this.renderFailure();
        }

        return (
            <Overlay {...this.props}>
                <form className="overlay--content--reserve-form">
                    <h2>Fragen sie ihr Lieblingsebike an</h2>
                    {
                        isGroupon &&
                        <KoenigSelect
                            label="Wählen Sie Ihre Wunschfiliale"
                            value="Bitte wählen"
                            options={branches}
                            onChange={branch => this.setState({ branch })}
                            isRequired />
                    }
                    <ContactForm
                        onChange={(key, val) => this.setState({ [key]: val })} />
                    {
                        isGroupon &&
                        <KoenigInput
                            label="Groupon Code"
                            onChange={code => this.setState({ code })}
                            isRequired />
                    }
                    <div className="cnt-msg">
                        <label>Nachricht</label>
                        <textarea
                            placeholder="Guten Tag,&#13;&#13;ich interessiere mich für dieses Angebot. Bitte kontaktieren Sie mich.&#13;&#13;Mit freundlichen Grüßen"
                            onChange={ev => this.handleChangeMessage(ev.target.value)} />
                    </div>
                    <div className="cnt-agb-datenschutz">
                        <Checkbox
                            onChange={agb => this.setState({ agb })} />
                        <span className="koenig-checkbox-text">
                            <Link to="/agb/" target="_blank">AGB</Link> & <Link to="/datenschutz/" target="_blank">Datenschutzerklärung</Link> habe ich gelesen
                        </span>
                        <span>(*) = Pflichtfelder</span>
                    </div>
                    <KoenigButton
                        text="Anfrage senden"
                        onClick={this.sendInquiry.bind(this)}
                        disabled={!this.isValid(isGroupon)} />
                </form>
            </Overlay>
        );

    }

    renderSuccessful() {
        const { onClose = () => { } } = this.props;

        return (
            <Overlay className="successful" onClose={onClose}>
                <h1>Vielen Dank für Ihre Anfrage!</h1>
                <p>
                    Schön, dass Sie sich für unser Angebot interessieren.<br />
                    Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
                </p>
                <p>Ihr Autohaus König</p>

                <KoenigButton
                    className="btn-back-to-page"
                    text="Zurück zur Fahrzeugseite"
                    onClick={this.props.onClose}
                />
            </Overlay>
        )
    }

    renderFailure() {
        const { onClose = () => { } } = this.props;

        return (
            <Overlay className="failure" onClose={onClose}>
                <h1>Vielen Dank für Ihre Anfrage!</h1>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>

                <KoenigButton
                    className="btn-back-to-page"
                    text="Zurück zur Fahrzeugseite"
                    onClick={this.props.onClose}
                />
            </Overlay>
        )
    }

    isValid = (isGroupon) => {
        let values = [
            'salutation', 'firstname', 'lastname',
            'zip_code', 'email', 'phone'
        ];

        if (isGroupon) {
            values.push('code');
            values.push('branch');
        }

        const allValuesAreSet = values.reduce((valid, key) => valid && this.state[key] !== null && this.state[key].length > 0, true);

        return allValuesAreSet && this.state.agb === true;
    }
}

export default EbikeInquiryOverlay;