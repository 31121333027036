import React from 'react';
import CommonPage from '../CommonPage';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../components/Breadcrumb';
import SimpleContent from '../../components/SimpleContent';
import BrandLogo from '../../elements/BrandLogo';
import API from '../../helper/api.helper';

const CSS_CLASS = 'page-gebrauchtwagen-marken';

class GebrauchtwagenMarkenPage extends React.Component {
    constructor(props) {
        super(props);

        let models, pageData;

        if (props.staticContext && props.staticContext.initialData) {
            [models, pageData] = props.staticContext.initialData;
        } else {
            [models, pageData] = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            models,
            ...pageData
        }
    }

    static requestInitialData(url) {
        if (url.indexOf('/gebrauchtwagen/automarken/') === 0) {
            return Promise.all([
                API
                    .GET(`/models?sortby=order`)
                    .catch(console.error),
                API
                    .GET(`/pages/gw_automarken`)
                    .catch(console.error)
            ]);
        }

        return [];
    }

    render() {
        const { title, headline, sub_headline, description, seo_content } = this.state;
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Automarken', current_page: true }
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>{headline}</h1>

                {this.renderBrandLogos()}

                <h2>{sub_headline}</h2>

                {this.renderModelGroups()}

                <SimpleContent content={seo_content} />
            </CommonPage>
        );
    }

    renderBrandLogos() {
        const { brands } = this.state;

        return (
            <div className={CSS_CLASS + '--logo-cnt'}>
                {
                    brands.map(({ _id, label, logo_id }, idx) => {
                        const logoId = logo_id ? logo_id : _id
                        const brandLabel = label.replace(/\+/g, '');

                        return (
                            <span key={`logo_${_id}_${idx}`} className={CSS_CLASS + '--logo'}>
                                    <a href={'/gebrauchtwagen/automarken/' + _id + '/'}>
                                        <BrandLogo brandId={logoId} />
                                    </a>
                                    <span>{brandLabel}</span>
                                </span>
                        );
                    })
                }
                <span className={CSS_CLASS + '--logo'}>
                    <span>
                        <a
                            href="/gebrauchtwagen/gebrauchtwagenangebote/"
                            className={CSS_CLASS + '--more-brands'}>
                            Weitere Marken
                        </a>
                    </span>
                    <span>Weitere Marken</span>
                </span>
            </div>
        )
    }

    renderModelGroups() {
        const { models } = this.state;
        const modelGroups = this.getModelGroups(models);

        return (
            <div className={CSS_CLASS + '--model-groups-cnt'}>
                {
                    modelGroups.map((group, idx) => this.renderGroup(group, idx))
                }
            </div>
        );
    }

    renderGroup({ brand, brandLabel, models }, idx) {
        return (
            <div className={CSS_CLASS + '--model-group'} key={'model_group_' + brand + idx}>
                {
                    brand === 'fiat'
                        ? this.renderSpecialFiatLink(brandLabel)
                        : this.renderLink(brandLabel, brand, idx)
                }
                {this.renderModelLinks(brand, models)}
            </div>
        );
    }

    renderSpecialFiatLink(brandLabel) {
        return (
            <div>
                {
                    ['fiat', 'abarth', 'fiat_professional'].map(
                        (brand, idx) => this.renderLink(brandLabel.split('+')[idx], brand, idx)
                    )
                }
            </div>
        );
    }

    renderLink(brandLabel, brand, idx) {
        return (
            <a
                href={'/gebrauchtwagen/automarken/' + brand + '/'}
                key={'brand_link_' + brand + idx}>
                {brandLabel}
            </a>
        );
    }

    getModelGroups(models) {
        const { brands } = this.state;

        return brands.map(({ _id, label }) => {
            return {
                brand: _id,
                brandLabel: label,
                models: models
                    .filter(m => m.brand._id && m.brand._id === _id)
                    .sort()
            };
        });
    }

    renderModelLinks(brand, models) {
        return (
            <ul className={CSS_CLASS + '--model-links'}>
                {
                    models.map(
                        (model, idx) => {


                            const separator = models.length - 1 === idx ? '' : ',';

                            return (
                                <li key={'model_link_' + model._id + idx}>
                                    <a
                                        href={`/gebrauchtwagen/automarken/${brand}/${model._id}/`}>
                                        {model.label + separator}
                                    </a>
                                </li>
                            );
                        }
                    )
                }
            </ul>
        );
    }
}

export default GebrauchtwagenMarkenPage;