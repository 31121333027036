export const ISO_DATE_FORMAT = "YYYYMMDD";

function toCurrency(euroCent) {
    if (isNaN(parseInt(euroCent))) {
        return 'k.A.';
    }

    const converted = parseInt(euroCent);

    if (converted === 0) {
        return '0,00';
    }

    return (converted / 100)
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

function getTotalPrice(shopItems = [], isKwsMember = false) {
    return shopItems.reduce((result, si) => {
        const { prices, special_offer = false } = si;

        if (special_offer
            && prices
            && prices.hasOwnProperty("special_offer")
            && prices.default !== prices.special_offer) {
            result += prices.special_offer;
        } else if (isKwsMember && prices && prices.hasOwnProperty("kws")) {
            result += prices.kws;
        } else if (prices && prices.hasOwnProperty("default")) {
            result += prices.default;
        }

        return result < 1 ? 0 : result;
    }, 0);
}

function createAppointmentMap(appointments) {
    if (!Array.isArray(appointments)) {
        return {};
    }

    return appointments.reduce((result, a) => {
        if (a && a.branch && a.day && a.time) {
            const branchId = a.branch + '';
            const day = a.day;

            if (typeof result[branchId] === 'undefined') {
                result[branchId] = {};
            }

            if (typeof result[branchId][day] === 'undefined') {
                result[branchId][day] = [];
            }

            result[branchId][day].push(a.time);
        }

        return result;
    }, {});
}

function linkTo(target, state) {
    if (typeof window !== 'undefined' && window.document) {
        const form = document.createElement("form");
        form.setAttribute('method', 'POST');
        form.setAttribute('action', target);

        const {
            brand, model, branch, day, time, shop_items,
            plate, vin, mileage, registration, kws_member,
            salutation, firstname, lastname, address,
            zip_code, location, email, phone, prepayment,
            oid,
            pick_up_service, pick_up_address_differs,
            pick_up_address_name,
            pick_up_address_contact_person,
            pick_up_address_phone,
            pick_up_address_street_nr,
            pick_up_address_zip_code,
            pick_up_address_location,
            pick_up_address_additional_info
        } = state;

        const input = document.createElement('input');
        input.setAttribute('name', 'data');
        input.setAttribute('type', 'hidden');
        input.setAttribute('value', JSON.stringify({
            brand, model, branch, day, time, shop_items,
            plate, vin, mileage, registration, kws_member,
            salutation, firstname, lastname, address,
            zip_code, location, email, phone, prepayment,
            oid,
            pick_up_service, pick_up_address_differs,
            pick_up_address_name,
            pick_up_address_contact_person,
            pick_up_address_phone,
            pick_up_address_street_nr,
            pick_up_address_zip_code,
            pick_up_address_location,
            pick_up_address_additional_info
        }));
        form.appendChild(input);

        document.getElementsByTagName('body')[0].appendChild(form);
        form.submit();
    }
}

export default {
    toCurrency, getTotalPrice, createAppointmentMap, linkTo
};