import './styles.scss';

import React from 'react';

export function Button({ className, text, onClick, disabled = false }) {
    const classes = ['quantity-button'];

    if (className) {
        classes.push(className);
    }

    if (disabled) {
        classes.push('disabled');
    }

    return (
        <button
            className={classes.join(' ')}
            onClick={onClick}
            disabled={disabled}>
            {text}
        </button>
    );
}

export function IncrementButton({ onClick, disabled }) {
    return (
        <Button
            text="+"
            className="btn-increment"
            onClick={onClick}
            disabled={disabled} />
    );
}

export function QuantityAd({ quantity }) {
    return <span className="quantity-ad">{quantity}</span>;
}

export function DecrementButton({ onClick }) {
    return (
        <Button
            text="-"
            className="btn-decrement"
            onClick={onClick} />
    );
}

function QuantitySelect(props) {
    const {
        value = 1,
        limit = 99,
        onDecrement = () => { },
        onIncrement = () => { }
    } = props;
    const intValue = parseInt(value);
    const intLimit = parseInt(limit);

    return (
        <div className="quantity-select">
            <IncrementButton
                onClick={onIncrement}
                disabled={intLimit <= intValue} />

            <QuantityAd quantity={Math.min(intValue, intLimit)} />

            <DecrementButton
                onClick={onDecrement} />
        </div>
    );
}

export default QuantitySelect;