import React from 'react';
import Icon from '../../Icon';
import { Link } from 'react-router-dom';

export default props => {
    const { id, title, links, className, onClick } = props;
    const itemProps = {className, onClick};

    return (
        <div id={id} {...itemProps}>
            <div className="title-row">
                <Icon variant="chevron_right" color="white" />
                <span>{title}</span>
            </div>
            <div className="links">
                {
                    links.map((link, idx) =>
                        <React.Fragment key={idx}>
                            {renderLink(link)}
                            <br />
                        </React.Fragment>
                    )
                }
            </div>
        </div>
    );
};

function renderLink(link) {
    if(link.external) {
        return (
            <a href={link.href} target="_blank">
                {link.title}
            </a>
        )
    } else {
        return <a href={link.href}>{link.title}</a>
    }
}