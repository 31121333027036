import './styles.scss';

import React from 'react';
import KoenigInput from '../../elements/KoenigInput';
import IconButton from '../../components/IconButton';

class NewsletterRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    } 


    render() {
        return (
            <form 
                id="form_newsletter" 
                action="https://eu1.cleverreach.com/f/61692-209160/wcs/" 
                method="post" 
                target="_blank" 
                ref={this.formRef}
            >
                {this.renderLayout()}
            </form>
        );
    }

    renderLayout() {
        if(this.props.showInline === true) {
            return (
                <div className="nl-input-container">
                    <input placeholder="Ihre E-Mail-Adresse" name="email"  />
                    <IconButton variant="chevron_right" color="white" onClick={() => {
                        this.formRef.current.submit();
                    }} />
                </div>
            );
        }

        return (
            <React.Fragment>
                <KoenigInput 
                    name="email" 
                    label="Newsletter abonnieren" 
                    placeholder="Geben Sie Ihre E-Mail-Adresse ein" 
                    isRequired 
                    isEmailField />
                {this.renderReCaptcha()}
                <button type="submit" className="koenig-button">Absenden</button>
                <noscript>
                    <a href="http://www.cleverreach.de">www.CleverReach.de</a>
                </noscript>
            </React.Fragment>
        );
    }

    renderReCaptcha() {
        return (
            <div id="4610752" rel="recaptcha" className="cr_ipe_item ui-sortable musthave">
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                <br />
                <div id="recaptcha_v2_widget" className="g-recaptcha" data-theme="light" data-size="normal"
                    data-sitekey="6Lfhcd0SAAAAAOBEHmAVEHJeRnrH8T7wPvvNzEPD"></div>
                <br />
            </div>
        );
    }
}

export default NewsletterRegistration;