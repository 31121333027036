import './styles.scss';

import React from 'react';
import BrandLogo from '../../elements/BrandLogo';
import KoenigImage from '../KoenigImage';

class BrandTeaser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            innerClick: false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(link) {
        const hasLocationHref = typeof window !== 'undefined' && window.location && window.location.href;
        const noInnerClick = this.state.innerClick === false;
        if (hasLocationHref && noInnerClick) {
            window.location.href = link;
        }
    };

    render() {
        const {
            teaser: { uid, image, text, model_list: modelList, link: boxLink, brand },
            style } = this.props;
        return (
            <div className="brand-teaser" style={style}>
                <div className="teaser-header">
                    <a href={boxLink} className="teaser-link" onClick={() => this.handleClick(boxLink)}>
                        <div className="image-header">
                            <BrandLogo brandId={this.getBrandId(uid)} />
                        </div>
                        <div className="image-container">
                            <KoenigImage fileHash={image} />
                        </div>
                    </a>
                    <span className="teaser-text">{text}</span>
                    <a href={boxLink} className="teaser-all-model-link" onClick={() => this.handleClick(boxLink)}>
                        <span className="teaser-all-model">Alle {brand} Modelle</span>
                    </a>

                </div>
                <span className="links">
                    {
                        modelList
                            .map(({ label, link }) => (
                                <a href={link} onClick={() => this.setState({ innerClick: true })}>{label}</a>
                            ))
                            .reduce((result, link, idx) => {
                                if (result === null) {
                                    result = [<React.Fragment key={idx}>{link}</React.Fragment>];
                                } else {
                                    result.push(<React.Fragment key={idx}>, {link}</React.Fragment>)
                                }
                                return result;
                            }, null)
                    }
                </span>
            </div>
        );
    }

    getBrandId(uid) {
        return uid
            .replace(/^fiat$/, 'fiat_abarth')
            .replace('-', '_');
    }
}

export default BrandTeaser;