export const config = {
    "filters": {
        "filters": [
            {
                "propertyType": "tag",
                "title": {
                    "de": "Marken"
                },
                "choices": [{
                    "key": "Renault",
                    "de": "Renault"
                }, {
                    "key": "Dacia",
                    "de": "Dacia"
                }, {
                    "key": "Fiat",
                    "de": "Fiat"
                }, {
                    "key": "Alfa Romeo",
                    "de": "Alfa Romeo"
                }, {
                    "key": "Jeep",
                    "de": "Jeep"
                }, {
                    "key": "Volkswagen",
                    "de": "VW"
                }, {
                    "key": "Citroën",
                    "de": "Citroën"
                }, {
                    "key": "Opel",
                    "de": "Opel"
                }, {
                    "key": "Kia",
                    "de": "Kia"
                }, {
                    "key": "Mazda",
                    "de": "Mazda"
                }, {
                    "key": "Suzuki",
                    "de": "Suzuki"
                }, {
                    "key": "Alpine",
                    "de": "Alpine"
                }, {
                    "key": "Piaggio",
                    "de": "Piaggio"
                }, {
                    "key": "eSchwalbe",
                    "de": "eSchwalbe"
                }, {
                    "key": "Aprilia",
                    "de": "Aprilia"
                }, {
                    "key": "Vespa",
                    "de": "Vespa"
                }, {
                    "key": "Ape",
                    "de": "Ape"
                }, {
                    "key": "Moto Guzzi",
                    "de": "Moto Guzzi"
                }],
                "innerOperator": "or"
            },
            {
                "propertyType": "type",
                "title": {
                    "de": "Services"
                },
                "choices": [
                    {
                        "key": "Autohändler",
                        "de": "Autohändler"
                    },
                    {
                        "key": "Gebrauchtwagenhändler",
                        "de": "Gebrauchtwagenhändler"
                    },
                    {
                        "key": "Autowerkstatt",
                        "de": "Autowerkstatt"
                    }
                ],
                "innerOperator": "or",
            },
        ],
        "outerOperator": "and",
    },
    "woosmapview": {
        "initialCenter": { // set the intial center od the map -> this is set to Berlin
            "lat": 52.520008,
            "lng": 13.404954
        },
        "initialZoom": 7,  // set the initial zoom of the map
        "breakPoint": 8,   // set breakpoint -> the zoom level at which the points change to a custom symbol for the stores (and back)
        "style": {
            "default": {
                "icon": {
                    "url": "https://t3api.autohaus-koenig.de/relaunch_img/content/standorte/woosmap/marker_yellow.png"
                },
                "selectedIcon": {
                    "url": "https://t3api.autohaus-koenig.de/relaunch_img/content/standorte/woosmap/marker_selected.png"
                }
            }
        },
        "tileStyle": {
            "color": "#fdc311"
        }
    },
    "theme": {
        "primaryColor": "#7cb932" // color of the widgets
    },
    "internationalization": {
        "lang": "de"    // the language in which the widget is displayed
    },
    "maps": {
        "provider": "woosmap", // which map provider is going to be used
        "clientKey": "", // clientID if you have a Google Maps Premium Plan
        "apiKey":   "", //Google Maps API Key
        "places": {
            "geocoder": {
                "region": "de"
            }
        },
        "localities": {
            "language": "de", // the language in which the search results are returned
            "types": [],    // what types are searched - cities, postalcodes, airports - leave empty to search everything
            "componentRestrictions": {
                "country": [
                    "de" // which countries will be searched
                ]
            }
        }

    }
};