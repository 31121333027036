import "./styles.scss"

import React from 'react';

import KoenigButton from '../../../../elements/KoenigButton';
import ApiHelper from '../../../../helper/api.helper';
import StepPage from "../components/StepPage";
import Functions from '../functions';
import Icon from '../../../../components/Icon';
import { DISCOUNT_ITEM } from "../Schritt4Page";

const CSS_CLASS = 'wtt--step2';
const CSS_PRODUCT_GROUP = CSS_CLASS + '--product-group';
const CSS_PRODUCT_ITEM = CSS_CLASS + '--product-item';

const CAT_PKW_SERVICE = 1;
const CAT_SCOOTER_SERVICE = 2;
const CAT_PKW_ACCESSORIES = 5;
const CAT_SCOOTER_ACCESSORIES = 4;
const WEW = 3;

class Schritt2Page extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};
        let products = [];
        let categories = [];

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [products, categories] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [products, categories] = window.__initialData__;
            }
        }

        if (postData.brand && postData.brand._id) {
            products = products.filter(p =>
                Array.isArray(p.brand_uid) && p.brand_uid.indexOf(postData.brand._id) !== -1
            );
        }

        if (postData.model && postData.model.uid) {
            products = products.filter(p =>
                Array.isArray(p.model_uid) && p.model_uid.indexOf(postData.model.uid) !== -1
            );
        }

        this.state = {
            products, categories,

            brand: null,
            model: null,
            branch: null,
            day: null,
            time: null,
            plate: null,
            vin: null,
            mileage: null,
            registration: null,
            kws_member: false,
            shop_items: [],

            ...postData
        };
    }

    static requestInitialData() {
        const products = ApiHelper.GET('/products')
            .then(data => {
                return data.filter(p => p && p.is_wtt_item === true);
            });

        const categories = ApiHelper.GET('/categories');

        return Promise.all([products, categories])
            .catch(err => console.error(err));
    }

    handleProductItemClick(product) {
        const { shop_items } = this.state;
        const { _id } = product;
        const isSelected = shop_items.reduce((result, si) => result || (si && si._id === _id), false);
        let new_shop_items = [];

        if (isSelected) {
            new_shop_items = shop_items.filter(si => si && si._id !== _id);

            if(new_shop_items.length === 1 && new_shop_items[0]._id === DISCOUNT_ITEM._id){
                new_shop_items = [];
            }
        } else {
            new_shop_items = [].concat(shop_items, product);
        }

        this.setState({ shop_items: new_shop_items });
    }

    handleShopItemRemoving(shopItemId) {
        const { shop_items } = this.state;
        let newShopItems = shop_items.filter(si => si && si._id !== shopItemId);

        if(newShopItems.length === 1 && newShopItems[0]._id === DISCOUNT_ITEM._id){
            newShopItems = [];
        }

        this.setState({
            shop_items: newShopItems
        });
    }

    handleBackLinking() {
        Functions.linkTo('/werkstatt/terminbuchung/', this.state);
    }

    handleNextLinking() {
        Functions.linkTo('/werkstatt/terminbuchung/schritt3/', this.state);
    }

    render() {
        const { shop_items } = this.state;

        return (
            <StepPage
                className="page-terminbuchung-schritt2"
                steps={[-1, 0, 1, 1]}
                onRemoveItem={this.handleShopItemRemoving.bind(this)}
                {...this.state}>
                <div className="intro-text">
                    <b>Welche Leistungen benötigen Sie für Ihr Fahrzeug?</b><br />
                    <p>Sämtliche Leistungen die Sie hier auswählen können, sind mit Festpreisen ausgewiesen. Mit Hilfe der grünen Buttons wählen Sie Ihre gewünschte Leistung aus, welche dann rechts in der Übersicht für einen guten Überblick erscheinen.</p>

                    Alle Leistungen zur Auswahl:
                </div>

                {this.renderServices()}

                <div className="wtt--step2--button--row">
                    <KoenigButton
                        className="back-button"
                        text="Zurück zu Schritt 1"
                        onClick={this.handleBackLinking.bind(this)} />

                    <KoenigButton
                        className="next-button"
                        text="Persönliche Daten eingeben"
                        onClick={this.handleNextLinking.bind(this)}
                        disabled={shop_items.length === 0} />
                </div>

            </StepPage>
        );
    }

    renderServices() {
        const { brand, products } = this.state;
        const pkwServiceCategory = this.getCategoryByID(CAT_PKW_SERVICE);
        const pkwAccessoryCategory = this.getCategoryByID(CAT_PKW_ACCESSORIES);
        const wewCategory = this.getCategoryByID(WEW);

        let catIconServices = pkwServiceCategory !== null ? pkwServiceCategory.image_src : '';
        let headerTextServices = "Halten Sie Ihr Fahrzeug betriebsbereit!";

        let catIconAccessories = pkwServiceCategory !== null ? pkwAccessoryCategory.image_src : '';
        let headerTextAccessories = "Gönnen Sie Ihrem Liebling auf vier Rädern neues Zubehör!"

        let catIconWew = wewCategory !== null ? wewCategory.image_src : '';
        let headerTextWew = "Mieten Sie ein Fahrzeug während der Reparatur!"

        const isBrandCheckable = brand && brand._id;
        const isScooterBrand = isBrandCheckable && ['vespa', 'sonstige-rollermarke'].indexOf(brand._id) !== -1;

        if (isScooterBrand) {
            const scooterServiceCategory = this.getCategoryByID(CAT_SCOOTER_SERVICE);
            catIconServices = scooterServiceCategory !== null ? scooterServiceCategory.image_src : '';
            headerTextServices = "Halten Sie Ihren Roller betriebsbereit!";

            const scooterAccessoryCategory = this.getCategoryByID(CAT_SCOOTER_ACCESSORIES);
            catIconAccessories = scooterAccessoryCategory !== null ? scooterAccessoryCategory.image_src : '';
            headerTextAccessories = "Gönnen Sie Ihrem Liebling auf zwei Rädern neues Zubehör!"
        }

        const serviceItemMap = this.getMappedServiceProducts();
        const accessoriesMap = this.getMappedAccessoriesProducts();
        const wewMap = this.getMappedWewProducts();

        return (
            <React.Fragment>
                <div className={[CSS_PRODUCT_GROUP, CSS_PRODUCT_GROUP + '--services'].join(' ')}>
                    <div className={CSS_PRODUCT_GROUP + '--header'}>
                        <img src={catIconServices} />
                        <b>{headerTextServices}</b>
                    </div>
                    {
                        Object.keys(serviceItemMap).map((key, idx) => {
                            const groupedProducts = serviceItemMap[key];

                            return (
                                <div key={'group-' + key + idx} className={CSS_PRODUCT_ITEM + '--group'}>
                                    {
                                        groupedProducts
                                            .map(this.renderProductItem.bind(this))
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                <div className={[CSS_PRODUCT_GROUP, CSS_PRODUCT_GROUP + '--accessories'].join(' ')}>
                    <div className={CSS_PRODUCT_GROUP + '--header'}>
                        <img src={catIconAccessories} />
                        <b>{headerTextAccessories}</b>
                    </div>
                    {
                        Object.keys(accessoriesMap).map((key, idx) => {
                            const groupedProducts = accessoriesMap[key];

                            return (
                                <div key={'group-' + key + idx} className={CSS_PRODUCT_ITEM + '--group'}>
                                    {
                                        groupedProducts
                                            .map(this.renderProductItem.bind(this))
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                <div className={[CSS_PRODUCT_GROUP, CSS_PRODUCT_GROUP + '--wew'].join(' ')}>
                    <div className={CSS_PRODUCT_GROUP + '--header'}>
                        <img src={catIconWew} />
                        <b>{headerTextWew}</b>
                    </div>
                    {
                        Object.keys(wewMap).map((key, idx) => {
                            const groupedProducts = wewMap[key];

                            return (
                                <div key={'group-' + key + idx} className={CSS_PRODUCT_ITEM + '--group'}>
                                    {
                                        groupedProducts
                                            .map(this.renderProductItem.bind(this))
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </React.Fragment>
        );
    }

    renderProductItem(product, idx) {
        const { shop_items, branch } = this.state;

        if (branch && branch?._id === 2) {
            if (
                // HA/AU
                product._id === 42
                // Anhängerlupplung
                || product._id === 86 || product._id === 92 || product._id === 144
                || product._id === 145 || product._id === 146
                // Scheinwerferlampenwechsel
                || product._id === 1
                // Zahnriemenwechsel
                || product._id === 7 || product._id === 131
            ) {
                return null;
            }
        }

        const { _id, title, description = '&#160;', prices, special_offer = false } = product;
        const classes = [CSS_PRODUCT_ITEM];
        const isSelected = shop_items.reduce((result, si) => result || (si && si._id === _id), false);

        if (special_offer === true) {
            classes.push('special-offer');
        }

        if (isSelected) {
            classes.push('selected');
        }

        return (
            <div key={"wtt-product-item-" + title + idx} className={classes.join(' ')} onClick={this.handleProductItemClick.bind(this, product)}>
                <div>
                    <div className={CSS_PRODUCT_ITEM + '--container'}>
                        <div>
                            <b className={CSS_PRODUCT_ITEM + '--title'}>{title}</b>
                            <span className={CSS_PRODUCT_ITEM + '--subline'}>{description}</span>
                        </div>
                        <div>
                            <span className={CSS_PRODUCT_ITEM + '--price'}>
                                {Functions.toCurrency(prices.default)}
                                <span>€</span>
                            </span>
                        </div>
                    </div>
                    <div className={CSS_PRODUCT_ITEM + '--button'}>
                        <Icon
                            variant="add"
                            color="white"
                            style={{ width: '1.5rem', display: 'block' }} />
                    </div>
                </div>
                {this.renderKwsHint(product)}
            </div>
        );
    }

    renderKwsHint(product) {
        const { prices, product_type, special_offer = false } = product;
        let itemText = product_type === 1 ? 'diese Leistung' : 'dieses Produkt';

        if (special_offer === true) {
            return null;
        }

        if (prices && prices.hasOwnProperty('kws')) {
            if (prices.kws === 0) {
                return (
                    <i className={CSS_PRODUCT_ITEM + '--kws-hint'}>
                        Mit dem König Werkstattservice erhalten Sie {itemText} <b>kostenfrei</b>.
                    </i>
                );
            } else {
                return (
                    <i className={CSS_PRODUCT_ITEM + '--kws-hint'}>
                        Mit dem König Werkstattservice erhalten Sie <b>10% Nachlass</b> auf {itemText}.
                    </i>
                );
            }
        }

        return null;
    }

    getCategoryByID(id) {
        const { categories = [] } = this.state;

        return categories.reduce((result, cat) =>
            result === null && cat._id === id ? cat : result,
            null
        );
    }

    getMappedServiceProducts() {
        return this.createProductItemMap(1);
    }

    getMappedAccessoriesProducts() {
        return this.createProductItemMap(2);
    }

    getMappedWewProducts() {
        return this.createProductItemMap(3);
    }

    createProductItemMap(productType) {
        const { products = [] } = this.state;

        return products
            .filter(p => p && p.product_type === productType)
            .sort(this.sortProducts)
            .reduce((map, product) => {
                if (product) {
                    const key = product.title;

                    if (typeof map[key] === 'undefined') {
                        map[key] = [];
                    }

                    map[key].push(product);
                }

                return map;
            }, {});
    }

    sortProducts(p1, p2) {
        const val1 = p1.title + p1.description;
        const val2 = p2.title + p2.description;

        if (val1 === val2) {
            return 0;
        }

        return val1 < val2 ? -1 : 1;
    }

}

export default Schritt2Page;