import './styles.scss';

import React from 'react';

const CSS_PREFIX = 'fc-sort-icon';

export default ({ onClick = () => {}, text }) => {
    const [active, setActive] = React.useState(null);

    return (
        <span className={CSS_PREFIX} onClick={() => handleClick(active, setActive, onClick)}>
            <span>
                <svg width='15' height='9' className={active === 1 ? 'active' : null}>
                  <polygon points="7.5,0 15,9 0,9" />
                </svg>
                <svg width='15' height='9' className={active === 2 ? 'active' : null}>
                  <polygon points="0,0 15,0 7.5,9" />
                </svg>
            </span>
            {text && <span>{text}</span>}
        </span>
    );
}

function handleClick(active, stateFunc, propFunc) {
    switch (active) {
        case null:
            stateFunc(1);
            break;
        case 1:
            stateFunc(2);
            break;
        case 2:
            stateFunc(1);
            break;
    }

    propFunc();
}