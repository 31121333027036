// @flow weak

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

// *******************************************************
// RAIL
// *******************************************************
export const SliderRail = ({ getRailProps }) => {
	return (
		<Fragment>
			<div className='range-slider__outer-rail' {...getRailProps()} />
			<div className='range-slider__inner-rail' />
		</Fragment>
	);
};

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired,
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export const Handle = ({ domain: [min, max], handle: { id, value, percent }, unit, index, getHandleProps }) => {
	return (
		<Fragment>
			<div
				className={`range-slider__tooltip ${index === 1 ? 'max' : ''}`}
				style={{
					left: `${percent}%`,
				}}>
				<span className='tooltiptext'>{`${value} ${unit}`}</span>
			</div>
			<div
				className='range-slider__outer-handle'
				style={{
					left: `${percent}%`,
				}}
				{...getHandleProps(id)}
			/>
			<div
				className='range-slider__inner-handle'
				role='slider'
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				style={{
					left: `${percent}%`,
				}}
			/>
		</Fragment>
	);
};

Handle.propTypes = {
	index: PropTypes.number,
	unit: PropTypes.string,
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

Handle.defaultProps = {
	disabled: false,
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export const Track = ({ source, target, getTrackProps, disabled }) => {
	return (
		<div
			className='range-slider__track'
			style={{
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`,
			}}
			{...getTrackProps()}
		/>
	);
};

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

Track.defaultProps = {
	disabled: false,
};
