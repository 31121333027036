import React from 'react';
import IconButton from '../../components/IconButton';
import moment from 'moment';
import 'moment/locale/de';

export const REVERSE_DATE_FORMAT = "YYYYMMDD";

class CalendarSheet extends React.Component {
    constructor(props) {
        super(props);

        const selectedDate = props.value && props.value.length > 0
            ? moment(props.value, REVERSE_DATE_FORMAT)
            : moment();

        this.state = { selectedDate };

        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleMonthIteration = this.handleMonthIteration.bind(this);
        this.handleMonthSubtraction = this.handleMonthSubtraction.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedDate !== this.state.selectedDate) {
            const func = this.props.onChange || function () { };
            func(this.state.selectedDate);
        }
    }

    getDayArray(mDate) {
        const retVal = [];
        const firstDay = moment(mDate.format("YYYYMM") + "01", REVERSE_DATE_FORMAT);
        const weekday = firstDay.isoWeekday();

        if (weekday > 1) {
            for (let i = 1; i < weekday; i++) {
                retVal.push(null);
            }
        }
        for (let i = 1; i <= mDate.daysInMonth(); i++) {
            retVal.push(i + '');
        }
        for (let i = retVal.length; i < 42; i++) {
            retVal.push(null);
        }
        return retVal;
    }

    getCssClassesFor(day) {
        const css = ['day'];
        const { selectedDate } = this.state;
        const { onDayValidation = () => true } = this.props;
        const isoDate = selectedDate.format('YYYYMM') + `0${day}`.substr(-2);

        if (day !== null && onDayValidation(isoDate) === false) {
            css.push('disabled');
        }

        if (day === selectedDate.format("D")) {
            css.push('selected');
        }

        return css.join(' ');
    }

    handleDayChange(ev) {
        ev.persist();
        if (ev.target.className.indexOf('disabled') !== -1) {
            return;
        }
        this.setState(prevState => {
            const yearMonth = prevState.selectedDate.format('YYYYMM');
            return {
                selectedDate: moment(yearMonth + ev.target.textContent, 'YYYYMMD')
            }
        });
    }

    handleMonthIteration(ev) {
        ev.stopPropagation();
        if (ev && ev.nativeEvent && ev.nativeEvent.stopImmediatePropagation) {
            ev.nativeEvent.stopImmediatePropagation();
        }
        this.setState(prevState => {
            return {
                selectedDate: prevState.selectedDate.add(1, 'M')
            }
        });
    }

    handleMonthSubtraction(ev) {
        ev.stopPropagation();
        ev.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => {
            return {
                selectedDate: prevState.selectedDate.subtract(1, 'M')
            }
        });
    }

    render() {
        const { className } = this.props;
        const days = this.getDayArray(this.state.selectedDate);
        const dayLabels = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
        const classes = ['calendar-sheet'];

        if (className) {
            classes.push(className);
        }

        return (
            <div className={classes.join(' ')} style={this.props.style}>
                <div className="control-container">
                    <IconButton
                        variant="black_chevron_left"
                        onClick={this.handleMonthSubtraction} />
                    <div className="month-year-ad">
                        {this.state.selectedDate.format('MMMM YYYY')}
                    </div>
                    <IconButton
                        variant="black_chevron_right"
                        onClick={this.handleMonthIteration} />
                </div>
                <div className="weekday-ad">
                    {
                        dayLabels.map((label, idx) => <span className="day" key={idx}>{label}</span>)
                    }
                </div>
                <div className="day-chooser-sheet">
                    {
                        days.map((day, idx) =>
                            <span
                                key={idx}
                                className={this.getCssClassesFor(day)}
                                onClick={ev => this.handleDayChange(ev)}>
                                {day}
                            </span>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default CalendarSheet;
