import './styles.scss';

import CommonPage from '../../CommonPage';
import BreadCrumb from '../../../components/Breadcrumb';
import React from 'react';
import Helmet from 'react-helmet';
import API from '../../../helper/api.helper';
import RatingWidget from '../../../components/RatingWidget';
import RadioButtonGroup from '../../../elements/RadioButtonGroup';
import Icon from '../../../components/Icon';
import KoenigSelect from '../../../elements/KoenigSelect';
import KoenigInput from '../../../elements/KoenigInput';
import KoenigButton from '../../../elements/KoenigButton';
import Checkbox from '../../../elements/Checkbox';
import formHelper from '../../../helper/form.helper';
import ReactHtmlParser from 'react-html-parser';
import Overlay from '../../../components/Overlay';
import TrendingWidget from './components/TrendingWidget';

const CSS_CLASS = 'page-simple-rating';
const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Autohaus König Bewertungen & Meinungen', path: '/bewertung/' },
    { label: 'Kritik abgeben', current_page: true }
];

class SimpleRatingPage extends React.Component {
    constructor(props) {
        super(props);

        let pageData, branches, brands;

        if (props.staticContext && props.staticContext.initialData) {
            [pageData, branches, brands] = props.staticContext.initialData;
        } else {
            if (window && window.__initialData__) {
                [pageData, branches, brands] = window.__initialData__;
                delete window.__initialData__;
            }
        }

        this.state = {
            ratings: [],
            pageData,
            branches,
            brands,
            salutation: null,
            firstname: null,
            lastname: null,
            email: null,
            branch: null,
            message: null,
            privacy: false,
            brand: null,
            vehicle_type: null,
            business: null
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/bewertung/negativ/') === 0) {
            return Promise.all([
                API
                    .GET('/pages/bewertung_negativ')
                    .catch(err => {
                        console.error(err);
                        return [];
                    }),
                API
                    .GET(`/branches`)
                    .catch(err => {
                        console.error(err);
                        return [];
                    }),
                API
                    .GET(`/brands`)
                    .catch(err => {
                        console.error(err);
                        return [];
                    })
            ]);
        }
    }

    async handleSubmit() {
        const document = { ...this.state };
        delete document.pageData;
        delete document.branches;
        delete document.brands;
        delete document.ratings;

        API
            .POST('/double_opt_in', { target: '/negativ_reviews', document })
            .then(() => this.setState({ transmitted: true }))
            .catch(err => {
                console.error(err);
                this.setState({ transmitted: false });
            });
    }

    handleChangeDivision(division) {
        const { pageData: { ratings } } = this.state;

        this.setState({
            ratings: ratings[division],
            division
        });
    }

    render() {
        const { transmitted, ratings } = this.state;
        const { headline, title } = this.state.pageData;
        const classes = [CSS_CLASS + '--slide']

        ratings.length > 0 && classes.push('active');

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <BreadCrumb crumbs={crumbs} />
                <h1>{ReactHtmlParser(headline)}</h1>
                {this.renderDivisionSelect()}
                <div className={classes.join(' ')}>
                {
                    ratings.length > 0 && (
                        <>
                            {this.renderTopics()}
                            {this.renderForm()}
                            {this.renderSubmitBtn()}
                        </>
                    )
                }
                </div>
                {typeof transmitted !== 'undefined' && this.renderOverlay()}
            </CommonPage>
        );
    }

    renderDivisionSelect() {
        const { ratings } = this.state;
        const classes = [CSS_CLASS + '--division'];

        ratings.length > 0 && classes.push('active');

        return (
            <div className={classes.join(' ')}>
                <span>Wählen Sie bitte den Bereich aus, den Sie speziell bewerten möchten:</span>
                <KoenigSelect
                    options={['Verkauf', 'Werkstatt']}
                    value="Bitte wählen"
                    onChange={key => this.handleChangeDivision(key)} />
            </div>
        );
    }

    renderRecommendation() {
        const { division } = this.state;
        const { recommendation_sale, recommendation_workshop } = this.state.pageData;
        const text = division === 'Verkauf' ? recommendation_sale : recommendation_workshop;

        return (
            <div className={CSS_CLASS + '--rating recommendation'}>
                <span>{text}</span>
                {this.renderRadioButtons('recommendation')}
            </div>
        );
    }

    renderTopics() {
        const { ratings } = this.state;

        return (
            <div className={CSS_CLASS + '--topics'}>
                <h2>Legen Sie los - es dauert nicht lang!</h2>
                {this.renderRecommendation()}
                {
                    ratings.map((topic, idx) => this.renderTopic(topic, idx))
                }
            </div>
        );
    }

    renderTopic({ title, items }, idx) {
        return (
            <div className={CSS_CLASS + '--topic'} key={'topic_' + idx}>
                <div>
                    <span>{idx + 1}.</span>
                    <span>
                        <span>{title}</span><br />
                        Bitte geben Sie an, ob die nachfolgenden Aussagen zutreffen.
                    </span>
                </div>
                {
                    items.map(rating => this.renderRating(rating, idx))
                }
            </div>
        );
    }

    renderRating(rating, idx) {
        const { text, type, id } = rating;

        return text &&
            <div className={CSS_CLASS + '--rating'} key={`rating_${id}_${idx}`}>
                <span>{text}</span>
                {type === 'widget' && <RatingWidget onChange={val => this.setState({ [id]: val })} />}
                {type === 'trending' && <TrendingWidget onChange={val => this.setState({ [id]: val })} />}
                {type === 'checkbox' && this.renderRadioButtons(id)}
            </div>
    }

    renderRadioButtons(id) {
        return (
            <RadioButtonGroup
                onChange={idx => this.setState({ [id]: idx === 0 })}
                icon={<Icon color="white" variant="checkmark" />}
            />
        );
    }

    renderForm() {
        const { branches, brands } = this.state;
        const branchOptions = branches.map(b => (b && b.official_name)
            ? b.name + ' (' + b.official_name + ')'
            : b.name
        );
        const brandOptions = brands.map(b => b.label);

        return (
            <div className={CSS_CLASS + '--form'}>
                <div>
                    <KoenigSelect
                        label={'Marke Ihres Fahrzeuges'}
                        options={brandOptions}
                        onChange={brand => this.setState({ brand })}
                        isRequired
                        className="spacing" />
                    <KoenigSelect
                        label={'Handelt es sich um einen Neu- oder Gebrauchtwagen?'}
                        options={['Neuwagen', 'Gebrauchtwagen']}
                        onChange={vehicle_type => this.setState({ vehicle_type })}
                        isRequired
                        className="spacing" />
                    <KoenigSelect
                        label={'Privatkauf oder für ein Gewerbe?'}
                        options={['Privat', 'Gewerbe']}
                        onChange={business => this.setState({ business })}
                        isRequired />
                </div>
                <div>
                    <KoenigSelect
                        label="Anrede"
                        options={['Herr', 'Frau']}
                        onChange={salutation => this.setState({ salutation })}
                        isRequired
                        className="spacing" />
                    <KoenigInput
                        label="Vorname"
                        onChange={firstname => this.setState({ firstname })}
                        isRequired
                        className="spacing" />
                    <KoenigInput
                        label="Nachname"
                        onChange={lastname => this.setState({ lastname })}
                        isRequired />
                </div>
                <div>
                    <KoenigInput
                        onChange={email => this.setState({ email })}
                        label="E-Mail-Adresse"
                        isRequired
                        className="spacing" />

                    <KoenigSelect
                        label="Filiale"
                        options={branchOptions}
                        value="Bitte wählen"
                        onChange={branch => this.setState({ branch })}
                        isRequired />
                </div>
                <div>
                    <label>Schreiben Sie uns gern Ihre Anmerkungen, Kritik, Sonstiges</label>
                    <textarea
                        placeholder="ihre Nachricht"
                        onChange={ev => this.setState({ message: ev.target.value })} />
                </div>
                <span>Sämtliche abgegebene Daten werden wir ausschließlich zur Auswertung der Bewertungen nutzen und zur Optimierung unseres Services.</span>
            </div>
        );
    }

    renderSubmitBtn() {
        return (
            <div className={CSS_CLASS + '--submit'}>
                {this.renderPrivacyCheckbox()}
                <KoenigButton
                    text="Meine Bewertung jetzt absenden"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={this.isFormInvalid()} />
            </div>
        );
    }

    isFormInvalid() {
        return !formHelper.isValid(
            ['firstname', 'lastname', 'salutation', 'branch', 'email', 'message'],
            this.state
        ) || !this.state.privacy;
    }


    renderPrivacyCheckbox() {
        return (
            <div className={CSS_CLASS + '--data-privacy'}>
                <Checkbox onChange={privacy => this.setState({ privacy })} />

                <span className="koenig-checkbox-text">
                        Ich habe die <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen
                    </span>
            </div>
        );
    }

    renderOverlay() {
        const { transmitted } = this.state;
        const overlayCss = CSS_CLASS + '--overlay';

        if (transmitted) {
            return this.renderSuccessOverlay(overlayCss);
        }

        return this.renderFailureOverlay(overlayCss);
    }

    renderSuccessOverlay(overlayCss) {
        return (
            <Overlay className={overlayCss + '--success'} onClose={() => this.setState({ transmitted: undefined })}>
                <h3>Vielen Dank für Ihre Bewertung</h3>
                <p>
                    Sie erhalten von uns eine E-Mail mit einem Aktivierungslink. Erst mit der Bestätigung dieses
                    Links<br />
                    wird Ihre Bewertung aktiv und kann von uns bearbeitet werden.
                </p>
                <KoenigButton
                    text="Zurück zur Übersicht"
                    linkTo="/bewertung/"
                />
            </Overlay>
        );
    }

    renderFailureOverlay(overlayCss) {
        return (
            <Overlay className={overlayCss + '--failure'} onClose={() => this.setState({ transmitted: undefined })}>
                <h3>Vielen Dank für Ihre Bewertung</h3>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>

                <KoenigButton
                    text="Zurück zur Übersicht"
                    linkTo="/bewertung/"
                    onClick={() => this.setState({ transmitted: undefined })}
                />
            </Overlay>
        );
    }
}

export default SimpleRatingPage;