import './styles.scss';

import React from 'react';
import Tabs from '../../../../components/VehicleBox/components/Tabs';
import Tab from '../../../../components/VehicleBox/components/Tab';
import Badges from '../../../../components/Badges';
import Hint from '../Hint';
import {
    getDurationInfo, getMileageInfo, getBatteryInfo,
    getPurchasePriceInfo, getFinalRateInfo
} from './infos';
import CurrencyHelper from '../../../../helper/Currency';
import PaymentButtons from '../PaymentButtons';
import Question from '../../../../components/SimpleFaq/components/Question';
import NumberFormater from '../../../../helper/NumberFormater';

const CSS_CLASS = 'fc-payment-tabs';

class PaymentTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    handleTabClick(label) {
        const { onPaymentChange = () => {} } = this.props;

        switch (label) {
            case 'Barkauf':
                onPaymentChange('cash');
                break;
            case 'Leasing':
                onPaymentChange('leasing');
                break;
            case 'Finanzierung':
                onPaymentChange('financing');
                break;
            case 'Abo':
                onPaymentChange('abo');
                break;
        }
    }

    render() {
        const { payments = {} } = this.props.vehicle;
        const { leasing = {}, financing = {}, prices = {}, abo = {} } = payments;
        let defaultTab = 'Barkauf';

        switch (true) {
            case financing.is_default:
                defaultTab = 'Finanzierung';
                break;
            case leasing.is_default:
                defaultTab = 'Leasing';
                break;
        }

        return (
            <div className={CSS_CLASS}>
                <Tabs defaultTab={defaultTab} onChange={label => this.handleTabClick(label)}>
                    <Tab label="Leasing" className={leasing.rate > 0 ? null : 'disabled'}>
                        {leasing.rate > 0 && this.renderTabContent('leasing', payments)}
                    </Tab>
                    <Tab label="Barkauf" className={prices.cash > 0 ? null : 'disabled'}>
                        {prices.cash > 0 && this.renderTabContent('cash', payments)}
                    </Tab>
                    <Tab label="Finanzierung" className={financing.rate ? null : 'disabled'}>
                        {financing.rate > 0 && this.renderTabContent('financing', payments)}
                    </Tab>
                    <Tab label="Abo" className={abo.rate ? null : 'disabled'}>
                        {abo.rate > 0 && this.renderTabContent('abo', payments)}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    renderTabContent(type, payments) {
        return (
            <div className={CSS_CLASS + '--tab-content'}>
                {this.renderBatches(type)}
                <div className={CSS_CLASS + '--sub-content'}>
                    {this.renderPaymentDetails(type, payments)}
                </div>
            </div>
        );
    }

    renderBatches(type) {
        let { vehicle: { badges } } = this.props;

        badges = badges.reduce((res, badge) => {
            if (badge.payment_type === type || typeof badge.payment_type === 'undefined') {
                res.push(badge)
            }

            return res;
        }, []);

        return <Badges badges={badges} maxItems={4} />
    }

    renderPaymentDetails(type, payments) {
        const { leasing, financing, prices, abo } = payments;

        switch (type) {
            case 'leasing':
                return this.renderLeasingDetails(leasing);
            case 'financing':
                return this.renderFinancingDetails(financing);
            case 'cash':
                return this.renderCashDetails(prices);
            case 'abo':
                return this.renderAboDetails(abo);
        }
    }

    renderLeasingDetails(leasing) {
        const {
            supply_costs, duration,
            factor, mileage, down_payment, rate, info
        } = leasing;
        const { kws_selection, insurance_selection } = this.props;
        const cssClass = CSS_CLASS + '--details';
        const priceInfo = 'Monatliche Leasingrate';
        const { currentImageIndex } = this.props;

        return (
            <div className={cssClass + ' leasing'}>
                <div>
                    <div>
                        {this.renderDetail('Laufleistung:', NumberFormater.addNumberSeparator(mileage) + ' km / Jahr',
                            <Hint text={getMileageInfo()} />)}
                        {this.renderDetail('Laufzeit:', duration + ' Monate', <Hint text={getDurationInfo()} />)}
                        {this.renderDetail('Anzahlung (anpassbar):', this.getPrice(down_payment, true))}
                        {this.renderDetail('Leasingfaktor:', factor)}
                        {this.renderDetail('Bereitstellungskosten:', this.getPrice(supply_costs, true))}
                        {this.renderBatteryDetail()}
                    </div>
                    {this.renderArrow(cssClass)}

                    {this.renderPrice(rate, priceInfo)}
                </div>
                <PaymentButtons
                    type={'leasing'}
                    vehicle={this.props.vehicle}
                    currentImageIndex={currentImageIndex}
                    kws_selection={kws_selection}
                    insurance_selection={insurance_selection} />
                {this.renderPaymentInfo('leasing', 'Informationen zum Online-Leasing', info)}
            </div>
        );
    }

    renderBatteryDetail() {
        const { vehicle: { fuel_type, battery_capacity, battery_range } } = this.props;

        if (fuel_type === 'Elektro') {
            return this.renderDetail(
                'Batterie:',
                battery_capacity + ' kWh',
                <Hint text={getBatteryInfo(battery_capacity, battery_range)} />
            );
        }
    }

    renderFinancingDetails(financing) {
        const {
            purchase_price, delivery_costs, supply_costs, duration,
            down_payment, effective_interest, final_rate, rate, info
        } = financing;
        const { kws_selection, insurance_selection } = this.props;
        const loanAmount = purchase_price - down_payment;
        const total = rate * duration + down_payment + supply_costs + delivery_costs;
        const priceInfo = 'Monatliche Finanzierungsrate';
        const cssClass = CSS_CLASS + '--details';

        return (
            <div className={cssClass + ' financing'}>
                <div>
                    <div>
                        {this.renderDetail('Fahrzeugpreis:', this.getPrice(purchase_price),
                            <Hint text={getPurchasePriceInfo()} />)}
                        {this.renderDetail('Anzahlung (anpassbar):', this.getPrice(down_payment, true))}
                        {this.renderDetail('Laufzeit (anpassbar):', duration + ' Monate')}
                        {this.renderDetail('Schlussrate:', this.getPrice(final_rate, true),
                            <Hint text={getFinalRateInfo()} />)}
                        {this.renderDetail('Darlehensbetrag:', this.getPrice(loanAmount, true))}
                        {this.renderDetail('Effektiver Jahreszins:', effective_interest + ' %')}
                        {this.renderDetail('Gesamtbetrag:', this.getPrice(total, true))}
                        {this.renderDetail('Bereitstellungskosten:', this.getPrice(supply_costs, true))}
                    </div>
                    {this.renderArrow(cssClass)}
                    {this.renderPrice(rate, priceInfo)}
                </div>
                <PaymentButtons
                    type={'financing'}
                    vehicle={this.props.vehicle}
                    kws_selection={kws_selection}
                    insurance_selection={insurance_selection} />
                {this.renderPaymentInfo('financing', 'Informationen zur Online-Finanzierung', info)}
            </div>
        );
    }

    renderCashDetails(prices) {
        const { uvp, cash, inhouse, supply_costs, info } = prices;
        const { kws_selection, insurance_selection } = this.props;
        const savings = uvp > 0 ? uvp - cash + supply_costs : 0;
        const priceInfo = 'Gesamtpreis';
        const cssClass = CSS_CLASS + '--details';
        const uvpPrice = uvp > 0 ? uvp + supply_costs : uvp;

        return (
            <div className={cssClass + ' cash'}>
                <div>
                    <div>
                        {this.renderDetail('UVP:', this.getPrice(uvpPrice))}
                        {this.renderDetail('Sie sparen:', this.getPrice(savings))}
                        {this.renderDetail('Barpreis:', this.getPrice(cash))}
                        {this.renderDetail('Hauspreis:', this.getPrice(inhouse))}
                        {this.renderDetail('Bereitstellungskosten:', this.getPrice(supply_costs, true))}
                    </div>
                    {this.renderArrow(cssClass)}
                    {this.renderPrice(cash, priceInfo)}
                </div>
                <PaymentButtons
                    type={'cash'}
                    vehicle={this.props.vehicle}
                    kws_selection={kws_selection}
                    insurance_selection={insurance_selection} />
                {this.renderPaymentInfo('cash', 'Informationen zum Online-Kauf', info)}
            </div>
        );
    }

    renderAboDetails(abo) {
        const { is_business } = this.props.vehicle;
        const { rate, rate_net, more_km_costs, more_km_costs_net, mileage_monthly, mileage_yearly, info } = abo;
        const { kws_selection, insurance_selection } = this.props;
        const price = is_business ? rate_net : rate;
        const moreKmCosts = is_business ? more_km_costs_net : more_km_costs;
        const cssClass = CSS_CLASS + '--details';
        const priceInfo = 'Monatliche Aborate';

        return (
            <div className={cssClass + ' abo'}>
                <div>
                    <div>
                        {this.renderDetail('Mehr-km Kosten:', this.getPrice(moreKmCosts, true))}
                        {this.renderDetail('Laufleistung monatlich:', NumberFormater.addNumberSeparator(mileage_monthly) + ' km')}
                        {this.renderDetail('Laufleistung jährlich:', NumberFormater.addNumberSeparator(mileage_yearly) + ' km')}
                    </div>
                    {this.renderArrow(cssClass)}
                    {this.renderPrice(price, priceInfo)}
                </div>
                <PaymentButtons
                    type={'abo'}
                    vehicle={this.props.vehicle}
                    kws_selection={kws_selection}
                    insurance_selection={insurance_selection} />
                {this.renderPaymentInfo('abo', 'Informationen zum Online-Abo', info)}
            </div>
        );
    }

    getPrice(value, byValue = false) {
        if (value > 0) {
            return CurrencyHelper.toCurrency(value, true) + ' €';
        }

        if (byValue) {
            return value + ' €';
        }

        return 'k.a.';
    }

    renderDetail(key, value, component) {
        return (
            <span>
                <span>{key}</span>
                {component}
                <span>{value}</span>
            </span>
        );
    }

    renderPrice(value, info) {
        const { is_business } = this.props.vehicle;

        return (
            <div>
                <span><span>{CurrencyHelper.toCurrency(value)}</span>€</span>
                <span className={CSS_CLASS + '--price-info'}>
                    <b>{info}</b><br />
                    {is_business ? 'zzgl. 19% MwSt.' : 'inkl. 19% MwSt.'}
                </span>
            </div>
        );
    }

    renderPaymentInfo(type, title, info) {
        const item = { question: title, answer: info };

        return info && (
            <Question key={type} item={item} />
        );
    }

    renderArrow(cssClass) {
        return (
            <React.Fragment>
                <div className={cssClass + '--arrow mobile'}>
                    <svg viewBox="0 0 500 50">
                        <polygon points="0,0 250,50 500,0" />
                    </svg>
                </div>
                <div className={cssClass + '--arrow desktop'}>
                    <svg viewBox="0 0 50 500">
                        <polygon points="0,0 50,250 0,500" />
                    </svg>
                </div>
            </React.Fragment>
        );
    }
}

export default PaymentTabs;