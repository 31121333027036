import { useEffect } from 'react';

function Criteo() {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//dynamic.criteo.com/js/ld/ld.js?a=93596';
        script.async = true;

        document.head.appendChild(script);
    }, [])

    return null;
}

export function addHomepageTag() {
    window.criteo_q = window.criteo_q || [];

    const deviceType = /iPad/.test(navigator.userAgent)
        ? "t"
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
            ? "m"
            : "d";

    window.criteo_q.push(
        { event: "setAccount", account: 93596 },
        { event: "setSiteType", type: deviceType },
        { event: "viewHome" }
    );
}

export function addConversionTag(price, productId, email, zipcode, transActionId) {
    window.criteo_q = window.criteo_q || [];

    if (!transActionId) {
        transActionId = Math.floor(Math.random() * 99999999999) + 1;
    }

    const deviceType = /iPad/.test(navigator.userAgent)
        ? "t"
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
            ? "m"
            : "d";

    window.criteo_q.push(
        { event: "setAccount", account: 93596 },
        { event: "setSiteType", type: deviceType },
        {
            event: "trackTransaction", id: transActionId, item: [
                { id: productId, price: '1', quantity: 1 }
            ]
        }
    );

    email && window.criteo_q.push({ event: "setEmail", email, });
    zipcode && window.criteo_q.push({ event: "setZipcode", zipcode });
}

export function addProductTag(productId) {
    window.criteo_q = window.criteo_q || [];

    const deviceType = /iPad/.test(navigator.userAgent)
        ? "t"
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
            ? "m"
            : "d";

    window.criteo_q.push(
        { event: "setAccount", account: 93596 },
        { event: "setSiteType", type: deviceType },
        { event: "viewItem", item: productId }
    );
}

export default Criteo;