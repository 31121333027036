import urlHelper from './url.helper';
import apiHelper from './api.helper';
import "isomorphic-fetch";

const MAKE_CODE_MAP = {
    Renault: 'REN',
    Dacia: 'DAC'
};
const ENGINE_TYPE_MAP = {
    Benzin: 0,
    Diesel: 1,
    Autogas: 2,
    Elektro: 3,
    'Hybrid-Benzin': 4
};

const DURATIONS = [12, 24, 36, 48, 60];

const createCalculatorLink = (vehicle) => {
    const {
        _id, brand, model, version, payments: { prices, leasing }, fuel_type,
        version_code, model_code
    } = vehicle;
    const { cash, uvp } = prices;
    const rciUrl = getRciUrl();
    const dealerEmail = getDealerEmail();
    const baseUrl = apiHelper.getBaseUrl();

    if (
        cash < 0
        || uvp < 0
        || typeof MAKE_CODE_MAP[brand] === 'undefined'
        || typeof ENGINE_TYPE_MAP[fuel_type] === 'undefined'
        || !rciUrl
        || !dealerEmail
        || !baseUrl
        || typeof version_code === 'undefined'
        || typeof model_code === 'undefined'
    ) {
        return null;
    }

    const returnUrl = getReturnUrl(_id);
    const duration = leasing && leasing.duration

    const params = {
        brand: 'RCI',
        rciNetNumber: 1915,
        dealerName: 'Autohaus Gotthard König GmbH',
        dealerEmail: dealerEmail,
        makeDescription: brand,
        makeCode: MAKE_CODE_MAP[brand],
        modelDescription: model,
        modelCode: model_code,
        versionDescription: version,
        versionCode: version_code,
        engineType: ENGINE_TYPE_MAP[fuel_type],
        vehicleType: 'N',
        vehiclePrice: cash / 100,
        priceType: 1,
        callingSystem: '01',
        phase: 0,
        proposalType: 'online',
        freeText: _id,
        preselectMileage: 10000,
        preselectDuration: DURATIONS.includes(duration) ? duration : 48,
        preselectContractKind: 'L',
        retailPrice: uvp / 100,
        interestRatePercent: 3.99,
        preselectDownpayment: 0,
        returnUrl: returnUrl,
        returnNegative: 'yes'
    };

    return rciUrl + urlHelper.generateQueryStringFrom(params);
}

const getRciUrl = () => {
    const basePath = '/kalkulator/#/?';

    if (typeof process.env !== 'undefined' && typeof process.env.RCI_URL !== 'undefined') {
        return process.env.RCI_URL + basePath;
    }

    if (typeof window !== 'undefined' && window.RCI_URL !== 'undefined') {
        return window.RCI_URL + basePath;
    }

    console.log('RCI Url nicht verfügbar');

    return false;
};

const getDealerEmail = () => {
    if (typeof process.env !== 'undefined' && typeof process.env.RCI_DEALER_EMAIL !== 'undefined') {
        return process.env.RCI_DEALER_EMAIL;
    }

    if (typeof window !== 'undefined' && window.RCI_DEALER_EMAIL !== 'undefined') {
        return window.RCI_DEALER_EMAIL;
    }

    console.log('RCI Dealer Email nicht verfügbar');

    return false;
}

const createCalculatorLinkFromBWS = async vehicle => {
    if (vehicle && MAKE_CODE_MAP[vehicle.marke]) {
        if (isValidVehicle(vehicle)) {
            const params = {
                brand: 'RCI',
                rciNetNumber: 1915,
                dealerName: 'Autohaus Gotthard König GmbH',
                dealerEmail: getDealerEmail(),
                makeDescription: vehicle.marke,
                makeCode: MAKE_CODE_MAP[vehicle.marke],
                modelDescription: vehicle.modell,
                modelCode: vehicle.typ,
                versionDescription: vehicle.version,
                versionCode: vehicle.versionscode,
                engineType: ENGINE_TYPE_MAP[vehicle.kraftstoff],
                vehicleType: getVehicleType(vehicle.fzart),
                vehiclePrice: vehicle.preis_boersenpreis_brutto,
                priceType: 1,
                callingSystem: '01',
                phase: 0,
                proposalType: 'online',
                freeText: vehicle.wgnr,
                preselectMileage: 10000,
                preselectDuration: 72,
                preselectContractKind: 'L',
                retailPrice: vehicle.preis_brutto,
                interestRatePercent: 3.99,
                preselectDownpayment: 0,
                returnUrl: getReturnUrl(vehicle.wgnr),
                currentMileage: parseInt(vehicle.km.replace('.', '')),
                hsn: vehicle.hsn,
                tsn: vehicle.tsn,
                vehicleNumber: vehicle.fgnr,
                firstRegistrationDate: getRegistrationDate(vehicle),
                returnNegative: 'yes'
            }

            if (await isCalculateAble(params)) {
                return getRciUrl() + urlHelper.generateQueryStringFrom(params);
            }
        }
    }

    return null;
}

const isValidVehicle = ({ fgnr, erstzulassung, fzart, km }) => {
    const type = getVehicleType(fzart);

    if (type !== 'N') {
        switch (true) {
            case typeof fgnr !== 'string' || fgnr.length === 0:
            case typeof erstzulassung !== 'string' || erstzulassung.length === 0:
            case typeof km !== 'string' || km.length === 0:
                return false;
        }
    }

    return true;
}

const getRegistrationDate = ({ erstzulassung }) => {
    const parts = erstzulassung.split('/');

    return `${parts[1]}-${parts[0]}-01`;
}

const getVehicleType = (type) => {
    switch (type) {
        case 'Neuwagen':
            return 'N';
        case 'Gebrauchtwagen':
            return 'G';
        default:
            return 'V';
    }
}

const getReturnUrl = (_id) => {
    return `${apiHelper.getBaseUrl()}/onlineshop/danke/${_id}/##callbackNr##/`
}

const isCalculateAble = async (body) => {
    body.contractType = 'V';
    body.netNumber = body.rciNetNumber;
    body.duration = 48;
    body.prepaymentPercent = 10.0;
    body.mileage = 10000;
    body.interestRate = 3.99;

    const res = await fetch(
        `https://onlinecalculator.rcipos.de/calculatorservice/calculate/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

    return res.ok;
}

export default { createCalculatorLink, createCalculatorLinkFromBWS }