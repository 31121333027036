import './styles.scss';

import Overlay from "../../../../components/Overlay";
import React from 'react';

const CSS_CLASS = 'fc-rci-calculator';

class RciCalculator extends React.Component {
    componentDidMount() {
        this.linkElement.click();
    }

    render() {
        const { onClose, calculatorLink} = this.props;

        return (
            <Overlay className={CSS_CLASS} onClose={() => onClose()}>
                <a
                    target="calculator"
                    ref={link => this.linkElement = link}
                    href={calculatorLink}>
                </a>
                <iframe name="calculator" width="600px" height="500px" frameBorder="0"></iframe>
            </Overlay>
        );
    }
}

export default RciCalculator;