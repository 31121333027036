import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/UsedCarButton';
import placeholderImage from '../../../assets/images/placeholder-image.png';

export function getAccidentLabel(num) {
    switch (num) {
        case 0:
            return 'Unfallfrei';
        case 1:
            return 'Unfallschaden';
        default:
            return 'Unfallschaden unbekannt';
    }
}

class ResultItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sofaUrl: true
        }
    }

    render() {
        const { vehicle } = this.props;

        let usedDetails = null;
        const financingHint = vehicle.preis_brutto > 1500 ? 'Direkt online finanzierbar!' : '';

        if (vehicle.fzart !== 'Neuwagen') {
            const accident =  getAccidentLabel(parseInt(vehicle.unfallwagen));
            usedDetails = `| ${vehicle.vorbesitzer} Fahrzeughalter | ${accident}`;
        }

        return (
            <div className='car-box'>
                <a
                    href={`/gebrauchtwagen/gebrauchtwagenangebote/${vehicle.wgnr}`}
                    title={`Mehr Anzeigen zum ${vehicle.marke} ${vehicle.modell} ${vehicle.version}`}>
                    <img
                        className='car-box__img'
                        src={vehicle.first_picture ? vehicle.first_picture.src : placeholderImage}
                        alt={`${vehicle.marke} ${vehicle.modell}`}
                    />
                </a>

                <div className='car-box__content'>
                    <div>
                        <div>
                            <h3 className='h4 car-box__title'>
                                {vehicle.marke} {vehicle.modell} {vehicle.version}
                            </h3>
                            <p className='text--large car-box__sub-title'>
                                {vehicle.kw} kW ({vehicle.ps} PS)
                                | {vehicle.kraftstoff} | {vehicle.getriebe} {usedDetails}
                            </p>
                        </div>

                        <div className='car-box__details'>
                            <p>
                                <span>{vehicle.erstzulassung ? `EZ: ${vehicle.erstzulassung} | ` : null}</span>
                                <span>{vehicle.km ? `Kilometerstand: ${vehicle.km} | ` : null}</span>
                                <span>{vehicle.fzart ? vehicle.fzart : null}</span>
                            </p>
                            <p className='car-box__equipment'>{this.truncate(vehicle.ausstattung_plain)}</p>
                        </div>

                        <p className='car-box__details-additional'>
                            {this.renderWltpNfez(vehicle)}
                        </p>
                    </div>
                    <div>
                        <div className="au-widget-car-rating lazy-loaded"
                             data-car-url={`https://www.autohaus-koenig.de/gebrauchtwagen/gebrauchtwagenangebote/${vehicle.wgnr}`}
                             data-layout="wider_no_border">
                        </div>
                        <div className='car-box__placeholder'></div>
                        <p className='car-box__price'>
                            <span>{`€ ${vehicle.preis} ,-`}</span>
                            <span>{vehicle.mwst_ausweisbar ? 'MwSt. ist ausweisbar' : 'MwSt. nicht ausweisbar'}</span>
                        </p>
                        <p className="car-box__financing-hint">{financingHint}</p>
                        <Button
                            title='Mehr Anzeigen'
                            addClass='car-box__show-more'
                            linkTo={`/gebrauchtwagen/gebrauchtwagenangebote/${vehicle.wgnr}`}
                        />
                    </div>
                </div>
            </div>
        );
    }

    truncate = (source) => {
        const size = 120;
        return source.length > size ? source.slice(0, size) + '...' : source;
    };

    renderWltpNfez = (vehicle) => {
        if (vehicle.messverfahren === "wltp") {
            return (
                <>
                    {vehicle.wltp_kombiniert ? (
                        <span>{vehicle.wltp_kombiniert} l/100km (komb.)**; </span>
                    ) : null}

                    {vehicle.wltp_co2_emissionen ? (
                        <span>{vehicle.wltp_co2_emissionen} g/ CO2/km(komb.)**</span>
                    ) : null}
                </>
            );
        } else if (vehicle.messverfahren === "nefz") {
            return (
                <>
                    {vehicle.verbrauch_kombiniert ? (
                        <span>{vehicle.verbrauch_kombiniert} l/100km (komb.)**; </span>
                    ) : null}

                    {vehicle.co2_emissionen ? (
                        <span>{vehicle.co2_emissionen} g/ CO2/km(komb.)**</span>
                    ) : null}
                </>
            );
        }
    };
};

ResultItem.propTypes = {
    vehicle: PropTypes.object,
};

export default ResultItem;
