import './styles.scss';

import React from 'react';
import {DRIVE_TYPES, GEAR_TYPES} from '../../../../helper/constant.helper';
import EmissionBadge from '../EmissionBadge';

const CSS_CLASS = 'fc-vehicle-details';

class VehicleDetails extends React.Component {
    render() {
        const { vehicle } = this.props;

        return (
            <div className={CSS_CLASS}>
                {this.renderFeatureList(vehicle)}
                {this.renderTechnicalDetails(vehicle)}
                {this.renderPowerConsumptions(vehicle)}
                {this.renderFootNote()}
            </div>
        );
    }

    renderFeatureList(vehicle) {
        const { features } = vehicle;

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Ausstattung</div>
                <ul className={CSS_CLASS + '--list'}>
                    {features.map(feature => <li key={feature}>{feature}</li>)}
                </ul>
            </div>
        );
    }

    renderTechnicalDetails(vehicle) {
        const {
            cylinders, cubic_capacity, drive_type, gears, gear_type,
            width, height, length, kerb_weight, fuel_tank_capacity
        } = vehicle

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Technische Daten</div>
                <div className={CSS_CLASS + '--list'}>
                    <div className={CSS_CLASS + '--headline'}>Motorisierung</div>
                    {this.renderListPoint('Anzahl Zylinder', cylinders)}
                    {this.renderListPoint('Hubraum (ccm)', cubic_capacity)}
                    {this.renderListPoint('Kraftstoff', vehicle.fuel_type)}
                    {this.renderListPoint('Antriebsart', DRIVE_TYPES[drive_type])}
                    {this.renderListPoint('Anzahl Gänge', gears)}
                    {this.renderListPoint('Getriebe', GEAR_TYPES[gear_type])}
                    <div className={CSS_CLASS + '--headline'}>Gewicht und Abmessungen</div>
                    {this.renderListPoint('Breite (mm)', width)}
                    {this.renderListPoint('Höhe (mm)', height)}
                    {this.renderListPoint('Länge (mm)', length)}
                    {this.renderListPoint('Kraftstofftankinhalt (l)', fuel_tank_capacity)}
                    {this.renderListPoint('Leergewicht (kg)', kerb_weight)}
                </div>
            </div>
        );
    }

    renderListPoint(label, value) {
        if (value) {
            return (
                <div className={CSS_CLASS + '--list-point'}>
                    <span>{label}</span>
                    <span className={CSS_CLASS + '--dotted-line'}></span>
                    <span>{value}</span>
                </div>
            );
        }
    }

    renderPowerConsumptions(vehicle) {
        const {
            top_speed, emissions_class, consumptions,
            acceleration, kerb_weight, fuel_type
        } = vehicle;
        const {
            combined_co2, urban, out_of_town, combined, avg_kwh
        } = consumptions;
        let co2forCalc = 1;

        if (fuel_type !== 'Elektro') {
            co2forCalc = combined_co2;
        }

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Leistung & Verbrauch</div>
                <div className={CSS_CLASS + '--list'}>
                    <div className={CSS_CLASS + '--headline'}>Leistung</div>
                    {this.renderListPoint('Beschleunigung 0-100 km/h (Sek.)', acceleration)}
                    {this.renderListPoint('Höchstgeschwindigkeit (km/h)', top_speed)}
                    <div className={CSS_CLASS + '--headline'}>Verbrauch**</div>
                    {this.renderListPoint('CO2-Emission kombiniert (g/km)', combined_co2)}
                    {this.renderListPoint('Kraftstoffverbrauch innerorts (l/100 km)', urban)}
                    {this.renderListPoint('Kraftstoffverbrauch außerorts (l/100 km)', out_of_town)}
                    {this.renderListPoint('Kraftstoffverbrauch kombiniert (l/100 km)', combined)}
                    {this.renderListPoint('Stromverbrauch kombiniert (kwh/100 km)', avg_kwh)}
                    {this.renderListPoint('Schadstoffklasse', emissions_class)}
                    <EmissionBadge combinedCo2={co2forCalc} kerbWeight={kerb_weight}/>
                </div>
            </div>
        );
    }

    renderFootNote() {
        return (
            <p>
                ** Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen
                CO2-Emissionen neuer Personenkraftwagen können dem‚
                Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer
                Personenkraftwagen entnommen werden, der an allen
                Verkaufsstellen, bei der Deutschen Automobil Treuhand GmbH (DAT), Hellmuth-Hirth-Str. 1, 73760
                Ostfildern-Scharnhausen, und
                unter <a href="http://www.dat.de/co2" target="_blank">www.dat.de/co2</a> unentgeltlich erhältlich ist.
            </p>
        );
    }
}

export default VehicleDetails;