import './styles.scss';

import React from 'react';
import Icon from '../../../../components/Icon';
import BrandListing from '../../../../components/BrandListing';
import BranchHelper from '../../../../helper/branch.helper';
import OpenTimesBlock from '../OpenTimesBlock';

class BranchListing extends React.Component {

    render = () => {
        const mainCssClass = this.props.className || 'branch-list';
        return (
            <div className={mainCssClass}>
                <div className={mainCssClass + '--headers'}>
                    <span>Standort</span>
                    <span>Marken</span>
                    <span>Öffnungszeiten</span>
                    <span>Kontakt</span>
                </div>
                <div className={mainCssClass + '--listing'}>
                    {this.renderBranchRows()}
                </div>
            </div>
        );
    }

    renderBranchRows = () => {
        if (Array.isArray(this.props.branches)) {
            const { branches, brandFilter = '' } = this.props;
            const className = this.props.mainCssClass || 'branch-list';
            const rowCssClass = className + '--branch-row';
            const colCssClass = rowCssClass + '--col';
            const iconStyles = {
                display: 'block',
                width: '1.125rem',
                height: '1.125rem'
            };
            const currentBrand = brandFilter.replace('elektro', 'renault');

            return (
                <React.Fragment>
                    {
                        branches
                            .filter(branch => {
                                if (brandFilter === 'all_items') {
                                    return true;
                                }
                                if (branch.sale_brands.indexOf(currentBrand) !== -1) {
                                    return true;
                                }
                                if (brandFilter === 'fiat_abarth_prof') {
                                    const isFiat = branch.sale_brands.indexOf('fiat') !== -1;
                                    const isAbarth = branch.sale_brands.indexOf('abarth') !== -1;
                                    if (isFiat || isAbarth) {
                                        return true;
                                    }
                                }
                                return false;
                            })
                            .map(
                                ({
                                    name,
                                    official_name = '',
                                    street,
                                    zip_code,
                                    location,
                                    sale_brands,
                                    telephone = '',
                                    _id,
                                    federal_state,
                                    opening_times = null,
                                    has_workshop = false,
                                    week_label
                                }, idx) => (
                                        <a key={'branch-row-' + idx}
                                            className={rowCssClass}
                                            href={'/standorte/' + BranchHelper.normalize(federal_state) + '/' + _id + '/'}
                                            target="_top"
                                        >
                                            <div className={colCssClass}>
                                                <span id={_id} className="jumpfix"></span>
                                                <b className={colCssClass + '--name'}>{name}</b>
                                                <span className={colCssClass + '--official-name'}>{official_name}</span>
                                                <span className={colCssClass + '--street'}>{street}</span>
                                                <span className={colCssClass + '--zip-location'}>{`${zip_code} ${location}`}</span>
                                            </div>
                                            <div className={colCssClass}>
                                                <BrandListing brands={sale_brands} />
                                            </div>
                                            <div className={colCssClass}>
                                                <div className={colCssClass + '--times'}>
                                                    <b>Verkauf:</b>
                                                    {this.renderSaleTime(opening_times, week_label ? week_label+": " : null)}
                                                </div>
                                                {(has_workshop && opening_times)
                                                    ? this.renderServiceTime(opening_times.service, colCssClass)
                                                    : this.renderHasNoWorkshop(colCssClass)
                                                }
                                            </div>
                                            <div className={colCssClass}>
                                                <span className={colCssClass + '--schedule'}>
                                                    <Icon
                                                        variant="schedule"
                                                        color="green"
                                                        style={iconStyles} />
                                                    <span className={colCssClass + '--schedule--text'}>0800 / 723 52 51</span>
                                                </span>
                                                <span className={colCssClass + '--phone'}>
                                                    <Icon
                                                        variant="phone"
                                                        color="#3b3b3b"
                                                        style={iconStyles} />
                                                    <span className={colCssClass + '--phone--text'}>{telephone}</span>
                                                </span>
                                                <span className={colCssClass + '--email'}>
                                                    <Icon
                                                        variant="email"
                                                        color="#3b3b3b"
                                                        style={iconStyles} />
                                                    <span className={colCssClass + '--email--text'}>E-Mail schreiben</span>
                                                </span>
                                            </div>
                                        </a>
                                    ))
                    }
                </React.Fragment>
            );
        }
    }

    renderSaleTime(opening_times, weekLabel) {
        if (opening_times && opening_times.sale) {
            return <OpenTimesBlock openingTimes={opening_times.sale} weekLabel={weekLabel} />
        }

        return null;
    }

    renderServiceTime = (service_times, colCssClass) => {
        return (
            <div className={colCssClass + '--times'}>
                <b>Service, Teile &amp; Zubehör:</b>
                <OpenTimesBlock openingTimes={service_times} />
            </div>
        )
    }

    renderHasNoWorkshop(colCssClass) {
        return (
            <div className={colCssClass + '--times'}>
                <b>Service, Teile &amp; Zubehör:</b>
                Keine Werkstatt vorhanden.
            </div>
        )
    }
}

export default BranchListing;