import React from 'react';
import PropTypes from 'prop-types';

import DropDown from '../../../components/DropDown';
import Icon from '../../../components/UsedCarIcon';

const SORT_DATA = [
	{ label: 'Sortieren nach Preis', value: 'preis' },
	{ label: 'Sortieren nach Kilometer', value: 'km' },
	{ label: 'Sortieren nach Leistung', value: 'ps' },
];

const SortBar = props => {
	const [active, toggleActive] = React.useState(props.currentOrderBy.order);

	const handleChange = query => {
		props.onChange(query);
	};

	return (
		<div className='sort-bar'>
			<DropDown
				options={SORT_DATA}
				onChangeValue={value => handleChange({ ...props.currentOrderBy, sort: value })}
				initialLabel={SORT_DATA.find(d => d.value === props.currentOrderBy['sort']).label}
			/>

			<button
				className={`sort-bar__btn ${active === 'DESC' ? 'active' : ''}`}
				onClick={() => {
					handleChange({ ...props.currentOrderBy, order: 'DESC' });
					toggleActive('DESC');
				}}>
				<Icon variant='arrow_downward' />
			</button>

			<button
				className={`sort-bar__btn ${active === 'ASC' ? 'active' : ''}`}
				onClick={() => {
					handleChange({ ...props.currentOrderBy, order: 'ASC' });
					toggleActive('ASC');
				}}>
				<Icon variant='arrow_upward' />
			</button>
		</div>
	);
};

export default SortBar;

SortBar.propTypes = {
	onChange: PropTypes.func.isRequired,
	currentOrderBy: PropTypes.object.isRequired,
};
