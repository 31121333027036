import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import ApiHelper from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';
import SectionHeadline from '../../components/SectionHeadline';
import TeaserBox from '../../components/TeaserBox';
import ReactHtmlParser from 'react-html-parser';
import BrandTeaser from '../../components/BrandTeaser';
import Abmeldeformular from './components/Abmeldeformular';

const CSS_CLASS = 'page-newsletter-abmelden';

class NewsletterAbmeldenPage extends React.Component {
    constructor(props) {
        super(props);

        let postData, pageData, startData = {'teaser': []};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [pageData, startData = {'teaser': []}] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [pageData, startData = {'teaser': []}] = window.__initialData__;
            }
        }

        this.state = {
            ...pageData,
            brandTeasers: startData.teaser
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/newsletter/abmeldung') !== -1) {
            const pageData = ApiHelper.GET('/pages/newsletter_abmelden');
            const startPage = ApiHelper.GET('/pages/start');

            return Promise.all([pageData, startPage])
                .catch(err => console.error(err));
        }
    }

    render() {
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Newsletter', path: '/newsletter/' },
            { label: 'Abmeldung', current_page: true }
        ];

        const {
            title = "Autohaus König",
            headline = "",
            hotteaserboxes = [],
            content = {},
            teaserboxes = []
        } = this.state;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />

                <h1>{headline}</h1>

                {this.renderTeaserImages()}
                {this.renderTeaserBox(hotteaserboxes, 'Letzte Chance vor der Abmeldung - vielleicht finden Sie diese Themen interessant?')}
                <h2>{content.headline}</h2>
                <div className={CSS_CLASS + '--content'}>
                    <div className={CSS_CLASS + '--content--left'}>
                        {ReactHtmlParser(content.text_1)}
                        <KoenigImage fileHash={content.image} />
                        {ReactHtmlParser(content.text_2)}
                    </div>
                    <div className={CSS_CLASS + '--content--right'}>
                        <Abmeldeformular />
                    </div>
                </div>
                {this.renderTeaserBox(teaserboxes, 'Auch diese Themen könnten für Sie sehr interessant sein')}
                {this.renderBrandTeaser()}
            </CommonPage>
        );
    }

    renderTeaserImages() {
        const { teaser_src = {}, headline } = this.state;
        const { mobile = '', desktop = '' } = teaser_src;
        const CssClassTeaser = CSS_CLASS + '--teaser';

        return (
            <React.Fragment>
                {mobile.length > 0 ? <KoenigImage className={CssClassTeaser + ' ' + CssClassTeaser + '--mobile'} fileHash={mobile} alt={headline} /> : null}
                {desktop.length > 0 ? <KoenigImage className={CssClassTeaser + ' ' + CssClassTeaser + '--desktop'} fileHash={desktop} alt={headline} /> : null}
            </React.Fragment>
        );
    }

    renderTeaserBox(data, headline) {
        if (data.length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <SectionHeadline text={headline} />
                <div className="teaserboxes">
                    {
                        data.map((data, idx) => {
                            return (
                                <TeaserBox key={data.headline + idx} {...data} />
                            );
                        })
                    }
                </div>
            </React.Fragment>
        );
    }

    renderBrandTeaser() {
        const { brandTeasers = [] } = this.state;
        
        if (brandTeasers <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <SectionHeadline text="Kennen Sie bereits unser gesamtes Marken-Porfolio?" />
                <div className="brand-teasers">
                    {
                        brandTeasers.map((teaser, idx) => {
                            return (
                                <div key={'page-newsletter-abmelden-'+teaser.uid + idx} className="cnt-brand-teaser">
                                    <BrandTeaser teaser={teaser} />
                                </div>
                            );
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default NewsletterAbmeldenPage;