import { useEffect } from 'react';

export default function AutoUncle() {
    useEffect(() => {
        let autouncleWidget;

        autouncleWidget = document.getElementById('au-widget-script');

        if (autouncleWidget === null) {
            autouncleWidget = document.createElement('script');
            autouncleWidget.async = true;
            autouncleWidget.src = 'https://www.autouncle.de/widgets/embed.js?source_name=Renault-koenig.de';
            autouncleWidget.setAttribute('data-source-name', 'Renault-koenig.de');
            autouncleWidget.id = 'au-widget-script';
            document.head.appendChild(autouncleWidget);
        }

        if (typeof auWidgetEmbed === 'object' && typeof auWidgetEmbed.init === 'function') {
            auWidgetEmbed.init();
        }
    });

    return null;
}
