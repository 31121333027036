import React from 'react';
import AccordionItem from './AccordionItem';

const aboutLinks = [
    {
        "title": "Über König",
        "href": "/unternehmen/geschichte/",
    },
    {
        "title": "News",
        "href": "https://news.autohaus-koenig.de/",
        "external": "true"
    },
    {
        "title": "Karriere",
        "href": "https://news.autohaus-koenig.de/karriere/"
    },
    {
        "title": "Events",
        "href": "https://news.autohaus-koenig.de/events/"
    },
    {
        "title": "Hertha BSC",
        "href": "/hertha-bsc/"
    }
];
const serviceHelpLinks = [
    {
        "title": "Online einkaufen",
        "href": "/onlineshop/",
    },
    {
        "title": "Kontakt",
        "href": "/kontakt/"
    },
    {
        "title": "Öffnungszeiten",
        "href": "/standorte/"
    },
    {
        "title": "Standorte",
        "href": "/standorte/"
    },
    {
        "title": "Behindertenrabatt",
        "href": "/aktionen/angebote-schwerbehinderte/"
    }
];
const koenigServiceLinks = [
    {
        "title": "Werkstatt",
        "href": "/werkstatt/"
    },
    {
        "title": "Fahrzeug-Inzahlungsnahme",
        "href": "/gebrauchtwagen/inzahlungnahme/"
    },
    {
        "title": "Newsletter abonnieren",
        "href": "/newsletter/"
    }
];
const koenigPopularModelLinks = [
    {
        "title": "Fiat 500 Leasing",
        "href": "/neuwagen/fiat/500/"
    },
    {
        "title": "Vespa kaufen",
        "href": "/zweirad/vespa/"
    },
    {
        "title": "Aprilia Leasing",
        "href": "/zweirad/aprilia/"
    },
    {
        "title": "Jeep Renegade Leasing",
        "href": "/neuwagen/jeep/renegade/"
    },
    {
        "title": "VW Taigo Leasing",
        "href": "/neuwagen/volkswagen/taigo/"
    },
    {
        "title": "Dacia Sandero kaufen",
        "href": "/neuwagen/dacia/sandero/"
    },
    {
        "title": "Maxus Transporter",
        "href": "/neuwagen/maxus/"
    }
];

export default props => {
    const defaultOpenStates = {
        about: false,
        faq: false,
        service: false,
        models: false
    };
    const [isOpen, setOpenState] = React.useState({ ...defaultOpenStates });

    return (
        <div id="accordion_section">
            <div>
                <AccordionItem
                    id="about"
                    title="Mehr über Autohaus König"
                    className={isOpen.about ? 'open' : ''}
                    onClick={() => setOpenState(prevState => ({ ...defaultOpenStates, about: !prevState.about }))}
                    links={aboutLinks} />

                <a href="/hertha-bsc/">
                    <img src="https://t3api.autohaus-koenig.de/relaunch_img/content/aktionen/hertha-bsc/hertha-footer.svg" />
                </a>
            </div>

            <AccordionItem
                id="service_help"
                title="Service &amp; Hilfe"
                className={isOpen.faq ? 'open' : ''}
                onClick={() => setOpenState(prevState => ({ ...defaultOpenStates, faq: !prevState.faq }))}
                links={serviceHelpLinks} />

            <AccordionItem
                id="koenig_service"
                title="Service rundum König"
                className={isOpen.service ? 'open' : ''}
                onClick={() => setOpenState(prevState => ({ ...defaultOpenStates, service: !prevState.service }))}
                links={koenigServiceLinks} />

            <AccordionItem
                id="popular_models"
                title="Beliebte Modelle"
                className={isOpen.models ? 'open' : ''}
                onClick={() => setOpenState(prevState => ({ ...defaultOpenStates, models: !prevState.models }))}
                links={koenigPopularModelLinks} />

            <div id="newsletter_abo">
                <div className="title-row">
                    <span>Ausgezeichnet</span>
                </div>

                <div className="content">
                    <a href="/unternehmen/focus-money-deutschlandtest/">
                        <img src="https://t3api.autohaus-koenig.de/relaunch_img/content/unternehmen/focus-money/Siegel_DT_Unternehmen_des_Jahres_2024_Autohaus_Gotthard_K_nig.png" />
                    </a>
                </div>
            </div>
        </div>
    )
};