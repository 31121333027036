import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import PageTile from '../../components/PageTile';
import Search from '../../components/Search';
import API from '../../helper/api.helper';
import qs from 'qs';

import hero from '../../assets/images/hero.png';
import img_kleinwagen from '../../assets/images/page-tile/page-tile_kleinwagen.jpg';
import img_suv from '../../assets/images/page-tile/page-tile_suv.jpg';
import img_roller from '../../assets/images/page-tile/page-tile_roller.jpg';
import pageTileData from './PageTile.json';
import SimpleContent from '../../components/SimpleContent';
import ReactHtmlParser from 'react-html-parser';
import SectionHeadline from '../../components/SectionHeadline';
import BrandLogo from '../../elements/BrandLogo';
import KoenigImage from '../../components/KoenigImage';

const CSS_CLASS = "page-gebrauchtwagenbereich";
const BRAND_MENU_CSS = CSS_CLASS + '--brand-menu';

class GebrauchtwagenbereichPage extends React.Component {

    constructor(props) {
        super(props);

        let postData, pageData, tiles = [];

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [pageData, tiles = []] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [pageData, tiles = []] = window.__initialData__;
            }
        }

        this.state = {
            pageData: pageData,
            tiles: tiles
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/gebrauchtwagen/') === 0) {
            const pageData = API.GET('/pages/gebrauchtwagen');
            const tiles = API.GET('/page_tiles?page=gebrauchtwagen');

            return Promise.all([pageData, tiles])
                .catch(err => console.error(err));
        }
    }

    render() {
        const pageData = this.state.pageData;

        if (!pageData || pageData.length === 0) {
            return this.renderNotFoundPage();
        } else {
            return this.renderPage();
        }
    }

    renderNotFoundPage() {
        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <p>Keine Daten gefunden.</p>

            </CommonPage>
        );
    }

    renderPage() {
        const { pageData, tiles } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Gebrauchtwagen', current_page: true },
        ];

        const renderTileImage = (category) => {
            if (category === 'Kleinwagen') return img_kleinwagen;
            if (category === 'SUV/Geländewagen') return img_suv;
            if (category === 'Motorroller') return img_roller;
        }

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{pageData.title}</title>
                    <meta name="description" content={pageData.description}/>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1>{ReactHtmlParser(pageData.headline)}</h1>

                {this.renderTeaserImages()}

                <div className="search-box-container" style={{ backgroundImage: `url(${hero})` }}>
                    <h2>Finden jetzt Sie Ihren passenden Gebrauchtwagen</h2>
                    <Search
                        onSearch={() => this.props.history.replace(`gebrauchtwagenangebote${this.props.location.search}`)} />
                </div>

                <SectionHeadline text="Unsere wichtigsten Gebrauchtwagen-Marken im Überblick" />
                {this.renderBrandMenu()}
                <SectionHeadline text="Interessante Gebrauchtwagen-Kategorien im Überblick" />
                <div className="cnt-page-tiles">
                    {
                        pageTileData.data.map((tile, index) => (
                            <PageTile
                                key={tile._id + '-' + index}
                                image={renderTileImage(tile.category)}
                                alt={tile.alt}
                                link={`gebrauchtwagenangebote?${qs.stringify({ s: { aufbau: tile.searchString } })}`}
                                hasSectionHeadline={false}
                                {...tile}
                            />
                        ))
                    }
                </div>

                <h3>Qualitätscheck & Garantie für gebrauchte Auto Angebote</h3>
                <div className="cnt-page-tiles">
                    {
                        tiles.map((tile, idx) => <PageTile key={tile._id + '-' + idx} {...tile} />)
                    }
                </div>

                <SimpleContent content={pageData.seo_content} />
            </CommonPage>
        );
    }

    renderBrandMenu() {
        const { pageData: { brands = [] } } = this.state;

        return (
            <div className={BRAND_MENU_CSS + '--container'}>
                {
                    brands.map(brand => this.renderBrandLink(brand))
                }
                <a
                    href="/gebrauchtwagen/gebrauchtwagenangebote/"
                    className={BRAND_MENU_CSS + '--more-brands'}>
                    Weitere Marken
                </a>
            </div>
        );
    }

    renderBrandLink(brand) {
        return (
            <a
                key={'gw-brand-link-' + brand._id}
                href={`/gebrauchtwagen/automarken/${brand._id.replace('_', '-')}/`}
                className={BRAND_MENU_CSS + '--link'}>
                <BrandLogo brandId={brand.logo_id ? brand.logo_id : brand._id} />
            </a>
        );
    }

    renderTeaserImages() {
        const { pageData: {teaser_src = {}, title} } = this.state;
        const { mobile, desktop } = teaser_src;
        const CSS_CLASS_TEASER = CSS_CLASS + '--teaser';

        return (
            <div className={CSS_CLASS_TEASER}>
                {<KoenigImage className={CSS_CLASS_TEASER + ' ' + CSS_CLASS_TEASER + '--mobile'} fileHash={mobile} alt={title} />}
                {<KoenigImage className={CSS_CLASS_TEASER + ' ' + CSS_CLASS_TEASER + '--desktop'} fileHash={desktop} alt={title} />}
            </div>
        );
    }
}

export default GebrauchtwagenbereichPage;