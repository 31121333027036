import './styles.scss'

import React from 'react';
import KoenigButton from '../../../elements/KoenigButton';
import StepPage from "../components/StepPage";
import KoenigSelect from "../../../elements/KoenigSelect";
import KoenigInput from "../../../elements/KoenigInput";
import NavigationHelper from "../../../helper/navigation.helper";
import Functions from '../../../components/SimpleShop/functions';

const CSS_CLASS = 'simple-shop--step1';
const CSS_FORM = CSS_CLASS + '--form';

class Schritt1Page extends React.Component {
    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            shop_items: [],

            salutation: null, firstname: null, lastname: null,
            address: null, zip_code: null, location: null,
            email: null, phone: null,

            ...postData
        };
    }

    handleProductRemoving(productId) {
        const { shop_items } = this.state;

        this.setState({
            shop_items: shop_items.filter(si => si && si.product._id !== productId)
        });
    }

    handleQuantityIncrementing(productId) {
        const { shop_items, itemLimit } = this.state;

        this.setState({
            shop_items: shop_items.map(si => {
                if (si && si.product && si.product._id === productId) {
                    si.quantity = Math.min(++si.quantity, itemLimit);
                    si.pos_total = si.quantity * Functions.getItemPrice(si.product);
                }

                return si;
            })
        });
    }

    handleQuantityDecrementing(productId) {
        const { shop_items } = this.state;

        this.setState({
            shop_items: shop_items
                .map(si => {
                    if (si && si.product && si.product._id === productId) {
                        if (--si.quantity === 0) {
                            return null
                        }
                    }

                    si.pos_total = si.quantity * Functions.getItemPrice(si.product);

                    return si;
                })
                .filter(si => si !== null)
        });
    }

    handleBackLinking() {
        const { shopUrl } = this.state;

        NavigationHelper.linkTo(shopUrl, this.state);
    }

    handleNextLinking() {
        NavigationHelper.linkTo('/shopping/checkout/schritt2/', this.state);
    }

    render() {
        const { shop_items, itemLimit, shopUrl } = this.state;

        return (
            <StepPage
                className="page-simple-shop--schritt1"
                steps={[0, 1]}
                shopUrl={shopUrl}
                itemLimit={itemLimit}
                postData={this.state}
                onQuantityIncrement={this.handleQuantityIncrementing.bind(this)}
                onQuantityDecrement={this.handleQuantityDecrementing.bind(this)}
                shop_items={shop_items}>

                <div className="intro-text">
                    <b>Ihre persönlichen Daten benötigen wir für Ihr Kundenkonto und die Rechnung.</b><br />
                    <p>Füllen Sie ganz bequem die nachfolgenden Felder aus. Ihre E-Mail-Adresse und Ihre Telefonnummer benötigen wir ausschließlich für die Abwicklung Ihrer Bestellung. Für den Fall einer Lieferverzögerung möchten wir Sie gern dazu kontaktieren.</p>
                </div>

                {this.renderForm()}

                <div className="simple-shop--step1--button--row">
                    <KoenigButton
                        className="back-button"
                        text="Zurück zur Übersicht"
                        onClick={this.handleBackLinking.bind(this)} />

                    <KoenigButton
                        className="next-button"
                        text="Prüfen & Bestellen"
                        onClick={this.handleNextLinking.bind(this)}
                        disabled={!this.isValid()} />
                </div>

            </StepPage>
        )
    }

    renderForm() {
        const {
            salutation, firstname, lastname, address,
            zip_code, location, email, phone
        } = this.state;

        return (
            <div className={CSS_FORM}>
                <div className={CSS_FORM + '--salutation'}>
                    <KoenigSelect
                        label="Anrede"
                        options={['Herr', 'Frau']}
                        value={salutation}
                        onChange={salutation => this.setState({ salutation })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--firstname'}>
                    <KoenigInput
                        label="Vorname"
                        value={firstname}
                        onChange={firstname => this.setState({ firstname })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--lastname'}>
                    <KoenigInput
                        label="Nachname"
                        value={lastname}
                        onChange={lastname => this.setState({ lastname })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--address'}>
                    <KoenigInput
                        label="Straße und Hausnummer"
                        value={address}
                        onChange={address => this.setState({ address })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--zip_code'}>
                    <KoenigInput
                        label="PLZ"
                        value={zip_code}
                        onChange={zip_code => this.setState({ zip_code })}
                        name="postal_code"
                        isRequired />
                </div>

                <div className={CSS_FORM + '--location'}>
                    <KoenigInput
                        label="Ort"
                        value={location}
                        onChange={location => this.setState({ location })}
                        isRequired />
                </div>

                <div className={CSS_FORM + '--email'}>
                    <KoenigInput
                        label="E-Mail"
                        value={email}
                        onChange={email => this.setState({ email })}
                        isEmailField
                        isRequired />
                </div>

                <div className={CSS_FORM + '--phone'}>
                    <KoenigInput
                        label="Telefonnummer"
                        value={phone}
                        onChange={phone => this.setState({ phone })}
                        isNumberField
                        isRequired />
                </div>
            </div>
        );
    }

    isValid() {
        const allInputsNotNull = [
            'salutation', 'firstname', 'lastname',
            'address', 'zip_code', 'location',
            'email', 'phone'
        ].reduce((result, key) => result && this.state[key] !== null, true);

        return allInputsNotNull && this.state.shop_items.length > 0;
    }
}

export default Schritt1Page;