import './styles.scss';

import React from 'react';

const CSS_CLASS = 'product-category-chooser';

class ProductCategoryChooser extends React.Component {
    constructor(props) {
        super(props);

        const { activeItem } = this.props;

        this.state = {
            selectedItem: activeItem
        }
    }

    render() {

        const { categories = [], onChange = () => { } } = this.props;
        const { selectedItem } = this.state;

        return (
            <div className={CSS_CLASS}>
                {
                    categories
                        .sort((a, b) => (a._id > b._id) ? 1 : -1)
                        .map(({ _id, label, bg_color }) => {
                            const classes = ['category-item'];
                            const isActiveItem = _id === selectedItem;
                            let clickFunc = this.handleClick.bind(this);

                            if (isActiveItem) {
                                classes.push('active');
                                clickFunc = function () { };
                            }

                            return (
                                <span
                                    key={label + '-' + _id}
                                    className={classes.join(' ')}
                                    onClick={() => clickFunc(_id, onChange)}
                                    style={{ backgroundColor: bg_color }}
                                >
                                    {label}
                                </span>
                            )
                        })
                }
            </div>
        )
    }

    handleClick(_id, onChange) {
        this.setState({ selectedItem: _id });
        onChange(_id);
    }
}

export default ProductCategoryChooser