function sort(vehicles) {
    const first = [],
        second = [],
        third = [],
        zero = [];

    for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];

        switch (vehicle.group) {
            case 1:
                first.push(vehicle);
                break;
            case 2:
                second.push(vehicle);
                break;
            case 3:
                third.push(vehicle);
                break;
            case 0:
                zero.push(vehicle);
                break;
        }
    }

    return [].concat(first, second, third, zero);
}

export default {
    sort
}