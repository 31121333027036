import './styles.scss';

import React from 'react';
import Icon from '../../../../components/Icon';
import Overlay from '../../../../components/Overlay';
import KoenigInput from '../../../../elements/KoenigInput';
import KoenigSelect from '../../../../elements/KoenigSelect';
import KoenigButton from '../../../../elements/KoenigButton';
import Api from '../../../../helper/api.helper';
import Checkbox from '../../../../elements/Checkbox';
import MoAdPixel from '../../../../components/MoAdPixel';
import fbHelper from '../../../../helper/facebook.helper';
import { addConversionTag } from '../../../../components/Criteo';

const CSS_CLASS = 'fc-offer-buttons';
const POSSIBLE_DAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
const callTime = [];
for (let i = 8; i <= 19; i++) {
    callTime.push(i + ' Uhr');
}

class OfferButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            salutation: null,
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            days: [],
            call_time_start: null,
            call_time_end: null,
            agb: false,
            branch: null,
            branches: [],
            message: 'Bitte rufen Sie mich zurück.',
            inProgress: false
        };
    }

    componentDidMount() {
        this.addShareScript();
        this.fetchBranches();
    }

    addShareScript() {
        window.a2a_config = {
            icon_color: '#3b3b3b'
        };

        const script = document.createElement('script');
        script.name = 'share-button-script'
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://static.addtoany.com/menu/page.js';

        document.head.appendChild(script);
    }

    fetchBranches() {
        Api
            .GET('/branches')
            .then(branches => this.setState({ branches }))
            .catch(console.error);
    }

    handleBranches() {
        const { vehicle: { brand } } = this.props;

        fbHelper.track('FindLocation');
        window.open(`/standorte/${brand.toLowerCase().replace(' ', '-')}/`, '_blank');
    }

    handleCallback() {
        fbHelper.track('Contact');
        this.setState({ callbackOverlayVisible: true });
    }

    handleShare() {
        this.setState({ shareOverlayVisible: true }, window.a2a.init_all);
    }

    handleTradeIn() {
        fbHelper.track('TradeInButtonClicked', undefined, true);
        window.auws.openModal(window.auws.ModuleTypes.TRADE_IN);
    }

    handleDayChange(selected, idx) {
        const { days } = this.state;
        let newDays;

        if (selected) {
            newDays = days.concat([POSSIBLE_DAYS[idx]]);
        } else {
            newDays = days.filter(item => item !== POSSIBLE_DAYS[idx]);
        }

        this.setState({ days: newDays });
    }

    sendCallback() {
        this.setState({ inProgress: true });
        const { vehicle } = this.props;
        const document = { ...this.state };
        delete document.shareOverlayVisible;
        delete document.showSuccessful;
        delete document.showFailure;
        delete document.callbackOverlayVisible;
        delete document.branches;
        delete document.inProgress;
        document.developed_from = 'Fahrzeug Rückrufservice';
        document.v_id = vehicle._id;

        Api.POST('/inquiries', document)
            .then(res => {
                addConversionTag(vehicle.payments.prices?.cash / 100, vehicle._id, document.email, false, res._id);
                this.setState({
                    showSuccessful: true,
                    callbackOverlayVisible: false,
                    callbackInquiryId: res._id,
                    inProgress: false
                })
            })
            .catch(error => {
                console.error(error);
                this.setState({ showFailure: true, callbackOverlayVisible: false, inProgress: false });
            });

        fbHelper.track('Lead');
    }

    render() {
        const { vehicle } = this.props;

        return (
            <div className={CSS_CLASS}>
                {this.renderBranchesButton(vehicle)}
                {this.renderCallbackButton(vehicle)}
                {this.renderShareButton(vehicle)}
                {this.renderTradeInButton(vehicle)}
                {this.renderShareOverlay()}
                {this.renderCallbackOverlay()}
            </div>
        );
    }

    renderBranchesButton() {
        return this.renderButton(
            'Filialen anzeigen',
            'branch',
            this.handleBranches
        );
    }

    renderCallbackButton() {
        return this.renderButton(
            'Rückruf Autokauf',
            'phone',
            this.handleCallback
        );
    }

    renderShareButton() {
        return this.renderButton(
            'Angebot teilen',
            'share',
            this.handleShare
        );
    }

    renderTradeInButton() {
        return this.renderButton(
            'Ihr Fahrzeug in Zahlung geben',
            'wife_hand',
            this.handleTradeIn
        );
    }

    renderButton(title, iconVariant, handler = () => {}) {
        return (
            <span className={CSS_CLASS + '--button'} onClick={handler.bind(this)}>
                <span><Icon variant={iconVariant} color="white" /></span>
                <span>{title}</span>
            </span>
        );
    }

    renderShareOverlay() {
        const { shareOverlayVisible } = this.state;

        return shareOverlayVisible && (
            <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
                <a className="a2a_dd" href="https://www.addtoany.com/share" />
                <a className="a2a_button_facebook" />
                <a className="a2a_button_facebook_messenger" />
                <a className="a2a_button_twitter" />
                <a className="a2a_button_email" />
                <a className="a2a_button_whatsapp" />
                <a className="a2a_button_telegram" />
            </div>
        );
    }

    renderCallbackOverlay() {
        const { callbackOverlayVisible, showSuccessful, showFailure, branches = [], inProgress } = this.state;
        const overlayCss = CSS_CLASS + '--callback';
        const branchOptions = branches.map(b => b.official_name ? b.name + ' (' + b.official_name + ')' : b.name);

        if (showSuccessful) {
            return this.renderSuccessfulOverlay(overlayCss);
        }

        if (showFailure) {
            return this.renderFailureOverlay(overlayCss);
        }

        return callbackOverlayVisible && (
            <Overlay className={overlayCss} onClose={() => this.setState({ callbackOverlayVisible: false })}>
                <KoenigSelect
                    label="Anrede"
                    options={['Herr', 'Frau']}
                    value="Bitte wählen"
                    onChange={salutation => this.setState({ salutation })}
                    isRequired />
                <KoenigInput
                    label="Vorname"
                    onChange={firstname => this.setState({ firstname })}
                    isRequired />
                <KoenigInput
                    label="Nachname"
                    onChange={lastname => this.setState({ lastname })}
                    isRequired />
                <KoenigInput
                    label="Rückrufnummer"
                    onChange={phone => this.setState({ phone })}
                    isRequired />
                <KoenigInput
                    label="E-Mail"
                    onChange={email => this.setState({ email })}
                    isRequired
                    isEmailField />
                <div className={overlayCss + '--days'}>
                    <label>{'Mögliche Tage*'}</label>
                    <span>
                        {
                            POSSIBLE_DAYS.map((day, idx) => {
                                return (
                                    <span key={'day_' + idx}>
                                        <Checkbox onChange={selected => this.handleDayChange(selected, idx)} />
                                        <span>{day}</span>
                                    </span>
                                );
                            })
                        }
                    </span>
                </div>
                <div className={overlayCss + '--calltime'}>
                    <label>In welchem Zeitraum passt Ihnen der Rückruf?*</label>
                    <KoenigSelect
                        className={overlayCss + '--calltime--start'}
                        value="Im Zeitraum von"
                        options={callTime}
                        onChange={call_time_start => this.setState({ call_time_start })}
                    />
                    <KoenigSelect
                        className={overlayCss + '--calltime--end'}
                        value="bis"
                        options={callTime}
                        onChange={call_time_end => this.setState({ call_time_end })}
                    />
                </div>
                <KoenigSelect
                    className={overlayCss + '--branches'}
                    label="Wählen Sie Ihre Wunschfiliale"
                    options={branchOptions}
                    onChange={branch => this.setState({ branch })}
                    value="Bitte Filiale wählen"
                />
                <div className={overlayCss + '--message'}>
                    <label>Ihre Nachricht</label>
                    <textarea
                        onChange={ev => this.setState({ message: ev.target.value })} />
                </div>
                <div>
                    <Checkbox
                        onChange={agb => this.setState({ agb })} />
                    <span>
                        <span className="koenig-checkbox-text">
						<a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> habe ich gelesen
                        </span>
                        <span>(*) = Pflichtfelder</span>
                    </span>
                </div>
                <KoenigButton
                    text="Anfrage senden"
                    onClick={this.sendCallback.bind(this)}
                    disabled={!this.isValid() || inProgress} />
            </Overlay>
        );
    }

    isValid() {
        const allInputsNotNull = [
            'salutation', 'firstname', 'lastname',
            'days', 'call_time_start', 'call_time_end', 'email', 'phone'
        ].reduce(
            (result, key) => {
                if (result && key === 'email') {
                    return this.isEmailValid(this.state[key]);
                }

                return result && this.state[key] && this.state[key].length > 0
            },
            true
        );

        return allInputsNotNull && this.state.agb;
    }

    isEmailValid = (email) => {
        return email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    renderFailureOverlay(overlayCss) {
        return (
            <Overlay className={overlayCss + '--failure'} onClose={() => this.setState({ showFailure: false })}>
                <h2>Vielen Dank für Ihre Anfrage!</h2>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>
            </Overlay>
        );
    }

    renderSuccessfulOverlay(overlayCss) {
        const { callbackInquiryId } = this.state;
        const { vehicle } = this.props;

        return (
            <Overlay className={overlayCss + '--successful'} onClose={() => this.setState({ showSuccessful: false })}>
                <h2>Vielen Dank für Ihre Anfrage!</h2>
                <p>
                    Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
                </p>
                <p>Ihr Autohaus König</p>
                <MoAdPixel inquiryId={callbackInquiryId} vehicle={vehicle} />
            </Overlay>
        );
    }
}

export default OfferButtons;