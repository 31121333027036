import React from 'react';
import API from '../../helper/api.helper';

class KoenigImage extends React.Component {
    render() {
        const { fileHash = '' } = this.props;

        if (fileHash !== null && fileHash.length > 0) {
            const hasNoJpgExtension = fileHash.indexOf('.jpg') === -1;
            const isPngImage = fileHash.indexOf('.png') !== -1;

            return hasNoJpgExtension && !isPngImage
                ? this.renderOptimizedImages(fileHash)
                : this.renderFallbackImage(fileHash);
        }

        return null;
    };

    renderOptimizedImages(fileHash) {
        const { alt = 'Bild', title = '', className = '', style = {}, onClick = () => { } } = this.props;
        const BASE_URL = API.getBaseUrl();

        return (
            <picture>
                <source srcSet={BASE_URL + '/' + fileHash + '.webp'} type="image/webp" />
                <source srcSet={BASE_URL + '/' + fileHash + '.jpg'} type="image/jpeg" />
                <img
                    className={className}
                    src={BASE_URL + '/' + fileHash + '.jpg'}
                    alt={alt}
                    title={title}
                    style={style}
                    onClick={onClick} />
            </picture>
        );
    }

    renderFallbackImage(srcUrl) {
        const { alt = 'Bild', title = '', className = '', style = {}, onClick = () => { }  } = this.props;

        return (
            <picture>
                <img
                    className={className}
                    src={srcUrl}
                    alt={alt}
                    title={title}
                    style={style}
                    onClick={onClick} />
            </picture>
        );
    }
}

export default KoenigImage;