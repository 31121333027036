function getTotalPrice(items = [], shippingCosts = 0, percentageVoucher = 0) {
    let total = items.reduce((result, i) => {
        const {product = {}, quantity} = i;
        const {prices} = product;

        if (prices && prices.hasOwnProperty("special_offer")) {
            result += prices.special_offer * quantity;
        } else if (prices && prices.hasOwnProperty("default")) {
            result += prices.default * quantity;
        }

        return result < 1 ? 0 : result;
    }, shippingCosts);

    if (percentageVoucher > 0) {
        const productTotal = total - shippingCosts;
        const percentFactor = (100 - percentageVoucher) / 100
        total = productTotal * percentFactor + shippingCosts
    }

    return total;
}

function getItemPrice(product) {
    const {prices = {}} = product;

    if (prices && typeof prices.special_offer !== 'undefined') {
        return prices.special_offer;
    } else if (prices && typeof prices.default !== 'undefined') {
        return prices.default;
    }

    return 'k.A.';
}

function toCurrency(euroCent) {
    if (isNaN(parseInt(euroCent))) {
        return 'k.A.';
    }

    const converted = parseInt(euroCent);

    if (converted === 0) {
        return '0,00';
    }

    return (converted / 100)
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export default {
    getTotalPrice, getItemPrice, toCurrency
};
