import React from 'react';
import CompanyLogo from '../../CompanyLogo';
import IconButton from '../../IconButton';
import Icon from '../../Icon';
import BrandLogo from '../../../elements/BrandLogo';
import {isScooter} from '../../VehicleBox/helper';

const CSS_CLASS = 'header--mobile';
const BRAND_MENU_CSS = CSS_CLASS + '--brand-menu';
const MAIN_MENU_CSS = CSS_CLASS + '--menu';

class MobileHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMainMenu: false,
            showBrandMenu: false
        };
    }

    handleMainMenuToggle() {
        this.setState(state => ({
            ...state,
            showMainMenu: !state.showMainMenu,
            showBrandMenu: false
        }));
    }

    handleBrandmenuOpening() {
        this.setState({
            showMainMenu: false,
            showBrandMenu: true
        });
    }

    handleBrandmenuClosing() {
        this.setState({
            showMainMenu: false,
            showBrandMenu: false
        });
    }

    render() {
        const {showMainMenu, showBrandMenu} = this.state;
        const classes = [CSS_CLASS];

        if (showMainMenu) {
            classes.push('full-height');
        }

        return (
            <React.Fragment>
                <div className={classes.join(' ')}>
                    {this.renderBrandMenu()}

                    <div className={CSS_CLASS + '--layout-helper'}>
                        <CompanyLogo style={{maxWidth: '90px', width: '100%'}}/>

                        <div className={CSS_CLASS + '--layout-helper--column'}>
                            <button className={BRAND_MENU_CSS + '--opener'}
                                    onClick={this.handleBrandmenuOpening.bind(this)}>MARKENAUSWAHL
                            </button>

                            <IconButton
                                className={MAIN_MENU_CSS + '--button'}
                                variant={showMainMenu ? 'close' : 'menu'}
                                onClick={this.handleMainMenuToggle.bind(this)}
                                color="white"/>
                        </div>
                    </div>

                    {
                        this.props.isSubPage !== true
                            ? this.renderMainMenu()
                            : this.renderSubMenu()
                    }
                </div>
                {this.renderFixedPositionHelper()}
            </React.Fragment>
        );
    }

    renderBrandMenu() {
        const {brands = []} = this.props;

        const classes = [BRAND_MENU_CSS];
        if (this.state.showBrandMenu !== true) {
            classes.push('closed');
        }

        return (
            <div className={classes.join(' ')} style={{zIndex: Number.MAX_SAFE_INTEGER}}>
                <div className={BRAND_MENU_CSS + '--title'}>
                    <span>WÄHLEN SIE EINE MARKE</span>
                    <IconButton
                        className={BRAND_MENU_CSS + '--close'}
                        variant="close"
                        onClick={this.handleBrandmenuClosing.bind(this)}
                        color="white"/>
                </div>
                <div className={BRAND_MENU_CSS + '--link--container'}>
                    {
                        brands.map(brand => this.renderBrandLink(brand))
                    }
                </div>
            </div>
        );
    }

    renderBrandLink(brand) {
        const vehicleType = isScooter(brand.label) ? 'zweirad' : 'neuwagen';
        const brandId = brand._id
            .replace(/^fiat$/, 'fiat_abarth')
            .replace('vespa', 'vespa_plain');

        return (
            <a
                key={'brand-link-' + brand._id}
                href={`/${vehicleType}/${brand._id.replace('_', '-')}/`}
                className={BRAND_MENU_CSS + '--link'}
                title={brand.label}
                style={{height: '4rem'}}>
                <BrandLogo
                    brandId={brandId}
                    color="#FFFFFF"
                    style={{height: '100%'}}
                    alt={brand.label}/>
            </a>
        );
    }

    renderMainMenu() {
        const {menuItems = []} = this.props;

        const classes = [MAIN_MENU_CSS];
        if (this.state.showMainMenu !== true) {
            classes.push('closed');
        }

        if (this.activeItemHasSubNavi()) {
            return this.renderSubMenu(true);
        }

        return (
            <nav className={classes.join(' ')}>
                {
                    menuItems.map((item, idx) => {
                        const classes = [MAIN_MENU_CSS + '--link', 'main'];
                        const hasSubmenuItems = item.subItems && item.subItems.length > 0;

                        if (item.active === true) {
                            classes.push('active');
                        }
                        return (
                            <a
                                key={`menu-item-${idx}`}
                                className={classes.join(' ')}
                                href={item.href}
                                target={item.target}>
                                <span className={MAIN_MENU_CSS + '--link--label'}>{item.label}</span>
                                {hasSubmenuItems ? this.renderChevronIcon('right') : this.renderIconPlaceholder()}
                            </a>
                        )
                    })
                }
            </nav>
        );
    }

    renderSubMenu(forFirstLevelItem = false) {
        const {menuItems = []} = this.props;
        const submenuItems = menuItems
            .filter(item => {
                if (item.active !== true || !item.subItems || item.subItems.length === 0) {
                    return false;
                }
                if (forFirstLevelItem === true && item.active === true) {
                    return true;
                }
                for (let i = 0; i < item.subItems.length; i++) {
                    if (item.subItems[i].active === true) {
                        return true;
                    }
                }
                return false;
            })
            .reduce((subItems, item) => {
                const parentItem = {
                    href: item.href,
                    label: item.label,
                    isParent: true
                };

                if (forFirstLevelItem === true) {
                    parentItem.href = '/';
                    parentItem.label = 'Start';
                    parentItem.active = true;
                }

                subItems.push(parentItem);
                item.subItems.forEach(subItem => subItems.push(subItem));
                return subItems;
            }, []);

        const classes = [MAIN_MENU_CSS, 'sub'];
        if (this.state.showMainMenu !== true) {
            classes.push('closed');
        }

        return (
            <nav className={classes.join(' ')}>
                {
                    submenuItems.map((item, idx) => {
                        const classes = [MAIN_MENU_CSS + '--link'];
                        const isParent = item.isParent;

                        if (item.active === true) {
                            classes.push('active');
                        }
                        return (
                            <a
                                key={`menu-item-${idx}`}
                                className={classes.join(' ')}
                                href={item.href}>
                                {isParent ? this.renderChevronIcon('left') : this.renderIconPlaceholder()}
                                <span className={MAIN_MENU_CSS + '--link--label'}>{item.label}</span>
                            </a>
                        )
                    })
                }
            </nav>
        );
    }

    renderFixedPositionHelper() {
        return <div className={CSS_CLASS + ' fixed-positon-helper'}
                    style={{height: '5rem', position: 'relative'}}></div>
    }

    renderChevronIcon(direction) {
        return <Icon
            variant={`chevron_${direction}`}
            color="white"
            style={this.getIconStyles()}/>
    }

    renderIconPlaceholder() {
        return <div className="icon" style={this.getIconStyles()}></div>
    }

    getIconStyles() {
        return {
            display: 'block',
            width: '1rem',
            height: '1.5rem',
            margin: '0 0.875rem'
        };
    }

    activeItemHasSubNavi() {
        const {menuItems = []} = this.props;
        const [activeItem] = menuItems.filter(item => item.active === true);

        if (typeof activeItem !== 'undefined') {
            return activeItem.subItems && activeItem.subItems.length > 0;
        }

        return false;
    }
}

export default MobileHeader;