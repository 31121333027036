import React from 'react';
import KoenigSelect from '../../elements/KoenigSelect';
import KoenigInput from '../../elements/KoenigInput';

import './styles.scss';

class ContactForm extends React.Component {

    handleChange = (key, value) => {
        const { onChange = function () { } } = this.props;
        onChange(key, value);
    }

    render() {
        const personDataInputs = [
            ['firstname', 'Vorname', true],
            ['lastname', 'Nachname', true],
            ['address', 'Straße und Hausnummer', true],
            ['zip_code', 'PLZ', true],
            ['location', 'Ort', true],
            ['email', 'E-Mail-Adresse', true],
            ['phone', 'Telefonnummer', true]
        ];

        return (
            <div className="contact-form-fields">
                <div className="cnt-salutation">
                    <KoenigSelect
                        label="Anrede"
                        options={['Herr', 'Frau']}
                        value={this.props.salutation ? this.props.salutation : 'Bitte wählen'}
                        onChange={this.handleChange.bind(this, 'salutation')}
                        isRequired />
                </div>
                {
                    personDataInputs.map(([id, labelText, isRequired]) =>
                        <div key={'contect-form-' + id} className={'cnt-' + id}>
                            <KoenigInput
                                label={labelText}
                                value={this.props[id]}
                                onChange={this.handleChange.bind(this, id)}
                                isEmailField={id === 'email'}
                                isRequired={isRequired === true} />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default ContactForm;