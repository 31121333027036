const mapToAlphabeticLetter = (branches = []) => {
    let jumpPoints = {
        A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [],
        N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: []
    };

    branches.forEach((branch) => {
        if (typeof (branch._id) !== 'undefined' && typeof (branch.name) !== 'undefined') {
            const firstLetter = branch.name.substring(0, 1).toUpperCase();
            jumpPoints[firstLetter].push(branch._id);
        }
    });

    return jumpPoints;
}

export default {
    mapToAlphabeticLetter
};