import React from 'react';
import CommonPage from '../../CommonPage';
import Helmet from 'react-helmet';
import Breadcrumb from '../../../components/Breadcrumb';
import API from '../../../helper/api.helper';
import BrandNavigation from '../components/BrandNavigation';
import JumpNavigation from '../components/JumpNavigation';
import BranchListing from '../components/BranchListing';
import BrandlistHelper from '../helper/brandlist.helper';
import JumpNavigationHelper from '../helper/jumpnavigation.helper';
import Woosmap from '../components/Woosmap';
import {BRAND_REG_EXP} from '../../../helper/constant.helper';

const mainCssClass = 'page-brand-branches';

class MarkeStandortePage extends React.Component {

    constructor(props) {
        super(props);
        let data, branches, brands = [];
        let brand = {};


        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            /* istanbul ignore else */
            if (typeof window !== 'undefined') {
                data = window.__initialData__;
                delete window.__initialData__;
            }
        }

        if (Array.isArray(data) && data.length === 3) {
            branches = getSortedBranches(data[0]);
            brand = data[1];
            brands = data[2];
        }

        this.state = {
            branches,
            brand: brand[0] || {},
            brands: brands
        }
    }

    static requestInitialData(url) {
        const regexp = new RegExp(`\/standorte\/(${BRAND_REG_EXP})\/.*`, 'g');
        const match = regexp.exec(url);
        const brandID = match !== null && match[1] ? match[1] : null;

        if (url.indexOf(`/standorte/${brandID}/`) === 0) {

            let normalizedBrandID = brandID;

            switch (brandID) {
                case "alfa-romeo": normalizedBrandID = "alfa_romeo"; break;
                case "moto-guzzi": normalizedBrandID = "moto_guzzi"; break;
                case "eschwalbe": normalizedBrandID = "eschwalbe"; break;
                case "mg-motor": normalizedBrandID = "mGMotor"; break;
                case "citroen": normalizedBrandID = "citroën"; break;
            }

            const branches = API
                .GET(`/branches?sale_brands=${normalizedBrandID}`)
                .catch(err => {
                    console.error(err);
                    return [];
                });

            const apiBrandId = normalizedBrandID === "citroën" ? "citroen" : normalizedBrandID;

            const brand = API
                .GET(`/brands/${apiBrandId}`)
                .catch(err => {
                    console.error(err);
                    return {};
                });

            const brands = API
                .GET(`/brands`)
                .catch(err => {
                    console.error(err);
                    return [];
                });

            return Promise.all([branches, brand, brands]);
        }
    }

    render() {
        const {brand, brands, branches} = this.state;
        const brandLabel = brand.label ? brand.label : '';
        const crumbsItems = [
            {label: 'Start', path: '/'},
            {label: 'Standorte', path: '/standorte'},
            {label: brandLabel, current_page: true}
        ];

        return (
            <CommonPage className={mainCssClass}>
                <Helmet>
                    <title>{brandLabel} Standorte - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbsItems}/>

                <BrandNavigation
                    brands={BrandlistHelper.getIdList(brands)}
                    className={mainCssClass}
                    active={brand._id}
                />

                <h1>{brandLabel} im Autohaus König</h1>

                <Woosmap brand={brand._id}/>

                <JumpNavigation jumpPoints={JumpNavigationHelper.mapToAlphabeticLetter(branches)}/>

                <BranchListing
                    branches={branches}
                    brandFilter='all_items'
                />

            </CommonPage>
        );
    }
}

export default MarkeStandortePage;

//////////////////////////////////////

function getSortedBranches(initBranches) {
    let branches = [];

    if (Array.isArray(initBranches)) {
        branches = initBranches;

        if (branches.length > 1) {
            branches = branches.sort(function (a, b) {
                return a.name.localeCompare(b.name)
            });
        }
    }

    return branches;
}