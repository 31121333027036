import './styles.scss';

import React from 'react';
import thumbUp from './thumbUp.svg';
import Icon from '../../../../../components/Icon';
import KoenigButton from '../../../../../elements/KoenigButton';
import StarRating from '../StarRating';
import apiHelper from '../../../../../helper/api.helper';
import DecisionOverlay from '../DecisionOverlay';
import NumberFormater from '../../../../../helper/NumberFormater';

const CSS_PREFIX = 'fc-average-rating';

class AverageRating extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total: 1,
            average: 1
        };
    }

    async componentDidMount() {
        const averageData = await apiHelper.GET('/google_reviews/average', true);

        if (averageData && averageData[0]) {
            this.setState({ total: averageData[0].total, average: averageData[0].average });
        }
    }

    render() {
        return (
            <div className={CSS_PREFIX}>
                {this.renderAverageContainer()}
                {this.renderArrow()}
                {this.renderRatingBtn()}
            </div>
        );
    }

    renderAverageContainer() {
        return (
            <div className={CSS_PREFIX + '--average-container'}>
                {this.renderAverage()}
                <hr />
                {this.renderNotes()}
            </div>
        );
    }

    renderAverage() {
        const { average, total } = this.state;
        const cssClass = CSS_PREFIX + '--average-calculation';

        return (
            <div className={CSS_PREFIX + '--average-calculation'}>
                <img src={thumbUp} alt="thumb" />
                <span>{parseFloat(average).toFixed(2)} / 5.00</span>
                {average && <StarRating average={Math.round(average)} />}
                <span className={cssClass + '--sum'}>{NumberFormater.addNumberSeparator(total)} Bewertungen*</span>
                <span className={cssClass + '--note'}>*Basierend auf dem Gesamtwert ausgewählter Google-Bewertungen aller Standorte der Autohaus König Gruppe. (letzte 12 Monate)</span>
            </div>
        );
    }

    renderArrow() {
        return (
            <React.Fragment>
                <div className={CSS_PREFIX + '--arrow mobile'}>
                    <svg viewBox="0 0 500 50">
                        <polygon points="0,0 250,50 500,0" />
                    </svg>
                </div>
                <div className={CSS_PREFIX + '--arrow desktop'}>
                    <svg viewBox="0 0 50 500">
                        <polygon points="0,0 50,250 0,500" />
                    </svg>
                </div>
            </React.Fragment>
        );
    }

    renderRatingBtn() {
        const { overlayVisible } = this.state;

        return (
            <div className={CSS_PREFIX + '--rating-btn'}>
                <img src={thumbUp} alt="thumb" />
                <span>Bitte bewerten Sie uns jetzt:</span>
                <KoenigButton text="Jetzt bewerten" onClick={() => this.setState({ overlayVisible: true })} />
                {overlayVisible && <DecisionOverlay onClose={() => this.setState({ overlayVisible: false })} />}
            </div>
        );
    }

    renderNotes() {
        return (
            <div className={CSS_PREFIX + '--notes'}>
                {this.renderNote('Helfen Sie uns und anderen Besuchern mit Ihrer Erfahrung.')}
                {this.renderNote('Neben einer Bewertung, können Sie auch Anmerkungen oder Kritik loswerden, welches wir intern zu Optimierungszwecken verarbeiten werden.')}
                {this.renderNote('Sehr schnell und einfach können Sie uns, den Service oder Ihren Standort bewerten.')}
            </div>
        );
    }

    renderNote(val) {
        return (
            <span>
                <Icon color="white" variant="chevron_right" />
                <span>{val}</span>
            </span>
        );
    }
}

export default AverageRating;