import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import Breadcrumb from '../../../components/Breadcrumb';
import API from '../../../helper/api.helper';
import KoenigButton from '../../../elements/KoenigButton';
import BrandListing from '../../../components/BrandListing';
import Icon from '../../../components/Icon';
import ReactHtmlParser from 'react-html-parser';
import Overlay from '../../../components/Overlay';
import ContactFormBranch from '../../../components/ContactFormBranch';
import BranchHelper from '../../../helper/branch.helper';
import EmployeeListing from './EmployeeListing';
import OpenTimesBlock from './../components/OpenTimesBlock';

import imgDefaultStandort from '../../../assets/images/standorte/standort-default.jpg';
import defaultMapJpg from '../../../assets/images/standorte/standort_map_default.jpeg';
import defaultMapWebp from '../../../assets/images/standorte/standort_map_default.webp';
import KoenigImage from '../../../components/KoenigImage';
import ContactForm from '../../../components/ContactForm';
import Checkbox from '../../../elements/Checkbox';
import OpenCloseInfo from './components/OpenCloseInfo';

const crumbs = [
    {label: 'Start', path: '/'},
    {label: 'Standorte', path: '/standorte'}
];

const mainCssClass = 'single-branch-page';
const branchCssClass = mainCssClass + '--branch';

class StandortEinzelansicht extends React.Component {
    constructor(props) {
        super(props);
        let branch;
        let appointmenzAllocationMailReciever;

        if (props.staticContext && props.staticContext.initialData) {
            [branch, appointmenzAllocationMailReciever] = props.staticContext.initialData;
        } else {
            [branch, appointmenzAllocationMailReciever] = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            branch,
            appointmenzAllocationMailReciever,
            overlayProbefahrt: false,
            showSuccessful: false,
            showFailure: false,
            salutation: null,
            firstname: null,
            lastname: null,
            address: null,
            zip_code: null,
            location: null,
            email: null,
            phone: null,
            message: null,
            agb: false,
            advertising: false,
            inProgress: false
        };

        this.handleShowSuccessful = this.handleShowSuccessful.bind(this);
        this.handleShowFailure = this.handleShowFailure.bind(this);
    }

    static requestInitialData = reqUrl => {
        const match = /\/standorte\/(.+)\/(.+)/i.exec(reqUrl);
        const branchID = match !== null && match[2] ? match[2] : null;
        let branches = [];
        const appointmenzAllocationMailReciever = process.env.APPOINTMENT_ALLOCATION_MAIL_RECIEVER;

        if (branchID != null) {
            branches = API.GET(`/branches/${branchID}`)
        }

        return Promise
            .all([branches, appointmenzAllocationMailReciever])
            .then(res => {
                if (res && res.length > 0 && res[0].status === 301) {
                    return {
                        status: 301,
                        target: "/standorte/"
                    }
                }
                return res;
            })
            .catch(console.error);
    }

    handleShowSuccessful() {
        this.setState({showSuccessful: true});
    };

    handleShowFailure() {
        this.setState({showFailure: true});
    };

    handleChangeMessage(value) {
        if (typeof value === 'string' && value.length > 0) {
            this.setState({message: value})
        } else {
            this.setState({message: 'Bitte kontaktieren Sie mich'});
        }
    }

    sendInquery(type) {
        this.setState({inProgress: true});

        const inquiry = this.getInquiry(type);
        this.setState({serviceInquiryOverlayVisible: false, saleInquiryOverlayVisible: false});
        API
            .POST('/inquiries', inquiry)
            .then(() => {
                this.setState({showSuccessful: true, inProgress: false});
            })
            .catch(() => {
                this.setState({showFailure: true, inProgress: false});
            });
    }

    getInquiry(type) {
        const {branch, advertising, appointmenzAllocationMailReciever} = this.state;
        let developed_from, receiver;

        switch (type) {
            case 'sale':
                developed_from = 'Kontakt Verkaufsteam ' + branch.name;
                receiver = appointmenzAllocationMailReciever || branch.email_sale;
                break;
            case 'service':
                developed_from = 'Kontakt Serviceteam ' + branch.name;
                receiver = appointmenzAllocationMailReciever || this.getServiceInquiryReceiver(branch);
                break;
        }

        const inquiry = {
            ...this.state,
            developed_from,
            receiver,
            advertising: advertising ? 'ja' : 'nein',
            no_lms: true
        };

        delete inquiry.showSuccessful;
        delete inquiry.showFailure;
        delete inquiry.saleInquiryOverlayVisible;
        delete inquiry.serviceOverlayVisible;
        delete inquiry.serviceInquiryOverlayVisible;
        delete inquiry.overlayProbefahrt;
        delete inquiry.branch;
        delete inquiry.inProgress;
        delete inquiry.appointmenzAllocationMailReciever;

        return inquiry;
    }

    getServiceInquiryReceiver(branch) {
        const appointmentReceivers = ['spandau-2', 'spandau-3', 'schoeneberg', 'hoppegarten', 'teltow-2'];

        if (appointmentReceivers.indexOf(branch._id) > -1) {
            return 'terminvergabe@autohaus-koenig.de';
        }

        return branch.email_service;
    }

    render = () => {
        const {
            branch, overlayProbefahrt, showSuccessful, showFailure, saleInquiryOverlayVisible,
            serviceOverlayVisible, serviceInquiryOverlayVisible
        } = this.state;

        const crumbItems = [...crumbs];
        const {name, google_route, sale_brands, service_tags, telephone, branch_image} = branch;
        const {street, zip_code, location, fax, opening_times, description, employees} = branch;
        crumbItems.push({
            path: '/standorte/' + BranchHelper.normalize(branch.federal_state),
            label: branch.federal_state
        });
        crumbItems.push({label: branch.name, current_page: true});
        const branchName = (branch.official_name) ? branch.name + ' (' + branch.official_name + ')' : branch.name;

        let openingTimes = branch.opening_times;
        if (branch.no_sale) {
            openingTimes = branch.opening_times.service;
        }
        if (!branch.has_workshop) {
            openingTimes = branch.opening_times.sale;
        }

        return (
            <CommonPage className={mainCssClass}>
                <Helmet>
                    <title>Standort {name} - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbItems}/>

                <h1 className={mainCssClass + '--headline'}>Autohaus König in {name}</h1>
                <OpenCloseInfo openingTimes={openingTimes} branchId={branch._id}/>
                <div className={branchCssClass}>
                    <div className={branchCssClass + '--services-buttons'}>
                        {(google_route) ?
                            <KoenigButton text="Route zum Standort" linkTo={google_route} targetBlank/> : null}
                        <KoenigButton
                            text="Probefahrt"
                            onClick={() => this.setState({overlayProbefahrt: true})}
                        />
                        <KoenigButton text="Werkstatttermin" linkTo="/werkstatt/terminbuchung/"/>
                    </div>
                    <div className={branchCssClass + '--data'}>
                        <div className={branchCssClass + '--data--box'}>
                            <div className={branchCssClass + '--data--box--brands'}>
                                <BrandListing brands={sale_brands}/>
                            </div>
                            <div className={branchCssClass + '--data--box--service-tags'}>
                                {this.renderServiceTags(service_tags)}
                            </div>
                            <div className={branchCssClass + '--data--box--address'}>
                                {this.renderStatusTime(opening_times)}
                                <span className={branchCssClass + '--data--box--address--name'}>{name}</span>
                                {this.renderOfficialName()}
                                {street}, {zip_code} {location}<br/>
                                <br/>
                                {(fax) ? 'Telefax: ' + fax : null}
                            </div>
                            {this.renderOpenTimesRow(opening_times, branch.has_workshop, telephone, branch.no_sale, branch.week_label)}
                        </div>
                    </div>
                    {(branch_image === null) ?
                        <KoenigImage className={branchCssClass + '--image'} fileHash={imgDefaultStandort}
                                     alt={name || ''}/> :
                        <KoenigImage className={branchCssClass + '--image'} fileHash={branch_image} alt={name || ''}/>
                    }
                    {(branch.branch_maps_url === null) ?
                        <div className={branchCssClass + '--map-link'}>
                            <div></div>
                        </div> :
                        (
                            <a className={branchCssClass + '--map-link'} href={branch.branch_maps_url} target="_blank">
                                <picture>
                                    <source srcSet={defaultMapWebp} type="image/webp"/>
                                    <source srcSet={defaultMapJpg} type="image/jpeg"/>
                                    <img src={defaultMapJpg} alt={'Maps'}/>
                                </picture>
                            </a>
                        )
                    }
                </div>
                {(employees) ? <EmployeeListing employees={employees}/> : null}
                {this.renderBranchContent(description, name)}

                {overlayProbefahrt && !showSuccessful && !showFailure ? this.renderOverlayProbefahrt(branchName) : null}
                {showSuccessful && this.renderOverlaySuccessful()}
                {showFailure && this.renderOverlFailure()}
                {saleInquiryOverlayVisible && this.renderSaleInquiryOverlay()}
                {serviceOverlayVisible && this.renderServiceOverlay()}
                {serviceInquiryOverlayVisible && this.renderServiceInquiryOverlay()}
            </CommonPage>
        );
    }

    renderServiceTags(serviceTags) {
        if (serviceTags) {

            return serviceTags.map(
                (tag, idx) => (
                    <span key={'service-tag-' + idx}>{tag}</span>
                )
            )
        }

        return null;
    }

    renderTelephone(telephone) {
        if (telephone) {
            return (
                <div className="grey-box">
                    Verkaufsberatung:<br/><br/>
                    <a className={branchCssClass + '--data--box--telephone'}
                       href={'tel:' + telephone.replace(/[\/-]/g, '').replace(/\s/g, '')}>
                        <Icon variant="phone" color="#3b3b3b"/>
                        <span>{telephone}</span>
                    </a><br/>
                </div>
            )
        }

        return null;
    }

    renderStatusTime() {
        return (
            <span className={branchCssClass + '--data--box--address--time'}></span>
        );
    };

    renderOpenTimesRow(openingTimes, hasWorkshop, telephone, noSale = false, weekLabel) {
        if (openingTimes) {
            return (
                <div className={branchCssClass + '--data--box--opening'}>
                    <div className={branchCssClass + '--data--box--opening--left'}>
                        {
                            openingTimes.sale && !noSale
                                ? this.renderOpenTimesCol(openingTimes.sale, 'Verkauf:', 'left', weekLabel)
                                : this.renderHasNoSale('left')
                        }
                        {this.renderTelephone(telephone)}
                        {telephone && this.renderContactButton('sale')}
                    </div>
                    <div className={branchCssClass + '--data--box--opening--right'}>
                        {(hasWorkshop && openingTimes.service)
                            ? this.renderOpenTimesCol(openingTimes.service, 'Service, Teile & Zubehör:', 'right')
                            : this.renderHasNoWorkshop('right')
                        }
                        <div className="grey-box">
                            Werkstatt-Hotline:<br/><span
                            className="title-small">(Mo - Fr: 07-19 Uhr, Sa: 09-18 Uhr)</span>
                            <a className={branchCssClass + '--data--box--telephone-service'} href="tel:08007235251">
                                <Icon variant="phone" color="#3b3b3b"/>
                                <span>0800 723 52 51</span>
                            </a>
                            <span className="phone-hint">(kostenlos aus dem dt. Festnetz)</span>
                        </div>
                        {this.renderContactButton('service')}
                    </div>
                </div>
            );
        }

        return null;
    };

    renderOpenTimesCol(openingTimes, headline, column = '', weekLabel) {
        return (
            <div className={branchCssClass + '--data--box--opening--' + column + '--col'}>
                <span className={branchCssClass + '--data--box--opening--' + column + '--col--title'}>{headline}</span>
                <OpenTimesBlock className={branchCssClass + '--data--box--opening--' + column + '--col--row'}
                                openingTimes={{...openingTimes}} weekLabel={weekLabel}/>
            </div>
        );
    }

    renderHasNoWorkshop(column = '') {
        return (
            <div className={branchCssClass + '--data--box--opening--' + column + '--col'}>
                <span className={branchCssClass + '--data--box--opening--' + column + '--col--title'}>Service, Teile & Zubehör:</span>
                Keine Werkstatt vorhanden.
            </div>
        )
    }

    renderOverlayProbefahrt = (branchName) => (
        <Overlay
            onClose={() => this.setState({overlayProbefahrt: false})}
        >
            <ContactFormBranch
                title="Probefahrt"
                developedFrom="Probefahrt"
                onSuccessful={this.handleShowSuccessful}
                onFailure={this.handleShowFailure}
                branch={branchName}
            />
        </Overlay>
    );

    renderOverlaySuccessful = () => (
        <Overlay className="successful" onClose={() => this.setState({showSuccessful: false})}>
            <h1>Vielen Dank für Ihre Anfrage!</h1>
            <p>
                Schön, dass Sie sich für unsere Probefahrt interessieren.<br/>
                Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
            </p>
            <p>Ihr Autohaus König</p>

            <KoenigButton
                className="btn-back-to-page"
                text="Zurück zum Standort"
                onClick={() => this.setState({
                    showSuccessful: false,
                    overlayProbefahrt: false
                })}
            />
        </Overlay>
    );

    renderOverlFailure = () => (
        <Overlay className="failure">
            <h1>Vielen Dank für Ihre Anfrage!</h1>
            <p>
                Leider ist ein Problem aufgetreten.<br/>
                Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
            </p>
            <p>
                <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
            </p>
            <p>Ihr Autohaus König</p>

            <KoenigButton
                className="btn-back-to-page"
                text="Zurück zur Fahrzeugseite"
                onClick={() => this.setState({
                    showFailure: false,
                    overlayProbefahrt: false
                })}
            />
        </Overlay>
    );

    renderBranchContent(description, name) {
        if (description != null) {
            return (
                <React.Fragment>
                    <h2 className={mainCssClass + '--headline'}>Informationen zum Standort in {name}</h2>
                    <p className={mainCssClass + '--description'}>{ReactHtmlParser(description)}</p>
                </React.Fragment>
            );
        }

        return null;
    }

    renderOfficialName() {
        const {official_name = null} = this.state.branch;

        if (official_name !== null) {
            return (
                <span className={branchCssClass + '--data--box--address--official-name'}>{official_name}</span>
            )
        }
    }

    renderContactButton(type) {
        let label, stateFunc;

        switch (type) {
            case 'sale':
                label = 'Verkauf anschreiben';
                stateFunc = () => this.setState({saleInquiryOverlayVisible: true});
                break;
            case 'service':
                label = 'Service anschreiben';
                stateFunc = () => this.setState({serviceOverlayVisible: true});
                break;
        }

        return (
            <div className={branchCssClass + '--contact-button'} onClick={stateFunc}>{label}</div>
        );
    }

    renderSaleInquiryOverlay() {
        return (
            <Overlay
                className={branchCssClass + '--inquiry-overlay'}
                onClose={() => this.setState({saleInquiryOverlayVisible: false})}
            >
                <p>Schreiben Sie unserer Verkaufsabteilung</p>
                {this.renderForm('sale')}

            </Overlay>
        );
    }

    renderServiceOverlay() {
        return (
            <Overlay
                className={branchCssClass + '--service-overlay'}
                onClose={() => this.setState({serviceOverlayVisible: false})}
            >
                <div>Kontaktaufnahme zu Ihrem Serviceteam</div>
                <div>Möchten Sie bequem und schnell einen Werkstatttermin buchen?</div>
                <div>
                    <a href="/werkstatt/terminbuchung/">Ja, ich möchte einen Termin buchen.</a>
                    <span
                        onClick={() => this.setState({
                            serviceInquiryOverlayVisible: true,
                            serviceOverlayVisible: false
                        })}
                    >
                        Nein, ich will eine Nachricht schreiben.
                    </span>
                </div>
            </Overlay>
        );
    }

    renderServiceInquiryOverlay() {
        return (
            <Overlay
                className={branchCssClass + '--inquiry-overlay'}
                onClose={() => this.setState({serviceInquiryOverlayVisible: false})}
            >
                <p>Schreiben Sie unserem Serviceteam</p>
                {this.renderForm('service')}

            </Overlay>
        );
    }

    renderForm(type) {
        const {inProgress} = this.state;

        return (
            <React.Fragment>
                <ContactForm onChange={(key, val) => this.setState({[key]: val})}/>
                <div>
                    <label>Ihre Nachricht*</label>
                    <textarea
                        onChange={ev => this.handleChangeMessage(ev.target.value)}/>
                </div>
                <div className="agb">
                    <Checkbox onChange={agb => this.setState({agb})}/>
                    <span>
                        Ich stimme den <a href="/agb/" target="_blank">AGB</a> und der <a href="/datenschutz/"
                                                                                          target="_blank">Datenschutzerklärung</a> der Autohaus Gotthard König GmbH zu und erkläre mich einverstanden, mit der Verarbeitung, Nutzung und der Weiterleitung meiner Daten zum Zwecke der Kontaktaufnahme.*
                    </span>
                </div>
                <div className="advertising">
                    <Checkbox onChange={advertising => this.setState({advertising})}/>
                    <span>Ja, ich möchte sehr gern kostenlos Informationen zu neuen Aktionsangeboten erhalten.</span>
                </div>
                <KoenigButton
                    className="submit"
                    text="Anfrage jetzt absenden"
                    onClick={this.sendInquery.bind(this, type)}
                    disabled={!this.isValid() || inProgress}/>
                <span>* Pflichtfelder</span>
            </React.Fragment>
        );
    }

    isValid() {
        const allInputsNotNull = [
            'salutation', 'firstname', 'lastname',
            'address', 'zip_code', 'location',
            'email', 'phone', 'message'
        ].reduce(
            (result, key) => {
                if (result && key === 'email') {
                    return this.isEmailValid(this.state[key]);
                }

                return result && this.state[key] !== null && this.state[key].length > 0
            },
            true
        );

        return allInputsNotNull && this.state.agb;
    }

    isEmailValid(email) {
        return email && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    renderHasNoSale(column) {
        return (
            <div className={branchCssClass + '--data--box--opening--' + column + '--col'}>
                <span className={branchCssClass + '--data--box--opening--' + column + '--col--title'}>Verkauf:</span>
                Kein Verkauf vorhanden.
            </div>
        )
    }
}

export default StandortEinzelansicht;