import React from 'react';
import Icon from '../../../components/Icon';

class EmployeeListing extends React.Component {

    render = () => {
        const { employees = [] } = this.props;
        const branchManagement = employees
            .filter(employee => employee.position === 'Filialleitung' || employee.position === 'Verkaufsleiter/-in')
            .reduce((res, emp) => {
                if (emp.position === 'Filialleitung') {
                    res.head.push(emp);
                } else {
                    res.saleHead.push(emp);
                }

                return res;
            }, {head: [], saleHead: []});
        const groupedEmployees = this.getGroupedByDepartment(employees);

        if (groupedEmployees != null) {
            return (
                <div className="single-branch-page--departments">
                    {this.renderDepartment('Neuwagenverkauf', groupedEmployees['Neuwagenverkauf'])}
                    {this.renderDepartment('Verkauf', groupedEmployees['Verkauf'])}
                    {this.renderDepartment('Gebrauchtwagenverkauf', groupedEmployees['Gebrauchtwagenverkauf'])}
                    {this.renderDepartment('Zweirad-Verkauf', groupedEmployees['Zweirad-Verkauf'])}
                    {this.renderDepartment('Gewerbekundenverkauf', groupedEmployees['Gewerbekundenverkaeufer'])}
                    {this.renderDepartment('Auslieferungszentrum', groupedEmployees['Auslieferungszentrum'])}
                    {this.renderDepartment('PKW / Transporter Service & Werkstatt', groupedEmployees['PKW_TransporterService_Werkstatt'])}
                    {this.renderDepartment('Leasingrücknahme', groupedEmployees['Leasingruecknahme'])}
                    {this.renderDepartment('Zweirad Service & Werkstatt', groupedEmployees['ZweiradService_Werkstatt'])}
                    {this.renderDepartment('Zweirad-Leasingrücknahme', groupedEmployees['Zweirad-Leasingruecknahme'])}
                    {this.renderDepartment('Teile & Zubehör', groupedEmployees['Teile_Zubehoer'])}
                    {this.renderDepartment('Karosserie & Lack', groupedEmployees['Karosserie_Lack'])}
                    {this.renderDepartment('Verkaufsleitung', branchManagement.saleHead)}
                    {this.renderDepartment('Filialleitung', branchManagement.head)}
                </div>
            );
        }

        return null;
    }

    getGroupedByDepartment(employees) {
        if (employees.length > 0) {
            const groupedByDepartment = employees.reduce((group, employee) => {
                const { department = '' } = employee;
                const normalizedDepartment = department
                    .replace(/\s/ig, '')
                    .replace(/[&\/]/g, '_')
                    .replace(/ü/, 'ue')
                    .replace(/ä/, 'ae')
                    .replace(/ö/, 'oe');

                group[normalizedDepartment] = []
                    .concat(group[normalizedDepartment], employee)
                    .filter(g => typeof g !== 'undefined' && g !== null
                        && g.position !== 'Filialleitung' && g.position !== 'Verkaufsleiter/-in'
                    );
                return group;
            }, {});

            return groupedByDepartment;
        }

        return null;
    }

    renderDepartment(department, employees) {
        if (employees && employees.length > 0) {
            const className = 'single-branch-page--employees';
            const modulo = employees.length % 2;
            return (
                <React.Fragment>
                    <span className="single-branch-page--departments--department">
                        {department}
                        <Icon variant="black_chevron_down" className="black-chevron-down" />
                    </span>
                    <div className={className}>
                        {employees.map(employee => (
                                <div className={className + '--cnt'} key={'employee' + employee.uid}>
                                    {this.renderEmployee(employee)}
                                </div>
                            )
                        )}
                        {(modulo == 1) ? this.renderEmptyBox(className) : null}
                    </div>
                </React.Fragment>
            )
        }

        return null;
    }

    renderEmployee(employee) {
        const className = 'single-branch-page--employees--cnt--employee';
        return (
            <div className={className}>
                <div className={className + '--name-block'}>
                    <span>{employee.first_name} {employee.last_name}</span>
                    Sprachen: deutsch{this.getLanguageString(employee.languages)}
                </div>
                {(employee.telephone) ? this.renderTelephone(className, employee.telephone) : null}
            </div>
        );
    }

    renderEmptyBox(className) {
        return (
            <div className={className + '--cnt'}>
                <div className={className + '--cnt--employee--empty'}>&nbsp;<br />&nbsp;</div>
            </div>
        );
    }

    renderTelephone(className, telephone) {
        return (
            <a className={className + '--telephone'} href={'tel:' + telephone.replace(/[\/-]/g, '').replace(/\s/g, '')}>
                <Icon variant="phone" color="#3b3b3b" />
                <span>{telephone}</span>
            </a>
        );
    }

    getLanguageString(languages) {
        if (languages && languages.length > 0) {
            return ', ' + languages.join(', ');
        }

        return null;
    }
}

export default EmployeeListing;