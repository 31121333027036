import React from 'react';

class OpenTimesBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            className: this.props.className,
            openingTimes: this.props.openingTimes,
            weekLabel: this.props.weekLabel ?? "Mo. - Fr.: "
        }
    }

    render() {
        const {openingTimes, weekLabel} = this.state;

        return (
            <React.Fragment>
                {this.renderOpentimesRow(openingTimes.week, weekLabel)}
                {this.renderOpentimesRow(openingTimes.saturday, 'Samstag: ')}
                {this.renderOpentimesRow(openingTimes.sunday, 'Sonntag: ')}
            </React.Fragment>
        );
    }


    renderOpentimesRow(times, label) {
        let time = null;
        const hourOfOpening = times.open[0];
        const hourOfClosing = times.close[0];
        const minuteOfOpening = times.open[1];
        const minuteOfClosing = times.close[1];


        if (hourOfOpening == 0 && hourOfClosing == 0) {
            time = 'geschlossen';
        } else {
            const openHour = (hourOfOpening < 10) ? '0' + hourOfOpening : hourOfOpening;
            const openMinute = (minuteOfOpening < 10) ? '0' + minuteOfOpening : minuteOfOpening;
            const closeHour = (hourOfClosing < 10) ? '0' + hourOfClosing : hourOfClosing;
            const closeMinute = (minuteOfClosing < 10) ? '0' + minuteOfClosing : minuteOfClosing;

            time = openHour + ':' + openMinute + ' - ' + closeHour + ':' + closeMinute + ' Uhr';
        }

        return (
            <div className={this.state.className}>
                <span>{label}</span>
                <span>{time}</span>
            </div>
        );
    }
}

export default OpenTimesBlock;