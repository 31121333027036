import "./styles.scss";

import React from 'react';
import CommonPage from '../../CommonPage';
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/Breadcrumb";
import Icon from "../../../components/Icon";
import KoenigButton from "../../../elements/KoenigButton";
import NavigationHelper from "../../../helper/navigation.helper";

const CSS_CLASS = 'simple-shop--failure-payment';

class FehlgeschlagenPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    render() {
        const { shopUrl } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Fehlgeschlagene Bestellung', current_page: true }
        ];

        return (
            <CommonPage className="page-simple-shop-shop-fehlgeschlagen-zahlung">
                <Helmet>
                    <title>Checkout - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>Ihre Bestellung ist fehlgeschlagen.</h1>

                <div className={CSS_CLASS}>
                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="close"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Bestellung fehlgeschlagen</b>
                    </div>

                    <p className={CSS_CLASS + '--reason'}>Die Bestellung konnte nicht erfolgreich verarbeitet werden.</p>

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            className={CSS_CLASS + '--button--back-to-shop'}
                            text="Zurück zum Shop"
                            onClick={NavigationHelper.linkTo.bind(null, shopUrl, this.state)} />
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default FehlgeschlagenPage;
