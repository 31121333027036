import { useEffect } from 'react';

function AutoUncleMain() {
    useEffect(() => {
        window.AuEmbed = window.AuEmbed || {
            pushedUrls: [], pushUrl: function (url) {AuEmbed.pushedUrls.push(url)}
        };
        window.AuEmbed.config = {
            id: "wqj7m7hvIs"
        };

        const d = {
            device: window.matchMedia("only screen and (max-width: 760px)").matches ? "mobile" : "desktop",
            pageType: AuEmbed.config.pageType,
            url: AuEmbed.config.previewUrl || document.location.href
        }

        const script = document.createElement('script');
        script.async = !0;
        script.src = "https://www.autouncle.de/de/customers/" + encodeURIComponent(AuEmbed.config.id) + "/website_script.js?d=" + encodeURIComponent(JSON.stringify(d));
        script.id = "au-cweb-script";
        script.onload = () => window.dispatchEvent(new Event('autouncle_loaded'));

        document.head.appendChild(script);
    });

    return null;
}

export default AutoUncleMain;