import { useEffect } from 'react';
import TagManager from 'react-gtm-module'

function GoogleTagManager() {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-P3G3R9Z'
        };

        TagManager.initialize(tagManagerArgs);
    });

    return null;
}

export default GoogleTagManager;