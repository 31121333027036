import React from 'react';
import { BWS_STORE_MAP } from '../.data/bwsStoreMap';
import apiHelper from '../../../helper/api.helper';
import config from '../.data/woosmap_config.json'

class Woosmap extends React.Component {
    async componentDidMount() {
        const { location } = this.props;
        const storeId = BWS_STORE_MAP[location.id];
        let branch;

        try {
            branch = await apiHelper.GET('/branches/' + storeId);
        } catch (e) {
            console.log(e);
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'woosmap_script';
        script.src = 'https://sdk.woosmap.com/map/map.js?key=' + window.WOOSMAP_KEY;
        script.onload = () => this.initMap(branch);

        document.head.appendChild(script);
    }

    initMap(branch) {
        const center = branch && branch.degree_of_longitude
            ? { lat: parseFloat(branch.degree_of_longitude), lng: parseFloat(branch.degree_of_latitude) }
            : { lat: 52.520008, lng: 13.404954 };
        const map = new woosmap.map.Map(document.getElementById('woosmap-map'), {
            center,
            zoom: 13
        });
        const storesOverlay = new woosmap.map.StoresOverlay(config);
        storesOverlay.setMap(map);
        woosmap.map.event.addListener(map, 'store_selected', (store) => {
            document.getElementById('store-info').innerHTML =
                `<div>` +
                `<span><b>${store.properties.name}</b></span><br/>` +
                `<span>${store.properties.address.lines[0]}</span><br/>` +
                `<span>${store.properties.address.zipcode} ${store.properties.address.city}</span><br/>`+
                `</div>`
        });
    }

    render() {
        return (
            <div className='fc-woosmap'>
                <div id="store-info"></div>
                <div id="woosmap-map"></div>
            </div>
        );
    }
}

export default Woosmap;
