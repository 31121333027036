import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import API from '../../helper/api.helper';
import SectionHeadline from '../../components/SectionHeadline';
import VehicleBox from '../../components/VehicleBox';
import pageData from './page_data.json';
import SimpleContent from '../../components/SimpleContent';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Ohne Anzahlung', current_page: true }
]
const CSS_CLASS = 'page-ohne-anzahlung';

class OhneAnzahlungPage extends React.Component {
    constructor(props) {
        super(props);

        let data;

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            if (typeof window !== 'undefined') {
                data = window.__initialData__;
                delete window.__initialData__;
            }
        }

        this.state = {
            data: {
                ...pageData,
                ...props.pageData,
                vehicles: data
            }
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/ohne-anzahlung') === 0) {
            return API
                .GET('/vehicles?filtered=1&sortby=preis&supply_costs=0&prepayment=0')
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });
        }
    }

    render() {
        const data = this.state.data;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Privatangebote OHNE Anzahlung und Bereitstellungskosten</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>{data.headline}</h1>

                {this.renderTeaser()}

                <SimpleContent content={data.seo_content} />

                {this.renderVehicles()}

            </CommonPage>
        );
    }

    renderTeaser() {
        const { teaser_src } = this.state.data;

        return (
            <div className={CSS_CLASS + '--header-img'}>
                {teaser_src && teaser_src.mobile
                    ? this.renderImage(teaser_src.mobile, CSS_CLASS + '--header-img--mobile')
                    : null}
                {teaser_src && teaser_src.desktop
                    ? this.renderImage(teaser_src.desktop, CSS_CLASS + '--header-img--desktop')
                    : null}
            </div>
        );
    }

    renderImage(src, cssClass) {
        const { headline } = this.state.data;

        return <img className={cssClass} src={src} alt={headline} />;
    }


    renderVehicles() {
        const { vehicles = {} } = this.state.data;

        return (
            <React.Fragment>
                <SectionHeadline text="Unsere Angebote im Überblick" />
                <div className={CSS_CLASS + '--vehicles'}>
                    {
                        vehicles
                            .private
                            .map((vehicle, idx) =>
                                <div key={"box-wrapper-" + idx + "-" + vehicle._id} className={CSS_CLASS + '--vehicles--box-wrapper'}>
                                    <VehicleBox vehicle={vehicle} />
                                </div>
                            )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default OhneAnzahlungPage;