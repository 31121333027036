import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../CommonPage';
import Breadcrumb from '../../components/Breadcrumb';
import ApiHelper from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';
import SimpleContent from '../../components/SimpleContent';
import PageTile from '../../components/PageTile';
import ReactHtmlParser from 'react-html-parser';

const CSS_CLASS = "page-onlineshop";
const CSS_CLASS_CONTENT = "page-onlineshop--content";

class OnlineshopPage extends React.Component {
    constructor(props) {
        super(props);

        let postData, pageData, tiles = [];

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [pageData, tiles = []] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [pageData, tiles = []] = window.__initialData__;
            }
        }

        this.state = {
            pageData: pageData,
            tiles: tiles
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/onlineshop') === 0) {
            const pageData = ApiHelper.GET('/pages/onlineshop');
            const tiles = ApiHelper.GET('/page_tiles?page=onlineshop');

            return Promise.all([pageData, tiles])
                .catch(err => console.error(err));
        }
    }

    render() {
        const pageData = this.state.pageData;

        if (!pageData || pageData.length === 0) {
            return this.renderNotFoundPage();
        } else {
            return this.renderPage();
        }
    }

    renderNotFoundPage() {
        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <p>Keine Daten gefunden.</p>

            </CommonPage>
        );
    }

    renderPage() {
        const { pageData } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Onlineshop', current_page: true },
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{pageData.title}</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>
                    {pageData.headline}
                </h1>

                <div className="cnt-page-tiles">
                    {
                        this.state.tiles.map(
                            (tile, idx) => <PageTile key={idx} {...tile} />
                        )
                    }
                </div>

                <SimpleContent content={pageData.seo_content} />

            </CommonPage>
        );
    }
}

export default OnlineshopPage;