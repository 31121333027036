import './styles/footer.scss';

import React from 'react';
// import CallbackSection from './components/CallbackSection';
import AccordionSection from './components/AccordionSection';
import NavigationSection from './components/NavigationSection';
import Copyright from './components/Copyright';

export default ({ style }) => {
    return (
        <footer id="page_footer" style={style}>
            {/* <CallbackSection /> */}
            <AccordionSection />
            <NavigationSection />
            <Copyright />
        </footer>
    );
};