import React from 'react';
import PropTypes from 'prop-types';


const Pagination = (props) => {
    const [activePage, setPage] = React.useState(props.currentPage);
    const [begin, setBegin] = React.useState();

    React.useEffect(() => {
       calculateBegin();
    }, [])

    const calculateBegin = () => {
        if (activePage < 2) {
            setBegin(activePage - 1);
        } else {
            setBegin(activePage - 3);
        } 
    }

    const onChangePage = (page) => {
        setPage(page);
        props.onSelectPage(page);
    }


    return ( 
        <div className="pagination">
            {activePage > 1 && <button className={`pagination__btn prev`} onClick={() => onChangePage(activePage - 1)} >{'<'}</button>}
            {activePage > 3 && (
                <React.Fragment>
                    <button className="pagination__btn first" onClick={() => onChangePage(props.pages[0])} >{1}</button>
                    <div className="pagination__placeholder">...</div>
                </React.Fragment>
            )}

            {props.pages.slice(begin, activePage + 2).map((p, i) => (
                <button key={`${activePage}-${i}`} className={`pagination__btn ${activePage === p ? 'active' : ''}`} onClick={() => onChangePage(p)}>
                    {p}
                </button>
            ))}

            

            {activePage <= props.pages.length - 3 && (
                <React.Fragment>
                    <div className="pagination__placeholder">...</div>
                    <button className="pagination__btn last" onClick={() => onChangePage(props.pages.length)} >{props.pages.length}</button>
                </React.Fragment>
            )}
            {activePage < props.pages.length && <button className={`pagination__btn next`} onClick={() => onChangePage(activePage + 1)} >{'>'}</button>}
        </div>
     );
}
 
export default Pagination;

Pagination.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.number),
    currentPage: PropTypes.number,
	onSelectPage: PropTypes.func,
};