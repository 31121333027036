import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../UsedCarIcon';

import './styles/picker.scss';

const Picker = props => {
    const node = React.useRef();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = e => {
        if (!node.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const handleClick = item => {
        setOpen(false);
        props.onChange(item);
    };

    const handleDelete = () => {
        props.onChange({});
    }

    const setTitle = () => {
        if (props.value) {
            return props.options[props.value] || props.value;
        } else if (props.alternativeVersion) {
            return props.title;
        }
        return props.initialLabel
    }

    return (
        <div className='picker' ref={node}>
            <button className={`picker__button ${props.value ? 'active' : ''}`} onClick={() => setOpen(!open)}>
				<span className='picker__title'>
					{setTitle()}
				</span>
                {props.value ?
                    <Icon variant='close' className="close" onClick={() => handleDelete()} size={35} color='#7cb932' />
                    : <Icon variant='chevron_down_small' className={`chevron ${open ? 'active' : ''}`} size={35} />}
            </button>

            <div
                className={`picker__modal ${props.alternativeVersion ? 'alternative-position' : ''} ${open ? 'open' : ''}`}
                style={{
                    maxHeight: open ? '250px' : 0,
                    overflowY: Object.keys(props.options).length > 5 ? 'scroll' : 'hidden',
                }}>
                <div className='picker__modal-title'>{props.title}</div>

                <ul className="picker__list">
                    {
                        Object.keys(props.options)
                            .sort((a, b) => {
                                const valueA = props.options[a];
                                const valueB = props.options[b];

                                if (valueA < valueB) {
                                    return -1;
                                }
                                if (valueA > valueB) {
                                    return 1;
                                }

                                return 0;
                            })
                            .map((key, idx) => {
                                const value = props.options[key];

                                return (
                                    <li
                                        key={`${key}-${idx}`}
                                        className="picker__list-item"
                                        onClick={() => {
                                            handleClick({ key, value });
                                        }}>
                                        {value}
                                    </li>
                                )
                            })
                    }
                </ul>
            </div>
        </div>
    );
};

export default Picker;

Picker.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    initialLabel: PropTypes.string,
    alternativeVersion: PropTypes.bool,
};
