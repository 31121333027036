import './styles.scss';

import React from 'react';
import KoenigImage from '../../../KoenigImage';
import KoenigButton from '../../../../elements/KoenigButton';
import CurrencyHelper from '../../../../helper/Currency';
import Overlay from '../../../Overlay';

const CSS_MAIN_CLASS = 'product-box';
const CSS_PRICE_SECTION_CLASS = CSS_MAIN_CLASS + '--details--price-section';

class ProductBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPreviewOverlay: false
        };
    }

    render() {
        const { _id = '', title = '', description = '', hint = '' } = this.props;
        const { showPreviewOverlay } = this.state;

        return (
            <div className={CSS_MAIN_CLASS} id={'product_box_' + _id}>
                <div className={CSS_MAIN_CLASS + '--header'}>
                    <span className={CSS_MAIN_CLASS + '--header--title'}>{title}</span>
                    <span className={CSS_MAIN_CLASS + '--header--description'}>{description}</span>
                </div>
                {this.renderImageSection()}
                <div className={CSS_MAIN_CLASS + '--details'}>
                    <div className={CSS_MAIN_CLASS + '--details--hint'}>
                        {hint}
                    </div>
                    {this.renderPriceSection()}
                </div>
                {this.renderButton()}

                {showPreviewOverlay ? this.renderOverlay() : null}
            </div>
        );
    }

    renderImageSection() {
        const { images = [] } = this.props;

        if (images.length > 0) {
            const { src = '', alt = '' } = images[0];

            return (
                <div className={CSS_MAIN_CLASS + '--image'} onClick={() => this.setState({ showPreviewOverlay: true })}>
                    <KoenigImage fileHash={src} title={alt} alt={alt} />
                    <span className={CSS_MAIN_CLASS + '--image--foot-note'}>
                        Bild zeigt Beispielabbildung des Produktes
                </span>
                </div>
            );
        } else {
            return (
                <div className={CSS_MAIN_CLASS + '--image'}></div>
            );
        }
    }

    renderPriceSection() {
        const { prices = null } = this.props;

        if (prices !== null) {
            const defaultPrice = parseInt(prices.default) > 0
                ? CurrencyHelper.toCurrency(prices.default, true)
                : 'k.a.';

            const specialOfferClass = parseInt(prices.special_offer) > 0
                ? 'has-special-offer'
                : null;

            return (
                <div className={CSS_PRICE_SECTION_CLASS}>
                    <span className={CSS_PRICE_SECTION_CLASS + '--price ' + specialOfferClass}>{defaultPrice}</span>
                    <span className={CSS_PRICE_SECTION_CLASS + '--currency ' + specialOfferClass}>€</span>
                    {this.renderSpecialOfferPrice(prices.special_offer)}<br />
                    <span className={CSS_PRICE_SECTION_CLASS + '--type'}>Kaufpreis</span><br />
                    <span className={CSS_PRICE_SECTION_CLASS + '--tax'}>inkl. 19% MwSt.</span><br />
                    <span className={CSS_PRICE_SECTION_CLASS + '--delivery'}>kostenloser Versand</span>
                </div>
            );
        }
    }

    renderSpecialOfferPrice(price = null) {
        if (price !== null) {

            return (
                <span className={CSS_PRICE_SECTION_CLASS + '--special-offer'}>
                    <span className={CSS_PRICE_SECTION_CLASS + '--special-offer--price'}>
                        {CurrencyHelper.toCurrency(parseInt(price), true)}
                    </span>
                    <span className={CSS_PRICE_SECTION_CLASS + '--special-offer--currency'}>€</span>
                </span>
            );
        } else {
            return (
                <span className={CSS_PRICE_SECTION_CLASS + '--special-offer'}></span>
            );
        }
    }

    renderButton() {
        const { onClick = () => { }, disabled } = this.props;

        return <KoenigButton
            className={CSS_MAIN_CLASS + "--details--button"}
            text="In den Warenkorb"
            onClick={onClick}
            disabled={disabled} />
    }

    renderOverlay() {
        const { images = [] } = this.props;

        if (images.length > 0) {
            const { src = '', alt = '' } = images[0];

            return (
                <Overlay onClose={() => this.setState({ showPreviewOverlay: false })}>
                    <KoenigImage fileHash={src} alt={alt} />
                </Overlay>
            )
        }
    }
}

export default ProductBox;
