import React from 'react';
import BrandLogo from '../../../../../elements/BrandLogo';

export default ({
    variant = 'all_items',
    text = '',
    isActive = '',
    url = ''
}) => {

    const cssClass = 'navigation-point';
    const classes = [cssClass];

    classes.push(cssClass + '--' + variant);

    if (isActive === true) {
        classes.push('active');
    }

    return (
        <a
            className={classes.join(' ')}
            href={url}
            target="_top"
        >
            <BrandLogo
                brandId={variant}
                className={cssClass + '--icon'}
                style={{ height: '40px', width: '100%' }}
                color={isActive ? 'white' : 'dark'} />
            {text ? <span className={cssClass + '--text'}>{text}</span> : null}
        </a>
    );
};