import "./styles.scss";

import React from 'react';
import CommonPage from '../../../CommonPage';
import InfoBox from '../components/InfoBox';
import Breadcrumb from '../../../../components/Breadcrumb';
import StepIndicator from '../../../../components/StepIndicator';
import RadioButtonGroup from '../../../../elements/RadioButtonGroup';
import Icon from '../../../../components/Icon';
import Functions, { ISO_DATE_FORMAT } from '../functions';
import Checkbox from '../../../../elements/Checkbox';
import telecashLogo from '../../../../assets/images/telecash-logo.png';
import paymentLogos from '../../../../assets/images/payment-logos_neu.png';
import KoenigButton from '../../../../elements/KoenigButton';
import ApiHelper from "../../../../helper/api.helper";
import { Helmet } from "react-helmet";
import TelecashHelper from "../../../../helper/telecash.helper";
import moment from 'moment';

import "moment/locale/de";
import "moment-timezone";
import "isomorphic-fetch";

const CSS_CLASS = 'wtt--step4';
const CSS_SUMMARY = CSS_CLASS + '--summary';

export const DISCOUNT_ITEM = {
    _id: 9999910,
    title: 'Sofortrabatt',
    prices: {
        default: -1000
    }
};

class Schritt4Page extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        if(postData.branch && postData.branch._id === 2) {
            let shopItems = postData.shop_items.filter(si => si && si._id !== DISCOUNT_ITEM._id);

            const sum = Functions.getTotalPrice(
                shopItems.filter(({ title }) => !/Werkstattersatzwagen/.test(title)),
                postData.kws_member
            )

            if (sum > 0) {
                shopItems = [].concat(shopItems, DISCOUNT_ITEM);

                postData.shop_items = shopItems;
                postData.prepayment = true;
                postData.payment = "prepaid";
            }
        }

        this.state = {
            prepayment: false,
            agb: false,
            blockElements: false,
            ...postData
        };
    }

    handleShopItemRemoving(itemId) {
        const { shop_items, kws_member, prepayment } = this.state;
        let forcePrepaymentDisabling = false;
        let shopItems = shop_items.filter(si => si && si._id !== itemId);

        if (Functions.getTotalPrice(shopItems, kws_member) <= 1000) {
            shopItems = shopItems.filter(si => si && si._id !== DISCOUNT_ITEM._id);
            forcePrepaymentDisabling = true;
        }

        this.setState({
            shop_items: shopItems,
            prepayment: forcePrepaymentDisabling || itemId === DISCOUNT_ITEM._id ? false : prepayment
        });
    }

    handlePrepaymentSelecting(selectedIndex) {
        const { shop_items, sst } = this.state;
        const isPrepaymentSelected = selectedIndex === (!sst ? 1 : 2);
        let possiblePayments = ['cash', 'terminal', 'prepaid'];

        if (!sst) {
            possiblePayments = possiblePayments.filter(p => p.indexOf('terminal') === -1);
        }

        let shopItems = shop_items.filter(si => si && si._id !== DISCOUNT_ITEM._id);

        if (isPrepaymentSelected && this.getSumWithoutWEW(shopItems) > 0) {
            // shopItems = [].concat(shopItems, DISCOUNT_ITEM);
        }

        this.setState({
            payment: possiblePayments[selectedIndex],
            prepayment: isPrepaymentSelected,
            shop_items: shopItems
        });
    }

    getSumWithoutWEW(shopItems = []) {
        const { kws_member } = this.state;

        return Functions.getTotalPrice(
            shopItems.filter(({ title }) => !/Werkstattersatzwagen/.test(title)),
            kws_member
        );
    }

    async handleSubmit() {
        const {
            brand, model, branch,
            shop_items, kws_member
        } = this.state;

        this.setState({
            blockElements: true
        });

        const appointment = { ...this.state };

        appointment.brand = brand._id;
        appointment.model = model.uid;
        appointment.branch = branch._id;
        appointment.total_price = Functions.getTotalPrice(shop_items, kws_member);

        delete appointment.blockElements;


        if (brand._id.indexOf('sonstige') === 0) {
            appointment.model = model.label;
        }

        await ApiHelper
            .POST('/appointments', appointment)
            .then(async savedAppointment => {
                if (this.state.prepayment === true) {
                    const paymentRequest = {
                        salutation: savedAppointment.salutation,
                        firstname: savedAppointment.firstname,
                        lastname: savedAppointment.lastname,
                        address: savedAppointment.address,
                        zip_code: savedAppointment.zip_code,
                        location: savedAppointment.location,
                        email: savedAppointment.email,
                        phone: savedAppointment.phone,
                        oid: savedAppointment._id,
                        total_price: savedAppointment.total_price,
                        response_success: '/werkstatt/terminbuchung/erfolgreich/',
                        response_failed: '/werkstatt/terminbuchung/zahlungsfehler/'
                    };

                    return await ApiHelper
                        .POST('/telecash/payments', paymentRequest)
                        .then(paymentObject => { TelecashHelper.execPaymentRequest(paymentObject) })
                        .catch(() => Functions.linkTo('/werkstatt/terminbuchung/zahlungsfehler/', { oid: savedAppointment._id }))
                }
                Functions.linkTo('/werkstatt/terminbuchung/erfolgreich/', this.state)
            })
            .catch(() => Functions.linkTo('/werkstatt/terminbuchung/fehlgeschlagen/', this.state));
    }

    isValid() {
        const {
            agb, shop_items
        } = this.state;

        if (agb === false) {
            return false;
        }

        if (!shop_items || shop_items.length === 0) {
            return false;
        }

        return true;
    }

    handleKwsSelection(idx) {
        const { shop_items, prepayment } = this.state;
        const hasDiscount = shop_items.filter(({_id}) => _id === DISCOUNT_ITEM._id).length > 0;
        let shopItems = shop_items;

        this.setState({ kws_member: idx === 0 }, () => {
            if (!idx && hasDiscount && this.getSumWithoutWEW(shop_items) === 0) {
                shopItems = shop_items.filter(si => si && si._id !== DISCOUNT_ITEM._id);
            } else if (prepayment && !hasDiscount) {
                shopItems = [].concat(shopItems, DISCOUNT_ITEM);
            }

            this.setState({shop_items: shopItems});
        });
    }

    render() {
        const {
            branch, day, time, kws_member,
            brand, model, plate, vin, mileage, registration,
            salutation, firstname, lastname,
            address, zip_code, location,
            email, phone, blockElements
        } = this.state;

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Werkstatt', path: '/werkstatt/' },
            { label: 'Terminbuchung', current_page: true }
        ];

        const indicatorSteps = [
            [-1, -1, -1, 0],
            ['Ihr Fahrzeug', 'Leistungen', 'Persönliche Daten', 'Prüfen & Bestellen']
        ];

        const brandText = brand && brand.label ? brand.label : '';
        const modelText = model && model.label ? model.label : '';
        const branchText = branch && branch.label ? branch.label : '';
        const addressParts = branch && branch.address_row ? branch.address_row.split(', ') : [];

        let vehicleHeadline = brandText + ' ' + modelText;
        if (brand && brand._id && brand._id.indexOf('sonstige') === 0) {
            vehicleHeadline = modelText;
        }

        return (
            <CommonPage className="page-terminbuchung-schritt4">
                <Helmet>
                    <title>Terminbuchung - Werkstatttermin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>Buchen Sie Ihren Werkstatttermin in nur 4 Schritten</h1>

                <div className={CSS_CLASS}>
                    <StepIndicator steps={indicatorSteps} />

                    <div className={CSS_CLASS + '--intro-text'}>
                        <b>Prüfen Sie nun bitte Ihre Buchung und bestellen Sie verbindlich.</b>
                        <p>In dieser Übersicht finden Sie noch einmal alle Informationen zu Ihrer Buchung. Sollte etwas
                            nicht stimmen, können Sie dies ganz einfach wieder korrigieren. Zum Abschluss dieser Buchung
                            können Sie entscheiden, ob Sie den Gesamtbetrag ganz einfach und bequem sofort zahlen oder
                            vor Ort wenn Sie zu Ihrem Termin die Filiale besuchen.</p>
                    </div>

                    <div className={CSS_CLASS + '--info-box--wrapper'}>
                        <InfoBox
                            label="Ihr Termin"
                            linkTo="/werkstatt/terminbuchung/"
                            className={CSS_CLASS + '--info-box--schedule'}
                            appointment={this.state}
                            headline={branchText}
                            infoItems={[
                                { "Standort": addressParts },
                                { "Datum": moment(day, ISO_DATE_FORMAT).format('DD.MM.YYYY') },
                                { "Uhrzeit": time }
                            ]} />

                        <InfoBox
                            label="Ihr Fahrzeug"
                            linkTo="/werkstatt/terminbuchung/"
                            className={CSS_CLASS + '--info-box--vehicle'}
                            appointment={this.state}
                            headline={vehicleHeadline}
                            infoItems={[
                                { "Kennzeichen": plate },
                                { "Fahrzeug-Identnummer": vin },
                                { "Kilometerstand": mileage },
                                { "Erstzulassung": moment(registration, ISO_DATE_FORMAT).format('DD.MM.YYYY') }
                            ]} />

                        <InfoBox
                            label="Ihre persönlichen Daten"
                            linkTo="/werkstatt/terminbuchung/schritt3/"
                            className={CSS_CLASS + '--info-box--personal-data'}
                            appointment={this.state}
                            headline={`${salutation} ${firstname} ${lastname}`}
                            infoItems={[
                                [
                                    { "Adresse": [address, `${zip_code} ${location}`] }
                                ],
                                [
                                    { "E-Mail": email },
                                    { "Telefon": phone }
                                ]
                            ]} />
                    </div>

                    <div className={CSS_CLASS + '--kws-selection'}>
                        <span>Nutzen Sie bereits unseren König Werkstattservice?</span>
                        <RadioButtonGroup
                            selectedIndex={kws_member === true ? 0 : 1}
                            onChange={idx => this.handleKwsSelection(idx)} />
                    </div>

                    {this.renderSummary()}

                    <div className={CSS_CLASS + '--legal-informations'}>
                        Es gelten die <a href="/agb/">Allgemeinen Geschäftsbedingungen</a>.
                    </div>

                    <div className={CSS_CLASS + '--data-usage'}>
                        <div className={CSS_CLASS + '--data-usage--check'}>
                            <Checkbox onChange={checked => this.setState({ agb: checked })} />
                        </div>
                        <div className={CSS_CLASS + '--data-usage--text'}>
                            <b>Ja</b>, ich bin einverstanden, dass meine Daten zur Vertragsabwicklung und Daten über
                            meine Nutzung der Werkstattterminbuchung gemeinsam mit einer pseudonymen Besucher-ID, die
                            auf Basis ausgelesener Informationen meines Endgerätes generiert wird, zu dem Zweck
                            verarbeitet werden, automatisiert zu prüfen, ob Anhaltspunkte für einen Missbrauch unseres
                            Online-Dienstes bestehen. Weitere Details zu diesem Verfahren finden
                            Sie <a href="/datenschutz/">hier</a>.
                        </div>
                    </div>

                    {this.renderPrepaymentSelection()}

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            text="Zurück zu Schritt 3"
                            className="back-button"
                            onClick={() => Functions.linkTo('/werkstatt/terminbuchung/schritt3/', this.state)} />

                        <KoenigButton
                            text="Jetzt verbindlich buchen"
                            className="submit"
                            onClick={this.handleSubmit.bind(this)}
                            disabled={!this.isValid()} />
                    </div>

                    {
                        blockElements === true
                            ? <div className={CSS_CLASS + '--blocker'}></div>
                            : null
                    }
                </div>

            </CommonPage>
        );
    }

    renderSummary() {
        const { shop_items = [], kws_member, branch } = this.state;

        const totalPrice = Functions.getTotalPrice(shop_items, kws_member);

        return (
            <div className={CSS_SUMMARY}>
                <div className={CSS_SUMMARY + '--row'}>
                    <b className={CSS_SUMMARY + '--label'}>Ausgewählte Leistungen im Überblick</b>
                    <div className={CSS_SUMMARY + '--link'}>
                        <Icon
                            variant="edit"
                            onClick={() => Functions.linkTo('/werkstatt/terminbuchung/schritt2/', this.state)}
                            style={{ display: 'block', width: '1.25rem' }} />
                    </div>
                </div>

                <div className={CSS_SUMMARY + '--listing'}>
                    {
                        shop_items.map((item, idx) => {
                            const { prices } = item;
                            const classes = [CSS_SUMMARY + '--listing--item'];

                            if (kws_member === true && prices && prices.hasOwnProperty('kws')) {
                                classes.push('kws');
                            }

                            return (
                                <div key={`wtt-step4-summary-item-${idx}`} className={classes.join(' ')}>
                                    <b className={CSS_SUMMARY + '--listing--item--title'}>{item.title}</b>
                                    <div className={CSS_SUMMARY + '--listing--item--action'} style={{ maxWidth: '1.25rem' }}>
                                        <Icon
                                            variant="trash"
                                            onClick={this.handleShopItemRemoving.bind(this, item._id)} />
                                    </div>
                                    <div className={CSS_SUMMARY + '--listing--item--price'}>
                                        <span className={CSS_SUMMARY + '--listing--item--price--default'}>{this.getDefaultPrice(item)} €</span>
                                        {this.renderKwsPrice(item)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={CSS_SUMMARY + '--total'}>
                    <div className={CSS_SUMMARY + '--total--column'}>
                        <span className={CSS_SUMMARY + '--total--vat-hint'}>inkl. 19% MwSt.</span>
                        <b className={CSS_SUMMARY + '--total--text'}>Summe</b>
                    </div>

                    <div className={CSS_SUMMARY + '--total--price'}>
                        <b>{Functions.toCurrency(totalPrice)}</b>
                        <b>€</b>
                    </div>
                </div>
            </div>
        );
    }

    renderKwsPrice(shopItem) {
        const { kws_member } = this.state;
        const { prices, special_offer = false } = shopItem;

        if (special_offer === true
            && prices
            && prices.hasOwnProperty('special_offer')
            && prices.default !== prices.special_offer) {
            return null;
        }

        if (kws_member === true && prices && prices.hasOwnProperty('kws')) {
            return <span className={CSS_SUMMARY + '--listing--item--price--kws'}>{Functions.toCurrency(prices.kws)} €</span>;
        }

        return null;
    }

    renderPrepaymentSelection() {
        const { prepayment, shop_items, kws_member, sst, branch } = this.state;
        const classes = [CSS_CLASS + '--prepayment--text'];
        let payments = [
            'Ich zahle vor Ort in der Filiale.',
            'Ich nutze die Expressannahme (ohne Wartezeit) und zahle vor Ort am Terminal oder am Tresen.',
            // `Ich zahle jetzt sofort${this.getSumWithoutWEW(shop_items) > 0 ? ' ' : ''}`
            'Ich zahle jetzt sofort.'
        ];

        if (Functions.getTotalPrice(shop_items, kws_member) <= 1000) {
            return null;
        }

        if (prepayment === true) {
            classes.push('active');
        }

        if (!sst) {
            payments = payments.filter(p => p.indexOf('Terminal') === -1);
        }

        if(branch && branch._id === 2) {
            payments = payments.filter(p => p.indexOf('zahle vor Ort') === -1);
        }

        return (
            <div className={CSS_CLASS + '--prepayment'}>
                <RadioButtonGroup
                    className={CSS_CLASS + '--prepayment--selection'}
                    groupItems={payments}
                    selectedIndex={branch && branch._id === 2 ? 0 : prepayment === true ? (!sst ? 1 : 2) : 0}
                    onChange={this.handlePrepaymentSelecting.bind(this)} />

                <div className={classes.join(' ')}>
                    <p>Mit Abschluss Ihrer Buchung werden Sie zu unseren Payment-Partner Telecash geleitet, wo Sie den
                        fälligen Betrag mit Paypal oder Kreditkarte bezahlen können. Nach der Zahlung
                        werden Sie zurück auf unsere Webseite geleitet.</p>
                    <p>Sollten Sie eine bezahlte Leistung nicht wahrnehmen können, so erhalten sie die gezahlte Summe in Gutscheinform erstattet.</p>
                    <div className={CSS_CLASS + '--prepayment--logos'}>
                        <img src={telecashLogo} style={{ width: '9.375rem' }} />
                        <img src={paymentLogos} />
                    </div>
                </div>
            </div>
        );
    }

    getDefaultPrice(shopItem) {
        const { prices, special_offer = false } = shopItem;

        if (special_offer === true && prices && prices.hasOwnProperty('special_offer')) {
            return Functions.toCurrency(prices.special_offer);
        }

        return Functions.toCurrency(prices.default);
    }
}

export default Schritt4Page;