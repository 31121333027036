import 'isomorphic-fetch';

import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import Breadcrumb from '../../../components/Breadcrumb';
import BranchHelper from '../../../helper/branch.helper';
import BrandFilter from './BrandFilter';
import BranchListing from '../components/BranchListing';
import API from '../../../helper/api.helper';

import {
    uniqueItems,
    normalizePossibleBrands,
    sortBrands
} from "./functions";

const mainCssClass = 'single-federal-state';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Standorte', path: '/standorte' }
];

class BundeslandStandortePage extends React.Component {

    constructor(props) {
        super(props);
        const urlMatch = props.match.params.federal_state;
        let branches = [];

        if (props.staticContext) {
            branches = props.staticContext.initialData.branches;
        } else {
            branches = window.__initialData__.branches;
            delete window.__initialData__.branches;
        }

        branches = branches
            .filter(branch => BranchHelper.normalize(branch.federal_state) === urlMatch);

        const possibleBrands = branches
            .reduce((possibleItems, branch) => possibleItems.concat(branch.sale_brands), [])
            .reduce(normalizePossibleBrands, [])
            .filter(uniqueItems)
            .sort(sortBrands);

        this.state = {
            brandFilter: 'all_items',
            branches, possibleBrands
        };
    }

    static requestInitialData = () => {
        return API
            .GET('/branches')
            .then(branches => ({ branches: BranchHelper.filter(branches) }));
    }

    handleFilterClick = brandId => {
        this.setState({ brandFilter: brandId });
    };

    render = () => {
        const { branches } = this.state;
        const crumbItems = [...crumbs];
        let currentFederalState = '';

        if (branches.length > 0) {
            currentFederalState = branches[0].federal_state;
            crumbItems.push({ label: currentFederalState, current_page: true });
        }

        return (
            <CommonPage className="page-bundesland-standorte">
                <Helmet>
                    <title>Standorte im Raum {currentFederalState} - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbItems} />

                <h1 className={mainCssClass + '--headline'}>Unsere Standorte im Raum {currentFederalState}</h1>

                <BrandFilter
                    {...this.state}
                    className={mainCssClass}
                    onBrandItemClick={this.handleFilterClick.bind(this)} />

                <BranchListing
                    {...this.state}
                    className={mainCssClass} />

            </CommonPage>
        )
    }
}

export default BundeslandStandortePage;