import './styles.scss';

import React from 'react';
import thumbUp from '../AverageRating/thumbUp.svg';
import { getNumberRepresentation, getWordRepresentation } from '../helper';
import StarRating from '../StarRating';
import Icon from '../../../../../components/Icon';
import moment from 'moment';

import "moment/locale/de";
import "moment-timezone";
import KoenigInput from '../../../../../elements/KoenigInput';
import SortIcon from '../SortIcon';
import KoenigSelect from '../../../../../elements/KoenigSelect';
import apiHelper from '../../../../../helper/api.helper';
import urlHelper from '../../../../../helper/url.helper';

const CSS_PREFIX = 'fc-review-listing';

class ReviewListing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            reviews: [],
            sort: 'desc'
        }
    }

    async componentDidMount() {
        const reviews = await this.fetchReviews(null, null, 1);

        this.setState({ reviews });
    }

    async fetchReviews(sort, filter, page) {
        let reviews = [], result;
        const params = {};

        if (sort) {
            params.sort = sort;
        }

        if (filter) {
            params.filter = filter;
        }

        if (page) {
            params.page = page;
        }

        params.limit = 9;

        try {
            result = await apiHelper.GET(`/google_reviews?paginated=1&${urlHelper.generateQueryStringFrom(params)}`, true);
            if (result && result[0] && result[0].data) {
                reviews = result[0].data;

                this.setState({
                    pageAmount: Math.ceil(result[0].metadata[0].total / 9),
                    pageNumber: result[0].metadata[0].page
                });
            }
        } catch (e) {
            console.log('Fehler beim Abrufen der Reviews. ' + e);
        }

        return reviews;
    }

    async handlePageDecrease(page = false) {
        const { sort, filter, pageNumber } = this.state;
        let newPageNumber = pageNumber;

        page ? newPageNumber = page : newPageNumber--;

        if (newPageNumber < 1) {
            return;
        }

        this.setState({ reviews: await this.fetchReviews(sort, filter, newPageNumber) });
    }

    async handlePageIncrease(amount, page = false) {
        const { sort, filter, pageNumber } = this.state;
        let newPageNumber = pageNumber;

        page ? newPageNumber = page : newPageNumber++;

        if (newPageNumber === amount) {
            return;
        }

        this.setState({ reviews: await this.fetchReviews(sort, filter, newPageNumber) });
    }

    handlePageInput(val, amount) {
        if (val) {
            let { pageNumber } = this.state;

            val < pageNumber && this.handlePageDecrease(val);
            val > pageNumber && this.handlePageIncrease(amount, val);
        }
    }

    async handleChangeSorting() {
        let reviews = [], newSort;
        const { sort, filter } = this.state;

        if (sort === 'desc') {
            reviews = await this.fetchReviews('asc', filter, 1);
            newSort = 'asc';
        } else if (sort === 'asc') {
            reviews = await this.fetchReviews('desc', filter, 1);
            newSort = 'desc';
        }

        this.setState({ reviews: reviews, pageNumber: 1, sort: newSort });
    }

    async handleFilterChange(val) {
        const { sort } = this.state;

        let filterValue;

        switch (val) {
            case 'Sterne (5)':
                filterValue = 'FIVE';
                break;
            case 'Sterne (4)':
                filterValue = 'FOUR';
                break;
            case 'Sterne (3)':
                filterValue = 'THREE';
                break;
            case 'Sterne (2)':
                filterValue = 'TWO';
                break;
            case 'Sterne (1)':
                filterValue = 'ONE';
                break;
        }

        const reviews = await this.fetchReviews(sort, filterValue, 1);

        this.setState(
            { reviews, pageNumber: 1, filter: filterValue }
        );
    }

    render() {
        return (
            <div className={CSS_PREFIX}>
                {this.renderActions()}
                {this.renderListing()}
                {this.renderPaginator()}
            </div>
        );
    }

    renderActions() {
        return (
            <div className={CSS_PREFIX + '--actions'}>
                <SortIcon onClick={this.handleChangeSorting.bind(this)} text="Aktualität" />
                {this.renderFilter()}
            </div>
        );
    }

    renderFilter() {
        const options = ['ohne Filterung', 'Sterne (5)', 'Sterne (4)', 'Sterne (3)', 'Sterne (2)', 'Sterne (1)'];

        return <KoenigSelect value="ohne Filterung" options={options} onChange={(val) => this.handleFilterChange(val)} />;
    }

    renderListing() {
        const { reviews } = this.state;

        return reviews.map(
            (rev, idx) => this.renderListItem(rev, idx)
        );
    }

    renderListItem(review, idx) {
        return (
            <div key={"list_item_" + idx} className={CSS_PREFIX + '--list-item'}>
                {this.renderRating(review)}
                {this.renderComment(review)}
            </div>
        );
    }

    renderRating(review) {
        const number = getNumberRepresentation(review.starRating);

        return (
            <div className={CSS_PREFIX + '--rating'}>
                <img src={thumbUp} alt="thumb" />
                <span><strong>{number}.00</strong> / 5.00</span>
                <StarRating average={number} />
                <span>{getWordRepresentation(number)}</span>
            </div>
        );
    }

    renderComment(review) {
        const { createTime, reviewer: { displayName }, comment } = review;

        return (
            <div className={CSS_PREFIX + '--comment'}>
                <span>{moment(createTime).format('DD.MM.YYYY')} | {displayName}</span>
                <span>{comment}</span>
            </div>
        );
    }

    paginate(reviews, page_number) {
        return reviews.slice((page_number - 1) * 9, page_number * 9);
    }

    renderPaginator() {
        const { pageNumber, pageAmount } = this.state;

        return (
            <div className={CSS_PREFIX + '--paginator'}>
                <Icon variant="black_chevron_left" onClick={() => this.handlePageDecrease()} />
                <KoenigInput value={pageNumber} onChange={val => this.handlePageInput(val, pageAmount)} />
                <span>von {pageAmount} Seiten</span>
                <Icon variant="black_chevron_right" onClick={() => this.handlePageIncrease(pageAmount)} />
            </div>
        );
    }
}

export default ReviewListing;