import React, {useState} from 'react';
import Icon from '../../../../components/Icon';

export default ({ children, slidesPresented = 1 }) => {
    const RIGHT = '-1', LEFT = '+1';
    const [index, setIndex] = useState(0);
    const [clientX, setClientX] = useState({ initial: 0, final: 0 });
    const [clientY, setClientY] = useState({ initial: 0, final: 0 });
    const nodes = React.Children.toArray(children);
    const slidingDisabled = slidesPresented > 1 && nodes.length < slidesPresented;

    const onSwiped = (direction) => {
        const change = direction === RIGHT ? RIGHT : LEFT;
        const adjustedIdx = index + Number(change);
        let newIdx;

        if (adjustedIdx >= nodes.length) {
            newIdx = 0;
        } else if (adjustedIdx < 0) {
            newIdx = nodes.length - 1;
        } else {
            newIdx = adjustedIdx;
        }

        setIndex(newIdx)
    }

    const handleTouch = (event, type) => {
        const x = event.nativeEvent.touches[0].clientX;
        const y = event.nativeEvent.touches[0].clientY;

        setClientX({ ...clientX, [type]: x });
        setClientY({ ...clientY, [type]: y });
    };

    const handleTouchEnd = (event) => {
        const horizontalSwipeIntended = clientY.final - clientY.initial < 150 && clientX.final > 0;
        const wasSwipedToRight = horizontalSwipeIntended && clientX.final > clientX.initial;
        const wasSwipedToLeft = horizontalSwipeIntended && clientX.final < clientX.initial;

        if (wasSwipedToRight) {
            onSwiped(RIGHT);
        }

        if (wasSwipedToLeft) {
            onSwiped(LEFT);
        }

        setClientX({ inital: 0, final: 0 });
        setClientY({ inital: 0, final: 0 });
    };

    const handlers = slidingDisabled
        ? {}
        : {
            onTouchStart: e => handleTouch(e, 'initial'),
            onTouchMove: e => handleTouch(e, 'final'),
            onTouchEnd: e => handleTouchEnd(e)
        }
    ;

    const renderChildren = () => {
        let currentIdx = index, section = [];

        for (let i = 0; i < slidesPresented; i++) {
            if (typeof nodes[currentIdx] === 'undefined' && nodes.length > slidesPresented) {
                currentIdx = 0;
            }

            section.push(nodes[currentIdx]);
            currentIdx++;
        }

        return section;
    }

    return (
        <React.Fragment>
            <div className="fc-vdp-slider" {...handlers}>
                {
                    renderChildren()
                }
            </div>
            {
                !slidingDisabled &&
                <Icon
                    className="button-left"
                    onClick={() => onSwiped(RIGHT)}
                    variant="chevron_left"
                    color="white"/>
            }
            {
                !slidingDisabled &&
                <Icon
                    className="button-right"
                    onClick={() => onSwiped(LEFT)}
                    variant="chevron_right"
                    color="white"/>
            }
        </React.Fragment>
    );
}
