import "./styles.scss";

import React from 'react';
import CommonPage from '../../../CommonPage';
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/Breadcrumb";
import Icon from "../../../../components/Icon";
import KoenigButton from "../../../../elements/KoenigButton";
import ApiHelper from "../../../../helper/api.helper";
import Functions from "../functions";
import TelecashHelper from "../../../../helper/telecash.helper";
import { DISCOUNT_ITEM } from "../Schritt4Page";

const CSS_CLASS = 'wtt--failure-payment';

class ZahlungsfehlerPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    async handlePaymentChange() {
        const { shop_items , kws_member} = this.state;
        const filteredShopItems = shop_items.filter(si => si && si._id !== DISCOUNT_ITEM._id);

        const appointment = {
            ...this.state,
            prepayment: false,
            payment_status: 'pending',
            shop_items: filteredShopItems,
            total_price: Functions.getTotalPrice(filteredShopItems, kws_member)
        };

        await ApiHelper
            .PUT('/appointments/' + appointment._id, appointment)
            .then(updatedAppointment => Functions.linkTo('/werkstatt/terminbuchung/erfolgreich/', updatedAppointment))
            .catch(() => Functions.linkTo('/werkstatt/terminbuchung/fehlgeschlagen/', appointment));
    }

    async handleRetryTelecash() {
        const appointment = { ...this.state };
        const paymentRequest = {
            salutation: appointment.salutation,
            firstname: appointment.firstname,
            lastname: appointment.lastname,
            address: appointment.address,
            zip_code: appointment.zip_code,
            location: appointment.location,
            email: appointment.email,
            phone: appointment.phone,
            oid: appointment._id,
            total_price: Functions.getTotalPrice(appointment.shop_items, appointment.kws_member),
            response_success: '/werkstatt/terminbuchung/erfolgreich/',
            response_failed: '/werkstatt/terminbuchung/zahlungsfehler/'
        };

        await ApiHelper
            .POST('/telecash/payments', paymentRequest)
            .then(paymentObject => { TelecashHelper.execPaymentRequest(paymentObject) })
            .catch(console.error);
    }

    render() {

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Werkstatt', path: '/werkstatt/' },
            { label: 'Terminbuchung', current_page: true }
        ];

        return (
            <CommonPage className="page-terminbuchung-fehlgeschlagen-zahlung">
                <Helmet>
                    <title>Terminbuchung - Werkstatttermin - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>Ihre Zahlung ist fehlgeschlagen.</h1>

                <div className={CSS_CLASS}>
                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="close"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Zahlung fehlgeschlagen</b>
                    </div>

                    <p className={CSS_CLASS + '--reason'}>Die Zahlung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut oder zahlen Sie den Betrag bei Abgabe Ihres Fahrzeugs in unserer Filiale.</p>

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            className={CSS_CLASS + '--button--cash-payment'}
                            text="Ich zahle doch vor Ort"
                            onClick={this.handlePaymentChange.bind(this)} />

                        <KoenigButton
                            className={CSS_CLASS + '--button--retry'}
                            text="Bezahlvorgang wiederholen"
                            onClick={this.handleRetryTelecash.bind(this)} />
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default ZahlungsfehlerPage;