import './trending_widget.scss';

import React, { useState } from 'react';

const CSS_PREFIX = 'fc-trending-widget';

function TrendingWidget({ onChange }) {
    const options = ['Stimmt nicht', 'Stimmt eher nicht', 'Stimmt teilweise', 'Stimmt!'];
    const [currentIndex, setCurrentIndex] = useState();

    const handleClick = idx => {
        onChange(options[idx]);
        setCurrentIndex(idx);
    }

    return (
        <div className={CSS_PREFIX}>
            {
                options.map((text, idx) => (
                    <span key={'option_' + idx} onClick={() => handleClick(idx)}>
                        <svg width="26px" height="26px" viewBox="0 0 100 100">
                          <circle
                              cx="50"
                              cy="50"
                              r="45"
                              stroke="black"
                              strokeWidth="5"
                              fill={currentIndex === idx ? 'black' : 'white'} />
                        </svg>
                        <span>{text}</span>
                    </span>
                ))
            }
        </div>
    );
}

export default TrendingWidget