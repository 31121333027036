import React from 'react';
import PropTypes from 'prop-types';

import imgEmissionAp from '../../../assets/images/co2-emission/co2-emission-a+.svg';
import imgEmissionApp from '../../../assets/images/co2-emission/co2-emission-a++.svg';
import imgEmissionAppp from '../../../assets/images/co2-emission/co2-emission-a+++.svg';
import imgEmissionA from '../../../assets/images/co2-emission/co2-emission-a.svg';
import imgEmissionB from '../../../assets/images/co2-emission/co2-emission-b.svg';
import imgEmissionC from '../../../assets/images/co2-emission/co2-emission-c.svg';
import imgEmissionD from '../../../assets/images/co2-emission/co2-emission-d.svg';
import imgEmissionE from '../../../assets/images/co2-emission/co2-emission-e.svg';
import imgEmissionF from '../../../assets/images/co2-emission/co2-emission-f.svg';
import imgEmissionG from '../../../assets/images/co2-emission/co2-emission-g.svg';

const imgEmissionMap = {
	'A+': imgEmissionAp,
	'A++': imgEmissionApp,
	'A+++': imgEmissionAppp,
	A: imgEmissionA,
	B: imgEmissionB,
	C: imgEmissionC,
	D: imgEmissionD,
	E: imgEmissionE,
	F: imgEmissionF,
	G: imgEmissionG,
};

const Features = ({ vehicle }) => {
	const featureBoxCss = 'feature-box';
	const { ausstattung = [], marke_id } = vehicle;

	const renderCo2EmissionImage = efficiencyClass => {
		return efficiencyClass &&
			<img className="emission-image"
			   title={efficiencyClass}
			   alt={efficiencyClass}
			   src={imgEmissionMap[efficiencyClass]} />
	};

	const featureMap = ausstattung.reduce((map, feature) => {
		const headline = feature.headline.replace(' ', '_').toLowerCase();

		if (typeof feature.items === 'undefined') {
			return map;
		}

		if (map[headline]) {
			map[headline].concat(feature.items);
		} else {
			map[headline] = feature.items;
		}

		return map;
	}, {});

	featureMap.sicherheit = featureMap.sicherheit ? featureMap.sicherheit : [];
	featureMap.sicherheit = featureMap.sicherheit.concat(featureMap.sicherheit_airbag);

	const renderBox = (headline, items = []) => {
		return (
			<div key={'feature_box_' + headline} className={featureBoxCss}>
				<h3 className={featureBoxCss + '--title'}>{headline}</h3>
				<div className={featureBoxCss + '--features'}>
					<ul className='--features-list'>
						{items.map((item, i) => (
							<li key={item + i}>{item}</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	const renderWltpNfez = (vehicle) => {
		if (vehicle.messverfahren === "wltp") {
			return (
				<ul className={featureBoxCss + '--features-list'}>
					<li>Langsam: {vehicle.wltp_langsam}l / 100km</li>
					<li>Mittel: {vehicle.wltp_mittel}l / 100km</li>
					<li>Schnell: {vehicle.wltp_schnell}l / 100km</li>
					<li>sehr Schnell: {vehicle.wltp_sehr_schnell}l / 100km</li>
					<li>Kombiniert: {vehicle.wltp_kombiniert}l / 100km</li>
					<li>CO2-Emission kombiniert: {vehicle.wltp_co2_emissionen}g/kg</li>
					<li>(Werte nach EU-Norm-Messverfahren)</li>
				</ul>
			);
		} else if (vehicle.messverfahren === "nefz") {
			return (
				<ul className={featureBoxCss + '--features-list'}>
					<li>Innerorts: {vehicle.verbrauch_stadt}l / 100km</li>
					<li>Außerorts: {vehicle.verbrauch_land}l / 100km</li>
					<li>Kombiniert: {vehicle.verbrauch_kombiniert}l / 100km</li>
					<li>CO2-Emission kombiniert: {vehicle.co2_emissionen}g/kg</li>
					<li>(Werte nach EU-Norm-Messverfahren)</li>
				</ul>
			);
		}
	};

	if (ausstattung.length === 0 && vehicle.co2_effizienzklasse.length === 0) return '';

	if (marke_id === 'vespa') {
		return (
			<React.Fragment>
				<h2>Ausstattungsdetails</h2>
				{renderBox('Krad', featureMap.krad)}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<h2>Ausstattungsdetails und Kraftstoffverbrauch</h2>

			<div className='feature-section'>
				{renderBox(
					'Sicherheit',
					featureMap.sicherheit
				)}

				{renderBox('Komfort', featureMap.komfort)}

				<div className='feature-section-column'>
					{renderBox('Licht', featureMap.licht)}
					{renderBox('Multimedia', featureMap.multimedia)}
				</div>

				<div className='feature-section-column'>
					{renderBox('Sonstiges', featureMap.sonstiges)}

					<div key="feature-box-verbrauch" className={featureBoxCss}>

						<h3 className={featureBoxCss + '--title'}>Verbrauch</h3>

						<div className={featureBoxCss + '--features'}>
							{renderWltpNfez(vehicle)}
							{renderCo2EmissionImage(vehicle.co2_effizienzklasse.toUpperCase())}
						</div>
					</div>
				</div>

			</div>
			<p className='feature-section__disclaimer'>
				<b>Hinweis:</b> Finanzierung ohne Anzahlung möglich. Irrtümer und zwischenzeitlicher Verkauf
				vorbehalten.
			</p>
		</React.Fragment>
	);
};

export default Features;

Features.propTypes = {
	vehicle: PropTypes.object,
};
