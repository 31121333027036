import React from 'react';
import Overlay from '../../../components/Overlay';

function VideoOverlay({ link, onClose }) {
    return (
        <Overlay onClose={onClose} className={'fc-video-overlay'}>
            <video controls autoPlay>
                <source src={link} />
            </video>
        </Overlay>
    );
}

export default VideoOverlay;