import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../pages/CommonPage';
import API from '../helper/api.helper';
import Breadcrumb from '../components/Breadcrumb';
import SimpleContent from '../components/SimpleContent';
import TeaserBox from '../components/TeaserBox';
import SectionHeadline from '../components/SectionHeadline';
import BrandTeaser from '../components/BrandTeaser';

import './404.scss';
const CSS_CLASS = "page-not-found";

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);

        let data = [];

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            data = window.__initialData__;
            delete window.__initialData__;
        }

        const pageData = data[0];
        const startData = data[1];

        this.state = {
            page: pageData,
            brandTeasers: startData.teaser
        };
    }

    static requestInitialData(url) {
        if (url === '/404/') {
            const pageData = API
                .GET('/pages/not_found')
                .catch(err => {
                    console.error(err);
                    return {};
                });

            const startPage = API
                .GET('/pages/start')
                .catch(err => {
                    console.error(err);
                    return {};
                });

            return Promise.all([pageData, startPage]);
        }
    }

    render() {
        const page = this.state.page;

        if (!page || page.length === 0) {
            return this.renderNotFoundPage();
        } else {
            return this.renderPage();
        }
    }

    renderNotFoundPage() {
        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <p>Fehler 404 – Seite nicht gefunden</p>

            </CommonPage>
        );
    }

    renderPage() {
        const { page } = this.state;
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Angebot verpasst', current_page: true }
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>
                    {page.headline}
                </h1>

                <SimpleContent content={page.content} />

                <SectionHeadline text="Unsere Marken im Überblick" />
                <div className="brand-teasers">
                    {this.renderBrandTeaser()}
                </div>

                <SectionHeadline text="Spannende Themen im Autohaus König" />
                <div className="teaserboxes">
                    {this.renderTeaserBox(page.teaserboxes)}
                </div>

            </CommonPage>
        );
    }

    renderTeaserBox(data = []) {
        return (
            data.map((data, idx) => {
                return (
                    <TeaserBox key={data.headline + idx} {...data} />
                );
            })
        );
    }

    renderBrandTeaser() {
        const { brandTeasers = [] } = this.state;
        return (
            brandTeasers.map((teaser, idx) => {

                return (
                    <div key={'404-brand-teaser-'+teaser.uid + idx} className="cnt-brand-teaser">
                        <BrandTeaser teaser={teaser} />
                    </div>
                );
            })
        );
    }
}

export default NotFoundPage
