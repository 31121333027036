import "isomorphic-fetch";

const http = require('http');

const getBaseUrl = () => {
    if (typeof process.env.BASE_URL !== 'undefined') {
        return process.env.BASE_URL;
    }
    if (typeof window !== 'undefined' && typeof window.BASE_URL !== 'undefined') {
        return window.BASE_URL;
    }
    return 'https://www.autohaus-koenig.loc';
};

const getApiUrl = () => {
    if (typeof process.env.API_URL !== 'undefined') {
        return process.env.API_URL;
    }
    if (typeof window !== 'undefined' && typeof window.API_URL !== 'undefined') {
        return window.API_URL;
    }
    return 'https://api.autohaus-koenig.loc';
};

const getAuthorizationTokenFromCookie = () => {
    if (typeof window === 'undefined' || !window.document) {
        return '';
    }

    const { cookie } = document;

    if (cookie) {
        const [token] = cookie
            .split(';')
            .filter(s => s.indexOf('token=') === 0);

        if (token && token != null) {
            return 'Bearer ' + token.replace('token=', '');
        }
    }

    return '';
};

const request = endpoint => {
    return fetch(
        `${getBaseUrl()}/api/v1.0${endpoint}`,
        {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(res => res.json());
};

const send = data => {
    return {
        to: endpoint => {
            return fetch(`${getBaseUrl()}/api/v1.0${endpoint}`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json());
        }
    }
};

const GET = (endpoint, withAutorization = false) => {
    let headers = {
        'Cache-Control': 'no-cache, no-store',
        'Access-Control-Allow-Origin': '*',
        'App-Name': 'AHK-Webseite'
    }

    if (withAutorization) {
        headers = { ...headers, 'Authorization': getAuthorizationTokenFromCookie() }
    }

    return fetch(
        `${getApiUrl()}/v1.0${endpoint}`,
        {
            method: 'GET',
            headers
        })
        .then(res => res.json());
}

const execFetchWithAuthorization = (method, endpoint, data) => {
    return fetch(`${getApiUrl()}/v1.0${endpoint}`, {
        method,
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuthorizationTokenFromCookie()
        }
    }).then(res => {
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res.json()
    });
};

const POST = (endpoint, data) => {
    return execFetchWithAuthorization('POST', endpoint, data);
};

const PUT = (endpoint, data) => {
    return execFetchWithAuthorization('PUT', endpoint, data);
};

export default {
    request, send, getBaseUrl, POST, GET, PUT
};
