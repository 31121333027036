import React from 'react';

import renaultLogoDark from '../../assets/images/marken-logos-farbig/dark/renault.svg';
import daciaLogoDark from '../../assets/images/marken-logos-farbig/dark/dacia.svg';
import fiatAbarthLogoDark from '../../assets/images/marken-logos-farbig/dark/fiat_abarth_prof.svg';
import jeepLogoDark from '../../assets/images/marken-logos-farbig/dark/jeep.svg';
import alfaRomeoLogoDark from '../../assets/images/marken-logos-farbig/dark/alfa_romeo.svg';
import vespaLogoDark from '../../assets/images/marken-logos-farbig/dark/vespa.svg';
import zeLogoDark from '../../assets/images/marken-logos-farbig/dark/elektro.svg';
import kiaLogoDark from '../../assets/images/marken-logos-farbig/dark/kia.svg';
import piaggioLogoDark from '../../assets/images/marken-logos-farbig/dark/piaggio.svg';
import apeLogoDark from '../../assets/images/marken-logos-farbig/white/ape.svg';
import suzukiLogoDark from '../../assets/images/marken-logos-farbig/dark/suzuki.svg';
import alpineLogoDark from '../../assets/images/marken-logos-farbig/dark/alpine.svg';

import renaultLogo from '../../assets/images/marken-logos-farbig/white/renault.svg';
import daciaLogo from '../../assets/images/marken-logos-farbig/white/dacia.svg';
import fiatAbarthLogo from '../../assets/images/marken-logos-farbig/white/fiat_abarth_prof.png';
import jeepLogo from '../../assets/images/marken-logos-farbig/white/jeep.png';
import alfaRomeoLogo from '../../assets/images/marken-logos-farbig/white/alfa_romeo.png';
import vespaLogo from '../../assets/images/marken-logos-farbig/white/vespa.png';
import zeLogo from '../../assets/images/marken-logos-farbig/white/elektro.png';
import kiaLogo from '../../assets/images/marken-logos-farbig/white/kia.png';
import piaggioLogo from '../../assets/images/marken-logos-farbig/white/piaggio.svg';
import apeLogo from '../../assets/images/marken-logos-farbig/white/ape.svg';
import suzukiLogo from '../../assets/images/marken-logos-farbig/white/suzuki.svg';
import alpineLogo from '../../assets/images/marken-logos-farbig/white/alpine.svg';

export default ({ variant = '', brandId }) => {
    const brand = brandId || '';
    let images = {};

    if (variant === 'dark') {
        images = {
            renault: renaultLogoDark,
            dacia: daciaLogoDark,
            fiat: fiatAbarthLogoDark,
            jeep: jeepLogoDark,
            alfa_romeo: alfaRomeoLogoDark,
            vespa: vespaLogoDark,
            elektro: zeLogoDark,
            kia: kiaLogoDark,
            piaggio: piaggioLogoDark,
            ape: apeLogoDark,
            alpine: alpineLogoDark,
            suzuki: suzukiLogoDark
        }
    } else {
        images = {
            renault: renaultLogo,
            dacia: daciaLogo,
            fiat: fiatAbarthLogo,
            jeep: jeepLogo,
            alfa_romeo: alfaRomeoLogo,
            vespa: vespaLogo,
            elektro: zeLogo,
            kia: kiaLogo,
            piaggio: piaggioLogo,
            ape: apeLogo,
            suzuki: suzukiLogo,
            alpine: alpineLogo
        }
    }

    return <img src={images[brand]} alt={generateAltTextByBrandId(brand)}/>;
}

function generateAltTextByBrandId(id){
    if(id === 'alfa_romeo'){
        return 'Alfa-Romeo-Logo';
    }
    const firstLetter = id.charAt(0).toUpperCase();
    return firstLetter + id.substr(1) + '-Logo';
}