import React from 'react';
import BrandLogo from '../../../elements/BrandLogo';
import { uniqueItems } from './functions';

const brandMap = {
    renault: 'Renault',
    dacia: 'Dacia',
    fiat: 'Fiat',
    jeep: 'Jeep',
    'alfa-romeo': 'Alfa-Romeo',
    vespa: 'Vespa',
    kia: 'Kia'
};

const ChoserButton = ({
    variant,
    text,
    onClick = function () { },
    isActive,
    isDisable = true
}) => {

    const cssClass = 'choser-button--brand';
    const classes = [cssClass];
    let clickFunc = onClick;

    classes.push(cssClass + '--' + variant);

    if (isActive === true) {
        classes.push('active');
    }

    if (isDisable === true) {
        classes.push('disabled');
        clickFunc = () => { };
    }

    return (
        <span className={classes.join(' ')} onClick={clickFunc}>
            <BrandLogo
                brandId={variant}
                className={cssClass + '--icon'}
                style={{ height: '40px', width: '100%' }}
                color={isActive ? 'white' : 'dark'} />
            {text ? <span className={cssClass + '--text'}>{text}</span> : null}
        </span>
    );
};

export default ({ className, onBrandItemClick, brandFilter, possibleBrands }) => {
    const filterCssClass = className + '--brand-filter';
    const brands = [
        'renault', 'elektro', 'dacia', 'fiat_abarth_prof',
        'jeep', 'alfa_romeo', 'kia', 'vespa'
    ]
    return (
        <div className={filterCssClass}>
            <span className={filterCssClass + '--question'}>Möchten Sie nach einer Marke filtern?</span>
            <div className={filterCssClass + '--choser'}>
                <ChoserButton
                    variant="all_items"
                    text="Alle anzeigen"
                    onClick={onBrandItemClick.bind(this, 'all_items')}
                    isActive={brandFilter === 'all_items'}
                    isDisable={false} />
                {
                    brands.map((brand, idx) => (
                        <ChoserButton
                            key={'choser-button-' + idx}
                            variant={brand}
                            onClick={onBrandItemClick.bind(this, brand)}
                            isActive={brandFilter === brand}
                            isDisable={possibleBrands.indexOf(brand) === -1} />
                    ))
                }
            </div>
        </div>
    );
};