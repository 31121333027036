import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Icon from '../../Icon';

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item || [],
            addClass: false,
            maxHeight: 0
        };
    }

    toggle(id) {
        this.setState({ addClass: !this.state.addClass });

        if (typeof window !== 'undefined' && window.document) {
            const dimensions = document.querySelector('#' + id + ' .answer .cnt-height').getBoundingClientRect();

            this.setState({ maxHeight: dimensions.height })
        }
    }

    render() {
        const { item } = this.state;
        const id = 'faq_item_' + item.key;

        let cntClass = ['cnt-question'];
        let maxHeight = 0;

        if (this.state.addClass) {
            cntClass.push('open');
            maxHeight = this.state.maxHeight
        }

        const style = {
            maxHeight
        };

        return (
            <div id={id} className={cntClass.join(' ')} onClick={ev => this.toggle(id)}>
                <div className="question">
                    <span>{item.question}</span>
                    <Icon variant="black_chevron_down" />
                </div>
                <div className="answer" style={style}>
                    <div className="cnt-height">
                        {ReactHtmlParser(item.answer)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Question;
