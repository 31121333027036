import '../../styles.scss';

import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import API from '../../../helper/api.helper';
import Breadcrumb from '../../../components/Breadcrumb';
import ModellNavigation from "../../../components/ModellNavigation";
import TeaserBox from '../../../components/TeaserBox';
import CategoryChooser, { ALL_VEHICLES, ALL_SCOOTERS, ALL_APES } from '../../../components/CategoryChooser';
import VehicleBox from '../../../components/VehicleBox';
import SimpleContent from '../../../components/SimpleContent';
import ColoredBrandLogos from '../../../components/ColoredBrandLogos';
import SectionHeadline from '../../../components/SectionHeadline';
import vehicleSorter from '../../../helper/vehicle.sorter';
import { BRAND_REG_EXP } from '../../../helper/constant.helper';

const CSS_CLASS = "page-single-brand-offers";

class SingleBrandStartPage extends React.Component {

    constructor(props) {
        super(props);
        let data = [];
        let possibleCategories = [];

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else if (typeof window !== 'undefined') {
            data = window.__initialData__;
            delete window.__initialData__;
        }

        const [pageData = {}, vehicles = [], models = [], vehicleType] = data || [];
        const allVehicles = [].concat(vehicles.private, vehicles.business);

        if (vehicles && vehicles.private) {
            possibleCategories = allVehicles
                .reduce((cats, v) => cats.concat(v.categories), [])
                .filter((c, idx, cats) => cats.indexOf(c) === idx)
        }

        this.state = {
            page: pageData,
            models: models,
            vehicles: vehicles,
            catKey: ALL_VEHICLES,
            possibleCategories,
            vehicleType
        };
    }

    static requestInitialData(url) {
        const regexp = new RegExp(`\/(neuwagen|roller)\/(${BRAND_REG_EXP})\/.*`, 'g');
        const match = regexp.exec(url);

        if (match && match[2]) {
            const brandId = match[2];

            const pageData = API
                .GET(`/pages/${brandId}`)
                .catch(/* istanbul ignore next */err => {
                    console.error(err);
                    return [];
                });

            const vehicles = API
                .GET(`/vehicles?filtered=1&brand=${brandId}&sortby=preis`)
                .catch(/* istanbul ignore next */err => {
                    console.error(err);
                    return [];
                });

            const models = API
                .GET(`/models/?brand=${brandId}&sortby=order`)
                .catch(/* istanbul ignore next */err => console.error(err));

            return Promise.all([pageData, vehicles, models, match[1]]);
        }
    }

    render() {
        const page = this.state.page;

        if (!page || page.length === 0) {
            return this.renderNotFoundPage();
        } else {
            return this.renderPage();
        }
    }

    renderNotFoundPage() {
        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König</title>
                </Helmet>

                <p>Es konnten keine Daten geladen werden.</p>

            </CommonPage>
        );
    }

    renderPage() {
        const { page, models = [], vehicles = [], catKey, possibleCategories, vehicleType } = this.state;
        const brandId = this.props.match.params.brand;
        const brand = this.getBrand(brandId);
        const title = page.title ? page.title : this.getTitle(brand);
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: vehicleType === 'neuwagen' ? 'Neuwagen' : 'Roller', path: `/${vehicleType}/` },
            { label: brand, current_page: true }
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={(page.meta_description) ? page.meta_description : ''} />
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <ModellNavigation models={models} />

                <h1 className={CSS_CLASS + '--headline'}>
                    <ColoredBrandLogos brandId={brandId.replace('-', '_')} />
                    {brand} im Autohaus König
                </h1>

                <div className="teaserboxes">
                    {this.renderTeaserBox(page.teaserboxes)}
                </div>

                <div className={CSS_CLASS + '--buns-bottom'}></div>

                <CategoryChooser
                    activeItem={catKey}
                    possibleCategories={possibleCategories}
                    variant={this.determineVariant(brandId)}
                    onChange={catKey => this.setState({ catKey })} />

                {this.renderVehicles(vehicles.private, 'cnt_vehicles_private')}

                {this.rendernBusinessVehicles()}

                <SectionHeadline text="Weitere spannende Themen" />

                <div className="teaserboxes">
                    {this.renderTeaserBox(page.extended_topics)}
                </div>

                <SimpleContent content={page.content} />

                <p className="page-all-brands-offers--co2info">** Weitere Informationen zum offiziellen
                    Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können
                    dem‚ Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer
                    Personenkraftwagen entnommen werden, der an allen Verkaufsstellen, bei der Deutschen Automobil
                    Treuhand GmbH (DAT), Hellmuth-Hirth-Str. 1, 73760 Ostfildern-Scharnhausen, und
                    unter <a href="http://www.dat.de/co2" target="_blank">www.dat.de/co2</a> unentgeltlich erhältlich
                    ist.</p>

            </CommonPage>
        );
    }

    getBrand(brandId) {
        if (brandId === 'fiat') {
            return 'Fiat, Abarth und Fiat Professional';
        }

        if (brandId === 'alfa-romeo') {
            return 'Alfa Romeo';
        }

        return (typeof brandId !== 'undefined' && brandId !== null) ?
            brandId.charAt(0).toUpperCase() + brandId.slice(1) : null;
    }

    getTitle(brand) {
        return [
            brand === 'Vespa' ? 'Neuroller' : 'Neuwagen',
            brand + ' König',
            brand + ' Leasing & Finanzierung',
            'Autohaus König'
        ].join(' - ');
    }

    renderTeaserBox(data = []) {
        return (
            data.map((data, idx) => {
                return (
                    <TeaserBox key={data.headline + idx} {...data} />
                );
            })
        );
    }

    rendernBusinessVehicles() {
        const { vehicles = [] } = this.state;

        if (typeof vehicles.business !== 'undefined' && vehicles.business.length > 0) {
            return (
                <React.Fragment>
                    <SectionHeadline text="Fahrzeuge für Gewerbetreibende" />
                    {this.renderVehicles(vehicles.business, 'cnt_vehicles_business')}
                </React.Fragment>
            );
        }
    }

    renderVehicles(vehicles, id) {
        vehicles = vehicleSorter.sort(vehicles);

        if (vehicles.length > 0) {
            const { catKey } = this.state;

            return (
                <div id={id}>
                    {
                        vehicles
                            .filter(({ categories }) => catKey === ALL_VEHICLES
                                || catKey === ALL_SCOOTERS
                                || catKey === ALL_APES
                                || categories.indexOf(catKey) !== -1
                            )
                            .map((vehicle, idx) => {
                                return (
                                    <div key={"box-wrapper-" + idx + "-" + vehicle.uid} className="box-wrapper">
                                        <VehicleBox vehicle={vehicle} />
                                    </div>
                                )
                            })
                    }
                </div>
            )
        }
    }

    determineVariant(brandId) {
        switch (brandId) {
            case 'vespa':
            case 'piaggio':
                return 'scooters';
            case 'ape':
                return 'apes';
            default:
                return 'vehicles';
        }
    }
}

export default SingleBrandStartPage;