import './styles.scss';

import React from 'react'
import KoenigImage from '../KoenigImage';
import Icon from '../Icon';
import KoenigButton from '../../elements/KoenigButton';
import Tabs from "./components/Tabs";
import Tab from "./components/Tab";
import Badges from '../../components/Badges';
import CurrencyHelper from '../../helper/Currency';
import farbRad from '../../assets/images/farb_rad.svg';
import UrlHelper from '../../helper/url.helper';
import { GEAR_TYPES } from '../../helper/constant.helper';
import LazyLoad from 'react-lazyload';
import { isScooter } from './helper';
import Co2Helper from '../../helper/co2.helper';

const CSS_CLASS = 'fc-vehicle-box';

class VehicleBox extends React.Component {

    handleImageClick() {
        const { vehicle } = this.props;
        const vehicleType = isScooter(vehicle.brand) ? 'roller' : 'neuwagen';
        const link = UrlHelper.normalize(`/${vehicleType}/${vehicle.brand}/${vehicle.model}/${vehicle._id}/`);

        window.open(link, '_self');
    }

    render() {
        const { vehicle = {} } = this.props;

        return (
            <div className={CSS_CLASS}>
                {this.renderBoxHeader(vehicle)}
                {this.renderDatInfo(vehicle)}
                {this.renderVehicleImage(vehicle)}
                {this.renderEngineSection(vehicle)}
                {this.renderPaymentTabs(vehicle)}
                {this.renderConsumptions(vehicle)}
                {this.renderDetailPageButton()}
            </div>
        );
    }

    renderBoxHeader(vehicle) {
        const headerCss = CSS_CLASS + '--box-header';
        const { brand, model, version, engine, koeniginfo } = vehicle;

        return (
            <div className={headerCss}>
                <span className={headerCss + '--headline'}>
                    {brand} {model}
                </span>
                <span className={headerCss + '--sub-headline'}>
                    {version} {engine}
                </span>
                <span className={headerCss + '--sub-headline'}>
                    {koeniginfo}
                </span>
            </div>
        );
    }

    renderDatInfo(vehicle) {
        const { dat_info = '' } = vehicle;

        return (
            <span className={CSS_CLASS + '--dat-info'}>
                {dat_info}
            </span>
        )
    }

    renderVehicleImage(vehicle) {
        const imageCss = CSS_CLASS + '--vehicle-image'
        const { action_img = {}, top_deal = true } = vehicle;
        const { src, alt } = action_img;

        return (
            <LazyLoad once height="100%" offset={1000}>
                <div className={imageCss} onClick={this.handleImageClick.bind(this)}>
                    {
                        top_deal &&
                        <span className={imageCss + '--top-deal'}>
                            TOP DEAL
                            <Icon variant="crown" color="white" />
                        </span>
                    }
                    {
                        src &&
                        <KoenigImage fileHash={src} alt={alt} />
                    }
                    <span className={imageCss + '--sample-note'}>
                        Bild zeigt Beispielabbildung des Fahrzeuges
                    </span>
                    {this.renderColorCircle()}
                </div>
            </LazyLoad>
        )
    }

    renderColorCircle() {
        const { vehicle = {} } = this.props;
        const { images = {} } = vehicle;

        return images.length > 1 && <img className={CSS_CLASS + '--color-circle'} src={farbRad} alt="Farbauswahl" />;
    }

    renderEngineSection(vehicle) {
        const engineCss = CSS_CLASS + '--engine';
        const { power, fuel_type, gear_type } = vehicle;

        return (
            <div className={engineCss}>
                <span>
                    <Icon variant="speedo" />
                    <span>{power}</span>
                </span>
                <span>
                    <Icon variant="gas_can" />
                    <span>{fuel_type}</span>
                </span>
                <span>
                    <Icon variant="gearbox" />
                    <span>{GEAR_TYPES[gear_type]}</span>
                </span>
            </div>
        )
    }

    renderPaymentTabs(vehicle) {
        const { payments = {} } = vehicle;
        const { leasing = {}, prices = {}, financing = {}, abo = {}, exclusive } = payments;
        let defaultTab = 'Barkauf';

        switch (true) {
            case financing.is_default:
                defaultTab = 'Finanzierung';
                break;
            case leasing.is_default:
                defaultTab = 'Leasing';
                break;
        }

        if (exclusive === 'leasing') {
            delete vehicle.payments.financing;
            vehicle.payments.prices && delete vehicle.payments.prices.cash;
        }

        return (
            <LazyLoad once height="100%" offset={1000}>
                <Tabs defaultTab={defaultTab}>
                    <Tab label="Leasing" className={leasing.rate > 0 ? null : 'disabled'}>
                        {leasing.rate > 0 && this.renderTabContent('leasing', leasing)}
                    </Tab>
                    <Tab label="Barkauf" className={prices.cash > 0 ? null : 'disabled'}>
                        {prices.cash > 0 && this.renderTabContent('cash', prices)}
                    </Tab>
                    <Tab label="Finanzierung" className={financing.rate ? null : 'disabled'}>
                        {financing.rate > 0 && this.renderTabContent('financing', financing)}
                    </Tab>
                    <Tab label="Abo" className={abo.rate ? null : 'disabled'}>
                        {abo.rate > 0 && this.renderTabContent('abo', abo)}
                    </Tab>
                </Tabs>
            </LazyLoad>
        );
    }

    renderTabContent(type, data) {
        const { vehicle: { koenig_highlights } } = this.props;

        return (
            <React.Fragment>
                {this.renderBadges(type)}
                <div className={CSS_CLASS + '--sub-content'}>
                    {this.renderHighlights(koenig_highlights)}
                    {this.renderPrice(type, data)}
                </div>
                {this.renderProvider(data)}
            </React.Fragment>
        );
    }

    renderBadges(type) {
        let { vehicle: { badges } } = this.props;

        badges = badges && badges.reduce((res, badge) => {
            if (badge.payment_type === type || typeof badge.payment_type === 'undefined') {
                res.push(badge)
            }

            return res;
        }, []);

        return <Badges badges={badges} />
    }

    renderHighlights(highlights = []) {
        return (
            <span>
                {highlights.slice(0, 3).map((item, idx) =>
                    <span key={'highlight_' + idx} className={CSS_CLASS + '--highlight'}>
                        <Icon
                            variant="checkmark"
                            color="green"
                            style={{ display: 'inline-block', width: '1.5rem', height: '1.25rem' }} />
                        <span className="text">{item}</span>
                    </span>)}
            </span>
        );
    }

    renderPrice(type, data) {
        const { is_business } = this.props.vehicle;
        let price = null;
        let label = null;

        switch (type) {
            case 'leasing':
                price = this.getPrice(data.rate);
                label = 'mtl. Leasingrate';
                break;
            case 'cash':
                price = this.getPrice(data.cash);
                label = 'Barpreis';
                break;
            case 'financing':
                price = this.getPrice(data.rate);
                label = 'mtl. Finanzierungsrate';
                break;
            case 'abo':
                const rate = is_business ? data.rate_net : data.rate;
                price = this.getPrice(rate);
                label = 'mtl. Aborate';
                break;
        }

        return (
            <span className={CSS_CLASS + '--price'}>
                <span><b>{price}</b></span> <span>€</span><br />
                <b>{label}</b><br />
                {is_business ? 'zzgl. 19% MwSt.' : 'inkl. 19% MwSt.'}
            </span>
        );
    }

    getPrice(price) {
        return CurrencyHelper.toCurrency(price);
    }

    renderConsumptions(vehicle) {
        const { consumptions = [], kerb_weight, fuel_type } = vehicle;
        const { combined_co2 = null } = consumptions;
        let efficiencyClass ='a+++';

        if (fuel_type !== 'Elektro') {
            efficiencyClass = Co2Helper.getCo2Class(combined_co2, kerb_weight);
        }

        if (efficiencyClass === null) {
            return '';
        }
        
        const efficiencyStyles = this.getEfficiencyStyles(efficiencyClass);
        const consumptionsCss = CSS_CLASS + '--consumptions';

        return (
            <span className={consumptionsCss}>
                <hr />
                {
                    consumptions
                        ? `${consumptions.combined}l/100KM (komb.)**; ${combined_co2}g CO2/KM (komb.)**`
                        : 'Keine Kraftstoffangaben'
                }
                <span className={consumptionsCss + '--efficiency'} style={efficiencyStyles}>{efficiencyClass.toUpperCase()}</span>
            </span>
        );
    }

    renderDetailPageButton() {
        const { vehicle = {} } = this.props;
        const vehicleType = isScooter(vehicle.brand) ? 'roller' : 'neuwagen';
        const link = UrlHelper.normalize(`/${vehicleType}/${vehicle.brand}/${vehicle.model}/${vehicle._id}/`);

        return (

            <KoenigButton
                text="Fahrzeug ansehen"
                linkTo={link} />
        );
    }

    renderProvider(data) {
        const { provider_name, provider_address } = data;

        return (
            <span className={CSS_CLASS + '--provider'}>
                {
                    provider_name && `${provider_name}, ${provider_address}`
                }
            </span>
        );
    }

    getEfficiencyStyles(efficiencyClass) {
        const map = {
            'a+++': '##009C40',
            'a++': '##009C40',
            'a+': '##009C40',
            'a': '#7DC51F',
            'b': '#7DC51F',
            'c': '#F8F300',
            'd': '#EC9712',
            'e': '#DA3732',
            'f': '#DA3732',
            'g': '#DA3732',
        };

        return { backgroundColor: map[efficiencyClass.toLowerCase()] };
    }
}

export default VehicleBox;