import "./styles.scss";

import React from 'react';
import CommonPage from '../../CommonPage';
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/Breadcrumb";
import Icon from "../../../components/Icon";
import KoenigButton from "../../../elements/KoenigButton";
import ApiHelper from "../../../helper/api.helper";
import Functions from "../../../components/SimpleShop/functions";
import TelecashHelper from "../../../helper/telecash.helper";

const CSS_CLASS = 'simple-shop--failure-payment';

class ZahlungsfehlerPage extends React.Component {

    constructor(props) {
        super(props);
        let postData = {};

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';

        if (hasContextPostData) {
            postData = props.staticContext.postData;
        } else if (hasClientPostData) {
            postData = window.__postData__;
        }

        this.state = {
            ...postData
        };

    }

    async handleRetryTelecash() {
        const order = { ...this.state };
        const paymentRequest = {
            salutation: order.salutation,
            firstname: order.firstname,
            lastname: order.lastname,
            address: order.address,
            zip_code: order.zip_code,
            location: order.location,
            email: order.email,
            phone: order.phone,
            oid: order._id,
            total_price: Functions.getTotalPrice(order.shop_items, order.shipping_costs),
            response_success: '/shopping/checkout/erfolgreich/',
            response_failed: '/shopping/checkout/zahlungsfehler/'
        };

        await ApiHelper
            .POST('/telecash/payments', paymentRequest)
            .then(paymentObject => { TelecashHelper.execPaymentRequest(paymentObject) })
            .catch((err) => console.error(err));
    }

    render() {

        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Fehlgeschlagene Zahlung', current_page: true }
        ];

        return (
            <CommonPage className="page-simple-shop-fehlgeschlagen-zahlung">
                <Helmet>
                    <title>Checkout - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <h1 className={CSS_CLASS + '--headline'}>Ihre Zahlung ist fehlgeschlagen.</h1>

                <div className={CSS_CLASS}>
                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="close"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Zahlung fehlgeschlagen</b>
                    </div>

                    <p className={CSS_CLASS + '--reason'}>Die Zahlung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.</p>

                    <div className={CSS_CLASS + '--button--row'}>
                        <KoenigButton
                            className={CSS_CLASS + '--button--retry'}
                            text="Bezahlvorgang wiederholen"
                            onClick={this.handleRetryTelecash.bind(this)} />
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default ZahlungsfehlerPage;
