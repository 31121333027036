const BWS_STORE_MAP = {
    48: 'schoeneberg',
    49: 'oranienburg',
    50: 'koepenick',
    51: 'charlottenburg',
    52: 'eisenhuettenstadt',
    53: 'bad-saarow',
    54: 'zossen',
    55: 'luckenwalde',
    56: 'spandau-2',
    57: 'teltow-2',
    58: 'hennigsdorf',
    59: 'prenzlau',
    60: 'hoppegarten',
    62: 'finsterwalde-massen',
    63: 'fuerstenwalde',
    64: 'steglitz',
    67: 'kahla',
    68: 'jena',
    69: 'erfurt-sued',
    70: 'erfurt-nord-2',
    71: 'spandau-3',
    74: 'gosen',
    75: 'reinickendorf-2',
    76: 'greiz',
    77: 'nohra',
    84: 'teltow',
    104: 'ueckermuende',
    105: 'pasewalk',
    106: 'spandau',
    107: 'haldensleben',
    108: 'leipzig',
    109: 'schoenebeck',
    110: 'zerbst',
    111: 'magdeburg',
    112: 'heyrothsberge',
    113: 'charlottenburg-2',
    115: 'erfurt-nord',
    127: 'steglitz',
    153: 'jena-2',
    155: 'seelow',
    156: 'halle',
    157: 'gera'
}

module.exports = {
    BWS_STORE_MAP
}