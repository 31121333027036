import './styles.scss';

import React from 'react';
import KoenigButton from '../../elements/KoenigButton';

class Overlay extends React.Component {

    handleCloseEvent(e) {
        e && e.stopPropagation && e.stopPropagation();
        e && e.nativeEvent && e.nativeEvent.stopImmediatePropagation && e.nativeEvent.stopImmediatePropagation();
        const { onClose = () => { } } = this.props;
        onClose();
    }

    preventEventBubbling(e) {
        e && e.stopPropagation && e.stopPropagation();
        e && e.nativeEvent && e.nativeEvent.stopImmediatePropagation && e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        const { children } = this.props;

        return (
            <div className="overlay" onClick={this.handleCloseEvent.bind(this)}>
                {this.renderOverlay(children)}
            </div>
        );
    };

    renderOverlay(children) {
        const { className } = this.props;
        const classes = ['overlay--content'];

        if (className && className.length > 0) {
            classes.push(className);
        }

        return (
            <div className={classes.join(' ')} onClick={this.preventEventBubbling.bind(this)}>
                <KoenigButton
                    text="x"
                    className="overlay--content--close"
                    onClick={this.handleCloseEvent.bind(this)}
                />
                {children}
            </div>
        );
    };
}

export default Overlay;