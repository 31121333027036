import React from "react";
import TableRow from "./TableRow";
import PropTypes from "prop-types";

const ProductDetail = ({vehicle}) => {
    return (
        <div className="product-details detail-box">
            <h3 className="h4 detail-box__title">Produktdetails</h3>
            <div className="detail-box__inner">
                <div className="detail-box__table">
                    <TableRow label="Status" value={"<b>verfügbar</b>"} markup={true}/>
                    <TableRow label="Angebots-Nr." value={vehicle.wgnr}/>
                    <TableRow label="Angebotstyp" value={vehicle.fzart}/>
                    <TableRow label="Modell" value={vehicle.modell}/>
                    <TableRow label="Kilometerstand" value={optimizeForVehicleAd(vehicle.km)}/>
                    <TableRow label="Erstzulassung" value={vehicle.erstzulassung}/>
                    <TableRow label="Kraftstoff" value={vehicle.kraftstoff}/>
                    <TableRow label="Schaltung" value={vehicle.getriebe}/>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;

ProductDetail.propTypes = {
    vehicle: PropTypes.object,
};

//////////////////////////////

function optimizeForVehicleAd(km) {
    return km.replace(/\s|\.|km$/g, "") + "km";
}