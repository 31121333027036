import React from 'react';
import Api from '../../helper/api.helper';
import KoenigImage from '../../components/KoenigImage';
import SimpleContent from '../../components/SimpleContent';
import CommonPage from '../../pages/CommonPage';
import Helmet from 'react-helmet';
import Breadcrumb from '../../components/Breadcrumb';
import EbikeProductBox from '../../pages/EbikeShopPage/components/EbikeProductBox';
import SimpleShop from '../../components/SimpleShop';

const CSS_CLASS = 'page-ebike-shop'

class EbikeShopPage extends React.Component {
    constructor(props) {
        super(props);
        let pageData = {}, postData = {}, ebikes = [];

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined'; 
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [pageData, ebikes] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [pageData, ebikes] = window.__initialData__;
            }
        }

        this.state = {
            ...pageData,
            ebikes,
            isContentVisible: true,

            ...postData
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/elektromobilitaet/e-bike-shop/') === 0) {
            return Promise.all([
                Api
                    .GET('/pages/ebike_shop')
                    .catch(err => {
                        console.error(err);
                        return [];
                    }),
                Api
                    .GET('/ebikes')
                    .catch(err => {
                        console.error(err);
                        return [];
                    })
            ])
        }
    }

    handleSetContentVisible(isVisible) {
        this.setState({ isContentVisible: isVisible });
    }

    render() {
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Elektro', path: '/elektro/' },
            { label: 'E-Bike-Shop', current_page: true }
        ];
        const { title, headline, seo_content, isContentVisible } = this.state;

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <div className={isContentVisible ? '' : 'content-visible'}>
                    <Breadcrumb crumbs={crumbs} />

                    <h1 className={CSS_CLASS + '--headline'}>{headline}</h1>

                    {this.renderTeaserImages()}

                    <SimpleContent content={seo_content} />
                </div>

                {this.renderSimpleShop()}
            </CommonPage>
        )
    }

    renderTeaserImages() {
        const { teaser_src = {}, headline } = this.state;
        const { mobile = '', desktop = '' } = teaser_src;
        const CssTeaser = CSS_CLASS + '--teaser';

        return (
            <div className={CssTeaser}>
                {
                    mobile.length > 0
                        ? <KoenigImage className={CssTeaser + '--mobile'} fileHash={mobile} alt={headline} />
                        : null
                }
                {
                    desktop.length > 0
                        ? <KoenigImage className={CssTeaser + '--desktop'} fileHash={desktop} alt={headline} />
                        : null
                }
            </div>
        );
    }

    renderSimpleShop() {
        const { ebikes } = this.state;

        return (
            <SimpleShop
                type='ebike'
                products={ebikes}
                productComp={EbikeProductBox}
                itemLimit="3"
                shopUrl="/elektromobilitaet/e-bike-shop/"
                {...this.state}
                setContentVisible={this.handleSetContentVisible.bind(this)} />
        );
    }
}

export default EbikeShopPage;