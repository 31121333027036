import "isomorphic-fetch";

import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import CategoryChooser, { ALL_VEHICLES, ALL_SCOOTERS } from '../../../components/CategoryChooser';
import VehicleBox from '../../../components/VehicleBox';
import API from "../../../helper/api.helper";
import vehicleSorter from '../../../helper/vehicle.sorter';

class SingleBrandsOffersPage extends React.Component {

    constructor(props) {
        super(props);
        let vehicles = [];

        if (props.staticContext && props.staticContext.initialData) {
            vehicles = props.staticContext.initialData;
        } else {
            vehicles = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            vehicles,
            catKey: ALL_VEHICLES,
            possibleCategories: vehicles
                .reduce((cats, v) => cats.concat(v.categories), [])
                .filter((c, idx, cats) => cats.indexOf(c) === idx)
        };
    }

    static requestInitialData(url) {
        const query = ['is_exclusive=0', 'sortby=preis'];

        if (url.indexOf('gewerbebereich') !== -1) {
            query.push('is_business=1');
        }

        const queryParam = query.length > 1 ? query.join('&') : query[0];
        return API
            .GET(`/vehicles?${queryParam}`)
            .catch(err => console.error(err));
    }

    render() {
        const brandId = this.props.match.params.brand;
        const { vehicles = [], catKey, possibleCategories } = this.state;
        const brand = (typeof brandId !== 'undefined' && brandId !== null) ?
            brandId.charAt(0).toUpperCase() + brandId.slice(1) : null;
        const titleParts = this.getTitleParts(brand);

        return (
            <CommonPage className="brand-offers">
                <Helmet>
                    <title>{titleParts.join(' - ')}</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <h1>Alle Modelle sind ohne Anzahlung erhältlich!</h1>
                <span className="exclude-info"></span>

                <CategoryChooser
                    activeItem={catKey}
                    possibleCategories={possibleCategories}
                    variant={this.determineVariant(brandId)}
                    onChange={catKey => this.setState({ catKey })} />

                {this.renderVehicles(vehicles, catKey)}
            </CommonPage>
        );
    }

    getTitleParts(brand) {
        if (brand === null) {
            return [
                'Neuwagen', 'Leasing & Finanzierung', 'Autohaus König'
            ];
        }
        const brandStr = (brand === 'Alfa-romeo') ? 'Alfa Romeo' : brand;

        return [
            brandStr === 'Vespa' ? 'Neuroller' : 'Neuwagen',
            brandStr + ' König',
            brandStr + ' Leasing & Finanzierung',
            'Autohaus König'
        ];
    }

    renderVehicles(vehicles, catKey) {
        vehicles = vehicleSorter.sort(vehicles);

        return (
            <div id="cnt_vehicles">
                    {
                        vehicles
                            .filter(({ categories }) => catKey === ALL_VEHICLES
                                || categories.indexOf(catKey) !== -1
                            )
                            .map((vehicle, idx) => {
                                if (idx == 7) {
                                    return (
                                        <React.Fragment key={"box-wrapper-" + idx + "-" + vehicle.uid}>
                                            <div className="box-wrapper">
                                                <VehicleBox vehicle={vehicle} />
                                            </div>
                                            <a id="image_after_8" href="/gebrauchtwagen/inzahlungnahme">
                                                <img src="https://t3api.autohaus-koenig.de/relaunch_img/content/neuwagen/Inzahlungnahme2_1280x300_mit_button.jpg" />
                                            </a>
                                        </React.Fragment>
                                    )
                                }

                                return (
                                    <div key={"box-wrapper-" + idx + "-" + vehicle.uid} className="box-wrapper">
                                        <VehicleBox vehicle={vehicle} />
                                    </div>
                                )
                            })
                    }
                </div>
        )
    }

    determineVariant(brandId) {
        switch (brandId) {
            case 'vespa':
            case 'piaggio':
                return 'scooters';
            case 'ape':
                return 'apes';
            default:
                return 'vehicles';
        }
    }
}

export default SingleBrandsOffersPage;