import React from 'react';
import CompanyLogo from '../../CompanyLogo';
import { TOP_MENU, MAIN_MENU } from '../../../routes';
import BrandLogo from '../../../elements/BrandLogo';
import { isScooter } from '../../VehicleBox/helper';

const CSS_CLASS = 'header--desktop';
const TOP_MENU_CSS = CSS_CLASS + '--top-menu';
const BRAND_MENU_CSS = CSS_CLASS + '--brand-menu';
const MAIN_MENU_CSS = CSS_CLASS + '--menu';
const SUB_MENU_CSS = CSS_CLASS + '--sub-menu';

class DesktopHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hoveredItem: {}
        }
    }

    handleMouseEnter(hoveredItem) {
        this.setState({ hoveredItem });
    }

    handleMouseLeave(item) {
        this.setState({ hoveredItem: {} });
    }

    render() {
        return (
            <React.Fragment>
                <div className={CSS_CLASS} style={{ zIndex: 10000 }}>
                    <div className={CSS_CLASS + '--content-wrapper'}>
                        {this.renderTopMenu()}
                        <div className={CSS_CLASS + '--layout-helper'}>
                            <CompanyLogo />
                        </div>
                        {this.renderBrandMenu()}
                        {this.renderMainMenu()}
                        {this.renderSubMenu()}
                    </div>
                </div>
                {this.renderFixedPositionHelper()}
            </React.Fragment>
        );
    }

    renderTopMenu() {
        const { menuItems = [] } = this.props;

        const topMenuItems = menuItems.filter(item => item.location === TOP_MENU);
        return (
            <div className={TOP_MENU_CSS + '--container'}>
                {
                    topMenuItems.map((item, idx) => this.renderTopMenuLink({ ...item, idx }))
                }
            </div>
        );
    }

    renderTopMenuLink(item) {
        return <a
            key={'top-menu-' + item.idx}
            className={TOP_MENU_CSS + '--link'}
            href={item.href}
            target={item.target}>
            {item.label}
        </a>
    }

    renderBrandMenu() {
        const { brands = [] } = this.props;

        return (
            <div className={BRAND_MENU_CSS + '--container'}>
                {
                    brands.map(brand => this.renderBrandLink(brand))
                }
            </div>
        );
    }

    renderBrandLink(brand) {
        const vehicleType = isScooter(brand.label) ? 'zweirad' : 'neuwagen';
        const brandId = brand._id
            .replace(/^fiat$/, 'fiat_abarth')
            .replace('vespa', 'vespa_plain')
            .replace('suzuki', 'suzuki_short')
            .replace('piaggio', 'piaggio_short');

        return (
            <a
                key={'desktop-brand-link-' + brand._id}
                href={`/${vehicleType}/${brand._id.replace('_', '-')}/`}
                className={BRAND_MENU_CSS + '--link'}
                title={brand.label}
                style={{ height: '2rem' }}>
                <BrandLogo
                    brandId={brandId}
                    color="#FFFFFF"
                    alt={brand.label}
                    style={{ height: '100%' }} />
            </a>
        );
    }

    renderMainMenu() {
        const { menuItems = [] } = this.props;
        const mainMenuItems = menuItems.filter(item => item.location === MAIN_MENU);

        return (
            <div className={MAIN_MENU_CSS + '--container'}>
                {
                    mainMenuItems.map((item, idx) => this.renderMainMenuLink({ ...item, idx }))
                }
            </div>
        );
    }

    renderMainMenuLink(item) {
        const { hoveredItem } = this.state;
        const classes = [MAIN_MENU_CSS + '--link'];

        if (hoveredItem.href === item.href) {
            classes.push('active');
        } else if (item.active === true && Object.keys(hoveredItem).length === 0) {
            classes.push('active');
        }
        return (
            <a
                key={'desktop-main-menu-link-' + item.idx}
                className={classes.join(' ')}
                href={item.href}
                onMouseEnter={this.handleMouseEnter.bind(this, item)}
                onMouseLeave={this.handleMouseLeave.bind(this, item)}>
                {item.label}
            </a>
        );
    }

    renderSubMenu() {
        const { menuItems = [] } = this.props;

        return menuItems.reduce((result, item, idx) => {
            if (item.subItems.length > 0) {
                result.push(
                    <div
                        key={'sub-menu-container-' + idx}
                        className={SUB_MENU_CSS + '--container'}
                        onMouseEnter={this.handleMouseEnter.bind(this, item)}
                        onMouseLeave={this.handleMouseLeave.bind(this, item)}
                        style={this.getSubMenuStylesFor(item)}>
                        {
                            item.subItems.map((subItem, sIdx) => {
                                const linkClasses = [SUB_MENU_CSS + '--link'];
                                if (subItem.active === true) {
                                    linkClasses.push('active');
                                }
                                return (
                                    <a
                                        key={'sub-menu-link-' + idx + sIdx}
                                        className={linkClasses.join(' ')}
                                        href={subItem.href}>
                                        {subItem.label}
                                    </a>
                                );
                            }
                            )
                        }
                    </div>
                );
            }
            return result;
        }, []);
    }

    getSubMenuStylesFor(item) {
        const { hoveredItem } = this.state;
        let isVisible = false;

        if (hoveredItem.href === item.href) {
            isVisible = true;
        } else if (item.active === true && Object.keys(hoveredItem).length === 0) {
            isVisible = true;
        }

        return {
            display: isVisible === true ? 'flex' : 'none',
            zIndex: item.active === true ? 1 : 10
        };
    }

    renderFixedPositionHelper() {
        const { hoveredItem } = this.state;
        const { menuItems = [] } = this.props;
        const activeItemHasSubItems = menuItems.reduce((result, item) => {
            return result === true || (item.active === true && item.subItems.length > 0);
        }, false);
        let showSubMenuDummy = false;

        if (Object.keys(hoveredItem).length > 0 && hoveredItem.subItems.length > 0) {
            showSubMenuDummy = true;
        } else if (activeItemHasSubItems && Object.keys(hoveredItem).length === 0) {
            showSubMenuDummy = true;
        }

        return (
            <div className={CSS_CLASS + ' fixed-positon-helper'} style={{ position: 'relative', maxWidth: '100%', width: '100%' }}>
                <div className={TOP_MENU_CSS + '--container'}></div>
                <div className={CSS_CLASS + '--layout-helper'} style={{ height: '9.125rem' }}></div>
                {showSubMenuDummy ? <div className={SUB_MENU_CSS + '--container'} style={{ height: '2.5rem' }}></div> : null}
            </div>
        );
    }
}

export default DesktopHeader;