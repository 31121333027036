const normalize = url => {
    const regVal = typeof url === 'object' ? 'object' : url + '';

    return regVal
        .toLowerCase()
        .replace(/_|\s/ig, '-')
        .replace(/\/neuwagen\/(abarth|fiat-prof.)\//i, '/neuwagen/fiat/')
        .replace(/é/ig, 'e')
        .replace(/ä/ig, 'ae')
        .replace(/ö/ig, 'oe')
        .replace(/ü/ig, 'ue')
        .replace(/\/\//ig, '/');
};

const serializeQuery = location => {
    if (typeof location !== 'undefined' && location.hasOwnProperty('search')) {
        const query = location.search;

        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : null
    }

    return null;
}

const generateQueryStringFrom = params => {
    if (typeof params === 'object') {
        
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    }

    return '';
}

export default {
    normalize, serializeQuery, generateQueryStringFrom
};