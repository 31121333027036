import React from 'react';
import IconButton from '../../components/IconButton';
import CalendarSheet, { REVERSE_DATE_FORMAT } from './CalendarSheet';
import moment from 'moment';

import 'moment/locale/de';

class Datepicker extends React.Component {
    constructor(props) {
        super(props);

        this.elementRef = React.createRef();

        this.state = {
            selectedDate: this.props.value || moment().format('YYYYMMDD'),
            showSheet: false,
            location: null,
            measurements: null
        };

        this.handleShowSheet = this.handleShowSheet.bind(this);
        this.handleHideSheet = this.handleHideSheet.bind(this);
        this.handleDayChoose = this.handleDayChoose.bind(this);
    }

    componentDidMount() {
        const elem = document.getElementById(this.props.id);
        if (elem) {
            this.setState({
                location: elem.getBoundingClientRect(),
                measurements: {
                    width: elem.clientWidth,
                    height: elem.clientHeight
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedDate !== this.state.selectedDate) {
            const changFunc = this.props.onChange || function () { };
            changFunc(this.state.selectedDate);
        }
        if (prevProps.value !== this.props.value) {
            this.setState({ selectedDate: this.props.value });
        }
    }

    handleShowSheet() {
        this.setState({ showSheet: true });
    }

    handleHideSheet() {
        this.setState({ showSheet: false });
    }

    handleDayChoose(newDate) {
        this.setState({
            showSheet: false,
            selectedDate: newDate.format(REVERSE_DATE_FORMAT)
        });
    }

    render() {
        const { label, isRequired } = this.props;
        const { showSheet, selectedDate } = this.state;
        const labelText = isRequired === true ? label + '*' : label;
        const cssClasses = ['datepicker-input'];

        if (this.props.notValid === true) {
            cssClasses.push('error');
        }

        return (
            <span id={this.props.id} className={cssClasses.join(' ')} >
                <label className="label">{labelText}</label>
                <span
                    className="datepicker-input--form-element"
                    onClick={this.handleShowSheet}
                    ref={this.elementRef} >
                    <input
                        defaultValue={moment(selectedDate, 'YYYYMMDD').format('DD.MM.YYYY')}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readOnly === true} />
                    <IconButton variant="black_calendar_sheet" />
                </span>
                {showSheet ? this.renderCalendarSheet() : null}
            </span>
        );
    }

    renderCalendarSheet() {
        const { onDayValidation = () => true } = this.props;
        return (
            <React.Fragment>
                <CalendarSheet
                    className="datepicker-input--calendar-sheet"
                    value={this.state.selectedDate}
                    onChange={this.handleDayChoose}
                    onDayValidation={onDayValidation} />
                <div
                    className="datepicker-input--click-area"
                    onClick={this.handleHideSheet}>
                </div>
            </React.Fragment>
        );
    }
}

export default Datepicker;