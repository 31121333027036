import currency from '../../../../helper/Currency';

export const getMileageInfo = () => {
    return 'Die Laufleistung legt fest, wie viele Kilometer im Jahr laut Leasingvertrag gefahren werde dürfen.'
}

export const getDurationInfo = () => {
    return 'Die Laufzeit legt die Dauer des Leasingvertrages fest.'
}

export const getDeliveryCostsInfo = (value) => {
    return `Das Auslieferungspaket in Höhe von ${currency.toCurrency(value)} € beinhaltet Zulassung, Kennzeichen, Verbandskasten, Fußmatten und Warnweste und ist bei Kauf einmalig zu entrichten.`
}

export const getPurchasePriceInfo = () => {
    return 'Der Fahrzeugpreis stellt den aktuellen Wert des Fahrzeuges dar.'
}

export const getFinalRateInfo = () => {
    return 'Die Schlussrate ist mit Beendigung eines Finanzierungsvertrages der letzte offene Betrag, der zu zahlen ist, um den Vertag abzuschließen.'
}

export const getKwsInfo = () => {
    return 'Mit dem König Werkstattservice sichern Sie sich kostenfreie Inspektionen und vergünstigten Werkstattservice, der sich lohnt. <a href="/werkstatt/koenig-werkstattservice/">Mehr erfahren</a>'
}

export const getInsuranceInfo = () => {
    return 'Vollkasko & Haftpflichtversicherung für den Zeitraum von einem Jahr. Versicherungsumfang: Kfz-Haftpflicht, VK (500 € SB) - inkl. TK (150 € SB) · die Aktion gilt für Fahrer ab einem Alter von 25 J.';
}

export const getBatteryInfo = (capacity, range) => {
    return `${capacity} kWh Batterie<br/>Bei voll geladener Batterie erreicht das Fahrzeug eine Reichweite von bis zu ${range} km.`;
}