import './styles.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';

const CSS_CLASS = 'fc-vdp-hint';

class Hint extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        ReactDOM.findDOMNode(this).addEventListener('mousedown', this.handleClose.bind(this));
    }

    componentWillUnmount() {
        ReactDOM.findDOMNode(this).removeEventListener('mousedown', this.handleClose.bind(this));
        this._isMounted = false;
    }

    handleClose(e) {
        if (e.target.tagName === 'A') {
            window.open(e.target.href, '_blank');
        }

        if (this._isMounted) {
            this.setState({ visible: false });
        }
    }

    render() {
        const { text } = this.props;
        const { visible } = this.state;

        return (
            <span className={CSS_CLASS} onClick={() => this.setState({ visible: true })}>
                i
                <span className={visible ? 'active' : null}>
                    {ReactHtmlParser(text)}
                </span>
            </span>
        );
    }
}

export default Hint;