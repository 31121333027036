import React from 'react';
import CommonPage from '../CommonPage';
import Helmet from 'react-helmet';
import Breadcrumb from '../../components/Breadcrumb';
import API from '../../helper/api.helper';
import BrandNavigation from './components/BrandNavigation';
import JumpNavigation from './components/JumpNavigation';
import BranchListing from './components/BranchListing';
import BrandlistHelper from './helper/brandlist.helper';
import JumpNavigationHelper from './helper/jumpnavigation.helper';
import ReactHtmlParser from 'react-html-parser';
import KoenigImage from '../../components/KoenigImage';
import Woosmap from './components/Woosmap';

const mainCssClass = 'page-branches';

const crumbsItems = [
    { label: 'Start', path: '/' },
    { label: 'Standorte', current_page: true }
];

class BranchesPage extends React.Component {
    constructor(props) {
        super(props);
        let data, branches, brands = [], pageData = [];

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            /* istanbul ignore else */
            if (typeof window !== 'undefined') {
                data = window.__initialData__;
                delete window.__initialData__;
            }
        }

        if(typeof(data) !== 'undefined'){
            if (data[0].length > 1) {
                branches = data[0].sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
            } else {
                branches = data[0];
            }
            brands = data[1];
            pageData = data[2];
        }

        this.state = {
            branches: branches,
            brands: brands,
            pageData: pageData
        };
    }

    static requestInitialData(url) {
        if (url.indexOf(`/standorte/`) === 0) {
            const branches = API
                .GET(`/branches`)
                .catch(err => {
                    console.error(err);
                    return [];
                });

            const brands = API
                .GET(`/brands`)
                .catch(err => {
                    console.error(err);
                    return [];
                });

            const pageData = API
                .GET('/pages/standorte')
                .catch(err => {
                    console.error(err);
                    return [];
                });

            return Promise.all([branches, brands, pageData]);
        }
    }

    render() {
        const { branches, brands, pageData } = this.state;

        return (
            <CommonPage className={mainCssClass}>
                <Helmet>
                    <title>{pageData.title}</title>
                    <meta name="description" content={pageData.description}/>
                </Helmet>

                <Breadcrumb crumbs={crumbsItems} />

                <BrandNavigation
                    brands={BrandlistHelper.getIdList(brands)}
                    className={mainCssClass}
                />

                <h1>{pageData.headline}</h1>
                <Woosmap />

                <JumpNavigation jumpPoints={JumpNavigationHelper.mapToAlphabeticLetter(branches)} />

                <BranchListing
                    branches={branches}
                    brandFilter='all_items'
                />

                <h2>{pageData.sub_headline}</h2>

                <div className="content">
                    <div className="content--text">
                        {ReactHtmlParser(pageData.content)}
                    </div>
                    <div className="content--image">
                        <KoenigImage fileHash={pageData.image} alt={pageData.alt} />
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default BranchesPage;