import "./header.scss";

import React from 'react';
import MobileHeader from './components/MobileHeader';
import { SUB_MENU, MAIN_MENU, TOP_MENU } from '../../routes';
import brands from '../../../deployment/content/brands.json';
import DesktopHeader from "./components/DesktopHeader";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            brands
        };
    }

    render() {
        const { style } = this.props;

        return (
            <header className="header" style={style}>
                <MobileHeader
                    brands={this.state.brands}
                    menuItems={this.getMenuItems()}
                    isSubPage={this.isSubPage()} />
                <DesktopHeader
                    brands={this.state.brands}
                    menuItems={this.getMenuItems()} />
            </header>
        );
    }

    getPathname() {
        const retVal = typeof pathname !== 'undefined' ? pathname : window.location.pathname;
        return retVal;
    }

    isSubPage() {
        const pathname = this.getPathname();
        const submenuItems = this.getSubmenuItems();
        return submenuItems.reduce((isSub, item) => {
            return isSub || pathname.indexOf(item.path) === 0;
        }, false);
    }

    getMenuItems() {
        const { routes = [] } = this.props;
        const pathname = this.getPathname();

        const menuItems = routes.filter(route =>
            route.menu &&
            (route.menu.location === MAIN_MENU || route.menu.location === TOP_MENU)
        );

        return menuItems
            .sort((r1, r2) => {
                const pos1 = '' + r1.menu.location + (r1.menu.position || 0);
                const pos2 = '' + r2.menu.location + (r2.menu.position || 0);
                if (pos1 === pos2) {
                    return 0;
                }
                return pos1 > pos2 ? 1 : -1;
            })
            .map(item => {
                const retVal = {
                    href: this.withTrailingSlash(item.path),
                    label: item.menu.label,
                    active: true,
                    location: item.menu.location,
                    subItems: this.getSubmenuItemsFor(item),
                    target: item.menu?.target ? item.menu.target : "_top"
                };
                if (pathname.length > 1 && pathname.indexOf(item.path) !== 0) {
                    delete retVal.active;
                }
                if (pathname === '/') {
                    delete retVal.active;
                }

                const currentPathIncludesKarriere = pathname.indexOf('karriere') !== -1;
                const itemPathStartsWithUnternehmen = item.path.indexOf('/unternehmen') === 0;
                const itemPathNotIncludesKarriere = item.path.indexOf('karriere') === -1;
                if (currentPathIncludesKarriere && itemPathStartsWithUnternehmen && itemPathNotIncludesKarriere) {
                    delete retVal.active;
                }
                return retVal;
            });
    }

    getSubmenuItems() {
        const { routes = [] } = this.props;
        const submenuItems = routes.filter(route => route.menu && route.menu.location === SUB_MENU);
        return this.transformDynamicRoutes(submenuItems);
    }

    getSubmenuItemsFor(item) {
        const pathname = this.getPathname();
        const subItemMap = this.getSubItemMap();

        if (typeof subItemMap[item.path] !== 'undefined') {
            const subItems = this.transformDynamicRoutes(subItemMap[item.path]);
            return subItems.map(route => {
                const retVal = {
                    href: this.withTrailingSlash(route.path),
                    label: route.menu.label,
                    active: true
                }
                if (pathname.length > 1 && pathname.indexOf(route.path) !== 0) {
                    delete retVal.active;
                }
                if (pathname === '/') {
                    delete retVal.active;
                }

                return retVal;
            });
        }
        return [];
    }

    transformDynamicRoutes(submenuRoutes) {
        return submenuRoutes.reduce((retVal, route) => {
            if (typeof route.menu.label === 'string') {
                retVal.push(route);
            } else {
                route.menu.label.forEach(([dynamicRoute, label], idx) => {
                    const newRoute = JSON.parse(JSON.stringify(route));
                    const regexp = new RegExp(`:.+\\(.*${dynamicRoute}.*\\)`, 'i');

                    newRoute.path = route.path.replace(regexp, dynamicRoute);
                    newRoute.menu.label = label;
                    newRoute.menu.position = idx;

                    retVal.push(newRoute);
                });
            }
            return retVal;
        }, []);
    }

    hasSubItems(item) {
        const subItemMap = this.getSubItemMap();
        return typeof subItemMap[item.path] !== 'undefined';
    }

    getSubItemMap() {
        const { routes = [] } = this.props;

        return routes
            .filter(route => route.menu && route.menu.location === SUB_MENU)
            .reduce((map, route) => {
                const [parentRoute] = routes.filter(r =>
                    r.menu
                    && r.menu.location === MAIN_MENU
                    && route.path.indexOf(r.path) === 0
                );
                if (parentRoute) {
                    if (typeof map[parentRoute.path] !== 'undefined') {
                        map[parentRoute.path].push(route);
                    } else {
                        map[parentRoute.path] = [route];
                    }
                }
                return map;
            }, {});
    }

    withTrailingSlash(path) {
        return (path + '/').replace(/\/\/$/, '/');
    }

}

export default Header;