import 'isomorphic-fetch';

const BASE_URL = 'https://api-koenig.carliner.de/api/web/api/v1';
const HEADER = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
};

const DEFAULT_SORT = { 
	orderBy: { sort: 'preis', order: 'ASC'},
	limit: 30,
	page: 1, 
};


const fetchVehicles = query => {
	return fetch(`${BASE_URL}/vehicles`, {
		method: 'POST',
		body: JSON.stringify({ ...DEFAULT_SORT, ...query }),
		headers: HEADER,
	}).then(response => {
		return response.json();
	});
};

const fetchSearchParams = query => {
	const search = { s: { ...query } }

	return fetch(`${BASE_URL}/search`, {
		method: 'POST',
		body: JSON.stringify(search),
		headers: HEADER,
	}).then(response => {
		return response.json();
	});
}; 

const fetchVehicleById = wgnr => {
	return fetch(`${BASE_URL}/vehicles/${wgnr}`, {
		method: 'GET',
		headers: HEADER,
	}).then(response => {
		return response.json();
	});
};      

export default {
	fetchVehicles,
	fetchSearchParams,
	fetchVehicleById,
};
