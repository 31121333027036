import './styles.scss';
import React from 'react';
import Currency from '../../../../helper/Currency';
import NumberFormater from '../../../../helper/NumberFormater';

const CSS_CLASS = 'fc-payment-details';

class PaymentDetails extends React.Component {
    render() {
        const { vehicle } = this.props;

        return (
            <div className={CSS_CLASS}>
                <hr />
                <h2>Informationen zum Leasing des {vehicle.brand} {vehicle.model} {vehicle.version}</h2>
                <div>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                    {this.renderThirdColumn()}
                </div>
            </div>
        );
    }

    renderFirstColumn() {
        const { vehicle: { payments } } = this.props;

        return (
            <div className={CSS_CLASS + '--column'}>
                {this.renderMonthlyCosts(payments)}
                {this.renderSingleCosts(payments)}
            </div>
        );
    }

    renderSecondColumn() {
        const { vehicle } = this.props;
        const { payments, is_business } = vehicle;

        return (
            <div className={CSS_CLASS + '--column'}>
                {this.renderGeneralCosts(payments, is_business)}
                {this.renderAdditionalInfos(payments)}
            </div>
        );
    }

    renderThirdColumn() {
        const { vehicle: { payments } } = this.props;

        return (
            <div className={CSS_CLASS + '--column'}>
                {this.renderProviderInfos(payments)}
            </div>
        );
    }

    renderMonthlyCosts(payments = {}) {
        const { leasing = {} } = payments;
        const { is_business } = this.props.vehicle;

        let netRate;

        if (leasing.rate) {
            netRate = is_business ? leasing.rate : this.getNetRate(leasing.rate);
        }

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Monatliche Kosten</div>
                <div className={CSS_CLASS + '--list'}>
                    {!is_business && this.renderListPoint('Rate (brutto):', this.getPrice(leasing.rate))}
                    {this.renderListPoint('Rate (netto):', this.getPrice(netRate))}
                    {this.renderListPoint('Laufzeit:', leasing.duration + ' Monate')}
                </div>
            </div>
        );
    }

    renderSingleCosts(payments = {}) {
        const { leasing } = payments;
        const total = leasing.supply_costs + leasing.down_payment;

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Einmalige Kosten</div>
                <div className={CSS_CLASS + '--list'}>
                    {this.renderListPoint('Anzahlung:', this.getPrice(leasing.down_payment))}
                    {this.renderListPoint('Bereitstellungskosten:', this.getPrice(leasing.supply_costs))}
                    {this.renderListPoint('', ' ')}
                    {this.renderListPoint('Gesamt, einmalig:', this.getPrice(total))}
                </div>
            </div>
        );
    }

    getPrice(val) {
        if (val === 0) {
            return '0,00 €'
        }

        return Currency.toCurrency(val || 0, true) + ' €';
    }

    getNetRate(rate) {
        const withOutTax = rate / 119 * 100;

        return parseInt(withOutTax);
    }

    renderGeneralCosts(payments, isBusiness) {
        const { leasing, prices } = payments;
        const total = leasing.rate * leasing.duration;
        const listPriceLabel = isBusiness ? 'Nettolistenpreis:' : 'Bruttolistenpreis:'

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Allgemeine Kosten</div>
                <div className={CSS_CLASS + '--list'}>
                    {this.renderListPoint('Leasingfaktor:', leasing.factor)}
                    {this.renderListPoint('Leasing-Gesamtbetrag:', this.getPrice(total))}
                    {this.renderListPoint(listPriceLabel, this.getPrice(prices.uvp))}
                    {this.renderListPoint('Kaufpreis:', this.getPrice(leasing.purchase_price > 0 ? leasing.purchase_price : prices.cash))}
                    {this.renderListPoint('Jährliche Fahrleistung:', NumberFormater.addNumberSeparator(leasing.mileage) + ' km / Jahr')}
                </div>
            </div>
        );
    }

    renderAdditionalInfos(payments) {
        const { leasing } = payments;
        const total = leasing.rate * leasing.duration;

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Zusätzliche Informationen</div>
                <div className={CSS_CLASS + '--list'}>
                    {this.renderListPoint('Mehr-km Kosten:', this.getPrice(leasing.more_km_costs))}
                    {this.renderListPoint('Minder-km Vergütung:', this.getPrice(leasing.less_km_costs))}
                    {this.renderListPoint('Effektiver Jahreszins:', leasing.effective_interest + ' %')}
                    {this.renderListPoint('Sollzins gebunden p.a.:', leasing.debit_interest + ' %')}
                    {this.renderListPoint('Nettodarlehensbetrag:', this.getPrice(total))}
                </div>
            </div>
        );
    }

    renderProviderInfos(payments) {
        const { leasing } = payments;

        return (
            <div>
                <div className={CSS_CLASS + '--title'}>Darlehensgeber</div>
                <p className={CSS_CLASS + '--list'}>
                    {leasing.provider_name}
                    <br />
                    {leasing.provider_address}
                    <br /><br />
                    Die Nutzung des Leasingangebotes setzt die vorhandene Bonität voraus.
                    <br /><br />
                    {
                        leasing.down_payment === 0 && '*Die dargestellten Angaben gelten für das Angebot ohne Anzahlung.'
                    }
                </p>
            </div>
        );
    }

    renderListPoint(label, value) {
        if (value) {
            return (
                <div className={CSS_CLASS + '--list-point'}>
                    <span>{label}</span>
                    <span>{value}</span>
                </div>
            );
        }
    }
}

export default PaymentDetails;