import React from 'react';
import CommonPage from '../CommonPage';
import Helmet from 'react-helmet';
import Breadcrumb from '../../components/Breadcrumb';
import KoenigButton from '../../elements/KoenigButton';
import Icon from '../../components/Icon';

const CSS_CLASS = 'page-onlineshop-danke';

class OnlineshopDankePage extends React.Component {
    render() {
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Onlineshop Danke', current_page: true }
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Onlineshop - Danke - Autohaus König</title>
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                <div className={CSS_CLASS}>
                    <h1 className={CSS_CLASS + '--headline'}>
                        Vielen Dank, dass Sie sich für ein Fahrzeug<br />
                        der Autohaus Gotthard König GmbH entschieden haben.
                    </h1>

                    <div className={CSS_CLASS + '--indicator'}>
                        <div className={CSS_CLASS + '--indicator--icon'}>
                            <Icon
                                variant="checkmark"
                                color="white"
                                style={{ display: 'block', width: '1.5rem', height: '1.25rem' }} />
                        </div>
                        <b className={CSS_CLASS + '--indicator--text'}>Bestellung abgeschlossen</b>
                    </div>

                    <KoenigButton
                        className={CSS_CLASS + '--home-link'}
                        text="Zurück zur Startseite"
                        linkTo="/" />
                </div>


            </CommonPage>
        );
    }
}

export default OnlineshopDankePage;