import React from 'react';

class FacebookPixel extends React.Component {
    componentDidMount() {
        if (typeof window !== 'undefined' && typeof fbq === 'undefined') {
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            fbq('init', '652237905491443');
            fbq('init', '254320212757558');

            const img1 = document.createElement('img');
            img1.src = "https://www.facebook.com/tr?id=652237905491443&ev=PageView&noscript=1";
            img1.height = "1";
            img1.width = "1";
            img1.style = "display:none";

            const img2 = document.createElement('img');
            img2.src = "https://www.facebook.com/tr?id=254320212757558&ev=PageView&noscript=1";
            img2.height = "1";
            img2.width = "1";
            img2.style = "display:none";

            document.head.appendChild(img1);
            document.head.appendChild(img2);
        }
    }

    render() {
        return null;
    }
}

export default FacebookPixel;