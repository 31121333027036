import './styles.scss';

import React from 'react';
import Overlay from '../../../../components/Overlay';
import KoenigSelect from '../../../../elements/KoenigSelect';
import ContactForm from '../../../../components/ContactForm';
import Checkbox from '../../../../elements/Checkbox';
import KoenigButton from '../../../../elements/KoenigButton';
import ApiHelper from '../../../../helper/api.helper';
import CurrencyHelper from '../../../../helper/Currency';
import MoAdPixel from '../../../../components/MoAdPixel';
import fbHelper from '../../../../helper/facebook.helper';
import { addConversionTag } from '../../../../components/Criteo';

class InquiryOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            branches: [],
            branch: 'Zentrale Verwaltung',
            salutation: null,
            firstname: null,
            lastname: null,
            address: null,
            zip_code: null,
            location: null,
            email: null,
            phone: null,
            message: 'Bitte kontaktieren Sie mich',
            agb: false,
            showSuccessful: false,
            showFailure: false,
            inquiryId: null,
            inProgress: false
        };
    }

    async componentDidMount() {
        const branches = await ApiHelper.GET('/branches?sale_brand=' + this.props.brand);
        this.setState({ branches });

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://aflare.de/fbp/v/1263/Contact';
        document.head.appendChild(script);
    }

    sendInquiry() {
        this.setState({inProgress: true});
        const { vehicle = {}, kws, insurance = null } = this.props;
        const { payments = {} } = vehicle;
        const leasingRate = typeof payments.leasing !== 'undefined'
            ? CurrencyHelper.toCurrency(payments.leasing.rate) + '€'
            : '';
        const developed_from = [
            vehicle.brand, vehicle.model, vehicle.version,
            (leasingRate ? 'für' : ''),
            leasingRate
        ].join(' ');
        const endpoint = vehicle.is_out_of_stock === true
            ? '/out_of_stock_inquiries'
            : '/inquiries';

        const data = {
            ...this.state,
            kws,
            insurance,
            developed_from,
            v_id: vehicle._id
        };

        delete data.branches;
        delete data.showSuccessful;
        delete data.showFailure;
        delete data.inquiryId;
        delete data.inProgress;

        ApiHelper
            .POST(endpoint, data)
            .then(res => {
                this.setState({ showSuccessful: true, inProgress: false });
                this.renderTrackingPixel(res._id)
                addConversionTag(leasingRate / 100, vehicle._id, data.email, data.zip_code, res._id);

                if (typeof res._id !== 'undefined') {
                    this.setState({ inquiryId: res._id });
                }
            }).catch(error => {
                this.setState({ showFailure: true, inProgress: false });
            });
    }

    render = () => {
        const { branches, showSuccessful, showFailure, inProgress } = this.state;
        const branchOptions = branches
            .map(b => b.official_name ? b.name + ' (' + b.official_name + ')' : b.name)
            .sort();
        const { vehicle } = this.props;

        if (showSuccessful) {
            return this.renderSuccessful();
        }

        if (showFailure) {
            return this.renderFailure();
        }

        return (
            <Overlay {...this.props}>
                <form className="overlay--content--reserve-form">
                    <h2>{typeof (vehicle) !== 'undefined' && vehicle.is_out_of_stock === true ? "Fahrzeug jetzt vormerken" : "Angebot zu diesem Fahrzeug anfordern"}</h2>
                    <KoenigSelect
                        label="Wählen Sie Ihre Wunschfiliale"
                        value="Zentrale Verwaltung"
                        options={branchOptions}
                        onChange={branch => this.setState({ branch })} />
                    <ContactForm
                        onChange={(key, val) => this.setState({ [key]: val })} />
                    <div className="cnt-agb-datenschutz">
                        <Checkbox
                            onChange={agb => this.setState({ agb })} />
                        <span className="koenig-checkbox-text">
                            <a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> habe ich gelesen
                        </span>
                        <span>(*) = Pflichtfelder</span>
                    </div>
                    <KoenigButton
                        text="Anfrage senden"
                        onClick={this.sendInquiry.bind(this)}
                        disabled={!this.isValid() || inProgress} />
                </form>
            </Overlay>
        );

    }

    renderSuccessful() {
        const { inquiryId } = this.state;
        const { onClose = () => { }, vehicle } = this.props;

        return (
            <Overlay className="successful" onClose={onClose}>
                <h1>Vielen Dank für Ihre Anfrage!</h1>
                <p>
                    Schön, dass Sie sich für unser Angebot interessieren.<br />
                    Wir werden schnellstmöglich Ihre Anfrage bearbeiten und uns bei Ihnen zurückmelden.
                </p>
                <p>Ihr Autohaus König</p>

                <MoAdPixel vehicle={vehicle} inquiryId={inquiryId} />

                <KoenigButton
                    className="btn-back-to-page"
                    text="Zurück zur Fahrzeugseite"
                    onClick={this.props.onClose}
                />
            </Overlay>
        )
    }

    renderFailure() {
        const { onClose = () => { } } = this.props;
        return (
            <Overlay className="failure" onClose={onClose}>
                <h1>Vielen Dank für Ihre Anfrage!</h1>
                <p>
                    Leider ist ein Problem aufgetreten.<br />
                    Bitte wenden Sie sich mit Ihrer Anfrage direkt an:
                </p>
                <p>
                    <a href="mailto:anfrage@autohaus-koenig.de">anfrage@autohaus-koenig.de</a>
                </p>
                <p>Ihr Autohaus König</p>

                <KoenigButton
                    className="btn-back-to-page"
                    text="Zurück zur Fahrzeugseite"
                    onClick={this.props.onClose}
                />
            </Overlay>
        )
    }

    isValid = () => {
        const allValuesAreSet = [
            'branch', 'salutation', 'firstname', 'lastname',
            'zip_code', 'email', 'phone'
        ].reduce((valid, key) => valid && this.state[key] !== null && this.state[key].length > 0, true);
        return allValuesAreSet && this.state.agb === true;
    }

    renderTrackingPixel(inquiryId) {
        this.renderAflarePixel();
        this.renderContactImpactPixel(inquiryId);
        this.renderFacebookPixel();
    }

    renderAflarePixel() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://aflare.de/fbp/v/1263/Lead';
        document.head.appendChild(script);
    }

    renderContactImpactPixel(inquiryId) {
        if (inquiryId) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = 'https://r.df-srv.de/v2/SOLD/HoYhIwgihCCGXCBqHXWY/a.js?value=' + inquiryId;
            document.head.appendChild(script);
        }
    }

    renderFacebookPixel() {
        fbHelper.track('Lead');
    }
}

export default InquiryOverlay;