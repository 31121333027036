import "./styles.scss";

import React from 'react';
import Icon from '../../../../../components/Icon';
import Functions from '../../functions';
import moment from 'moment';

import "moment/locale/de";
import "moment-timezone";

export const PLEASE_DO_SELECT = "Bitte wählen";

const CSS_CLASS = 'wtt-summary';
const SCHEDULE_CSS = CSS_CLASS + '--schedule';
const ITEM_LIST_CSS = CSS_CLASS + '--item-list';
const TOTAL_CSS = CSS_CLASS + '--total';

class Summary extends React.Component {

    render() {
        const {
            shop_items = [],
            onRemoveItem = () => {
            },
            kws_member = false
        } = this.props;
        const totalPrice = Functions.getTotalPrice(shop_items, kws_member);

        return (
            <div className={CSS_CLASS}>
                {this.props.branch?.mpo?.length > 0 &&
                    <MpoHint/>
                }
                {!this.props.branch?.mpo &&
                    <section className={CSS_CLASS + "--ahk-booking"}>
                        <div className={CSS_CLASS + '--box-header'}>Ihre Übersicht</div>
                        <div className={SCHEDULE_CSS}>

                            {this.renderVehicleRow()}
                            {this.renderBranchRow()}

                        </div>

                        <div className={ITEM_LIST_CSS}>
                            {
                                shop_items.map((item, idx) => {
                                    const {_id, title, prices} = item;
                                    const classes = [ITEM_LIST_CSS + '--item'];
                                    const isKwsItem = kws_member === true && prices.hasOwnProperty("kws");

                                    if (isKwsItem) {
                                        classes.push('kws');
                                    }

                                    return (
                                        <div key={'si-item-' + _id + title + idx} className={classes.join(' ')}>
                                            <span className={ITEM_LIST_CSS + '--item--title'}>{title}</span>
                                            <Icon variant="trash"
                                                  onClick={() => onRemoveItem(_id)}
                                                  style={{height: '1rem', display: 'block'}}/>
                                            <div className={ITEM_LIST_CSS + '--item--total'}>
                                                <span
                                                    className="default">{Functions.toCurrency(prices.default)} €</span>
                                                {
                                                    isKwsItem
                                                        ? <span
                                                            className="kws">{Functions.toCurrency(prices.kws)} €</span>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                shop_items.length === 0
                                    ? <span className="empty-list">Es wurden noch keine Leistungen gewählt</span>
                                    : null
                            }
                        </div>

                        <div className={TOTAL_CSS}>
                            <span className={TOTAL_CSS + '--text'}>inkl. 19% MwSt.<br/><b>Summe</b></span>
                            <span
                                className={TOTAL_CSS + '--price'}>{Functions.toCurrency(totalPrice)}<span>€</span></span>
                        </div>
                    </section>
                }
            </div>
        );
    }

    renderVehicleRow() {
        const {
            brand = {},
            model = {},
        } = this.props;

        const isBrandSet = brand && brand.label;
        const isModelSet = model && model.label;
        let vehicleText = isBrandSet && isModelSet ? `${brand.label} ${model.label}` : PLEASE_DO_SELECT;

        if (vehicleText !== PLEASE_DO_SELECT && /sonstige/i.test(brand._id)) {
            vehicleText = model.label;
        }
        return (
            <div className={SCHEDULE_CSS + '--vehicle'}>
                <Icon variant="vehicle_small_compact" style={{width: '2rem', display: 'block'}}/>
                <span className={SCHEDULE_CSS + '--vehicle--text'}>{vehicleText}</span>
            </div>
        );
    }

    renderBranchRow() {
        const {branch, day = null, time = null} = this.props;
        const isBranchSet = branch && branch.label && branch.address_row;
        const classes = [SCHEDULE_CSS + '--branch--text'];

        let branchText = PLEASE_DO_SELECT;
        if (isBranchSet) {
            branchText = (
                <React.Fragment>
                    <b>{branch.label}</b>
                    <span>{branch.address_row}</span>
                    {day !== null ? moment(day, 'YYYYMMDD').format('DD.MM.YYYY') : null}
                    {time !== null ? ` um ${time} Uhr` : null}
                </React.Fragment>
            );
        } else {
            classes.push('empty');

        }

        return (
            <div className={SCHEDULE_CSS + '--branch'}>
                <Icon variant="location_pin" style={{height: '2rem', display: 'block'}}/>
                <span className={classes.join(' ')}>{branchText}</span>
            </div>
        );
    }
}

function MpoHint() {
    return (
        <section className={CSS_CLASS + "--mpo-hint"}>
            <header>
                <Icon variant="caution_info"/>
                <h3>Achtung!</h3>
            </header>
            <p>
                Sie haben einen Standort gewählt der bereits unser neues Buchungssystem verwendet.
            </p>
        </section>
    )
}

export default Summary;