import './styles.scss';

import KoenigImage from '../KoenigImage';
import React from 'react';

const CSS_CLASS = 'fc-teaser-img';

function TeaserImage({teaser_src = {}, alt}) {
    const { mobile, desktop } = teaser_src;

    return (
        <div className={CSS_CLASS}>
            {
                mobile && <KoenigImage className={CSS_CLASS + '--mobile'} fileHash={mobile} alt={alt} />
            }
            {
                desktop && <KoenigImage className={CSS_CLASS + '--desktop'} fileHash={desktop} alt={alt} />
            }
        </div>
    );
}

export default TeaserImage;