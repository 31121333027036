import React from 'react';
import Helmet from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/UsedCarButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import API from '../../helper/api.usedCars.helper';
import fbHelper from '../../helper/facebook.helper';

import CommonPage from '../CommonPage';
import Pagination from './components/Pagination';
import ResultItem from './components/ResultItem';
import SortBar from './components/SortBar';
import Search from '../../components/Search';
import KoenigButton from '../../elements/KoenigButton';

import './styles/styles.scss';
import AutoUncle from '../../components/AutoUncle';

const crumbs = [
	{ label: 'Start', path: '/' },
	{ label: 'Gebrauchtwagen', path: '/gebrauchtwagen' },
	{ label: 'Gebrauchtwagenangebote', current_page: true }
];


class GebrauchtwagenAngebotePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			query: qs.parse(props.location.search, { ignoreQueryPrefix: true }),
			data: { count: 0 },
			isLoading: true,
			facebookIds: []
		}
		this.resultListRef = React.createRef();
	}

	fetch() {
		this.setState({ isLoading: true })
		const pathname = this.props.location.pathname;
		const searchString = qs.stringify({ ...this.state.query });

		this.props.history.push({
			pathname: pathname,
			search: searchString,
		});

		API.fetchVehicles(this.state.query)
			.then((res) => {
				this.setState({ data: res })
			})
			.finally(() => {
				this.setState({ isLoading: false })
			})
	}

	componentDidMount() {
		this.fetch();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.query !== this.state.query) {
			this.fetch();
			fbHelper.track('Search', {
				content_type: 'vehicle',
				content_ids: this.state.facebookIds,
			});
		}
	}


	formatTotalCount = (count) => {
		const num = Number(count);
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	}

	render() {
		const { isLoading, data, query, facebookIds } = this.state;

		return (
			<CommonPage className='page-gebrauchtwagen'>
				<Helmet>
					<title>Königs Gebrauchtwagen - Autohaus König</title>
					<meta name="robots" content="noindex"></meta>
				</Helmet>

				<Breadcrumb crumbs={crumbs} />

				<h1>
					<KoenigButton
						text="Zurück"
						linkTo="/gebrauchtwagen/"
						className="button-back"
					/>
					Wir zeigen Ihnen {data.count && this.formatTotalCount(data.count)} Treffer
				</h1>

				{data && (
					<Search
						resultListRef={this.resultListRef}
						onSearch={(searchParams) => this.setState({ query: { ...query, ...searchParams } })}
					/>
				)}

				{isLoading ? (
					<LoadingSpinner />
				) : (
						<React.Fragment>
							<SortBar
								currentOrderBy={query.orderBy ? query.orderBy : data.orderBy}
								onChange={(orderBy) => this.setState({ query: { ...query, orderBy: orderBy } })}
							/>

							<div ref={this.resultListRef} className='result-list'>
								{data.vehicles.map((car) => {
									facebookIds.push(car.wgnr);

									return (
										<ResultItem key={car.wgnr} vehicle={car} />
									)
								})}
							</div>

							<div className="page-gebrauchtwagen__footer">
								<Button title="Zurück nach oben" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />

								{data.pages && (
									<Pagination
										pages={data.pages}
										currentPage={data.page}
										onSelectPage={(p) => this.setState({ query: { ...this.state.query, page: p } })}
									/>
								)}
							</div>
						</React.Fragment>
					)}
				<AutoUncle />
			</CommonPage>
		);
	}

};

export default GebrauchtwagenAngebotePage;
