import apiHelper from './api.helper';
import moment from "moment";

import "moment/locale/de";
import "moment-timezone";

const track = (event_name, custom_data, isCustomEvent = false) => {
    if (window) {
        const body = {
            event_name,
            event_time: moment().tz('Europe/Berlin').unix(),
            action_source: 'website',
            custom_data,
            user_data: {
                client_user_agent: window.navigator.userAgent
            }
        };
        const track = isCustomEvent ? 'trackCustom' : 'track';

        window.fbq(track, event_name, custom_data);
        //apiHelper.POST('/facebook', body);
    }
}

export default {track};