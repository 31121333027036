export const GEAR_TYPES = {
    1: 'Schaltgetriebe',
    2: 'Halbautomatik',
    3: 'Automatik'
}
export const STATUS_TYPES = {
    1: 'Neuwagen',
    2: 'Tageszulassung',
    3: 'Vorführwagen'
}
export const PROVISION_TYPES = {
    1: 'Sofort verfügbar',
    2: 'Bestellfahrzeug',
    3: 'im Vorlauf'
}
export const DRIVE_TYPES = {
    1: 'Frontantrieb',
    2: 'Heckantrieb'
}
export const EMISSION_CLASS_TYPES = {
    1: 'Euro1',
    2: 'Euro2',
    3: 'Euro3',
    4: 'Euro4',
    5: 'Euro5',
    6: 'Euro6',
    7: 'Euro6d',
    8: 'Euro6d Temp'
}

/**
 * Don't forget to alter the NGinX configuration on the live server when a new brand was added.
 * IMPORTANT: only add new and don't remove existing entries for backward compatibility.
 *
 * Search for (/var/www/proxy/data/nginx/app.ssl.conf):
 *
 * ```
 * location ~* /neuwagen/(piaggio|eschwalbe|aprilia|vespa|ape|moto-guzzi|govecs|suzuki-roller)/(.*) {
 *   return 301 /zweirad/$1/$2;
 * }
 *
 * location ~* /zweirad/(renault|fiat|fiat-professional|elektro|dacia|jeep|alfa-romeo|kia|suzuki|maxus|volkswagen|mazda|opel|citroen|mg-motor)/(.*) {
 *   return 301 /neuwagen/$1/$2;
 * }
 * ```
 */
export const BRAND_REG_EXP = "renault|fiat|fiat-professional|elektro|dacia|jeep|alfa-romeo|vespa|kia|piaggio|ape|suzuki|maxus|aprilia|govecs|elaris|moto-guzzi|volkswagen|eschwalbe|mazda|opel|citroen|suzuki-roller";