const toCents = val => {
    val = (val + "")
        .replace(/\./g, '')
        .replace(/,/g, '.');
    if (isNaN(val)) {
        val = parseFloat(val);
    }
    return parseInt(val * 100);
};

const toCurrency = (val, withCent = false) => {
    let output = '';
    const centsAsString = '000' + val;
    const asDecimalString = centsAsString.substr(0, (centsAsString.length - 2)) + ',' + centsAsString.substr(-2);
    output = asDecimalString.replace(/,00$/i, '');
    output = __addNumberSeparator(output);
    output = output.replace(/^[0]+/i, '').replace(/^,/i, '0,');

    if (withCent && !output.includes(',')) {
        output = output + ',00';
    }

    return output.length > 0 ? output : '0';
};

const currencyFormat = (price) => {
    const num = Number(price);
    return '€ ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ,-';
};

export default {
    toCents, toCurrency, currencyFormat,
};

//----------
// Internals
//------------

const __addNumberSeparator = val => {
    const parts = val.split(',');
    let retVal = parts[0].replace(/^0+/i, '');
    while (3 - (retVal.length % 3) !== 3) {
        retVal = '0' + retVal;
    }
    const chunks = retVal.match(/\d{1,3}/g);
    retVal = chunks ? chunks.join('.') : '';
    return retVal + (parts.length > 1 ? ',' + parts[1] : '');
};
