import React from 'react';
import PropTypes from 'prop-types';

class Hint extends React.Component {
    contentRef = React.createRef();
    triggerRef = React.createRef();

    state = {
        showPanel: false
    }

    render = () => {
        const { showPanel } = this.state;
        const { children, linkTarget = "" } = this.props;
        const { current } = this.contentRef;

        const visibleStyling = this.getVisibleStyling();

        return (
                <a
                    ref={this.triggerRef}
                    className="hint--trigger"
                    href={linkTarget}
                    target="_blank"
                    onMouseEnter={() => this.setState({ showPanel: true })}
                    onMouseLeave={() => this.setState({ showPanel: false })}
                >
                    ?
                <div
                        className="hint--panel"
                        onMouseEnter={() => this.setState({ showPanel: true })}
                        onMouseLeave={() => this.setState({ showPanel: false })}
                        style={showPanel ? visibleStyling : null}>
                        <div className="hint--panel-content" ref={this.contentRef}>{children}</div>
                    </div>
                </a>
        );
    }

    getVisibleStyling = () => {
        const { width, height } = this.getHintContentRect();


        return {
            height: ((height + 30) / 16) + 'rem',
            width: ((width + 30) / 16) + 'rem',
            top: '1.375rem',
            opacity: 1,
            transition: 'opacity .25s linear'
        };
    }

    getHintContentRect = () => {
        const { current } = this.contentRef;

        if (current && current.childNodes[0] && this.isBrowser()) {
            return current.childNodes[0].getBoundingClientRect();
        }
        return {};
    }

    getLeftPos = () => {
        const { current: trigger } = this.triggerRef;
        const { current: content } = this.contentRef;

        if (trigger && content && this.isBrowser()) {
            const windowWidth = window.innerWidth;
            const rightEdge = trigger.offsetLeft + content.clientWidth;
            const isAboveRightEdge = windowWidth < rightEdge;


            if (isAboveRightEdge) {
                const newLeft = trigger.offsetLeft + (windowWidth - rightEdge - 60);
                return newLeft < 0 ? 0 : newLeft;
            }

            return trigger.offsetLeft;
        }
        return 0;
    }

    isBrowser = () => {
        return typeof window !== 'undefined' && window.document;
    }
}

Hint.propTypes = {
    panelWidth: PropTypes.number.isRequired
};

export default Hint;