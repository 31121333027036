const getCo2Class = (combinedCo2, kerbWeight) => {

    if (!combinedCo2 || !kerbWeight) {
        return null;
    }

    const reference = 36.59079 + 0.08987 * kerbWeight;
    const percent = (combinedCo2 - reference) * 100 / reference;

    switch (true) {
        case percent <= -55:
            return 'a+++';
        case percent <= -46:
            return 'a++';
        case percent <= -37:
            return 'a+';
        case percent <= -28:
            return 'a';
        case percent <= -19:
            return 'b';
        case percent <= -10:
            return 'c';
        case percent <= -1:
            return 'd';
        case percent <= 8:
            return 'e';
        case percent <= 17:
            return 'f';
        default:
            return 'g';
    }
}

export default {
    getCo2Class
}