import React from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import CommonPage from '../CommonPage';
import PageTile from '../../components/PageTile';
import API from '../../helper/api.helper';

import '../styles.scss';

import Breadcrumb from '../../components/Breadcrumb';
import KoenigImage from '../../components/KoenigImage';

const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Werkstatt', current_page: true },
];

class Werkstatt extends React.Component {
    constructor(props) {
        super(props);
        let data;

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            data = window.__initialData__;
            delete window.__initialData__;
        }

        const [pageTiles = [], pageData = {}] = data;

        this.state = {
            tiles: pageTiles,
            ...pageData
        };
    }

    static requestInitialData(url) {
        if (url === '/werkstatt/') {
            const pageTiles = API
                .GET('/page_tiles?page=werkstatt')
                .catch(err => console.error(err));

            const pageData = API
                .GET('/pages/werkstatt')
                .catch(err => {
                    console.error(err);
                    return [];
                });

            return Promise.all([pageTiles, pageData]);
        }
    }

    render() {
        const { tiles = [] } = this.state;
        const { headline = "", sub_headline = "", content = "", image = "" } = this.state;

        return (
            <CommonPage>
                <div id="werkstatt_content">
                    <Helmet>
                        <title>Werkstatt &amp; Service - Autohaus König</title>
                    </Helmet>

                    <Breadcrumb crumbs={crumbs} />

                    <h1>
                        {headline}<br />
                        Jetzt Termin vereinbaren unter: 0800&nbsp;-&nbsp;723&nbsp;52&nbsp;51
                </h1>

                    <div className="cnt-page-tiles">
                        {
                            tiles.map((tile, idx) => <PageTile key={idx} {...tile} />)
                        }
                    </div>

                    <h2>{sub_headline}</h2>

                    <div className="content">
                        <div className="content--text">
                            {ReactHtmlParser(content)}
                        </div>
                        <div className="content--image">
                            <KoenigImage fileHash={image} alt={sub_headline} />
                        </div>
                    </div>
                </div>
            </CommonPage>
        );
    }
}

export default Werkstatt;
