import "./styles.scss";

import React from 'react';
import Icon from "../../../../../components/Icon";
import Functions from "../../functions";

const CSS_CLASS = "wtt--info-box";
const CSS_ROW = CSS_CLASS + '--row';
const CSS_BOX = CSS_CLASS + '--box';
const CSS_LISTING = CSS_BOX + '--listing';

class InfoBox extends React.Component {

    handleLinkTo() {
        const { linkTo, appointment } = this.props;

        Functions.linkTo(linkTo, appointment);
    }

    render() {
        const { className, style } = this.props;
        const classes = [CSS_CLASS];

        if(className){
            classes.push(className);
        }

        return (
            <div className={classes.join(' ')} style={style}>
                {this.renderLabelLinkRow()}
                {this.renderBox()}
            </div>
        )
    }

    renderLabelLinkRow() {
        const { label } = this.props;

        return (
            <div className={CSS_ROW}>
                <b className={CSS_CLASS + '--label'}>{label}</b>
                {this.renderLinkIcon()}
            </div>
        );
    }

    renderLinkIcon() {
        const { linkTo } = this.props;

        if (!linkTo) {
            return;
        }

        return (
            <div className={CSS_CLASS + '--link'}>
                <Icon
                    variant="edit"
                    onClick={this.handleLinkTo.bind(this)}
                    style={{ display: 'block', width: '1.25rem' }} />
            </div>
        );
    }

    renderBox() {
        const { headline } = this.props;

        return (
            <div className={[CSS_ROW, CSS_BOX].join(' ')}>
                <b className={CSS_BOX + '--headline'}>{headline}</b>
                {this.renderListings()}
                {this.renderText()}
            </div>
        );
    }

    renderListings() {
        const { infoItems = [] } = this.props;

        if (infoItems.length === 0) {
            return;
        }

        if (Array.isArray(infoItems[0])) {
            return (
                <React.Fragment>
                    {this.renderSingleListing(infoItems[0], true)}
                    {this.renderSingleListing(infoItems[1], true)}
                </React.Fragment>
            );
        }

        return this.renderSingleListing(infoItems);
    }

    renderText() {
        const { infoText } = this.props;

        return infoText && <p>{infoText}</p>;
    }

    renderSingleListing(listing, isMultiColumn) {
        const classes = [CSS_LISTING];

        if (isMultiColumn) {
            classes.push('multi-column');
        }

        return (
            <table className={classes.join(' ')}>
                <tbody>
                    {
                        listing.map((item, idx) => {
                            const keyNames = Object.keys(item);
                            let infoValue = item[keyNames[0]];

                            if (Array.isArray(infoValue)) {
                                infoValue = infoValue.map((line, idx) =>
                                    <React.Fragment key={CSS_CLASS + 'item-line' + idx}>
                                        {line}
                                        {idx + 1 < infoValue.length ? <br /> : null}
                                    </React.Fragment>
                                );
                            }

                            return (
                                <tr
                                    key={CSS_CLASS + 'item-' + idx}
                                    className={CSS_LISTING + '--item'}>

                                    <td valign="top">{keyNames[0]}:</td>
                                    <td >{infoValue}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }
}

export default InfoBox;