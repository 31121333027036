import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import API from '../../helper/api.usedCars.helper';
import Picker from './Picker';
import CarChooser from './CarChooser';
import RangeSlider from '../RangeSlider';
import UsedCarButton from '../UsedCarButton';

import './styles/search.scss';

const SEARCH_MAIN = [
	{ title: 'Marke', key: 'marke', searchKey: 'markeId' },
	{ title: 'Modell', key: 'modell', searchKey: 'modell' },
	{ title: 'Angebotstyp', key: 'fzartId', searchKey: 'fzartId' },
	{ title: 'Filiale', key: 'location', searchKey: 'locationId' },
];

const SEARCH_EZ = [
	{ title: 'Von', key: 'ez_von', searchKey: 'von' },
	{ title: 'Bis', key: 'ez_bis', searchKey: 'bis' },
];

const SEARCH_SLIDER = [
	{ title: 'Preis', key: 'preis_von', searchKey: 'preis', unit: '€', min: 0, max: 80000, steps: 500 },
	{ title: 'Kilometer', key: 'km_von', searchKey: 'km', unit: 'km', min: 0, max: 200000, steps: 500 },
];

const SEARCH_ADDITIONAL = [
	{ title: 'Kategorie', key: 'aufbauBereich', searchKey: 'aufbauBereich' },
	{ title: 'Kraftstoff', key: 'kraftstoff', searchKey: 'kraftstoff' },
	{ title: 'Getriebe', key: 'getriebe', searchKey: 'getriebeId' },
];

class Search extends React.Component {
	constructor(props) {
		super(props);
		let initialSearch = { ...qs.parse(props.location.search, { ignoreQueryPrefix: true }).s };

		this.state = {
			searchParams: null,
			count: null,
			query: { ...initialSearch, ez: initialSearch ? initialSearch['ez'] : {} },
			showAll: false,
		};
	}

	fetch() {
		const pathname = this.props.location.pathname;
		const searchString = qs.stringify({ s: { ...this.state.query } });

		this.props.history.push({
			pathname: pathname,
			search: searchString,
		});

		API.fetchSearchParams(this.state.query).then((res) => {
			this.setState({ searchParams: res.search_data });
			this.setState({ count: res.count });
		});
	}

	componentDidMount() {
		this.fetch();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.query !== this.state.query) {
			this.fetch();
		}
	}

	onChangeParam(searchKey, item) {
		if (searchKey === 'markeId') {
			const val = item.value ? item.value.toLowerCase().replace(/[\s]|[\-]/gi, '_') : undefined;
			this.setState({ query: { ...this.state.query, [searchKey]: val, modell: undefined } });
		} else if (searchKey === 'getriebeId') {
			this.setState({ query: { ...this.state.query, [searchKey]: item.key } });
		} else if (searchKey === 'von' || searchKey === 'bis') {
			this.setState({
				query: {
					...this.state.query,
					['ez']: {
						...this.state.query['ez'],
						[searchKey]: item.value,
					},
				},
			});
		} else {
			this.setState({ query: { ...this.state.query, [searchKey]: item.key } });
		}
	}

	render() {
		const { showAll, query, searchParams, count } = this.state;
		if (!searchParams) return null;

		return (
			<React.Fragment>
				<div className={`search ${showAll ? 'show-all' : ''}`}>
					{SEARCH_MAIN.filter((o, i) => (!showAll ? i <= 1 : true)).map((param, idx) => (
						<div key={idx + param.key} className={`search__${param.searchKey}`}>
							<div className='search__title'>{param.title}</div>

							<Picker
								title={param.title}
								initialLabel='Alle'
								value={query ? query[param.searchKey] : null}
								options={searchParams[param.key]}
								onChange={(selectedItem) => this.onChangeParam(param.searchKey, selectedItem)}
							/>
						</div>
					))}

					{SEARCH_SLIDER.filter((o, i) => (!showAll ? i === 0 : true)).map((param, idx) => (
						<div key={idx + param.searchKey} className={`search__${param.searchKey}`}>
							<div className='search__title'>{param.title}</div>
							<RangeSlider
								steps={param.steps}
								unit={param.unit}
								range={Object.keys(searchParams[param.key]).map(Number)}
								onChangeValue={(values) =>
									this.setState({ query: { ...query, [param.searchKey]: values } })
								}
								min={param.min}
								max={param.max}
								values={
									query && query[param.searchKey]
										? [query[param.searchKey].von, query[param.searchKey].bis]
										: []
								}
							/>
						</div>
					))}

					{showAll && (
						<div className='search__no-space'>
							<div className='search__title'>Erstzulassung</div>
							{SEARCH_EZ.map((param, idx) => (
								<Picker
									key={idx + param.key}
									title={param.title}
									initialLabel={param.title}
									value={query && query['ez'] ? query['ez'][param.searchKey] : null}
									options={searchParams[param.key]}
									onChange={(selectedItem) =>
										this.onChangeParam(param.searchKey, selectedItem)
									}
								/>
							))}
						</div>
					)}

					{showAll && (
						<div className='search__car-type'>
							<div className='search__title'>Fahrzeugtyp</div>
							<CarChooser
								value={query ? query['aufbau'] : 'all'}
								onChange={(item) => this.setState({ query: { ...query, aufbau: item } })}
							/>
						</div>
					)}

					{showAll && (
						<div className='search__additional'>
							<div className='search__title'>Weitere Filter</div>
							{SEARCH_ADDITIONAL.map((param, idx) => (
								<div key={idx + param.key}>
									<Picker
										title={param.title}
										initialLabel='Alle'
										options={searchParams[param.key]}
										onChange={(selectedItem) =>
											this.onChangeParam(param.searchKey, selectedItem)
										}
										alternativeVersion
										value={query ? query[param.searchKey] : null}
									/>
								</div>
							))}
						</div>
					)}

					<UsedCarButton
						addClass='search__submit'
						variant='default'
						title={`Suchen (${count})`}
						onClick={() => {
							this.props.onSearch({ s: query });
							this.props.resultListRef && this.props.resultListRef.current
								? this.props.resultListRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
								})
								: null;
						}}
					/>

					<div className='search__show-more'>
						<button onClick={() => this.setState((prev) => ({ showAll: !prev.showAll }))}>
							{!showAll ? 'Weitere Filter anzeigen' : 'Weitere Filter ausblenden'}
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(Search);

Search.propTypes = {
	onSearch: PropTypes.func.isRequired,
	resultListRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};
