import React from 'react';

import './checkbox.scss';
import elementSprite from '../element-sprite.svg';

export default ({ className, checked, style, onChange = function () { } }) => {
    const [active, setActive] = React.useState(checked === true);
    const classes = ['koenig-checkbox'];
    const viewBox = !active ? "52 0 26 26" : "78 26 26 26";
    const id = active ? "checkbox_checked" : "checkbox";

    if (className) {
        classes.push(className);
    }

    if (active === true) {
        classes.push('checked');
    }

    React.useEffect(() => {
        setActive(checked === true);
    }, [checked])

    return (
        <span
            className={classes.join(' ')}
            onClick={() => {
                const newVal = !active;
                setActive(newVal);
                onChange(newVal);
            }}
            style={style}
        >
            <svg viewBox={viewBox} preserveAspectRatio="xMidYMid meet">
                <use xlinkHref={elementSprite + '#' + id} />
            </svg>
        </span>
    );
};
