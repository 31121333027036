import React from 'react';
import KoenigSelect from '../../elements/KoenigSelect';
import ContactForm from '../ContactForm';
import Checkbox from '../../elements/Checkbox';
import KoenigButton from '../../elements/KoenigButton';
import API from '../../helper/api.helper';

import './styles.scss';

class ContactFormBranch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            branches: [],
            branch: (props.branch) ? props.branch : null,
            salutation: null,
            firstname: null,
            lastname: null,
            address: null,
            zip_code: null,
            location: null,
            email: null,
            phone: null,
            message: null,
            agb: false,
            inProgress: false,
            lms: { vendor: "carliner" }
        };
    }

    componentDidMount() {
        return API
            .GET('/branches')
            .then(branches => this.setState({ branches }))
            .catch(console.error);
    }

    async sendInquiry() {
        this.setState({ inProgress: true });

        const {
            onSuccessful = () => { },
            onFailure = console.error
        } = this.props;

        const developed_from = (this.props.developedFrom) ? this.props.developedFrom : 'Anfrage';
        const data = {
            ...this.state,
            developed_from
        };
        delete data.branches;
        delete data.inProgress;

        return API
            .POST('/inquiries', data)
            .then(onSuccessful)
            .catch(onFailure);
    }

    render() {
        const { branches, branch, inProgress } = this.state;
        const branchOptions = branches.map(b => (b && b.official_name)
            ? b.name + ' (' + b.official_name + ')'
            : b.name
        );

        return (
            <form className="contact-form-branch">
                <h2>{this.props.title ? this.props.title : "Anfrage"}</h2>

                <KoenigSelect
                    label="Wählen Sie Ihre Wunschfiliale"
                    options={branchOptions}
                    onChange={branch => this.setState({ branch })}
                    value={branch}
                />

                <ContactForm
                    onChange={(key, val) => this.setState({ [key]: val })} />

                <div className="cnt-msg">
                    <label>Nachricht</label>
                    <textarea
                        onChange={ev => this.setState({ message: ev.target.value })} />
                </div>

                <div className="cnt-agb-datenschutz">
                    <Checkbox onChange={agb => this.setState({ agb })} />

                    <span className="koenig-checkbox-text">
                        <a href="/agb/" target="_blank">AGB</a> & <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> habe ich gelesen
                    </span>
                    <span>(*) = Pflichtfelder</span>
                </div>

                <KoenigButton
                    text="Anfrage senden"
                    onClick={this.sendInquiry.bind(this)}
                    disabled={!this.isValid() || inProgress} />
            </form>
        );
    }

    isValid = () => {
        const allValuesAreSet = [
            'salutation', 'firstname', 'lastname',
            'address', 'zip_code', 'location', 'email', 'phone'
        ].reduce((valid, key) => valid && this.state[key] !== null && this.state[key].length > 0, true);
        return allValuesAreSet && this.state.agb === true;
    }
}

export default ContactFormBranch;