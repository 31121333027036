import './styles.scss';

import React from 'react';
import Icon from '../../../../components/Icon';
import Overlay from '../../../../components/Overlay';
import fbHelper from '../../../../helper/facebook.helper';

const CSS_CLASS = 'fc-vehicle-buttons';

class VehicleButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://data.twinner.com/displaydata/widget/api-viewer-360.js';
        script.name = 'twinner-script';

        document.head.appendChild(script);
    }

    handle360ButtonClick(url) {
        this.setState({ twinnerOverlayVisible: true }, () => {
            window.twinner_widget = new window.Twinner({
                element: document.getElementById('twinner-cnt'),
                language: "de",
                url: url
            });
        });
    }

    handleVideoButtonClick(videoId) {
        this.setState({ videoId, videoOverlayVisible: true });
        fbHelper.track('VideoButtonClicked', undefined, true);
    }

    handleBrochureButtonClick(href) {
        window.open(href, '_blank');
    }

    handleTwinnerClose() {
        this.setState({twinnerOverlayVisible: false});
        window.twinner_widget.destroy();
    }

    render() {
        return (
            <div className={CSS_CLASS}>
                {this.render360Button()}
                {this.renderVideoButton()}
                {this.renderBrochureButton()}
                {this.renderVideoOverlay()}
                {this.renderTwinnerOverlay()}
            </div>
        );
    }

    render360Button() {
        const { vehicle: { token360view } } = this.props;
        let handler = () => {}, className = 'disabled';

        if (token360view) {
            handler = this.handle360ButtonClick.bind(this, token360view);
            className = 'active';
        }

        return this.renderButton(
            'three_sixty_degrees',
            '360° Ansicht',
            handler,
            className
        );
    }

    renderVideoButton() {
        const { vehicle: { video_ids } } = this.props;
        let handler = () => {}, className = 'disabled';

        if (video_ids && video_ids.youtube) {
            handler = this.handleVideoButtonClick.bind(this, video_ids.youtube);
            className = 'active';
        }

        return this.renderButton(
            'video',
            'Video ansehen',
            handler,
            className
        );
    }

    renderBrochureButton() {
        let handler = () => {}, className = 'disabled';
        const { vehicle: { files = [] } } = this.props;
        const href = files.reduce((href, { name, src }) => {
            if (name === 'Broschüre' && href.length === 0) {
                return href.concat(src);
            }

            return href;
        }, '');

        if (href.length > 0) {
            handler = this.handleBrochureButtonClick.bind(this, href)
            className = 'active';
        }

        return this.renderButton(
            'book',
            'Broschüre ansehen (PDF)',
            handler,
            className
        );
    }

    renderButton(variant, text, handler = () => {}, className) {
        return (
            <span onClick={handler} className={CSS_CLASS + '--button ' + className}>
                <Icon variant={variant} />
                <span>{text}</span>
            </span>
        );
    }

    renderVideoOverlay() {
        const { videoId, videoOverlayVisible } = this.state;

        return videoOverlayVisible && (
            <Overlay onClose={() => this.setState({ videoOverlayVisible: false })}>
                <div className="video">
                    <div className="video--cnt">
                        <iframe
                            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                            allowFullScreen="allowfullscreen"
                        ></iframe>
                    </div>
                </div>
            </Overlay>
        );
    }

    renderTwinnerOverlay() {
        const { twinnerOverlayVisible } = this.state;

        return twinnerOverlayVisible && (
            <Overlay className={CSS_CLASS + '--twinner'} onClose={this.handleTwinnerClose.bind(this)}>
                <div id='twinner-cnt'></div>
            </Overlay>
        );
    }
}

export default VehicleButtons;