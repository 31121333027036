import React from 'react';
import CommonPage from '../CommonPage';
import { Helmet } from 'react-helmet';
import SimpleContent from '../../components/SimpleContent';
import API from '../../helper/api.helper';
import API_USED_CAR from '../../helper/api.usedCars.helper';
import SortBar from '../GebrauchtwagenPage/components/SortBar';
import KoenigButton from '../../elements/KoenigButton';
import ResultItem from '../GebrauchtwagenPage/components/ResultItem';
import LoadingSpinner from '../../components/LoadingSpinner';
import Breadcrumb from '../../components/Breadcrumb';
import Pagination from '../GebrauchtwagenPage/components/Pagination';
import ReactHtmlParser from 'react-html-parser';
import GwModelNavigation from './components/GwModelNavigation';
import AutoUncle from '../../components/AutoUncle';
import { BRAND_REG_EXP } from '../../helper/constant.helper';

const CSS_CLASS = 'page-gebrauchtwagen-marke';

class GebrauchtwagenMarkePage extends React.Component {
    constructor(props) {
        super(props);

        let models, pageData, brand;

        if (props.staticContext && props.staticContext.initialData) {
            [models, pageData, brand] = props.staticContext.initialData;
        } else {
            [models, pageData, brand] = window.__initialData__;
            delete window.__initialData__;
        }

        this.state = {
            models,
            ...pageData,
            brand,
            brandLabel: brand.charAt(0).toUpperCase() + brand.slice(1),
            isLoading: true,
            data: { count: 0 },
            query: {
                page: 1,
                orderBy: { sort: "preis", order: "ASC" }
            }
        }
    }

    static requestInitialData(url) {
        const regexp = new RegExp(`\/gebrauchtwagen\/automarken\/(${BRAND_REG_EXP})`, 'g');
        const match = regexp.exec(url);
        const brand = match !== null && match[1] ? match[1] : null;

        if (brand !== null) {
            return Promise.all([
                API
                    .GET(`/models/?brand=${brand}&sortby=order`)
                    .catch(console.error),
                API
                    .GET(`/pages/gw_${brand}`)
                    .catch(console.error),
                brand
            ]);
        }

        return [];
    }

    componentDidMount() {
        this.fetchVehicles();
    }

    componentDidUpdate(_, prevState) {
        if (prevState.query !== this.state.query) {
            this.fetchVehicles();
        }
    }

    fetchVehicles() {
        const { brand, query: { page, orderBy } } = this.state;

        API_USED_CAR.fetchVehicles({ s: { markeId: brand.replace('-', '_') }, page, orderBy })
            .then((res) => {
                this.setState({ data: res });
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }


    render() {
        const {
            brandLabel, models = [], isLoading,
            seo_content, data, query, headline, title, description
        } = this.state;
        const { vehicles, pages, page } = data;
        const crumbs = [
            { label: 'Start', path: '/' },
            { label: 'Automarken', path: '/gebrauchtwagen/automarken/' },
            { label: brandLabel, current_page: true }
        ];

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                <Breadcrumb crumbs={crumbs} />

                {models.length > 0 && this.renderModelNavigation(models)}

                <h1>{ReactHtmlParser(headline)}</h1>

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                        <React.Fragment>
                            <SortBar
                                currentOrderBy={query.orderBy}
                                onChange={(orderBy) => this.setState({ query: { ...query, orderBy } })}
                            />

                            <div ref={this.resultListRef} className='result-list'>
                                {vehicles.map((car) => (
                                    <ResultItem key={car.wgnr} vehicle={car} />
                                ))}
                            </div>

                            <div className={CSS_CLASS + '--footer'}>
                                <KoenigButton
                                    text="Nach oben"
                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                    icon="black_chevron_down" />
                                {pages && (
                                    <Pagination
                                        pages={pages}
                                        currentPage={page}
                                        onSelectPage={(p) => this.setState({ query: { ...query, page: p } })}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}

                <SimpleContent content={seo_content} headlineLevel={2} />
                <AutoUncle />
            </CommonPage>
        );
    }

    renderModelNavigation() {
        const { models, brand, brandLabel } = this.state;

        return (
            <GwModelNavigation
                brandLabel={brandLabel}
                models={models}
                brand={brand}
                activeLabel={brandLabel} />
        );
    }
}

export default GebrauchtwagenMarkePage;
