import React from 'react';

import categorySprite from '../../assets/images/category-sprite.svg';
import './styles.scss';

export const ALL_VEHICLES = 'all_items';
export const ALL_SCOOTERS = 'all_scooter';
export const ALL_APES = 'all_apes';

const sprites = [
    { id: ALL_VEHICLES, label: 'Alle anzeigen', x: 173, y: 0, type: 0 },
    { id: 'klein-kompakt', label: 'Klein & Kompakt', y: 0, type: 1 },
    { id: 'kombi', label: 'Kombi', y: 82, type: 1 },
    { id: 'sport-coupe', label: 'Sport / Coupé', y: 164, type: 1 },
    { id: 'suv', label: 'SUV', y: 246, type: 1 },
    { id: 'van', label: 'Van', y: 328, type: 1 },
    { id: 'transporter', label: 'Transport', y: 410, type: 1 },
    { id: 'limousine', label: 'Limousine', y: 574, type: 1 },
    { id: 'elektro', label: 'Elektro', y: 492, type: 1 },
    { id: '50-ccm', label: '50 ccm', x: 173, y: 82, type: 2 },
    { id: '125-ccm', label: '125 ccm', x: 173, y: 574, type: 2 },
    { id: '300-ccm', label: '300 ccm', x: 173, y: 656, type: 2 },
    { id: 'elektro-roller', label: 'Elektro', x: 173, y: 328, type: 2 },
    { id: 'ape-kasten', label: 'Kasten', x: 173, y: 246, type: 3 },
    { id: 'ape-cross', label: 'Cross', y: 738, type: 3 },
    { id: 'ape-pritsche', label: 'Pritsche', x: 173, y: 738, type: 3 }
];

export default ({
    activeItem,
    onChange = ()=>{},
    variant = 'vehicles',
    possibleCategories = []
}) => {
    const [selectedItem, setActiveItem] = React.useState(activeItem || null);
    let variantType;

    switch(variant) {
        case 'scooters':
            sprites[0].id = ALL_SCOOTERS;
            sprites[0].y = 164;
            sprites[0].x = 173;
            variantType = 2;
            break;
        case 'apes':
            sprites[0].id = ALL_APES;
            sprites[0].y = 656;
            sprites[0].x = 0;
            variantType = 3;
            break;
        default:
            sprites[0].id = ALL_VEHICLES;
            sprites[0].y = 0;
            sprites[0].x = 173;
            variantType = 1;
    }

    return (
        <div id="category_chooser">
            {
                sprites
                    .filter(s => s.type === 0 || s.type === variantType)
                    .map(({ id, label, x = 0, y, type }) => {
                        const classes = ['category-item'];
                        const isActiveItem = id === selectedItem;
                        const isActiveAllScooterItem = id === ALL_SCOOTERS && selectedItem === ALL_VEHICLES;
                        const isActiveAllApes = id === ALL_APES && selectedItem === ALL_VEHICLES;
                        let clickFunc = handleClick;

                        if (isActiveItem || isActiveAllScooterItem || isActiveAllApes) {
                            classes.push('active');
                        }

                        if (type !== 0 && possibleCategories.indexOf(id) === -1) {
                            classes.push('disabled');
                            clickFunc = function () { };
                        }

                        return (
                            <div
                                key={label + '-' + y}
                                className={classes.join(' ')}
                                onClick={() => clickFunc(id, setActiveItem, onChange)}
                            >
                                <svg viewBox={`${x} ${y} 171 80`} className="icon" style={{ maxHeight: '40px' }}>
                                    <use xlinkHref={`${categorySprite}#${normalizeIdForSVG(id)}`} />
                                </svg>
                                <span className="label">{label}</span>
                            </div>
                        )
                    })
            }
        </div>
    )
};

function handleClick(id, stateFunc, changeFunc) {
    stateFunc(id);
    changeFunc(id);
}

function normalizeIdForSVG(id) {
    return id
        .replace(/(50)-ccm/ig, 'scooter');
}