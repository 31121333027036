import './styles.scss';
import koeniglogo from '../../../../assets/images/koenig-logo-gelb.svg';

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { config } from './config';
import BrandLogo from '../../../../elements/BrandLogo';
import { idMap } from './idMap.js';
import Icon from '../../../../components/Icon';
import OpenTimesBlock from '../OpenTimesBlock';
import api from '../../../../helper/api.helper';

const brandMap = {
    'Fiat': 'fiat_abarth_prof_short',
    'Vespa': 'scooter_triple'
};
const CSS_PREFIX = 'fc-woosmap';
let branches = [];
let brands = [];

class Woosmap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const script = document.createElement('script');
        script.src = '/assets/woosmap_webapp.js';
        script.type = 'text/javascript';
        branches = await api
            .GET('/branches');
        brands = await api
            .GET('/brands');

        script.onload = this.initialize.bind(this);

        document.head.appendChild(script);
    }

    initialize() {
        const { brand } = this.props;

        if (brand) {
            this.setPreselectedBrand(brand);
        }

        this.setGoogleApiKey()

        const webapp = new WebApp('store_locator_map', window.WOOSMAP_KEY);
        webapp.setConf(config);
        this.addStoreOverview(webapp);
        this.addStoreDetail(webapp);
        this.addFilterPanel(webapp);
        this.addFilter(webapp);

        const isMobile = document.querySelector('body').clientWidth < 450;
        webapp.render(isMobile);
    }

    setPreselectedBrand(brand) {
        const choices = config.filters.filters[0].choices;

        config.filters.filters[0].choices = choices.map(choice => {
            const comparable = choice.key.toLowerCase().replace(' ', '_');

            if (comparable === brand) {
                choice.selected = true;
            }

            return choice;
        });
    }

    setGoogleApiKey() {
        config.maps.apiKey = window.GOOGLE_API_KEY;
    }

    render() {
        return <div id="store_locator_map"></div>;
    }

    addStoreOverview(webapp) {
        webapp.setSummaryStoreRenderer(function (store) {
            console.log(store);
            const storeOverview = document.createElement('div');
            storeOverview.id = 'storeOverview';
            storeOverview.innerHTML = ReactDOMServer.renderToStaticMarkup(renderStore(store));

            return storeOverview;
        });
    }

    addStoreDetail(webapp) {
        webapp.setFullStoreRenderer((store) => {

            const storeDetail = document.createElement('div');
            storeDetail.id = 'storeDetail';
            storeDetail.innerHTML = ReactDOMServer.renderToStaticMarkup(renderSingleStore(store));

            return storeDetail;
        });
    }

    addFilterPanel(webapp) {
        webapp.setFilterPanelRenderer(function (title, children) {
            const divPanel = document.createElement('div');
            const innerDiv = document.createElement('div');

            divPanel.className = CSS_PREFIX + '--filter-panel';
            divPanel.innerHTML = ReactDOMServer.renderToStaticMarkup(renderFilterPanel(title, children));
            children.forEach(el => {
                el.classList.add(CSS_PREFIX + '--filter-item--' + title.toLowerCase());
                innerDiv.appendChild(el);
            });
            divPanel.appendChild(innerDiv);

            return divPanel;
        });
    }

    addFilter(webapp) {
        webapp.setFilterRenderer(function (key, label, selected) {
            const divFilterItem = document.createElement('div');
            divFilterItem.className = selected ? CSS_PREFIX + '--active' : '';
            divFilterItem.innerHTML = ReactDOMServer.renderToStaticMarkup(renderFilterItem(key, label, selected));

            return divFilterItem;
        });
    }
}

function renderFilterItem(key, label) {
    const brandId = brands.reduce((res, { _id, label }) => {
        if (res) {
            return res;
        }

        if (key === 'Fiat') {
            return brandMap[key];
        }

        if (key === label) {
            return _id
        }

        return res;
    }, false);

    if (!brandId) {
        return <span>{label}</span>;
    }

    return (
        <a>
            <BrandLogo brandId={brandId} />
        </a>
    );
}

function renderFilterPanel(title) {
    return (
        <div className={CSS_PREFIX + '--filter-group'}>{title}</div>
    );
}

function renderStore(store) {
    return (
        <div className={CSS_PREFIX + '--store-info'}>
            {renderLeftColumn(store)}
            {renderStoreDetails(store)}
        </div>
    );
}

function renderLeftColumn(store) {
    return (
        <div>
            <img src={koeniglogo} alt="Autohaus König" />
            <span>{store.properties.distance_text}</span>
        </div>
    );
}

function renderStoreDetails(store) {
    return (
        <div className={CSS_PREFIX + '--store-details'}>
            {renderBrandLogos(store)}
            {renderStoreAddress(store)}
            {renderOpeningHours(store, CSS_PREFIX + '--full-width-svg')}
            {renderPhone(store, CSS_PREFIX + '--full-width-svg')}
            {renderDetailViewButton(store)}
        </div>
    );
}

function renderBrandLogos(store) {
    return (
        <div className="brand-logos">
            {
                store.properties.tags.map((el, idx) => {
                    const brandId = brands.reduce((res, { _id, label }) => {
                        if (el === label) {
                            return _id
                        }

                        return res;
                    }, false);

                    if (brandId) {
                        return <BrandLogo key={`${brandId}_${idx}`} brandId={brandId} />
                    }
                })
            }
        </div>
    );
}

function renderStoreAddress(store) {
    return (
        <span className='store-address'>
            {store.properties.address.lines}<br />
            {store.properties.address.zipcode} {store.properties.address.city}
        </span>
    )
}

function renderOpeningHours(store, cssClass = '') {
    if (store.properties.open && store.properties.open.open_now) {
        return (
            <div className={CSS_PREFIX + '--list-item opening-hours ' + cssClass}>
                <Icon variant="clock" />
                <span>Heute: {store.properties.open.open_hours[0].start} - {store.properties.open.open_hours[0].end}</span>
            </div>
        );
    }

    return (
        <div className={CSS_PREFIX + '--list-item'}><span></span><span>Momentan geschlossen!</span></div>
    );
}

function renderPhone(store, cssClass = '') {
    return (
        <div className={CSS_PREFIX + '--list-item phone-number ' + cssClass}>
            <Icon variant="phone" />
            <span>{store.properties.contact.phone}</span>
        </div>
    );
}

function renderDetailViewButton(store) {
    return (
        <a className={CSS_PREFIX + '--button'} href={store.properties.contact.website}>zur Filialseite</a>
    );
}

function renderSingleStore(store) {
    const branch = getBranch(store);

    return (
        <div className={CSS_PREFIX + '--single-store-info'}>
            <div>
                {renderBrandLogos(store)}
                {renderStoreName(store)}
                {renderOpeningHint(store)}
            </div>
            <div>
                {renderOpeningHours(store)}
                {renderPhone(store)}
                {renderAddress(store)}
                {renderAppointment(store)}
                {branch && renderOpening('Verkauf', branch.opening_times.sale)}
                {branch && renderOpening('Service', branch.opening_times.service)}
            </div>
        </div>
    );
}

function getBranch(store) {
    const store_id = idMap[store.properties.store_id];

    for (let branch of branches) {
        if (branch._id === store_id) {
            return branch;
        }
    }
}

function renderStoreName(store) {
    return (
        <div className={CSS_PREFIX + '--list-item store-name'}>
            <span>
                <img src={koeniglogo} alt="Autohaus König" />
            </span>
            <span>
                <span><b>{store.properties.address.city}</b></span>
                <span>{store.properties.name}</span>
                <span className={CSS_PREFIX + '--distance'}>{store.properties.distance_text}</span>
            </span>
        </div>
    );
}

function renderAddress(store) {
    return (
        <div className={CSS_PREFIX + '--list-item'}>
            <Icon variant="location_pin" />
            {renderStoreAddress(store)}
        </div>
    );
}

function renderAppointment() {
    return (
        <div className={CSS_PREFIX + '--list-item'}>
            <Icon variant="workshop" />
            <span>
                <a className={CSS_PREFIX + '--button'} href="/werkstatt/terminbuchung/">Werkstatttermin Buchen</a>
            </span>
        </div>
    );
}

function renderOpening(department, openingTime) {
    return (
        <React.Fragment>
            <hr />
            <div className={CSS_PREFIX + '--list-item'}>
                <span><b>{department}</b></span>
                <span className={CSS_PREFIX + '--column'}>
                <OpenTimesBlock openingTimes={openingTime} />
            </span>
            </div>
        </React.Fragment>
    );
}

function renderOpeningHint(store) {
    let openMsg = "Leider geschlossen";

    if (store.properties.open.open_now) {
        openMsg = "Jetzt geöffnet";
    }

    return (
        <div className={CSS_PREFIX + '--list-item opening-hint'}><span></span><span>{openMsg}</span></div>
    )
}


export default Woosmap;