import React from 'react';
import Helmet from 'react-helmet';
import CommonPage from '../../CommonPage';
import Breadcrumb from '../../../components/Breadcrumb';
import API from "../../../helper/api.helper";
import VehicleBox from '../../../components/VehicleBox';
import SimpleContent from '../../../components/SimpleContent';
import SectionHeadline from '../../../components/SectionHeadline';
import CategoryChooser, { ALL_SCOOTERS, ALL_VEHICLES } from '../../../components/CategoryChooser';
import vehicleSorter from '../../../helper/vehicle.sorter';

class AllBrandsOffersPage extends React.Component {
    constructor(props) {
        super(props);

        let data = [];

        if (props.staticContext && props.staticContext.initialData) {
            data = props.staticContext.initialData;
        } else {
            /* istanbul ignore else */
            if (typeof window !== 'undefined') {
                data = window.__initialData__;
                delete window.__initialData__;
            }
        }

        const [page, vehicles, vehicleType] = data || [];

        this.state = {
            page, vehicles, vehicleType,
            catKey: vehicleType === 'vehicles' ? ALL_VEHICLES : ALL_SCOOTERS,
            possibleCategories: this.getPossibleCategoriesBy(vehicles)
        };
    }

    static requestInitialData(url) {
        const matches = /^\/(neuwagen|roller)\/$/g.exec(url);

        if (matches && matches[1]) {
            const pageData = API
                .GET(`/pages/${matches[1]}`)
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            const vehicleData = API
                .GET(`/vehicles?filtered=${matches[1]}&sortby=preis`)
                .catch(/* istanbul ignore next */ err => {
                    console.error(err);
                    return [];
                });

            const vehicleType = matches[1] === 'neuwagen' ? 'vehicles' : 'scooters';

            return Promise.all([pageData, vehicleData, vehicleType]);
        }
    }

    getPossibleCategoriesBy(vehicles = {}) {
        const { private: consumer } = vehicles;
        return []
            .concat(consumer)
            .filter(el => typeof el !== 'undefined' && el !== null)
            .reduce((cats, v) => cats.concat(v.categories), [])
            .filter((c, idx, cats) => cats.indexOf(c) === idx);
    }

    render() {
        const page = this.state.page;
        const crumbs = [
            { label: 'Start', path: '/' },
            {
                label: this.state.vehicleType === 'vehicles'
                    ? 'Neuwagen'
                    : 'Roller',
                current_page: true
            },
        ];

        if (!page || page.length === 0) {
            return (
                <CommonPage className="page-all-brands-offers">
                    <Helmet>
                        <title>Autohaus König</title>
                    </Helmet>

                    <p>Es konnten keine Daten geladen werden.</p>

                </CommonPage>
            );
        } else {
            const { vehicles = {}, catKey, possibleCategories } = this.state;

            return (
                <CommonPage className="page-all-brands-offers">
                    <Helmet>
                        <title>{page.title}</title>
                    </Helmet>

                    <Breadcrumb crumbs={crumbs} />

                    <h1>{page.headline}</h1>

                    <CategoryChooser
                        activeItem={catKey}
                        possibleCategories={possibleCategories}
                        variant={this.state.vehicleType}
                        onChange={catKey => this.setState({ catKey })} />

                    {this.renderVehicles(vehicles.private, 'cnt_vehicles_private')}

                    <SectionHeadline text="Fahrzeuge für Gewerbetreibende" />

                    {this.renderVehicles(vehicles.business, 'cnt_vehicles_business')}

                    <SimpleContent content={page.content} />

                    <p className="page-all-brands-offers--co2info">** Weitere Informationen zum offiziellen
                        Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen
                        können dem‚ Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch
                        neuer Personenkraftwagen entnommen werden, der an allen Verkaufsstellen, bei der Deutschen
                        Automobil Treuhand GmbH (DAT), Hellmuth-Hirth-Str. 1, 73760 Ostfildern-Scharnhausen, und
                        unter <a href="http://www.dat.de/co2" target="_blank">www.dat.de/co2</a> unentgeltlich
                        erhältlich ist.</p>
                </CommonPage>
            );
        }
    }

    renderVehicles(vehicles = [], id) {
        vehicles = vehicleSorter.sort(vehicles);

        if (vehicles.length > 0) {
            const { catKey } = this.state;

            return (
                <div id={id}>
                    {
                        vehicles
                            .filter(({ categories }) => catKey === ALL_VEHICLES || catKey === ALL_SCOOTERS
                                || categories.indexOf(catKey) !== -1
                            )
                            .map((vehicle, idx) => {
                                const { payments = {} } = vehicle;

                                return (
                                    <div key={"box-wrapper-" + idx + "-" + vehicle.uid} className="box-wrapper">
                                        <VehicleBox vehicle={vehicle} />
                                    </div>
                                )
                            })
                    }
                </div>
            )
        }
    }
}

export default AllBrandsOffersPage;